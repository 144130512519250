import { Fabric8Tool } from './tool.model';

export enum Fabric8SmartObjectType {
  distributedHoles = 'distributedHoles',
  equallySpacedHoles = 'equallySpacedHoles',
}

export interface Fabric8SmartObjectData {
  // sourceElementID: string;
  type: Fabric8SmartObjectType;
  parameters:
    | Fabric8EquallySpacedHolesParameters
    | Fabric8DistributedHolesParameters;
}

export interface Fabric8EquallySpacedHolesParameters {
  drillTool: Fabric8Tool;
  distance: number;
}

export interface Fabric8DistributedHolesParameters {
  drillTool: Fabric8Tool;
  count: number;
}
