<div
  class="wbox scrolly animbox"
  [ngClass]="collapsed ? 'collapsed' : 'not-collapsed'"
>
  <div id="container">
    <div id="header">
      <!-- <img id="icon" src="assets/logo.svg"/> -->
      <div
        (click)="reloadApp()"
        id="icon"
        [inlineSVG]="'logo-smartworks2.svg'"
      ></div>
      <div class="spacer"></div>
      <mat-icon
        [inline]="true"
        class="pin-button"
        (click)="collapsed = !collapsed"
        >push_pin</mat-icon
      >
    </div>

    <ng-template [ngIf]="rootGroup" [ngIfElse]="loading">
      <div class="center">
        <div class="btn-primary" (click)="onClickNewProject()">
          <div class="icon" [inlineSVG]="'icon/plus.svg'"></div>
          {{ "BROWSE.NEW_PROJECT_BUTTON" | translate }}
        </div>

        <div class="btn-primary" (click)="onClickNewGroup()">
          <div class="icon" [inlineSVG]="'icon/group.svg'"></div>
          {{ "BROWSE.NEW_GROUP_BUTTON" | translate }}
        </div>
      </div>

      <div swTreeGroup class="groups">
        <sw-projects-group
          [indentLevel]="-1"
          [parent]="undefined"
          [(group)]="rootGroup"
        >
        </sw-projects-group>
        <!-- (groupChange)="updateRootGroup(g)"> -->
      </div>
      <div class="spacer" (click)="deselectGroup()"></div>
    </ng-template>

    <ng-template #loading>
      <div class="center flex grow">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </ng-template>

    <div class="center grey">
      <small>smartworks v {{ version }}</small>
    </div>
  </div>
</div>
