import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Fabric8ToolbarService } from '../toolbar/fabric8-toolbar.service';

@Component({
  selector: 'sw-toolset',
  templateUrl: './toolset.component.html',
  styleUrls: ['./toolset.component.scss'],
})
export class ToolsetComponent {
  constructor(
    private router: Router,
    public toolbarService: Fabric8ToolbarService
  ) {}

  close(): void {
    this.router.navigate(['fabric8']);
  }
}
