<ng-template #loadingOrError>
  <sw-message
    *ngIf="errorMessage"
    [message]="errorMessage"
    icon="warning"
    actionText="{{ 'GLOBAL.RETRY' | translate }}"
    (action)="reloadProject()"
  >
  </sw-message>
  <div *ngIf="!errorMessage" class="center spacer">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div class="wbox scrolly flex column">
  <ng-template [ngIf]="project" [ngIfElse]="loadingOrError">
    <section>
      <div class="row">
        <sw-editable
          id="project-name"
          class="title"
          [(value)]="project.name"
          [alwaysEditing]="true"
        ></sw-editable>
      </div>
    </section>
    <hr />
    <section id="plugins">
      <div class="row heading">
        <div class="small-title">
          {{ "PLUGINS.SMARTWORKS_PLUGINS_TITLE" | translate }}
        </div>
      </div>

      <ng-container *ngFor="let p of plugins">
        <ng-container *ngIf="p.available">
          <ng-template [ngIf]="isActive(p.id)" [ngIfElse]="inactive">
            <div class="active plugin-box row">
              <div class="icon {{ p.id }}"></div>
              <div class="text">
                <div class="name">{{ p.name }}</div>
                <div
                  *ngIf="p.library === false"
                  class="template-name"
                  [innerHtml]="templateName(p.id)"
                ></div>
              </div>
              <div class="spacer"></div>
              <div
                class="btn-primary destructive right-anim"
                (click)="removePluginData(p)"
              >
                <div>{{ "PLUGINS.REMOVE_BUTTON" | translate }}</div>
              </div>
            </div>
          </ng-template>

          <ng-template #inactive>
            <!-- <div> -->
            <div
              class="plugin-box"
              [ngClass]="{ 'selecting-template': p.id === addingPluginID }"
            >
              <div class="row">
                <div class="icon {{ p.id }}"></div>
                <div class="name">{{ p.name }}</div>
                <div class="spacer"></div>
                <ng-template
                  [ngIf]="p.id !== addingPluginID"
                  [ngIfElse]="cancelAdding"
                >
                  <div
                    *ngIf="['MM_14d_'].includes(p.id) === false"
                    class="btn-primary right-anim"
                    (click)="fetchTemplates(p)"
                  >
                    <div>{{ "PLUGINS.ADD_BUTTON" | translate }}</div>
                  </div>
                  <div
                    *ngIf="p.id === 'MM_14d_'"
                    class="btn-primary right-anim"
                    (click)="openModelManager()"
                  >
                    <div>{{ "PLUGINS.OPEN_BUTTON" | translate }}</div>
                  </div>
                  <!-- <div *ngIf="p.id === 'fabric8'" class="btn-primary right-anim"
                                             (click)="openFabric8()">
                                            <div>{{ "PLUGINS.OPEN_BUTTON" | translate}}</div>
                                        </div> -->
                </ng-template>
                <ng-template #cancelAdding>
                  <div class="btn-primary grey" (click)="cancelAddingPlugin()">
                    <div>{{ "GLOBAL.CANCEL" | translate }}</div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="p.id === addingPluginID" class="templates-list">
                <div *ngIf="p.library === false" class="small-title info">
                  {{ "PLUGINS.SELECT_TEMPLATE" | translate }}
                </div>
                <div *ngIf="p.templates.length === 0">
                  <mat-spinner [diameter]="40"></mat-spinner>
                </div>
                <div *ngFor="let t of p.templates" class="row template">
                  <div class="template-name" (click)="selectTemplate(t)">
                    {{ t.templateName }}
                  </div>
                  <sw-dots-menu itemID="0" *ngIf="t.templateType === 'user'">
                    <div
                      class="hover-btn red"
                      [inlineSVG]="'icon/delete.svg'"
                      (click)="deleteTemplate(t, p)"
                      matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
                      matTooltipPosition="above"
                    ></div>
                  </sw-dots-menu>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </ng-template>
        </ng-container>
        <!-- isAvailable end -->

        <div class="disabled plugin-box row" *ngIf="!p.available">
          <div class="icon {{ p.id }}"></div>
          <div class="name">{{ p.name }}</div>
          <div class="spacer"></div>
          <div class="btn-primary right-anim" (click)="tryPlugin(p.id)">
            <div>{{ "PLUGINS.TRY_BUTTON" | translate }}</div>
          </div>
        </div>
      </ng-container>
    </section>

    <div class="spacer"><br /></div>

    <div class="row">
      <div class="btn-primary grey" (click)="onCancel()">
        <div>{{ "GLOBAL.CANCEL" | translate }}</div>
      </div>
      <div class="spacer"></div>
      <div class="btn-primary save" (click)="onDone()">
        <div>{{ "GLOBAL.DONE" | translate }}</div>
      </div>
    </div>
  </ng-template>
</div>
