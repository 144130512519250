import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { Project } from 'src/app/model/project.model';
import { ProjectsGroup } from 'src/app/model/projects-group.model';
import { ProjectGroupsManagerService } from 'src/app/modules/project-browser/groups-manager.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { SwConfirmDialogComponent } from 'src/app/shared/sw-confirm-dialog';
import { wDragDropListEvent } from 'src/app/modules/wTree/tree-node.model';
import { DropHelperService } from '../drop-helper.service';

@Component({
  selector: 'sw-project-row',
  templateUrl: './project-row.component.html',
  styleUrls: ['./project-row.component.scss'],
})
export class ProjectRowComponent implements OnInit, OnDestroy {
  constructor(
    private groupsManagerService: ProjectGroupsManagerService,
    private projectService: ProjectService,
    private dropHelper: DropHelperService,
    private uiService: UIService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  @Input() parent: ProjectsGroup;
  @Output() parentChange: EventEmitter<ProjectsGroup> = new EventEmitter();

  @Input() project: Project;

  @Input() indentLevel = 0;
  isHidden = false;

  @Input() parentIsDragged = false;
  dragging = false;
  isDragging(): boolean {
    return this.dragging || this.parentIsDragged;
  }

  dragStarted(event) {
    this.dragging = true;
  }

  dragReleased(event) {
    this.dragging = false;
  }

  dropped(event: wDragDropListEvent) {
    const groupsToUpdate = this.dropHelper.processDrop(event);
    this.updateGroups(groupsToUpdate);
  }

  isActive: boolean = false;

  activeProjectSubscriber: Subscription;
  ngOnInit(): void {
    this.activeProjectSubscriber = this.projectService.project.subscribe(
      (p) => {
        if ((Boolean(p) && Boolean(this.project)) !== true) {
          return false;
        }
        this.isActive = this.project.id == p.id;
      }
    );
  }

  ngOnDestroy(): void {
    this.activeProjectSubscriber.unsubscribe();
  }

  private async updateGroups(groupsToUpdate: ProjectsGroup[]) {
    this.uiService.disableUI(true);
    try {
      await this.groupsManagerService.updateGroups(groupsToUpdate);
    } catch {
      // TODO: restore previous state or retry API call
      console.error('updating groups API call failed');
    }
    this.parentChange.emit(this.parent);
    this.uiService.enableUI();
  }

  async onClickProjectDuplicate() {
    this.uiService.disableUI(true);
    try {
      const duplicatedProject = await this.projectService.duplicateProject(
        this.project
      );
      const oldIndex = this.parent.projects.indexOf(this.project);
      this.parent.projects.splice(oldIndex + 1, 0, duplicatedProject);
      await this.groupsManagerService.updateGroup(this.parent);
    } catch (err) {
      this.uiService.showErrors(err);
    }
    this.uiService.enableUI();
  }

  onClickProjectSettings() {
    this.projectService.openSettings(this.project.id);
  }

  onClickProjectShare() {
    this.projectService.openSharing(this.project.id);
  }

  onClickProject() {
    this.groupsManagerService.selectGroup(undefined);
    this.projectService.edit(this.project.id);
  }

  async projectRemove() {
    this.uiService.disableUI(true);
    try {
      await this.projectService.deleteProject(this.project.id);
      this.parent.projects = this.parent.projects.filter(
        (p) => p.id !== this.project.id
      );
      await this.groupsManagerService.updateGroup(this.parent);
    } catch (err) {
      this.uiService.showErrors(err);
    }
    this.uiService.enableUI();
  }

  onClickProjectRemove() {
    const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
      width: '300px',
      data: {
        title: this.translate.instant('DELETE_PROJECT.TITLE'),
        prompt: this.translate.instant('DELETE_PROJECT.MESSAGE', this.project),
        cancelButton: this.translate.instant('GLOBAL.CANCEL'),
        actionButton: this.translate.instant('GLOBAL.DELETE'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
        this.projectRemove();
      } else {
        // closed
      }
    });
  }
}
