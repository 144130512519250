<h1 mat-dialog-title>
  {{ "MODEL_MANAGER.NEW_HUB_TITLE" | translate }}
</h1>

<div mat-dialog-content>
  <section>
    <div class="row">
      <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div>

      <mat-form-field>
        <mat-label>{{
          "MODEL_MANAGER.NEW_HUB_NAME_LABEL" | translate
        }}</mat-label>
        <input matInput [(ngModel)]="data.hubName" cdkFocusInitial />
      </mat-form-field>
    </div>
  </section>

  <section>
    <div class="small-title">
      {{ "MODEL_MANAGER.NEW_HUB_2D_DECOY" | translate }}
    </div>

    <sw-segmented-buttons
      [buttons]="['no', 'yes']"
      [allowMultipleSelection]="false"
      [allowEmptySelection]="false"
      [selection]="['yes']"
      (selectionChanged)="data.create2d = $event[0] == 'yes'"
    >
    </sw-segmented-buttons>
  </section>
  <hr />
  <section>
    <div class="small-title">
      {{ "MODEL_MANAGER.NEW_HUB_PROXY" | translate }}
    </div>
    <sw-segmented-buttons
      [buttons]="proxyOptions"
      [allowMultipleSelection]="false"
      [allowEmptySelection]="false"
      [selection]="['medium']"
      (selectionChanged)="proxyOptionChanged($event[0])"
    >
    </sw-segmented-buttons>
  </section>
  <section>
    <div class="row" *ngIf="sketchupSelectionError !== null">
      <ng-template [ngIf]="sketchupSelectionError === 'no-selection'">
        <mat-icon>highlight_alt</mat-icon>
        <!-- <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div> -->
        {{ "MODEL_MANAGER.NEW_HUB_NO_SELECTION_ERROR" | translate }}
      </ng-template>
      <ng-template
        [ngIf]="sketchupSelectionError === 'invalid-selection-part-of-hub'"
      >
        <mat-icon>highlight_alt</mat-icon>
        <!-- <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div> -->
        {{ "MODEL_MANAGER.NEW_HUB_INVALID_SELECTION_ERROR" | translate }}
      </ng-template>
      <ng-template [ngIf]="sketchupSelectionError === 'has-hub-child'">
        <mat-icon>highlight_alt</mat-icon>
        <!-- <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div> -->
        {{ "MODEL_MANAGER.NEW_HUB_HAS_HUB_CHILD_ERROR" | translate }}
      </ng-template>
      <ng-template [ngIf]="sketchupSelectionError === 'has-hub-parent'">
        <mat-icon>highlight_alt</mat-icon>
        <!-- <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div> -->
        {{ "MODEL_MANAGER.NEW_HUB_HAS_HUB_PARENT_ERROR" | translate }}
      </ng-template>
      <ng-template
        [ngIf]="sketchupSelectionError === 'multiple-components-selected'"
      >
        <mat-icon>highlight_alt</mat-icon>
        <!-- <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div> -->
        {{ "MODEL_MANAGER.NEW_HUB_MULTIPLE_SELECTION_ERROR" | translate }}
      </ng-template>
      <!-- <ng-template [ngIf]="sketchupSelectionError === null">
        <mat-icon>highlight_alt</mat-icon>
        {{ 'MODEL_MANAGER.NEW_HUB_SELECTION_OK' | translate }}
      </ng-template> -->
    </div>
    <div class="row" *ngIf="validName() === false">
      <ng-template [ngIf]="duplicateName() === true">
        <mat-icon>highlight_alt</mat-icon>
        {{ "MODEL_MANAGER.NEW_HUB_DUPLICATE_NAME_ERROR" | translate }}
      </ng-template>
      <ng-template [ngIf]="emptyName() === true">
        <mat-icon>highlight_alt</mat-icon>
        {{ "MODEL_MANAGER.NEW_HUB_EMPTY_NAME_ERROR" | translate }}
      </ng-template>
    </div>

    <div class="row" *ngIf="largeModel">
      <mat-icon>warning</mat-icon>
      {{ "MODEL_MANAGER.LARGE_MODEL" | translate }}
    </div>
  </section>
</div>
<div mat-dialog-actions>
  <button mat-button class="btn-primary grey" (click)="onNoClick()">
    {{ "GLOBAL.CANCEL" | translate | uppercase }}
  </button>
  <div class="spacer"></div>
  <button
    mat-button
    class="btn-primary"
    [mat-dialog-close]="data"
    [disabled]="isValid() === false"
  >
    {{ "GLOBAL.OK" | translate }}
  </button>
</div>
