import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataNode } from 'src/app/model/data-node.model';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { SketchupService } from 'src/app/services/sketchup.service';
import { SmartworksPluginComponent } from '../swplugin/swplugin.component';
import { UnitConversionService } from '../../project/services/unit-conversion.service';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { ProjectData } from 'src/app/model/project-data.model';

@Component({
  selector: 'sw-plugin-sketchup-objects',
  templateUrl: './sketchup-objects.component.html',
  styleUrls: ['./sketchup-objects.component.scss'],
})
export class SketchupObjectsComponent
  extends SmartworksPluginComponent
  implements OnInit, OnDestroy
{
  // inherited from SmartworksPluginComponent
  @Input() declare projectData: ProjectData;
  @Input() declare rootNode?: DataNode;

  projectID: string;
  importing: boolean = false;

  constructor(
    public sketchUp: SketchupService,
    public dataNodeService: DataNodeService,
    public projectService: ProjectService,
    private unitConversion: UnitConversionService
  ) {
    super(sketchUp, dataNodeService, projectService);
  }

  ngOnInit(): void {
    console.log('SketchupObjectsComponent');

    const project = this.projectService.getProject();
    this.projectID = project.id;

    // overwrite the window JS callback
    this.sketchUp.registerFunctions({
      finishedNodesUpload: () => {
        this.importing = false;
        return '';
      },
    });
  }

  ngOnDestroy(): void {
    this.unregister();
  }

  async nodesUpdated(nodes: DataNode[]) {
    // console.log("nodesUpdated")
  }

  async defaultAction(): Promise<any> {
    console.log('Sketchup-objects default action called - NOOP');
    return true;
  }

  async exportAllSketchupObjects() {
    if (this.importing) return;
    if (this.isCurrentFile) {
      this.importing = true;
      let r = await this.sketchUp.bridge.get('exportAllObjects');
    }
  }

  getTotalPrice(): string {
    if (!this.rootNode) return;

    var total = this.computePriceForNode(this.rootNode);

    var att: DataAttribute = {
      name: '',
      value: '',
      value_raw: total,
      units: 'EUROS',
      list: null,
      tag: '',
      access: null,
      options: null,
      errors: [],
    };

    return this.unitConversion.valueFromRawValueAndUnit(att);
  }

  computePriceForNode(node: DataNode) {
    var price = 0;
    node.attributes
      .filter((a) => a.tag == '_gardn8_object_price')
      .forEach((p) => {
        price += parseFloat(p.value_raw);
      });
    var childrenPrices = node.children.map((c) => this.computePriceForNode(c));

    return childrenPrices.reduce(
      (total, childPrice) => total + childPrice,
      price
    );
  }
}
