import { Injectable } from '@angular/core';
import { CanvasForm, Pt } from 'pts';
import { ContextMenuItem } from 'src/app/modules/fabric8/contextmenu/contextmenu.options';
import { colors } from '../../swcanvas/colors';
import { Fabric8ToolType } from '../../toolset/Fabric8ToolType';
import { CameraService } from '../camera.service';
import { ElementsService } from '../elements.service';
import { ToolHandlerService } from '../tool-handler.service';
import { ToolService } from '../tool.service';
import { Fabric8MouseEvent } from './models/Fabric8MouseEvent.model';
import { Fabric8ToolHandler } from './models/Fabric8ToolEvents.model';
import { ToolState } from './models/ToolState.model';
import { SnapService, SnapTo } from './snap';

@Injectable({
  providedIn: 'root',
})
export class ZoomPanTool implements Fabric8ToolHandler {
  state: ToolState = ToolState.idle;
  cursorPoint: Pt | undefined;
  cursorOrigin: Pt | undefined;
  cursorSnapped?: SnapTo;

  constructor(
    private toolService: ToolService,
    private elementsService: ElementsService,
    private cameraService: CameraService,
    private toolHandlerService: ToolHandlerService,
    private snap: SnapService
  ) {
    this.toolHandlerService.registerTool(Fabric8ToolType.ZOOM_PAN, this);
  }
  getContextMenuItems(event: Fabric8MouseEvent): ContextMenuItem[] {
    return [];
  }
  contextMenuAction(item: ContextMenuItem) {
    throw new Error('Method not implemented.');
  }

  private startZoomPanAt(point: Pt) {
    this.cursorOrigin = point;
  }

  private zoomPanTo(point: Pt) {
    const vector = point.$subtract(this.cursorOrigin!);
    this.cameraService.pan(vector);
    this.cursorOrigin = point;
  }

  private endZoomPanAt(point: Pt) {
    this.zoomPanTo(point);
    this.cursorOrigin = undefined;
  }

  public snapCursor(event: Fabric8MouseEvent) {
    this.cursorPoint = event.objectSpacePoint;
  }

  // handle mouse events
  public mouseClick(event: Fabric8MouseEvent) {}

  public mouseDown(event: any) {
    if (this.state == ToolState.idle) {
      this.startZoomPanAt(event.pointer);
      this.state = ToolState.panning;
    }
  }

  public mouseDrag(event: any) {
    this.zoomPanTo(event.pointer);
  }

  public mouseUp(event: any) {
    // console.log('mouseUp', event)
    this.state = ToolState.idle;
    // this.toolService.commitElement();
  }

  public mouseDrop(event: any) {
    // console.log('mouseDrop', event)
    this.endZoomPanAt(this.cursorPoint);
  }

  public mouseMove(event: Fabric8MouseEvent) {
    // console.log('mouseMove', event)
  }

  public keyDown(event: any) {}

  public reset() {
    this.state = ToolState.idle;
  }

  public draw(form: CanvasForm) {
    if (this.cursorPoint && this.cursorSnapped) {
      const p = this.cameraService.transform([this.cursorPoint]);
      var shape = 'circle';
      switch (this.cursorSnapped) {
        case SnapTo.objectPoints:
        case SnapTo.elementPoints:
        case SnapTo.guidelinesIntersections:
        case SnapTo.guidelineToObjectIntersections:
          shape = 'square';
          break;
        default:
          shape = 'circle';
          break;
      }

      if (
        this.cursorSnapped == SnapTo.grid ||
        this.cursorSnapped == SnapTo.roundUnits
      ) {
        //don't draw
      } else {
        form.fillOnly(colors.activeTool).point(p[0], 5, shape);
      }
    }
  }
}
