import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SwConfirmDialogComponent } from 'src/app/shared/sw-confirm-dialog';
import { Fabric8NodeType } from '../models/Fabric8NodeType';
import { Fabric8Object } from '../models/object.model';
import { CurrentObjectService } from '../services/current-object.service';
import { Fabric8SketchupService } from '../services/fabric8-sketchup.service';
import { ObjectsManagerService } from '../services/objects-manager.service';
import { UndoService } from '../services/undo.service';

@Component({
  selector: 'sw-objects-panel',
  templateUrl: './objects-panel.component.html',
  styleUrls: ['./objects-panel.component.scss'],
})
export class ObjectsPanelComponent implements OnInit, OnDestroy {
  currrentObjectIndexSubscription: Subscription;
  currentObjectID?: string;
  expanded: boolean = true;

  currentObject: Fabric8Object;

  constructor(
    private router: Router,
    public objectsManager: ObjectsManagerService,
    private currentObjectService: CurrentObjectService,
    private undoService: UndoService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private sketchupService: Fabric8SketchupService
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentObjectID();
    this.reload();
  }

  ngOnDestroy(): void {
    this.currrentObjectIndexSubscription.unsubscribe();
  }

  subscribeToCurrentObjectID(): void {
    this.currrentObjectIndexSubscription =
      this.objectsManager.currentObjectId.subscribe((objectId: string) => {
        this.currentObjectID = objectId;
        this.currentObject = this.currentObjectService.get();
      });
  }

  async clickSelectObject(object: Fabric8Object) {
    // this.currentObjectIndex = objectIndex
    this.objectsManager.currentObjectId.next(object.guid);
    await this.sketchupService.selectObject(object.guid);
  }

  openSettings() {
    this.router.navigate(['/fabric8/settings']);
  }

  openToolsetSettings() {
    this.router.navigate(['/fabric8/toolsets']);
  }

  async deleteFabric8Object(fabric8Obj: Fabric8Object) {
    const sameObject = this.currentObject.guid === fabric8Obj.guid;

    if (sameObject) {
      if (this.hasFabric8Data()) {
        const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
          width: '300px',
          data: {
            title: this.translate.instant(
              'FABRIC8.DELETE_OBJECT_WITH_ELEMENTS_TITLE'
            ),
            prompt: this.translate.instant(
              'FABRIC8.DELETE_OBJECT_WITH_ELEMENTS_MESSAGE'
            ),
            cancelButton: this.translate.instant('GLOBAL.CANCEL'),
            actionButton: this.translate.instant('GLOBAL.DELETE'),
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.deleteObj(fabric8Obj);
          }
        });

        return;
      }

      this.deleteObj(fabric8Obj);
    }
  }

  private deleteObj(object: Fabric8Object): void {
    this.objectsManager.deleteObject(object);
    // _.delay(() => {
    //   this.reload();
    // }, 500);
  }

  addFabric8GroupNode(object: Fabric8Object) {
    if (!object.rootNode.children) {
      object.rootNode.children = [];
    }

    this.currentObjectService.addEmptyGroupOnNode(object.rootNode);
    this.currentObjectService.update();
  }

  private hasFabric8Data(): boolean {
    const elements = this.currentObjectService.getObjectNodesFlatList([
      Fabric8NodeType.Element,
      Fabric8NodeType.Guideline,
      Fabric8NodeType.GuidelineSegment,
    ]).length;

    return elements !== 0;
  }

  async reload() {
    await this.objectsManager.loadObjects();
    this.addUndoStepForAllObjects();
  }

  addUndoStepForAllObjects() {
    this.objectsManager
      .getObjects()
      .forEach((o) => this.undoService.addStepForObject(o));
  }

  async importSelection() {
    const importedObjectID = await this.objectsManager.importSelection();
    this.undoService.clearStack();
    this.addUndoStepForAllObjects();

    if (importedObjectID) {
      const importedObjectIndex = this.objectsManager
        .getObjects()
        .findIndex((o) => o.guid == importedObjectID);
      this.objectsManager.currentObjectId.next(importedObjectID);
    }
  }

  async export() {
    this.currentObjectService.export();
  }
}
