import {
  AfterContentInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  UserPresenceItem,
  UserPresenceService,
} from 'src/app/modules/user-presence-module/user-presence.service';

@Component({
  selector: 'sw-user-presence',
  templateUrl: './user-presence.component.html',
  styleUrls: ['./user-presence.component.scss'],
})
export class UserPresenceComponent
  implements AfterContentInit, OnInit, OnDestroy
{
  @Input() dataNodeID?: string;
  @Input() projectID?: string;

  users: UserPresenceItem[];

  constructor(private userPresenceService: UserPresenceService) {}

  presenceSubscription: Subscription;

  ngOnInit(): void {
    if (Boolean(this.dataNodeID) == false && Boolean(this.projectID) == false) {
      throw 'UserPresence needs at least one of [dataNodeID] or [projectID]';
    }
  }

  ngAfterContentInit(): void {
    if (Boolean(this.dataNodeID)) {
      this.subscribeToDataNodeSelectionEvents();
    } else {
      this.subscribeToProjectSelectionEvents();
    }
  }

  private subscribeToDataNodeSelectionEvents() {
    this.presenceSubscription =
      this.userPresenceService.usersDataNodeSelectionUpdatedEvent.subscribe(
        (u) => {
          if (u == this.dataNodeID) {
            this.updateDataNodeUsers();
          }
        }
      );
  }

  private subscribeToProjectSelectionEvents() {
    this.presenceSubscription =
      this.userPresenceService.usersProjectSelectionUpdatedEvent.subscribe(
        (u) => {
          if (u == this.projectID) {
            this.updateProjectUsers();
          }
        }
      );
  }

  updateDataNodeUsers() {
    const u = this.userPresenceService.dataNodeUsers.get(
      String(this.dataNodeID)
    );
    if (u == undefined) {
      this.users = [];
    } else {
      this.users = Array.from(u.values());
    }
  }

  updateProjectUsers() {
    const u = this.userPresenceService.projectUsers.get(String(this.projectID));
    if (u == undefined) {
      this.users = [];
    } else {
      this.users = Array.from(u.values());
    }
  }

  ngOnDestroy(): void {
    this.presenceSubscription.unsubscribe();
  }
}
