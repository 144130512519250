import { Injectable, NgZone } from '@angular/core';
import { DataNode } from 'src/app/model/data-node.model';
import { SketchupService } from 'src/app/services/sketchup.service';
import { WindowRef } from '../../shared/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class DragService {
  draggingNode: DataNode | null = null;

  outsideWindow: Boolean = false;

  constructor(
    private _ngZone: NgZone,
    private windowRef: WindowRef,
    private sketchUp: SketchupService
  ) {
    // Managing drag events that go outside the window feels like a hack
    // a lot of event handling is needed, which makes the code unreadable and hard to manage

    // The easier solution is to add a button with
    //    element.draggable = false
    //    element.mousedown -> prepare for activation
    //    element.mouseout or mouseleave -> activate tool
    //    element.mouseover -> deactivate tool

    //disable
    return;

    this._ngZone.runOutsideAngular(() => {
      // dragover and mouseover are used to find out when the cursor returns to the window
      this.windowRef.nativeDocument.documentElement.addEventListener(
        'dragover',
        (event) => {
          // event.preventDefault()
          if (event.target != null) {
            // outside the window
            this.onMouseEnter();
          }
        },
        {
          capture: false,
          passive: true,
        }
      );

      this.windowRef.nativeDocument.documentElement.addEventListener(
        'mouseover',
        (event) => {
          if (event.target != null) {
            // event.preventDefault()
            this.onMouseEnter();
          }
        },
        {
          capture: false,
          passive: true,
        }
      );

      this.windowRef.nativeDocument.documentElement.addEventListener(
        'dragleave',
        (event) => {
          if (event.relatedTarget == null) {
            this.onDragLeave();
            event.preventDefault();
          }
        },
        {
          capture: false,
          passive: false,
        }
      );

      this.windowRef.nativeDocument.documentElement.addEventListener(
        'dragend',
        (event) => {
          this._ngZone.run(() => {
            console.log('Window.DRAGEND');
            this.nodeDragEnded(event);
          });
        },
        {
          capture: false,
          passive: false,
        }
      );

      // this.windowRef.nativeDocument.onmouseout = (event) => {
      //   this.onDragLeave()
      // }
      // this.windowRef.nativeDocument.onmouseover = (event) => {
      //   event.preventDefault()
      //   this.onDragEnter()
      // }
    });
  }

  public nodeDragStarted(node: DataNode) {
    console.error('nodeDragStarted');
    this.draggingNode = node;
    this.outsideWindow = false;

    // this.sketchUp.bridge.call('activateGardN8PlantTool', this.draggingNode)
  }

  public nodeDragCancel() {
    // console.error("nodeDragCanceled")

    this.draggingNode = null;
    this.sketchUp.bridge.call('deactivateGardN8PlantTool');
  }

  public nodeDragEnded(event: DragEvent) {
    console.error('nodeDragEnded');
    if (this.outsideWindow && this.draggingNode != null) {
      // this.sketchUp.bridge.call('deactivateGardN8PlantTool')
      event.stopImmediatePropagation();
    } else {
      this.sketchUp.bridge.call('deactivateGardN8PlantTool');
    }
    this.draggingNode = null;
  }

  private onMouseEnter() {
    if (this.outsideWindow == false) return;
    console.error('MOUSE entered the window');
    this.sketchUp.bridge.call('deactivateGardN8PlantTool');
    this.windowRef.nativeDocument.documentElement.classList.remove(
      'wdragleave'
    );
    this.outsideWindow = false;
  }

  private onDragLeave() {
    this.outsideWindow = true;
    console.error('DRAG LEFT THE WINDOW');
    this.windowRef.nativeDocument.documentElement.classList.add('wdragleave');

    this._ngZone.runTask((e) => {
      if (this.draggingNode != null) {
        this.sketchUp.bridge.call('activateGardN8PlantTool', this.draggingNode);
        //   this.sketchUp.bridge.call('dragEnter', this.draggingNode)
        //   this.nodeDragEnded()
      }
    });
  }

  public setDataOn(dataTransfer: any) {
    // e.dataTransfer.setData("image/jpeg", "/assets/img/drag.jpg")
    const url =
      'application/sketchup:node.skp:https://smartworks.kaapisoft.com/assets/img/cube.skp';
    dataTransfer.setData('DownloadURL', url);
  }

  public setDataOn_(dataTransfer: any) {
    // e.dataTransfer.setData("image/jpeg", "/assets/img/drag.jpg")

    dataTransfer.setData(
      'DownloadURL', // Type of drag - DownloadURL in this case
      'application/file:' + // Type of file we're generating
        'cubex.skp' + // name for the new file
        ':data:application/file;base64,' + // to convince the browser this is binary data
        'base64content' // The actual content you want in the file
    );
  }
}
