import { Component, OnDestroy, OnInit } from '@angular/core';
import { RxStompService, StompState } from '@stomp/ng2-stompjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sw-mq-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss'],
})
export class ConnectionStatusComponent implements OnInit, OnDestroy {
  state = 0;
  sub: Subscription;
  errorSub: Subscription;
  error: any = false;

  constructor(private rxStompService: RxStompService) {}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.errorSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.sub = this.rxStompService.connectionState$.subscribe((state) => {
      // CONNECTING = 0,
      // OPEN = 1,
      // CLOSING = 2,
      // CLOSED = 3

      this.state = state;
    });

    this.errorSub = this.rxStompService.stompErrors$.subscribe((err) => {
      this.error = err;
      console.warn('ERROR', err);
    });
  }
}
