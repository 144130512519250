import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LibraryItem } from 'src/app/model/library-item.model';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { LibraryLabelsService } from '../library-labels.service';
import { LibraryNavigationService } from '../library-navigation.service';
import { LibraryViewService } from '../library-view.service';
import { LibraryService } from '../library.service';
import { animations } from './animations';

@Component({
  selector: 'sw-library-item-list',
  templateUrl: './library-item-list.component.html',
  styleUrls: ['./library-item-list.component.scss'],
  animations: animations,
})
export class LibraryItemListComponent implements OnInit, OnDestroy {
  isEditing: boolean;
  isSearching = false;

  state: 'Loading' | 'ItemsLoaded' | 'NoResults' = 'Loading';

  itemsSubscription: Subscription;
  editingSubscription: Subscription;
  items: LibraryItem[];

  plugin: SWPlugin;

  constructor(
    public libraryViewService: LibraryViewService,
    public libraryLabelsService: LibraryLabelsService,
    public libraryNav: LibraryNavigationService
  ) {}

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
    this.editingSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.itemsSubscription = this.libraryViewService.items.subscribe(
      (items) => {
        this.items = items;

        if (items == null) {
          this.state = 'Loading';
          return;
        }

        if (this.items.length == 0) {
          this.state = 'NoResults';
        } else {
          this.state = 'ItemsLoaded';
        }
      }
    );

    this.editingSubscription = this.libraryViewService.isEditing$.subscribe(
      (editing) => {
        this.isEditing = editing;
      }
    );

    this.plugin = this.libraryViewService.plugin;

    this.state = 'Loading';
    this.load();
  }

  async load() {
    await this.libraryLabelsService.getAll(this.plugin.id);
    await this.libraryViewService.getAll();
  }

  deselectItem(): void {
    this.libraryViewService.selectItem(null);
  }

  // tracking
  itemByID(index, item) {
    return item.id;
  }

  onClickAddItem(): void {}
}
