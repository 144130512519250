import { Message } from '@stomp/stompjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LibraryLabel } from 'src/app/model/library-label.model';
// import { LibraryLabelsService } from 'src/app/modules/library/library-labels.service';
import { UserPresenceService } from 'src/app/modules/user-presence-module/user-presence.service';
import {
  UpdateLibraryItemMessage,
  UpdateLibraryLabelMessage,
} from 'src/app/services/liveupdates/messages';
import { B64Service } from 'src/app/shared/b64.service';
import { MQService } from 'src/app/modules/rmq/mq.service';

@Injectable({
  providedIn: 'root',
})
export class LibraryLiveUpdateService implements OnDestroy {
  labelsSubscription: Subscription;

  // [libID : [labelChannel, subscription] ]
  librarySubscriptionsMap: Map<string, Map<string, Subscription>> = new Map();

  // labelTopicSubscription: Map<string, Subscription> = new Map()
  userTopicSubscription: Subscription;

  updateLibraryItemSignal: Subject<UpdateLibraryItemMessage> = new Subject();
  deleteLibraryItemSignal: Subject<string> = new Subject();
  reloadLibrarySignal: Subject<string> = new Subject();

  updateLibraryLabelSignal: Subject<UpdateLibraryLabelMessage> = new Subject();
  deleteLibraryLabelSignal: Subject<string> = new Subject();

  constructor(
    private mqService: MQService,
    private userPresenceService: UserPresenceService,
    // private libraryLabelsService: LibraryLabelsService,

    private b64: B64Service
  ) {
    this.init();
  }

  init() {
    // this.labelsSubscription = this.libraryLabelsService.labels.subscribe(labels => {
    //   this.updateSubscriptions(labels)
    // })

    this.initUserChannelSubscription();
  }

  ngOnDestroy(): void {
    // rabbitmq subscriptions
    this.librarySubscriptionsMap.forEach((labelTopicSubscription, key) => {
      labelTopicSubscription.forEach((s) => s.unsubscribe());
      labelTopicSubscription.clear();
    });
    this.userTopicSubscription?.unsubscribe();

    //rxjs subscriptions
    this.labelsSubscription?.unsubscribe();
  }

  private initUserChannelSubscription() {
    this.userTopicSubscription = this.mqService.userChannel.subscribe(
      (message) => this.receivedLiveUpdateMessage(message)
    );
  }

  public updateLabelsForLibrary(labels: LibraryLabel[], libraryID: string) {
    var librarySubscriptionsMap = this.librarySubscriptionsMap.get(libraryID);

    if (Boolean(librarySubscriptionsMap) == false) {
      const newMap: Map<string, Subscription> = new Map();
      this.librarySubscriptionsMap.set(libraryID, newMap);
    }

    this.updateSubscriptions(
      labels, //the current labels
      this.librarySubscriptionsMap.get(libraryID) // the subscriptions map
    );
  }

  private updateSubscriptions(
    labels: LibraryLabel[],
    labelTopicSubscriptions: Map<string, Subscription>
  ) {
    const labelChannelIDs = labels.map((l) => 'label-' + String(l.id));

    var newLabelChannelIDs = labelChannelIDs.filter(
      (labelChannelID) => !labelTopicSubscriptions.has(labelChannelID)
    );

    // unsubscribe from label channels that are missing
    // for each existing subscription, check if the label is still present
    labelTopicSubscriptions.forEach((sub, labelChannel) => {
      if (labelChannelIDs.includes(labelChannel) == false) {
        // label is not present anymore, unsubscribe from topic, remove from Map
        console.info('UNSUBSCRIBED FROM LABEL CHANNEL : ' + labelChannel);
        sub.unsubscribe();
        labelTopicSubscriptions.delete(labelChannel);
      }
    });

    // subscribe to new label channels
    newLabelChannelIDs.forEach((channelID) => {
      const newSub = this.mqService
        .topic(channelID)
        .subscribe((message) => this.receivedLiveUpdateMessage(message));

      // console.log("SUBSCRIBED TO LABEL CHANNEL : label-"+label.id)
      labelTopicSubscriptions.set(channelID, newSub);
    });
  }

  private receivedLiveUpdateMessage(message: Message) {
    const m = message.body.split('>');

    if ('ULI' == m[0]) {
      const mc: UpdateLibraryItemMessage = {
        libraryItemID: m[1],
        pluginID: m[2],
        hash: m[3],
      };
      console.warn(message.body);
      this.updateLibraryItemSignal.next(mc);
      message.ack();
      return;
    }

    if ('DLI' == m[0]) {
      // Delete Library Item
      console.warn(message.body);
      this.deleteLibraryItemSignal.next(m[1]);
      message.ack();
    }

    if ('ULL' == m[0]) {
      const mc: UpdateLibraryLabelMessage = {
        labelID: m[1],
        hash: m[2],
        base64: m[3],
      };
      console.warn(message.body);
      this.updateLibraryLabelSignal.next(mc);
      message.ack();
      return;
    }

    if ('DLL' == m[0]) {
      console.warn(message.body);
      this.deleteLibraryLabelSignal.next(m[1]);
      // this.reloadLibrarySignal.next(m[1])
      message.ack();
      return;
    }

    if ('RELOAD' == m[0]) {
      console.warn(message.body);
      location.reload();
    }
  }
}
