import { Injectable } from '@angular/core';
import { UserAccess } from 'src/app/model/user-access.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private authService: AuthService) {}

  makeUserOwner(): UserAccess {
    const u = this.authService.user$.getValue();
    return new UserAccess(u.email, 'owner', u.username);
  }
}
