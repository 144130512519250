import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sw-color-block',
  templateUrl: './color-block.component.html',
  styleUrls: ['./color-block.component.scss'],
})
export class ColorBlockComponent {
  @Input() colors: string[];

  constructor() {}

  byIndex(i) {
    return i;
  }
}
