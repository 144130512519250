import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UIService } from 'src/app/core/services/ui.service';
import { DataNode } from 'src/app/model/data-node.model';
import { wDragDropListEvent } from 'src/app/modules/wTree/tree-node.model';
import { Fabric8Node } from '../../models/node.model';
import { animations } from './animations';
import { Subscription } from 'rxjs';
import { HoverService } from '../../services/hover.service';

import { Fabric8NodeType } from '../../models/Fabric8NodeType';
import { CurrentObjectService } from '../../services/current-object.service';
import { Fabric8GuidelineSegment } from '../../models/guideline-segment.model';

@Component({
  selector: 'sw-fabric8-node',
  templateUrl: './fabric8-node.component.html',
  styleUrls: ['./fabric8-node.component.scss', './drop-zone.scss'],
  animations: animations,
})
export class Fabric8NodeComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  @Output() deleteNode: EventEmitter<any> = new EventEmitter();

  @Input() parent: Fabric8Node;
  @Input() node: Fabric8Node;
  @Output() nodeChange: EventEmitter<Fabric8Node> =
    new EventEmitter<Fabric8Node>();

  @Input() indentLevel = 0;
  expanded = true;
  isHidden = false;

  // dragging info
  @Input() parentIsDragged = false;
  dragging = false;
  isDragging(): boolean {
    return this.dragging || this.parentIsDragged;
  }

  isHovered = false;
  hoverSubscription: Subscription;

  state:
    | 'Init'
    | 'LoadingChildren'
    | 'FailedLoadingChildren'
    | 'ChildrenLoaded' = 'Init';

  constructor(
    private uiService: UIService,
    private hoverService: HoverService,
    private currentObject: CurrentObjectService
  ) {}

  hoverNode(node: Fabric8Node) {
    if (!node) {
      this.hoverService.hoverNodes([]);
    } else {
      this.hoverService.hoverNodes([node]);
    }
  }

  ngOnInit(): void {
    this.subscribeToHover();
  }

  subscribeToHover() {
    this.hoverSubscription = this.hoverService.hoveredElementIndices$.subscribe(
      (hovered) => {
        const found = hovered?.find((i) => i.id == this.node.id);
        this.isHovered = found != undefined;
      }
    );
  }

  ngOnDestroy(): void {
    this.hoverSubscription.unsubscribe();
  }

  async ngAfterContentInit() {
    await this.loadChildren();
  }

  async loadChildren() {
    this.state = 'ChildrenLoaded';
  }

  dragStarted(event) {
    console.log('node: dragStarted');
    this.dragging = true;
    // this.attributeEditorService.deselectNode();
    // this.dragService.nodeDragStarted(this.node)
  }

  dragReleased(event) {
    console.log('node: dragReleased');
    this.dragging = false;
    // this.dragService.nodeDragEnded()
  }

  dropped(event: wDragDropListEvent) {
    // this.dragService.nodeDragCancel()

    if (event.from.sourceList === event.to.targetList) {
      moveItemInArray(event.to.targetList, event.fromIndex, event.toIndex);

      this.updateNodes([event.to.target as DataNode]);
    } else {
      transferArrayItem(
        event.from.sourceList,
        event.to.targetList,
        event.fromIndex,
        event.toIndex
      );

      this.updateNodes([
        event.from.source as DataNode,
        event.to.target as DataNode,
      ]);
    }
  }

  hasChildren(): boolean {
    if (this.node == undefined) {
      return false;
    }

    if (this.node.children?.length > 0) {
      return true;
    }
    return false;
  }

  //TODO: implement
  async updateNodes(nodes: DataNode[]) {
    this.uiService.disableUI(true);
    try {
      // await this.dataNodeService.updateNodes(nodes);
    } catch (e) {
      console.error("can't update nodes");
    }
    this.uiService.enableUI();
  }

  async deleteChildAtIndex(index) {
    const deletedNode = this.node.children[index];
    this.uiService.disableUI(true);
    try {
      console.log('delete child at index', index);
      this.currentObject.deleteNodeById(deletedNode.id);

      // This is caught by the root node to show the delete object dialog when there are no more nodes
      // It will also call the sketchup:removeFabric8Data to remove the dictionary
      this.deleteNode.emit(deletedNode);
      // this.node.children.splice(index, 1);
      // this.nodeChange.next(this.node);
    } catch (err) {
      alert("can't remove node");
    }
    this.uiService.enableUI();
  }

  isOpened(): boolean {
    return false;
  }

  toggleAttributes() {}

  endEditingName(name: string) {
    this.node.name = name;
    this.nodeChange.next(this.node);
  }

  // angular trackBy function
  nodeByID(index, node) {
    if (this.parent === undefined) {
      return node.id;
    }
    return this.parent.id + '-' + node.id;
  }

  isElement(): boolean {
    return this.node.type == Fabric8NodeType.Element;
  }

  isGroup(): boolean {
    return this.node.type == Fabric8NodeType.Group;
  }

  isGuideline(): boolean {
    return this.node.type == Fabric8NodeType.Guideline;
  }

  isGuidelineSegment(): boolean {
    return this.node.type == Fabric8NodeType.GuidelineSegment;
  }
}
