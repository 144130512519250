import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LODstates,
  MM2DType,
  MMLODType,
  MMProxyDensity,
} from './models/MMLOD.model';
import {
  MMState,
  ModelManagerSketchUpService,
} from './model-manager-sketch-up.service';
import { MMHub } from './models/MMHub.model';
import { UIService } from 'src/app/core/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import {
  NewHubDialogComponent,
  NewHubDialogData,
} from './new-hub-dialog/new-hub-dialog.component';
import { Subscription } from 'rxjs';
import { ModelManagerHelperService } from './model-manager-helper/model-manager-helper.service';

@Component({
  selector: 'sw-model-manager',
  templateUrl: './model-manager.component.html',
  styleUrls: ['./model-manager.component.scss'],
})
export class ModelManagerComponent implements OnInit, OnDestroy {
  LODstates = LODstates;

  hubs: MMHub[] = [];
  hubsSub: Subscription;

  MMState = MMState;

  needsRebuild = false;

  constructor(
    private router: Router,
    public mm: ModelManagerSketchUpService,
    private helper: ModelManagerHelperService,
    public ui: UIService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hubsSub = this.mm.hubs.subscribe((h) => {
      this.hubs = h;
      this.needsRebuild = this.hubs.some((h) => h.state.needsRefresh);
    });

    this.ui.disableUI(false);
    this.mm.reload();
    this.mm.startReloadTimer();
    this.ui.enableUI();
  }

  ngOnDestroy(): void {
    this.mm.stopReloadTimer();
    this.hubsSub?.unsubscribe();
  }

  openWizard() {
    this.router.navigate(['/model-manager/wizard']);
  }

  openSettings() {
    this.router.navigate(['/model-manager/settings']);
  }

  async rebuildHubs() {
    this.ui.disableUI(true);
    await this.mm.rebuildHubs();
    this.ui.enableUI();
  }

  async newHub(data: NewHubDialogData): Promise<MMHub> {
    this.ui.disableUI(true);
    var theHub: MMHub = undefined;

    try {
      theHub = await this.mm.newHub(data.hubName, data.definition);

      var type = MMLODType.Empty;
      var options = undefined;
      if (data.create2d) {
        type = MMLODType.TwoD;
        options = MM2DType.Box;
        await this.mm.createLod(theHub, type, options);
      }

      if (data.createProxy !== MMProxyDensity.None) {
        type = MMLODType.Proxy;
        options = data.createProxy;
        await this.mm.createLod(theHub, type, options);
      }

      await this.mm.reload();
      const hub = this.mm.hubs.getValue().find((h) => h.name == theHub.name);
      await this.mm.setLODType(hub, type, options);
    } catch (e) {
      switch (e) {
        case 'lod-type-missing':
          console.error("LOD wasn't created - can't switch ", type, options);
          break;
        case 'error-empty-selection':
          alert(this.translate.instant('Please select an object'));
          break;
        case 'error-2d-flat-invalid':
          alert(this.translate.instant('MODEL_MANAGER.ERROR_2D_FLAT'));
          break;
        default:
          console.error(e);
        // alert("Unexpected error: please report");
      }
    } finally {
      this.ui.enableUI();
    }

    return theHub;
  }

  onClickAddHub(): void {
    this.mm.stopReloadTimer();

    const data: NewHubDialogData = {
      hubName: '',
      definition: '',
      create2d: true,
      createProxy: MMProxyDensity.Medium,
    };
    const dialogRef = this.dialog.open(NewHubDialogComponent, {
      width: '400px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(async (result: NewHubDialogData) => {
      if (Boolean(result) == false) {
        // do nothing
      } else {
        this.ui.disableUI(true);
        const newHub = await this.newHub(result);
        // this.mm.updateHub(newHub);
        this.mm.selectHub(newHub);
        this.helper.selectByHUBName(newHub.name);
        this.ui.enableUI();
      }

      // start reloading hub data
      this.mm.startReloadTimer();
    });
  }

  trackByName(index, item: MMHub) {
    return item.name;
  }
}
