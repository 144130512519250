<div class="row">
  <div
    *ngFor="let btn of buttons"
    (click)="clicked(btn)"
    [ngClass]="{ selected: selection.includes(btn) }"
    class="option pointer"
  >
    {{ btn }}
  </div>
</div>
