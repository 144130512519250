<div class="full-page login-page">
  <div class="logo"></div>
  <div class="login-container">
    <form
      *ngIf="!authService.loggedIn()"
      name="form"
      id="login-form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <mat-form-field>
        <mat-label for="username">{{
          "LOGIN.SMARTWORKS_ID" | translate
        }}</mat-label>
        <input
          matInput
          placeholder=""
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="form.username"
          [disabled]="loggingIn"
          required
          #username="ngModel"
        />
        <mat-error
          class="alert alert-danger"
          role="alert"
          *ngIf="username.errors && f.submitted"
        >
          {{ "LOGIN.SMARTWORKS_ID_REQUIRED" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="password">{{ "LOGIN.PASSWORD" | translate }}</mat-label>
        <input
          matInput
          placeholder=""
          type="password"
          name="password"
          [(ngModel)]="form.password"
          required
          [disabled]="loggingIn"
          minlength="6"
          #password="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="password.errors && f.submitted"
        >
          <mat-error *ngIf="password.errors.required">{{
            "LOGIN.PASSWORD_REQUIRED" | translate
          }}</mat-error>
          <mat-error *ngIf="password.errors.minlength">
            {{ "LOGIN.PASSWORD_TOO_SHORT" | translate }}
          </mat-error>
        </div>
      </mat-form-field>

      <ng-container *ngIf="!loggingIn">
        <button
          mat-button
          class="btn btn-primary btn-block"
          style="width: auto"
        >
          {{ "LOGIN.LOGIN_BUTTON" | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="loggingIn">
        <mat-spinner class="center" [diameter]="40"></mat-spinner>
      </ng-container>

      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="f.submitted && isLoginFailed"
      >
        {{ "LOGIN.LOGIN_FAILED" | translate }}: {{ errorMessage }}
      </div>
    </form>
  </div>
</div>
