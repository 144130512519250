import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

export interface SWNotification {
  id: string;
  message: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private authService: AuthService, private http: HttpClient) {
    this.initPolling();
  }

  notification$: BehaviorSubject<SWNotification> = new BehaviorSubject(
    undefined
  );
  notifications: SWNotification[] = [];
  state: 'fetching' | 'visible' | 'idle';

  userSubscription: Subscription;
  pollTimer: any;

  initPolling() {
    this.userSubscription = this.authService.user$.subscribe((user) => {
      if (user) {
        this.startPolling();
      } else {
        this.stopPolling();
      }
    });
  }

  startPolling() {
    this.state = 'idle';
    this.pollTimer = setInterval(() => {
      this.poll();
    }, 25000);
  }

  stopPolling() {
    clearInterval(this.pollTimer);
  }

  async poll() {
    // console.log("[notifications]: poll")
    if (this.state !== 'idle') {
      // console.log("[notifications]: NOT IDLE")
      // console.log(this.state)
      return;
    }

    try {
      await this.fetchNotifications();
    } catch (e) {
      this.state = 'idle';
    }
  }

  async fetchNotifications() {
    // console.log("[notifications]: fetching")
    this.state = 'fetching';

    const url = environment.apiUrl + '/notification/unread';
    this.notifications = await this.http.get<SWNotification[]>(url).toPromise();

    this.state = 'idle';

    // on sceen notifications
    let onscreenNotification = this.notifications.filter(
      (n) => (n.type = 'on_screen')
    );

    if (onscreenNotification.length > 0) {
      this.displayMessage(onscreenNotification[0]);
    }

    // we can process other events/notification types here
  }

  displayMessage(notification: SWNotification) {
    this.state = 'visible';
    this.notification$.next(notification);
  }

  async readNotification(notification: SWNotification) {
    this.state = 'idle';

    const url = environment.apiUrl + '/notification/read';
    const request = { id: notification.id };
    await this.http.post(url, request).toPromise();
    this.notification$.next(undefined);
  }
}
