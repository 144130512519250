<div
  id="indicator"
  [ngClass]="{
    connecting: state === 0,
    open: state === 1,
    closing: state === 2,
    closed: state === 3,
    error: error !== false
  }"
>
  &nbsp;
</div>
