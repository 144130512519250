import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LibraryNavigationService {
  constructor(private router: Router) {}

  public closeLibrary() {
    const n = [
      {
        outlets: {
          library: null,
        },
      },
    ];

    this.router.navigate(n);
  }

  public openLibrary(plugin: string) {
    const n = [
      {
        outlets: {
          library: [plugin],
        },
      },
    ];

    this.router.navigate(n);
  }

  // public openLabels(pluginID: string, itemID: string | null) {
  //   const inner = {
  //     outlets: {
  //       'labels' : [ itemID ]
  //     }
  //   }

  //   const n = [{
  //     outlets: {
  //       'library': [ pluginID, inner ]
  //     }
  //   }]

  //   this.router.navigate(n)
  // }

  // public closeLabels(pluginID) {
  //   const inner = {
  //     outlets: {
  //       'labels' : null
  //     }
  //   }
  //   const n = [{
  //     outlets: {
  //       'library': [ pluginID, inner ]
  //     }
  //   }]

  //   this.router.navigate(n)
  // }
}
