import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { TreeGroupService } from './tree-group.service';

@Directive({
  selector: '[swTreeGroup]',
  providers: [TreeGroupService],
})
export class TreeGroupDirective implements OnInit {
  constructor(
    private treeGroupService: TreeGroupService,
    private element: ElementRef,
    private _ngZone: NgZone
  ) {}

  @Input()
  wTreeGroupID: string;

  ngOnInit(): void {
    this.treeGroupService.id = this.wTreeGroupID;
    this.treeGroupService.setDragImage('/assets/img/drag.svg');
    this.enableDrag();
  }

  enableDrag() {
    this._ngZone.runOutsideAngular(() => {
      this.element.nativeElement.ondragover = (e) => false;
    });
  }
}
