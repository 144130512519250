import { Fabric8Element } from './element.model';
import { Fabric8GuidelineSegment } from './guideline-segment.model';
import { Fabric8Guideline } from './guideline.model';
import { Fabric8NodeData } from './types/Fabric8NodeData.type';

export enum Fabric8NodeType {
  Element = 'element',
  Guideline = 'guideline',
  GuidelineSegment = 'guidelineSegment',
  Group = 'group',
  Object = 'object',
}

export function getNodeType(data: Fabric8NodeData): Fabric8NodeType {
  if (data instanceof Fabric8Element) return Fabric8NodeType.Element;
  if (data instanceof Fabric8GuidelineSegment)
    return Fabric8NodeType.GuidelineSegment;
  if (data instanceof Fabric8Guideline) return Fabric8NodeType.Guideline;
  return Fabric8NodeType.Element;
}
