import { Fabric8Element } from '../../models/element.model';
import { Fabric8GuidelineSegment } from '../../models/guideline-segment.model';
import { Fabric8Guideline } from '../../models/guideline.model';
import { Fabric8Node, IFabric8Node } from '../../models/node.model';
import { Fabric8NodeType } from '../../models/Fabric8NodeType';
import { Fabric8NodeData } from '../../models/types/Fabric8NodeData.type';

export function newNodeWithData(
  data: Fabric8NodeData,
  id: string = ''
): Fabric8Node {
  var type: Fabric8NodeType;
  switch (data.constructor) {
    case Fabric8Element:
      type = Fabric8NodeType.Element;
      break;
    case Fabric8Guideline:
      type = Fabric8NodeType.Guideline;
      break;
    case Fabric8GuidelineSegment:
      type = Fabric8NodeType.GuidelineSegment;
      break;
    default:
      type = Fabric8NodeType.Group;
      break;
  }

  var name: string;
  switch (type) {
    case Fabric8NodeType.Element:
      name = 'Element';
      break;
    case Fabric8NodeType.Guideline:
      name = 'Guideline';
      break;
    case Fabric8NodeType.GuidelineSegment:
      name = 'Guideline Segment';
      break;
    default:
      name = 'Group';
      break;
  }

  const newNode = new Fabric8Node({
    [IFabric8Node]: true,
    name: name,
    id: id,
    type: type,
    data: data,
    readOnly: false,
  });

  return newNode;
}
