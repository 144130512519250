import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { ModelManagerHelperService } from '../model-manager-helper/model-manager-helper.service';
import { ModelManagerSettingsService } from '../model-manager-settings/model-manager-settings.service';
import { ModelManagerSketchUpService } from '../model-manager-sketch-up.service';
import { MMHub } from '../models/MMHub.model';
import { LODstates, MMLOD, MMLODType } from '../models/MMLOD.model';

@Component({
  selector: 'sw-mm-hub',
  templateUrl: './mm-hub.component.html',
  styleUrls: ['./mm-hub.component.scss'],
})
export class MmHubComponent implements OnInit, OnDestroy {
  @Input() hub: MMHub;

  selectionObserver: Subscription;
  isActive: boolean;
  deleting = false;
  rebuilding = false;

  buildingObserver: Subscription;
  selectedHubsObserver: Subscription;
  isSelected: boolean;

  LODstates = LODstates;

  missingLODtype?: MMLODType;
  creatingLOD: boolean = false;

  MMLODType = MMLODType;

  constructor(
    public mm: ModelManagerSketchUpService,
    private mms: ModelManagerSettingsService,
    private helper: ModelManagerHelperService,
    private uiService: UIService,
    private ref: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.selectionObserver = this.mm.currentHubID$.subscribe((hubID) => {
      this.isActive = hubID == this.hub.name;
    });

    this.selectedHubsObserver = this.mm.selectedHubs$.subscribe(
      (hubCandidates) => {
        this.isSelected = Boolean(
          hubCandidates.find((c) => c.hub == this.hub.name)
        );
        this.ref.detectChanges();
      }
    );

    this.isSelected = Boolean(
      this.mm.selectedHubs.getValue().find((c) => c.hub == this.hub.name)
    );

    this.buildingObserver = this.mm.isBuilding.subscribe(
      (b) => (this.rebuilding = b == this.hub.name)
    );
  }

  ngOnDestroy(): void {
    this.selectionObserver?.unsubscribe();
    this.selectedHubsObserver?.unsubscribe();
    this.buildingObserver?.unsubscribe();
  }

  activeLODType(): MMLODType {
    return this.missingLODtype ?? this.mm.getLODType(this.hub.active_lod);
  }

  async createMissingLOD(type, options?: any) {
    this.creatingLOD = true;
    this.hub = await this.mm.createLod(this.hub, type, options);
    this.mm.updateHub(this.hub);
    this.creatingLOD = false;
    // await this.mm.setLODType(this.hub, type, options)
  }

  async onSetLODType(type: MMLODType, options?: any) {
    this.uiService.disableUI(false);

    if (this.missingLODtype == type) {
      try {
        await this.createMissingLOD(type, options);
      } catch (e) {
        // can't create LOD
        if (e == 'error-2d-flat-invalid') {
          alert(this.translate.instant('MODEL_MANAGER.ERROR_2D_FLAT'));
          // console.error(this.translate.instant('MODEL_MANAGER.ERROR_2D_FLAT'))
        }
        this.creatingLOD = false;
        this.uiService.enableUI();
        return;
      }
    }

    this.missingLODtype = undefined;

    try {
      await this.mm.setLODType(this.hub, type, options);
      // await this.mm.reload()
    } catch (e) {
      if (e === 'lod-type-missing') {
        this.missingLODtype = type;
      }

      if (e === 'proxy-different-density') {
        await this.createMissingLOD(type, options);
        await this.mm.setLODType(this.hub, type, options);
      }
    }
    this.uiService.enableUI();
  }

  async refresh() {
    await this.mm.refreshLODs(this.hub);
  }

  async onClickDeleteHub() {
    this.deleting = true;
    await this.mm.deleteHub(this.hub);
  }

  toggleSelection() {
    if (this.isActive) {
      this.mm.selectHub(undefined);
    } else {
      this.mm.selectHub(this.hub);
      this.helper.selectByHUBName(this.hub.name);
    }
  }

  toggleVisibility() {
    this.hub.hidden = !this.hub.hidden;
    this.mm.setHidden(this.hub);
  }

  autoRotateEnabled(): boolean {
    return this.hub.autorotate;
  }

  async toggleAutoRotate() {
    const newState = !this.hub.autorotate;
    await this.mm.setHUBAutoRotate(this.hub, newState);
    this.hub.autorotate = newState;
  }
}
