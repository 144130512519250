import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[swIndentSpacer]',
})
export class IndentDirective implements OnInit {
  @Input() swIndentSpacer = 0;

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  ngOnInit() {
    this.renderer.setStyle(
      this.element.nativeElement,
      'min-width',
      this.swIndentSpacer + 'rem'
    );
    this.renderer.setStyle(this.element.nativeElement, 'align-self', 'stretch');
    this.renderer.setStyle(
      this.element.nativeElement,
      'display',
      'inline-flex'
    );
  }
}
