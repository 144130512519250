<div class="row heading">
  <div class="icon dark mr" [inlineSVG]="'icon/sharing.svg'"></div>
  <div class="small-title">{{ "SETTINGS_SHARING.TITLE" | translate }}</div>
</div>
<div class="row" *ngFor="let s of access; let aIndex = index">
  <div>{{ s.email }}</div>
  <div class="spacer"></div>
  <!-- <mat-form-field>
        <mat-select [(value)]="s.access">
            <mat-option value="owner">{{ 'SETTINGS_SHARING.ROLE_OWNER' | translate }}</mat-option>
            <mat-option value="editor">{{ 'SETTINGS_SHARING.ROLE_EDITOR' | translate }}</mat-option>
            <mat-option value="readOnly">{{ 'SETTINGS_SHARING.ROLE_VIEWER' | translate }}</mat-option>
        </mat-select>
    </mat-form-field> -->
  <div class="tcell menu">
    <sw-dots-menu [itemID]="'sh' + aIndex">
      <ng-template [ngIf]="!isCurrentUser(s.email)">
        <div
          class="hover-btn red"
          [inlineSVG]="'icon/delete.svg'"
          (click)="onClickRemoveSharing(aIndex)"
          matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
          matTooltipPosition="above"
        ></div>
      </ng-template>
    </sw-dots-menu>
  </div>
</div>
<form name="newShare">
  <div class="row">
    <input
      type="email"
      name="shareWithEmail"
      email
      class="share-email"
      (keydown.enter)="addShareEmail()"
      [(ngModel)]="shareWithEmail"
      (blur)="addShareEmail()"
      placeholder="{{ 'SETTINGS_SHARING.EMAIL_PLACEHOLDER' | translate }}"
    />
    <div class="btn-primary grey" (click)="addShareEmail()">
      <div>{{ "SETTINGS_SHARING.ADD_BUTTON" | translate }}</div>
    </div>
  </div>
</form>
