import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { DataAttribute, types } from 'src/app/model/data-attribute.model';
import { TranslateService } from '@ngx-translate/core';
import { UnitConversionService } from 'src/app/modules/project/services/unit-conversion.service';
import { AttributeEditorService } from 'src/app/modules/attributes/attribute-editor.service';

@Component({
  selector: 'sw-attribute-editor',
  templateUrl: './attribute-editor.component.html',
  styleUrls: ['./attribute-editor.component.scss'],
})
export class AttributeEditorComponent {
  @Input() originalAttributes: DataAttribute[];
  @Input() attributes: DataAttribute[];
  @Output() attributesChange = new EventEmitter<DataAttribute[]>();

  @Input() lockedAttributeTags: string[] = [];

  selectedIndex: number = null;
  types: string[] = types;

  constructor(
    private translate: TranslateService,
    private unitConversion: UnitConversionService,
    private attrEditorService: AttributeEditorService
  ) {}

  drop(event: CdkDragDrop<DataAttribute[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    // this.attributesChange.next(this.node)
  }

  select(i) {
    this.selectedIndex = i;
  }

  validate() {
    //skip unchanged attributes
    const attrs = this.attrEditorService.getChangedAttributes(
      this.originalAttributes,
      this.attributes
    );
    this.attrEditorService.validateAttributes(attrs, this.lockedAttributeTags);
  }

  isEditLocked(attr: DataAttribute) {
    if (attr.tag == undefined) return false;
    if (attr.errors && attr.errors.length > 0) return false;

    return this.lockedAttributeTags.includes(attr.tag.toLowerCase());
    // return this.attrEditorService.lockedAttributeNames.includes(attr.name.toLowerCase())
  }

  tagChange(i, newTag: string) {
    // remove spaces from tag
    var newTagName = newTag.replace(new RegExp(' ', 'g'), '').trim();
    this.attributes[i].tag = newTagName;
    this.validate();
  }

  nameChange(i, newName: string) {
    let NAME_MAX_CHARS = 50;
    // remove spaces from name
    // newName = newName.replace(new RegExp(" ", 'g'), "")
    newName = newName.replace(/(?:\r\n|\r|\n)/g, '');
    this.attributes[i].name = newName.trim().substring(0, NAME_MAX_CHARS);
    this.validate();
  }

  tagPlaceholder(i) {
    if (this.attributes[i].name && this.attributes[i].name.length > 0) {
      return this.attrEditorService.getTagOrMakeTagFromName(this.attributes[i]);
    } else {
      return this.translate.instant('ATTR.TAG');
    }
  }

  onDuplicate(index) {
    let copy = this.translate.instant('ATTR.ATTRIBUTE_COPY');
    const clone: DataAttribute = JSON.parse(
      JSON.stringify(this.attributes[index])
    );
    clone.name += ' ' + copy; // Attribute name + Copy
    this.attributes.splice(index + 1, 0, clone);

    // this.nodeChange.next(this.node)
    this.validate();
  }

  onClickRemove(index) {
    this.attributes.splice(index, 1);

    // this.nodeChange.next(this.node)
    this.validate();
  }

  onChangeType(index, newType) {
    // var type = this.attributes[index].type
    if (newType != 'dropdown') {
      // remove list and options
      this.attributes[index].list = undefined;
      this.attributes[index].options = undefined;
      this.attributes[index].access = undefined;
    } else {
      this.attributes[index].access = 'LIST';
    }

    const currentType = this.attributes[index].type;
    if (currentType == newType) return;

    this.attributes[index].type = newType;
    this.attributes[index].units =
      this.unitConversion.getUnitsFromType(newType);
    this.attributes[index].value = this.unitConversion.valueFromRawValueAndUnit(
      this.attributes[index]
    );
  }

  addAttribute() {
    let name = ''; //this.translate.instant('ATTR.NEW_ATTRIBUTE')
    const n = new DataAttribute(name, '', null);
    n.type = 'text';
    n.units = 'STRING';
    n.access = 'TEXTBOX';
    if (this.attributes === undefined) {
      this.attributes = [n];
    } else {
      this.attributes.push(n);
    }
    // this.nodeChange.emit(this.node)

    this.validate();
  }

  attrTrack(index, attr) {
    return index;
  }
}
