import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ModelManagerHelperService } from '../model-manager-helper/model-manager-helper.service';
import { ModelManagerSketchUpService } from '../model-manager-sketch-up.service';
import { MMHubCandidate } from '../models/MMHubCandidate.model';
import { MMProxyDensity } from '../models/MMLOD.model';

enum MMSelectionError {
  NoSelection = 'no-selection',
  MultipleComponents = 'multiple-components-selected',
  PartOfHub = 'invalid-selection-part-of-hub',
}

export interface NewHubDialogData {
  hubName: string;
  definition: string;
  create2d: boolean;
  createProxy: MMProxyDensity;
}

@Component({
  selector: 'sw-new-hub-dialog',
  templateUrl: './new-hub-dialog.component.html',
  styleUrls: ['./new-hub-dialog.component.scss'],
})
export class NewHubDialogComponent implements OnInit, OnDestroy {
  proxyOptions = Object.values(MMProxyDensity);
  sketchupSelectedComponentName: string;
  sketchupSelectionError: string | null = null;

  fetchingData = false;
  largeModel = false;

  constructor(
    private mmHelper: ModelManagerHelperService,
    private mmSketchup: ModelManagerSketchUpService,
    public dialogRef: MatDialogRef<NewHubDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewHubDialogData
  ) {}

  selectionSubscription: Subscription;
  getSelectionTimer: any;
  ngOnInit(): void {
    this.initSelectionSubscription();
    this.getSelection();
    // this.initGetSelectionTimer()
  }

  ngOnDestroy(): void {
    clearInterval(this.getSelectionTimer);
    // this.selectionSubscription?.unsubscribe()
  }

  initSelectionSubscription(): void {
    this.selectionSubscription = this.mmSketchup.selectedHubs.subscribe(
      (candidates) => {
        // console.log("selected ", candidates.length, " candidates")
        // console.warn(candidates?.map(c => c.name).join(", "))
        this.processSelectionCandidates(candidates);
      }
    );
  }

  // initGetSelectionTimer(): void {
  //   this.getSelectionTimer = setInterval(async () => {
  //     if (this.fetchingData) return
  //     this.fetchingData = true
  //     const candidates = await this.mmHelper.getCandidatesFromSelection()
  //     this.processSelectionCandidates(candidates)
  //     // await this.getSelection()
  //     this.fetchingData = false
  //   }, 1000)
  // }

  processSelectionCandidates(candidates: MMHubCandidate[]) {
    try {
      if (candidates.length == 0) throw 'no-selection';

      // detect different definitions
      const definition = candidates[0].definition;
      candidates
        .filter((c, index) => index != 0)
        .forEach((c) => {
          if (c.definition != definition) throw 'multiple-components-selected';
        });

      if (candidates[0].hub || candidates[0].lod)
        throw 'invalid-selection-part-of-hub';

      if (this.mmHelper.hasHubChild(candidates[0])) throw 'has-hub-child';

      if (candidates[0].ancestor_hub) throw 'has-hub-parent';

      const name = candidates[0].name;

      this.largeModel = candidates[0].polyCount > 50000;

      if (this.sketchupSelectedComponentName == name) {
        return;
      }
      this.sketchupSelectedComponentName = name;
      this.data.hubName = name;
      this.data.definition = candidates[0].definition;
      this.sketchupSelectionError = null;
    } catch (e) {
      this.sketchupSelectionError = e;
      this.sketchupSelectedComponentName = undefined;
    }
  }

  async getSelection() {
    //read from file
    // var t1 = new Date()
    const candidates = await this.mmHelper.getCandidatesFromSelection();

    // var t2 = new Date(); var dif = t1.getTime() - t2.getTime();
    // var Seconds_from_T1_to_T2 = dif / 1000;
    // console.log("Got data in " + Math.abs(Seconds_from_T1_to_T2))

    this.processSelectionCandidates(candidates);
  }

  proxyOptionChanged(value: string) {
    this.data.createProxy = value as MMProxyDensity;
  }

  isValid(): boolean {
    return this.validName() && !this.sketchupSelectionError;
  }

  validName(): boolean {
    if (this.emptyName()) return false;
    if (this.duplicateName()) return false;

    return true;
  }

  emptyName(): boolean {
    return !this.data.hubName || this.data.hubName.length < 1;
  }

  duplicateName(): boolean {
    return Boolean(
      this.mmSketchup.hubs.getValue().find((h) => this.data.hubName == h.name)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
