import { Component, Input } from '@angular/core';
import { DataNode } from 'src/app/model/data-node.model';
import { ProjectData } from 'src/app/model/project-data.model';
import { DetailPanelService } from 'src/app/services/detail-panel.service';
import { SketchupService } from 'src/app/services/sketchup.service';
import { LibraryViewService } from '../../library/library-view.service';
import { DataNodeService } from '../../project/services/data-node.service';
import { ProjectService } from '../../project/services/project.service';
import { SmartworksPluginComponent } from '../swplugin/swplugin.component';

@Component({
  selector: 'sw-plugin-gardn8',
  templateUrl: './gardn8.component.html',
  styleUrls: ['./gardn8.component.scss'],
})
// implements OnInit
export class Gardn8Component extends SmartworksPluginComponent {
  // inherited from SmartworksPluginComponent
  @Input() declare projectData: ProjectData;
  @Input() declare rootNode?: DataNode;

  importDisabled: boolean = true;

  constructor(
    public sketchUp: SketchupService,
    public dataNodeService: DataNodeService,
    public projectService: ProjectService,
    public libraryViewService: LibraryViewService,
    public detailPanelService: DetailPanelService
  ) {
    super(sketchUp, dataNodeService, projectService);
  }

  // ngOnDestroy(): void {
  //   super.ngOnDestroy()
  // }

  import(): void {
    alert('TODO: scan file and import the gardn8 items');
  }

  uproot(): void {
    this.sketchUp.bridge.call('activateGardN8UprootTool');
  }

  addNewItem() {
    this.libraryViewService.toggleEditing(true);
    this.libraryViewService.addNewItemEvent.emit();
  }
}
