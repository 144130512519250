import {
  style,
  sequence,
  animate,
  trigger,
  transition,
  query,
} from '@angular/animations';

const enterAnimation = [
  style({ height: '0', opacity: 0 }),
  sequence([
    animate('.10s ease', style({ height: '*', opacity: 0 })),
    animate('.40s ease', style({ height: '*', opacity: 1 })),
  ]),
];

const leaveAnimation = [
  style({ height: '*', opacity: '1' }),
  sequence([
    animate('.15s ease', style({ height: '*', opacity: 0 })),
    animate('.30s ease', style({ height: '0', opacity: 0 })),
  ]),
];

export const animations = [
  trigger('parent', [transition(':enter', [])]),
  trigger('inout', [
    transition(':leave', [query('.label', leaveAnimation)]),
    transition(':enter', [query('.label', enterAnimation)]),
  ]),
];
