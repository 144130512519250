import { Fabric8Node } from '../../models/node.model';
import { Fabric8NodeType } from '../../models/Fabric8NodeType';

export function getNodesFlatList(
  types: Fabric8NodeType[],
  rootNode: Fabric8Node
): Fabric8Node[] {
  if (!rootNode) return [];
  const nodes: Fabric8Node[] = [];

  const traverse = (n: Fabric8Node, types: Fabric8NodeType[]) => {
    if (!types || (n.data && types.includes(n.type))) {
      nodes.push(n);
    }
    n.children?.forEach((c) => {
      traverse(c, types);
    });
  };

  traverse(rootNode, types);
  return nodes;
}
