<h1 *ngIf="data.title" mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p [innerHtml]="data.prompt"></p>
</div>
<div mat-dialog-actions>
  <button class="btn-primary grey" mat-button (click)="onNoClick()">
    {{ data.cancelButton }}
  </button>
  <div class="spacer"></div>
  <button class="btn-primary" mat-button [mat-dialog-close]="true">
    {{ data.actionButton }}
  </button>
</div>
