<div *ngFor="let g of list; let i = index; trackBy: itemByID"
[@inout]>
<div swDropZone class="inter"
    [wIndentLevel]="indentLevel"

    [wDropTarget]="container"
    [wDropTargetList]="list"
    [wDropTargetListIndex]="i"
    wDropAcceptType="GROUP"

    ></div>
    <!-- [wDropDisabled]="isDragging()" -->
    <ng-content>  
    </ng-content>
</div>
<ng-template [ngIf]="list.length>0"> 
<div swDropZone class="inter last-child-dropzone"
    [wIndentLevel]="indentLevel"

    [wDropTarget]="container"
    [wDropTargetList]="list"
    [wDropTargetListIndex]="list.length"
    wDropAcceptType="GROUP"

    ></div>
    <!-- [wDropDisabled]="isDragging()" -->
</ng-template>