import { Injectable } from '@angular/core';
import { Fabric8Object } from '../models/object.model';
import { CurrentObjectService } from './current-object.service';
import { Fabric8SketchupService } from './fabric8-sketchup.service';
import { ObjectsManagerService } from './objects-manager.service';

@Injectable({
  providedIn: 'root',
})
export class UndoService {
  undoStack: Map<string, string[]> = new Map<string, string[]>();
  undoCursor: Map<string, number> = new Map<string, number>();

  constructor(private objectsManager: ObjectsManagerService) {}

  addStepForObject(object: Fabric8Object) {
    console.log('addStepForObject', object.guid);
    if (!object) return;
    const guid = object.guid;
    const cursor = this.undoCursor.get(guid) || 0;
    if (!this.undoStack.has(guid)) {
      this.undoStack.set(guid, []);
    }

    const stack = this.undoStack.get(guid);

    // remove all steps after the current cursor
    stack.splice(cursor + 1, stack.length - cursor);

    const newStep = object.toJSON();
    // avoid adding the same step twice
    if (newStep == stack[stack.length - 1]) return;

    stack.push(newStep);
    this.undoStack.set(guid, stack);
    this.undoCursor.set(guid, stack.length - 1);

    this.log(guid);
  }

  log(guid: string) {
    // output a . character for each step,
    // and an x for the current step
    const stack = this.undoStack.get(guid);
    const cursor = this.undoCursor.get(guid) || 0;
    console.log(
      stack.map((step, index) => (index == cursor ? 'x' : '.')).join('')
    );
  }

  clearStack() {
    this.undoStack.clear();
    this.undoCursor.clear();
  }

  undo(object: Fabric8Object) {
    // console.log('undo');
    this.step(-1, object);
    this.log(object.guid);
  }

  redo(object: Fabric8Object) {
    // console.log('redo');
    this.step(1, object);
    this.log(object.guid);
  }

  private step(increment: number, object: Fabric8Object) {
    if (!object) return;
    const guid = object.guid;

    if (!this.undoStack.has(guid)) return;

    var cursor = this.undoCursor.get(guid) || 0;
    const stack = this.undoStack.get(guid);
    if (stack.length == 0) return;

    cursor = cursor + increment;
    if (cursor < 0 || cursor >= stack.length) return;

    const step = stack[cursor];

    const historyObject = new Fabric8Object(step);
    this.undoCursor.set(guid, cursor);

    this.objectsManager.updateObject(historyObject);
  }
}
