import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContextMenuData } from 'src/app/modules/fabric8/contextmenu/contextmenu.options';

@Injectable({
  providedIn: 'root',
})
export class Fabric8ContextMenuService {
  data = new BehaviorSubject<ContextMenuData>(undefined);

  constructor() {}

  open(data: ContextMenuData): void {
    this.data.next(data);
  }

  close(): void {
    this.data.next(undefined);
  }
}
