<ng-container [ngSwitch]="mm.state$ | async">
  <ng-container *ngSwitchCase="MMState.NotSketchup">
    <div id="splash" class="wbox center flex column">
      <div class="spacer"></div>
      <div class="center flex">
        <div class="title">
          <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div>
          <h1>{{ "MODEL_MANAGER.TITLE" | translate }}</h1>
        </div>
      </div>
      <p class="row" [innerHTML]="'MODEL_MANAGER.NOT_SKETCHUP' | translate"></p>
      <div class="spacer"></div>
      <div id="sw-logo" [inlineSVG]="'/logo-smartworks2.svg'"></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="MMState.NoFile">
    <div id="splash" class="wbox center flex column">
      <div class="spacer"></div>
      <div class="center flex">
        <div class="title">
          <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div>
          <h1>{{ "MODEL_MANAGER.TITLE" | translate }}</h1>
        </div>
      </div>
      <div class="spacer"></div>
      <div id="sw-logo" [inlineSVG]="'/logo-smartworks2.svg'"></div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div id="grid" class="wbox container">
      <div id="header">
        <div class="top row">
          <div class="icon" [inlineSVG]="'mm/model-manager-icon.svg'"></div>
          {{ "MODEL_MANAGER.TITLE" | translate }}
          <div class="spacer"></div>
          <!-- <mat-icon class="pointer" (click)="mm.reload()">refresh</mat-icon> -->
          <mat-icon inline class="pointer" (click)="onClickAddHub()"
            >add</mat-icon
          >
          <mat-icon inline class="pointer" (click)="openWizard()"
            >auto_fix_high</mat-icon
          >
          <mat-icon inline class="pointer" (click)="openSettings()"
            >tune</mat-icon
          >
        </div>
        <div class="row lods">
          <div
            class="refresh"
            [ngClass]="{ active: needsRebuild }"
            [matTooltip]="
              (needsRebuild
                ? 'MODEL_MANAGER.REBUILD_CHANGED_HUBS'
                : 'MODEL_MANAGER.REBUILD_HUBS'
              ) | translate
            "
            matTooltipPosition="above"
            (click)="rebuildHubs()"
          >
            <mat-icon inline class="icon lod-icon pointer">refresh</mat-icon>
          </div>
          <div
            *ngFor="let s of LODstates"
            [matTooltip]="s | titlecase"
            matTooltipPosition="above"
            class="icon lod-icon pointer"
            [inlineSVG]="'mm/i-' + s + '.svg'"
            (click)="mm.setGlobalLODType(s)"
          ></div>
        </div>
      </div>

      <ng-template [ngIf]="(mm.state$ | async) === MMState.Empty">
        <sw-message
          [message]="'MODEL_MANAGER.NO_HUBS' | translate"
          icon="smart_toy"
          actionText="{{ 'MODEL_MANAGER.NEW_BUTTON' | translate }}"
          (action)="onClickAddHub()"
        >
        </sw-message>
      </ng-template>

      <ng-template [ngIf]="(mm.state$ | async) === MMState.Loaded">
        <div id="content" class="scrolly">
          <sw-mm-hub
            *ngFor="let h of hubs; let i = index; trackBy: trackByName"
            [hub]="hubs[i]"
          >
          </sw-mm-hub>

          <div class="spacer"></div>
          <div id="sw-logo" [inlineSVG]="'/logo-smartworks2.svg'"></div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
