import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetailPanelService {

  public currentPanel = new BehaviorSubject<false | "labels" | "materials">(false)

  constructor() { }

  toggleShowingLabels() {
    if (this.currentPanel.getValue() == 'labels') {
      this.closeDetailPanel()
    } else {
      this.showLabels()
    }
  }

  showMaterials() {
    this.currentPanel.next('materials') 
  }

  showLabels()        { this.currentPanel.next('labels') }

  closeDetailPanel()  { this.currentPanel.next(false) }
}
