import { ProjectData } from './project-data.model';
import { UserAccess } from './user-access.model';

export class Project {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public access: UserAccess[],
    public data: ProjectData[],

    public sketchUpFilePath: string | undefined = undefined,
    public layoutFilePath: string | undefined = undefined
  ) {}
}
