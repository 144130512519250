import {
  animate,
  query,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';

const enterAnimation = [
  style({ height: '0', outline: '2px solid black' }),
  sequence([
    animate(
      '.10s ease',
      style({ height: '*', opacity: '1', outline: '2px solid black' })
    ),
    animate(
      '.50s ease',
      style({ height: '*', opacity: 1, outline: '2px solid transparent' })
    ),
  ]),
];

const leaveAnimation = [
  style({ height: '*', opacity: '1' }),
  sequence([
    animate('.10s ease', style({ height: '*', opacity: '.2' })),
    animate('.20s ease', style({ height: '0', opacity: 0 })),
  ]),
];

export const animations = [
  trigger('parent', [transition(':enter', [])]),
  trigger('inout', [
    transition(':leave', [
      query('.node.row', leaveAnimation, { optional: true }),
    ]),
    transition(':enter', [
      query('.node.row', enterAnimation, { optional: true }),
    ]),
  ]),
];
