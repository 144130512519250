import { Pt } from 'pts';
import { SkpPoint, Pt2skpPoint, skpPoint2Pt } from './Pt2skpPoint';
import { Fabric8SmartObjectData } from './smartgroup.model';
import { Fabric8Tool } from './tool.model';

export const IFabric8GuidelineSegment = Symbol('IFabric8GuidelineSegment');
export interface IFabric8GuidelineSegment {
  [IFabric8GuidelineSegment]: boolean;
  points: Pt[];
  tool: Fabric8Tool;
  smartObjectData?: Fabric8SmartObjectData;
}

export const SkpFabric8GuidelineSegment = Symbol('SkpFabric8GuidelineSegment');
export interface SkpFabric8GuidelineSegment {
  [SkpFabric8GuidelineSegment]: true;
  points: SkpPoint[];
  tool: Fabric8Tool;
  smartObjectData?: Fabric8SmartObjectData;
}

export class Fabric8GuidelineSegment implements IFabric8GuidelineSegment {
  [IFabric8GuidelineSegment] = false;
  points: Pt[] = [];
  tool: Fabric8Tool;
  smartObjectData?: Fabric8SmartObjectData;

  constructor(data: IFabric8GuidelineSegment);
  constructor(data: SkpFabric8GuidelineSegment);
  constructor(data: string);
  constructor(
    data: IFabric8GuidelineSegment | SkpFabric8GuidelineSegment | string
  ) {
    if (typeof data === 'string') {
      const e = JSON.parse(data);
      return this.fromSkp(e);
    }
    if (data[IFabric8GuidelineSegment]) {
      const e = data as IFabric8GuidelineSegment;
      this.points = e.points;
      this.tool = e.tool;
      this.smartObjectData = e.smartObjectData;
    } else {
      // SkpFabric8GuidelineSegment
      this.fromSkp(data as SkpFabric8GuidelineSegment);
    }
  }

  public toJSON(): string {
    const data = this.toSkp();
    return JSON.stringify(data, null, 2);
  }

  public toSkp(): SkpFabric8GuidelineSegment {
    const data: SkpFabric8GuidelineSegment = {
      [SkpFabric8GuidelineSegment]: true,
      points: this.points.map((point) => Pt2skpPoint(point)),
      tool: this.tool,
      smartObjectData: this.smartObjectData,
    };
    return data;
  }

  private fromSkp(data: SkpFabric8GuidelineSegment): Fabric8GuidelineSegment {
    this.points = data.points.map((point) => skpPoint2Pt(point));
    this.tool = data.tool;
    this.smartObjectData = data.smartObjectData;
    return this;
  }
}
