import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';

import { ProjectsGroup } from 'src/app/model/projects-group.model';
import { ProjectLiveUpdatesService } from 'src/app/services/liveupdates/project-live-updates.service';
import { ProjectGroupsManagerService } from 'src/app/modules/project-browser/groups-manager.service';

import { wDragDropListEvent } from 'src/app/modules/wTree/tree-node.model';
import { DropHelperService } from '../drop-helper.service';
import { animations } from './animations';
import { UpdateGroupMessage } from 'src/app/services/liveupdates/messages';

@Component({
  selector: 'sw-projects-group',
  templateUrl: './projects-group.component.html',
  styleUrls: ['./css/projects-group.component.scss', './css/drop-zone.scss'],
  animations: animations,
})
export class ProjectsGroupComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  constructor(
    public groupsManagerService: ProjectGroupsManagerService,
    private liveUpdateService: ProjectLiveUpdatesService,
    private dropHelper: DropHelperService,
    private uiService: UIService
  ) {}

  @Input() parent: ProjectsGroup;
  @Input() group: ProjectsGroup;
  @Output() groupChange: EventEmitter<ProjectsGroup> =
    new EventEmitter<ProjectsGroup>();

  @Input() indentLevel = 0;
  // isHidden = false

  // dragging info
  @Input() parentIsDragged = false;
  dragging = false;
  isDragging(): boolean {
    return this.dragging || this.parentIsDragged;
  }

  state:
    | 'Init'
    | 'LoadingChildren'
    | 'FailedLoadingChildren'
    | 'ChildrenLoaded' = 'Init';

  isSelected: boolean;
  selectionSubscription: Subscription;
  liveUpdateSub: Subscription;

  ngOnInit(): void {
    this.selectionSubscription =
      this.groupsManagerService.selectedGroup$.subscribe((selectedGroup) => {
        this.isSelected = selectedGroup === this.group;
      });
    this.initLiveUpdateSubscription();
  }

  ngOnDestroy(): void {
    this.selectionSubscription?.unsubscribe();
    this.liveUpdateSub?.unsubscribe();
  }

  private initLiveUpdateSubscription() {
    this.liveUpdateSub = this.liveUpdateService.updateGroupSignal.subscribe(
      (m: UpdateGroupMessage) => {
        if (m.groupID != this.group.id) return;
        this.reloadGroup();
      }
    );
  }

  async reloadGroup() {
    try {
      await this.groupsManagerService.reloadGroup(this.group);
      this.reloadChangedChildren('groups');
      this.reloadChangedChildren('projects');
      this.liveUpdateService.loadedGroup(this.group);
    } catch (err) {
      console.error(err);
      console.error("can't reload group: " + this.group.id);
    }
  }

  async reloadChangedChildren(type: 'groups' | 'projects') {
    if (type == 'groups') {
      var ids = this.group.groupIDs;
      var list: any = this.group.groups;
    } else {
      var ids = this.group.projectIDs;
      var list: any = this.group.projects;
    }

    for (var childIDIndex = 0; childIDIndex < ids.length; childIDIndex++) {
      var childID = ids[childIDIndex];
      const childIndex = list.findIndex((c) => c.id == childID);

      if (childIDIndex == childIndex) {
        // the child is already loaded and placed at the right index
        continue;
      }

      if (childIndex == -1) {
        // the childID is not found / not loaded
        try {
          if (type == 'groups') {
            const nodes = await this.groupsManagerService.fetchGroups([
              childID,
            ]);
            var node: any = nodes[0];
          } else {
            const nodes = await this.groupsManagerService.getProjectsMeta([
              childID,
            ]);
            var node: any = nodes[0];
          }
          // insert loaded node at childIDindex
        } catch (err) {
          console.error("can't load node");
          console.error(err);
        }
        list.splice(childIDIndex, 0, node);
        continue;
      }

      // Child at a different index
      // swap it/move it to the correct position
      moveItemInArray(list, childIndex, childIDIndex);
    }

    // if the children array is larger, it means at least one was deleted
    const delta = list.length - ids.length;
    if (delta > 0) {
      list.splice(ids.length, delta);
    }
  }

  ngAfterContentInit(): void {
    this.loadChildren();
  }

  async loadChildren() {
    if (this.hasChildren() === false) {
      this.state = 'ChildrenLoaded';
      return;
    }
    this.state = 'LoadingChildren';
    // console.warn("loadChildren called on :" + this.group.id)
    try {
      this.group = await this.groupsManagerService.loadChildren(this.group);
      // this.groupChange.next(this.group)
      // this.state = "FailedLoadingChildren"
      this.state = 'ChildrenLoaded';
      this.liveUpdateService.loadedGroup(this.group);
    } catch (e) {
      this.state = 'FailedLoadingChildren';
      this.uiService.showErrors(e);
    }
  }

  async updateGroupName(name, group: ProjectsGroup) {
    this.uiService.disableUI(true);
    await this.groupsManagerService.updateGroup({ ...group, name });
    this.uiService.enableUI();
  }

  async onClickDeleteGroup(id) {
    this.uiService.disableUI(true);
    try {
      await this.groupsManagerService.deleteGroup(id);
      this.parent.groups = this.parent.groups.filter((g) => g.id !== id);
      await this.groupsManagerService.updateGroup(this.parent);
      this.groupsManagerService.selectGroup(undefined);
    } catch (err) {
      this.uiService.showErrors(err);
    }
    this.uiService.enableUI();
  }

  dragStarted(event) {
    this.dragging = true;
  }

  dragReleased(event) {
    this.dragging = false;
  }

  dropped(event: wDragDropListEvent) {
    const groupsToUpdate = this.dropHelper.processDrop(event);
    this.updateGroups(groupsToUpdate);
  }

  private async updateGroups(groupsToUpdate: ProjectsGroup[]) {
    this.uiService.disableUI(true);
    try {
      await this.groupsManagerService.updateGroups(groupsToUpdate);
    } catch {
      // TODO: restore previous state or retry API call
      console.error('updating groups API call failed');
    }
    // this.groupChange.emit(this.parent)
    this.uiService.enableUI();
  }

  // tracking
  projectByID(index, project) {
    return project.id;
  }

  groupByID(index, group) {
    return group.id;
  }

  hasChildren(): boolean {
    if (this.group == undefined) {
      return false;
    }

    if (this.group.groupIDs === undefined) {
      this.group.groupIDs = [];
    }
    if (this.group.projectIDs === undefined) {
      this.group.projectIDs = [];
    }

    if (this.group.projectIDs.length || this.group.groupIDs.length) {
      return true;
    }

    return false;
  }
}
