<ng-container *ngTemplateOutlet="parent ? theGroup : childNodes"></ng-container>

<ng-template #theGroup>
  <div
    swDrag
    (wDragStarted)="dragStarted($event)"
    (wDragReleased)="dragReleased($event)"
    (wDrop)="dropped($event)"
    [wDragNode]="group"
    wDragObjectType="GROUP"
    [wDragParent]="parent"
    [wDragParentList]="parent.groups"
  >
    <ng-container [ngTemplateOutlet]="groupRow"></ng-container>

    <div [hidden]="!(hasChildren() && group.expanded)">
      <ng-container [ngTemplateOutlet]="childNodes"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #groupRow>
  <div
    class="group-row row"
    swDragHandle
    (click)="groupsManagerService.selectGroup(group)"
    [ngClass]="{
      dragging: dragging,
      'dragging-parent': parentIsDragged,
      selected: isSelected
    }"
  >
    <sw-tree-indent [level]="indentLevel"></sw-tree-indent>

    <div class="name">
      <div class="icon dark mr" [inlineSVG]="'icon/group.svg'"></div>
      <sw-editable
        class="small-title"
        [(value)]="group.name"
        (valueChange)="updateGroupName($event, group)"
      >
      </sw-editable>
      <div
        swDropZone
        [wDropDisabled]="isDragging()"
        [wDropTarget]="group"
        [wDropTargetList]="group.groups"
        [wDropTargetListIndex]="0"
        wDropAcceptType="GROUP"
      ></div>
      <div
        swDropZone
        [wDropDisabled]="isDragging()"
        [wDropTarget]="group"
        [wDropTargetList]="group.projects"
        [wDropTargetListIndex]="0"
        wDropAcceptType="PROJECT"
      ></div>
    </div>

    <div class="spacer"></div>
    <!-- expand/collapse arrow -->
    <ng-template [ngIf]="hasChildren()">
      <div
        class="hover-btn arrow transparent"
        [ngClass]="{ collapsed: !group.expanded, expanded: group.expanded }"
        (click)="group.expanded = !group.expanded"
      ></div>
    </ng-template>
    <ng-template [ngIf]="!hasChildren()">
      <sw-dots-menu [itemID]="group.id">
        <div
          class="hover-btn red"
          [inlineSVG]="'icon/delete.svg'"
          (click)="onClickDeleteGroup(group.id)"
          matTooltip="{{ 'BROWSE.DELETE_GROUP_TOOLTIP' | translate }}"
          matTooltipPosition="above"
        ></div>
      </sw-dots-menu>
    </ng-template>
  </div>
</ng-template>

<ng-template #childNodes>
  <ng-template [ngIf]="state === 'LoadingChildren'">
    <!-- <mat-spinner class="center" [diameter]="20"></mat-spinner> -->
    <ng-container [ngTemplateOutlet]="childProjectsPlaceholders"></ng-container>
  </ng-template>
  <ng-template [ngIf]="state === 'FailedLoadingChildren'">
    <div class="flex center">
      <mat-icon>cloud_off</mat-icon>&nbsp;
      {{ "BROWSE.FAILED_LOADING_CHILDREN" | translate }}
    </div>
  </ng-template>
  <ng-template [ngIf]="state === 'ChildrenLoaded'">
    <div @parent>
      <ng-container [ngTemplateOutlet]="childProjects"></ng-container>
      <ng-container [ngTemplateOutlet]="childGroups"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #childProjectsPlaceholders>
  <div
    *ngFor="let p of group.projectIDs; let i = index; trackBy: projectByID"
    class="row project-placeholder"
  >
    <div [swIndentSpacer]="indentLevel + 1"></div>
    <mat-progress-bar
      mode="indeterminate"
      style="width: 4rem"
    ></mat-progress-bar>
  </div>
  <div
    *ngFor="let p of group.groupIDs; let i = index; trackBy: groupByID"
    class="row project-placeholder"
  >
    <div [swIndentSpacer]="indentLevel + 1"></div>
    <mat-progress-bar
      mode="indeterminate"
      style="width: 4rem"
    ></mat-progress-bar>
  </div>
</ng-template>

<ng-template #childProjects>
  <div
    *ngFor="let p of group.projects; let i = index; trackBy: projectByID"
    [@inout]
  >
    <div
      swDropZone
      class="inter"
      [wIndentLevel]="indentLevel + 1"
      [wDropTarget]="group"
      [wDropTargetList]="group.projects"
      [wDropTargetListIndex]="i"
      wDropAcceptType="PROJECT"
      [wDropDisabled]="isDragging()"
    ></div>
    <sw-project-row
      [indentLevel]="indentLevel + 1"
      [parentIsDragged]="isDragging()"
      [(parent)]="group"
      [project]="group.projects[i]"
    ></sw-project-row>
  </div>
  <!-- last child dropzone -->
  <ng-template [ngIf]="group.projects.length > 0">
    <div
      swDropZone
      class="inter last-child-dropzone"
      [wIndentLevel]="indentLevel + 1"
      [wDropTarget]="group"
      [wDropTargetList]="group.projects"
      [wDropTargetListIndex]="group.projects.length"
      wDropAcceptType="PROJECT"
      [wDropDisabled]="isDragging()"
    ></div>
  </ng-template>
</ng-template>

<ng-template #childGroups>
  <!-- <sw-drop-list 
         [(list)]="group.groups"
         [container]="group"
         [indentLevel]="indentLevel + 1"
         listObjectType="GROUP"
    ></sw-drop-list> -->
  <div
    *ngFor="let g of group.groups; let i = index; trackBy: groupByID"
    [@inoutgroup]
  >
    <div
      swDropZone
      class="inter"
      [wIndentLevel]="indentLevel + 1"
      [wDropTarget]="group"
      [wDropTargetList]="group.groups"
      [wDropTargetListIndex]="i"
      wDropAcceptType="GROUP"
      [wDropDisabled]="isDragging()"
    ></div>
    <sw-projects-group
      [indentLevel]="indentLevel + 1"
      [parentIsDragged]="isDragging()"
      [parent]="group"
      [(group)]="group.groups[i]"
    >
    </sw-projects-group>
    <!-- (groupChange)="console.log('group changed')"> -->
  </div>
  <ng-template [ngIf]="group.groups.length > 0">
    <div
      swDropZone
      class="inter last-child-dropzone"
      [wIndentLevel]="indentLevel + 1"
      [wDropTarget]="group"
      [wDropTargetList]="group.groups"
      [wDropTargetListIndex]="group.groups.length"
      wDropAcceptType="GROUP"
      [wDropDisabled]="isDragging()"
    ></div>
  </ng-template>
</ng-template>
