import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  NotificationsService,
  SWNotification,
} from 'src/app/components/sw-notification/notifications.service';

@Component({
  selector: 'sw-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  constructor(private notificationService: NotificationsService) {}

  notificationSubscription: Subscription;
  notification: SWNotification = undefined;

  ngOnInit(): void {
    this.notificationSubscription =
      this.notificationService.notification$.subscribe((n) => {
        console.log(n);
        this.notification = n;
      });
  }

  dismiss(): void {
    this.notificationService.readNotification(this.notification);
  }

  ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
  }
}
