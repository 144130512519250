import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SketchupService } from 'src/app/services/sketchup.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialPathsService {
  localFolder: string | undefined;
  folderPaths$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  MATERIALS_FOLDER_KEY = 'MATERIALS_FOLDER_PATHS';

  constructor(private sketchup: SketchupService) {
    this.readLocalMaterialsFolderPaths();
  }

  async readLocalMaterialsFolderPaths() {
    try {
      const paths = await this.sketchup.localStorageGet(
        this.MATERIALS_FOLDER_KEY
      );
      this.folderPaths$.next(paths || []);
    } catch (e) {
      console.info('Error reading local materials folder paths', e);
      this.folderPaths$.next([]);
    }
  }

  async addLocalMaterialsFolder(path: string) {
    const currentPaths = this.folderPaths$.getValue();
    if (!this.folderPathExists(path)) {
      currentPaths.push(path);
      await this.updateFolderPaths(currentPaths);
    }
  }

  async removeLocalMaterialsFolder(path: string) {
    const currentPaths = this.folderPaths$.getValue();
    const pathIndex = currentPaths.indexOf(path);
    if (pathIndex > -1) {
      currentPaths.splice(pathIndex, 1);
    }
    await this.updateFolderPaths(currentPaths);
  }

  async updateFolderPaths(paths: string[]) {
    await this.sketchup.localStorageSet(this.MATERIALS_FOLDER_KEY, paths);
    this.folderPaths$.next(paths);
  }

  async pickLocalMaterialsFolder(): Promise<string> {
    const path = await this.sketchup.bridge.get(
      'pickMaterialsFolder',
      this.localFolder
    );
    return path;
  }

  private folderPathExists(path: string): boolean {
    return this.folderPaths$.getValue().includes(path);
  }
}
