export enum MMLODType {
  Empty = 'empty',
  TwoD = '2d',
  Proxy = 'proxy',
  // LOD = "lod",
  Original = 'full',
}

export enum MM2DType {
  Box = 'box',
  Center = 'center',
  Top = 'top',
  Front = 'front',
  Side = 'side',
}

export enum MMProxyDensity {
  None = 'none',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export const LODstates = Object.values(MMLODType);

export interface MMLOD {
  id: string;
  name: string;
  type: MMLODType;
}
