<section class="sketchup-file">
  <div class="row">
    <div class="icon dark mr" [inlineSVG]="'icon/sketchup.svg'"></div>
    <div class="small-title">
      {{ "SETTINGS_FILE_LINK.SU_FILE_LINK_TITLE" | translate }}
    </div>
    <div class="spacer"></div>
  </div>

  <ng-template
    [ngIf]="sketchupService.isSketchup"
    [ngIfElse]="openFileInSketchUp"
  >
    <div class="name">
      <ng-template
        [ngIf]="hasSketchUpLinkedFile()"
        [ngIfElse]="notLinkedMessage"
      >
        <small>{{ "SETTINGS_FILE_LINK.LOCAL_SKETCHUP_PATH" | translate }}</small
        ><br />
        {{ project.sketchUpFilePath }}
      </ng-template>
      <ng-template #notLinkedMessage>
        {{ "SETTINGS_FILE_LINK.SKETCHUP_NOT_LINKED" | translate }}
      </ng-template>
    </div>
    <!-- <div class="template-name"
                [innerHtml]='lastFileSync(p)'></div> -->
    <div class="row center">
      <ng-template [ngIf]="hasSketchUpLinkedFile()">
        <div
          class="btn-primary destructive right-anim"
          (click)="unlinkFile('sketchup')"
        >
          {{ "SETTINGS_FILE_LINK.UNLINK_FILE_BUTTON" | translate }}
        </div>
      </ng-template>
      <div class="spacer"></div>
      <div
        class="btn-primary right-anim"
        (click)="relinkSketchUpFile()"
        [matTooltip]="
          sketchupService.isSketchup
            ? undefined
            : ('SETTINGS_FILE_LINK.OPEN_IN_SKETCHUP' | translate)
        "
        matTooltipPosition="above"
      >
        {{
          (hasSketchUpLinkedFile()
            ? "SETTINGS_FILE_LINK.RELINK"
            : "SETTINGS_FILE_LINK.LINK"
          ) | translate
        }}
      </div>
    </div>
  </ng-template>

  <ng-template #openFileInSketchUp>
    <div class="row flex buttons">
      <div class="spacer"></div>
      <small
        class="hint"
        [innerHTML]="'SETTINGS_FILE_LINK.OPEN_IN_SKETCHUP' | translate"
      >
      </small>
      <div class="spacer"></div>
    </div>
  </ng-template>
</section>
<hr />
<section class="sketchup-file">
  <div class="row">
    <div class="icon dark mr" [inlineSVG]="'icon/layout.svg'"></div>
    <div class="small-title">
      {{ "SETTINGS_FILE_LINK.LAYOUT_FILE_LINK_TITLE" | translate }}
    </div>
    <div class="spacer"></div>
  </div>

  <ng-template
    [ngIf]="sketchupService.isSketchup"
    [ngIfElse]="openLayoutInSketchUp"
  >
    <div class="name">
      <ng-template
        [ngIf]="hasLayOutLinkedFile()"
        [ngIfElse]="layoutNotLinkedMessage"
      >
        <small>{{ "SETTINGS_FILE_LINK.LOCAL_LAYOUT_PATH" | translate }}</small
        ><br />
        {{ project.layoutFilePath }}
      </ng-template>
      <ng-template #layoutNotLinkedMessage>
        {{ "SETTINGS_FILE_LINK.LAYOUT_NOT_LINKED" | translate }}
      </ng-template>
    </div>
    <!-- <div class="template-name"
                [innerHtml]='lastFileSync(p)'></div> -->
    <div class="row center">
      <ng-template [ngIf]="hasLayOutLinkedFile()">
        <div
          class="btn-primary destructive right-anim"
          (click)="unlinkFile('layout')"
        >
          {{ "SETTINGS_FILE_LINK.UNLINK_FILE_BUTTON" | translate }}
        </div>
      </ng-template>
      <div class="spacer"></div>
      <div
        class="btn-primary right-anim"
        (click)="relinkLayOutFile()"
        [matTooltip]="
          sketchupService.isSketchup
            ? undefined
            : ('SETTINGS_FILE_LINK.OPEN_IN_SKETCHUP' | translate)
        "
        matTooltipPosition="above"
      >
        {{
          (hasLayOutLinkedFile()
            ? "SETTINGS_FILE_LINK.RELINK"
            : "SETTINGS_FILE_LINK.LINK"
          ) | translate
        }}
      </div>
    </div>
  </ng-template>

  <ng-template #openLayoutInSketchUp>
    <div class="row flex buttons">
      <div class="spacer"></div>
      <small
        class="hint"
        [innerHTML]="'SETTINGS_FILE_LINK.OPEN_IN_SKETCHUP' | translate"
      >
      </small>
      <div class="spacer"></div>
    </div>
  </ng-template>
</section>
