import { Directive } from '@angular/core';

@Directive({
  selector: '[swDragHandle]',
})
// implements OnDestroy, OnInit
export class DragHandleDirective {
  constructor() {}
  // ngOnDestroy(): void {
  //   // throw new Error('Method not implemented.');
  // }
  // ngOnInit(): void {
  //   // throw new Error('Method not implemented.');
  // }
}
