import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SwConfirmDialogComponent } from 'src/app/shared/sw-confirm-dialog';
import { Fabric8Element } from '../models/element.model';
import { Fabric8Node } from '../models/node.model';
import { Fabric8NodeType } from '../models/Fabric8NodeType';
import { Fabric8Object } from '../models/object.model';
import { CurrentObjectService } from '../services/current-object.service';
import { ObjectsManagerService } from '../services/objects-manager.service';
import { ToolService } from '../services/tool.service';
import { Fabric8NodeDataPointIndex } from '../services/toolHandlers/models/Fabric8NodeDataPointIndex.model';
import { HoverService } from '../services/hover.service';

@Component({
  selector: 'sw-fabric8-elements',
  templateUrl: './fabric8-elements.component.html',
  styleUrls: ['./fabric8-elements.component.scss', './css/elements.scss'],
})
export class Fabric8ElementsComponent implements OnInit, OnDestroy {
  @Input() object: Fabric8Object;
  @Output() objectChange = new EventEmitter<Fabric8Object>();

  hoveredElementIndicesSubscription: Subscription;
  hoveredElementIndices: Fabric8NodeDataPointIndex[];

  hoveredElementIdsSubscription: Subscription;
  hoveredElementIds: string[] = [];

  // selectedElement: Fabric8Element
  // selectedElementIndex: Fabric8Element

  constructor(
    public toolService: ToolService,
    private translate: TranslateService,
    private hoverService: HoverService,
    private objectsManager: ObjectsManagerService,
    private currentObject: CurrentObjectService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // this.hoveredElementIdsSubscription =
    //   this.hoverService.hoveredElementIds$.subscribe((ids: string[]) => {
    //     this.hoveredElementIds = ids;
    //   });

    this.hoveredElementIndicesSubscription =
      this.hoverService.hoveredElementIndices$.subscribe(
        (indices: Fabric8NodeDataPointIndex[]) => {
          this.hoveredElementIndices = indices;
        }
      );
  }

  ngOnDestroy(): void {
    this.hoveredElementIndicesSubscription?.unsubscribe();
  }

  // updateElement(element: Fabric8Element) {
  //   this.toolService.updateElement(element);
  //   this.currentObject.updateCurrentObject(true);
  // }

  selectElement(element: Fabric8Element) {
    // this.selectedElement = element
    // this.hoverService.setPoint(element.points[0]);
  }

  deleteNode(node: Fabric8Node) {
    const hasNoMoreFabric8Data = this.hasNoMoreFabric8Data();

    if (!hasNoMoreFabric8Data) return;

    const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
      width: '300px',
      data: {
        title: this.translate.instant('FABRIC8.DELETE_TITLE'),
        prompt: this.translate.instant('FABRIC8.DELETE_MESSAGE'),
        cancelButton: this.translate.instant('GLOBAL.CANCEL'),
        actionButton: this.translate.instant('GLOBAL.DELETE'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.objectsManager.deleteObject(this.object);
        // this.toolService.deleteCurrentObject();
        // _.delay(() => {
        //   this.toolService.loadObjects();
        // }, 500);
      }
    });
  }

  private hasNoMoreFabric8Data() {
    const elementsCount =
      this.objectsManager.getElementsFlatListForObject(
        [
          Fabric8NodeType.Element,
          Fabric8NodeType.Guideline,
          Fabric8NodeType.GuidelineSegment,
        ],
        this.object
      ).length || 0;
    // const guidelinesCount = this.object.guidelines?.length || 0;
    const fabric8DataLength = elementsCount;

    return fabric8DataLength === 0;
  }
}
