import { Injectable } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

function _document(): any {
  return document;
}

@Injectable({
  providedIn: 'root',
})
export class WindowRef {
  constructor() {}

  get nativeWindow(): any {
    return _window();
  }

  get nativeDocument(): any {
    return _document();
  }
}
