import { Injectable } from '@angular/core';
import { SketchupService } from 'src/app/services/sketchup.service';

@Injectable({
  providedIn: 'root',
})
export class PluginActionsService {
  constructor(private sketchUp: SketchupService) {}

  public itemActionForPlugin(pluginID: string, item: any) {
    if (pluginID == 'gardn8') {
      this.sketchUp.bridge.call('activateGardN8PlantTool', item);
    }
  }
}
