import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sw-dots-menu',
  templateUrl: './dots-menu.component.html',
  styleUrls: ['./dots-menu.component.scss'],
  animations: [
    trigger('panelInOut', [
      transition('void => *', [
        style({
          transform: 'translateX(0rem)',
          opacity: 0,
          pointerEvents: 'none',
        }),
        animate('50ms ease-out'),
      ]),
      // transition('* => void', [
      //     animate("100ms ease-out"),
      //     style({transform: 'translateX(3rem)', opacity: 0})
      // ])
    ]),
  ],
})
export class DotsMenuComponent {
  @Input() itemID: string;
  @Input() light = false;
  public isActive: boolean = false;

  constructor() {}
}
