import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { LibraryItem } from 'src/app/model/library-item.model';
import { LibraryLabel } from 'src/app/model/library-label.model';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { DetailPanelService } from 'src/app/services/detail-panel.service';
import { SearchService } from 'src/app/services/search.service';
import { SwConfirmDialogComponent } from 'src/app/shared/sw-confirm-dialog';
import { LibraryLabelsService } from '../../library-labels.service';
import { LibraryViewService } from '../../library-view.service';
import { animations } from './animations';

@Component({
  selector: 'sw-library-labels-list',
  templateUrl: './library-labels-list.component.html',
  styleUrls: ['./library-labels-list.component.scss'],
  animations: animations,
  providers: [SearchService],
})
export class LibraryLabelsListComponent implements OnInit, OnDestroy {
  state: 'Loading' | 'ItemsLoaded' | 'NoResults' = 'Loading';
  plugin: SWPlugin;
  labels: LibraryLabel[];
  labelsSubscription: Subscription;
  editingLabel: LibraryLabel = null;
  currentItem: LibraryItem;
  currentItemSubscription: Subscription;

  constructor(
    public libraryViewService: LibraryViewService,
    public detailPanelService: DetailPanelService,
    private libraryLabelsService: LibraryLabelsService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private ui: UIService,
    public searchService: SearchService
  ) {
    this.labelsSubscription = this.libraryLabelsService.labels.subscribe(
      (labels) => {
        this.labels = labels;

        if (labels == null) {
          this.state = 'Loading';
          return;
        }

        if (this.labels.length == 0) {
          this.state = 'NoResults';
        } else {
          this.state = 'ItemsLoaded';
        }

        if (this.editingLabel) {
          this.selectLabelWithID(this.editingLabel.id);
        }
      }
    );
  }

  ngOnInit(): void {
    this.plugin = this.libraryViewService.plugin;
    this.subscribeToCurrentItem();
  }

  ngOnDestroy(): void {
    this.currentItemSubscription?.unsubscribe();
  }

  subscribeToCurrentItem() {
    this.currentItemSubscription = this.libraryViewService.item.subscribe(
      (item) => {
        this.currentItem = item;
      }
    );
  }

  onClickEditLabel(label: LibraryLabel) {
    if (this.editingLabel == label) {
      this.editingLabel = null;
      return;
    }

    this.editingLabel = label;
  }

  deselectItem() {
    console.error('Not implemented');
  }

  endLabelEditing(label: LibraryLabel = null) {
    if (label) {
      this.libraryLabelsService.updateLabelUserAction(label);
    }
    this.editingLabel = null;
  }

  async clickBox(label: LibraryLabel) {
    if (this.currentItem.labelIDs.includes(label.id)) {
      this.currentItem.labelIDs = this.currentItem.labelIDs.filter(
        (l) => l != label.id
      );
      this.currentItem.labels = this.currentItem.labels.filter(
        (l) => l.id != label.id
      );
    } else {
      this.currentItem.labelIDs.push(label.id);
      this.currentItem.labels.push(label);
    }
    this.ui.disableUI(false);
    try {
      await this.libraryViewService.saveItem(this.currentItem);
    } catch (err) {
      console.error(err);
    }
    this.ui.enableUI();
  }

  async onClickAddItem() {
    const labelName = this.translate.instant('LABELS.NEW_LABEL_NAME');
    var newLabel = await this.libraryLabelsService.createLabel(
      this.plugin.id,
      labelName
    );
    this.selectLabelWithID(newLabel.id);
  }

  selectLabelWithID(labelID: string) {
    const selectedLabel = this.labels.find((l) => l.id == labelID);
    this.editingLabel = selectedLabel || null;
  }

  onDeleteClicked(label: LibraryLabel) {
    const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
      width: '300px',
      data: {
        title: this.translate.instant('DELETE_LABEL.TITLE'),
        prompt: this.translate.instant('DELETE_LABEL.MESSAGE', label),
        cancelButton: this.translate.instant('GLOBAL.CANCEL'),
        actionButton: this.translate.instant('GLOBAL.DELETE'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
        this.deleteLabel(label);
      } else {
        // closed
      }
    });
  }

  deleteLabel(label: LibraryLabel) {
    this.libraryLabelsService.deleteLabel(label);
  }

  // tracking
  labelByID(index, item) {
    return item.id;
  }

  filteredLabels() {
    var listLabels = this.labels;

    const search = this.searchService.query().query;
    if (search) {
      listLabels = listLabels.filter((l) =>
        l.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (this.currentItem) {
      listLabels = listLabels.filter(
        (l) => !this.currentItem.labelIDs.includes(l.id)
      );
      listLabels = [...this.currentItem.labels, ...listLabels];
    }

    return listLabels;
  }

  close() {
    this.detailPanelService.closeDetailPanel();
  }
}
