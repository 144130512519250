import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SWCanvasComponent } from './swcanvas/swcanvas.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { AttributesModule } from '../attributes/attributes.module';
import { WTreeModule } from '../wTree/w-tree.module';
import { ContextmenuComponent } from './contextmenu/contextmenu.component';
import { Fabric8ElementComponent } from './fabric8-elements/fabric8-element/fabric8-element.component';
import { Fabric8ElementsComponent } from './fabric8-elements/fabric8-elements.component';
import { Fabric8GuidelineRowComponent } from './fabric8-elements/fabric8-guideline-row/fabric8-guideline-row.component';
import { Fabric8NodeComponent } from './fabric8-elements/fabric8-node/fabric8-node.component';
import { SwToolConfigurationComponent } from './modal/sw-tool-configuration.component';
import { ObjectsPanelComponent } from './objects-panel/objects-panel.component';
import { Fabric8SketchupService } from './services/fabric8-sketchup.service';
import { Fabric8SettingsComponent } from './settings/fabric8-settings.component';
import { Fabric8SettingsService } from './settings/fabric8-settings.service';
import { ToolGroupComponent } from './toolset/tool-group/tool-group.component';
import { ToolRowComponent } from './toolset/tool-row/tool-row.component';
import { ToolsetComponent } from './toolset/toolset.component';
import { Fabric8GuidelineSegmentRowComponent } from './fabric8-elements/fabric8-guideline-segment-row/fabric8-guideline-segment-row.component';
import { GetDataAttributesPipe } from './fabric8-elements/fabric8-guideline-segment-row/GetDataAttributesPipe';
import { Fabric8GuidelineSegmentAttributesComponent } from './fabric8-elements/fabric8-guideline-segment-attributes/fabric8-guideline-segment-attributes.component';

const routes: Routes = [
  {
    path: 'fabric8',
    pathMatch: 'full',
    component: SWCanvasComponent,
  },
  {
    path: 'fabric8/settings',
    pathMatch: 'full',
    component: Fabric8SettingsComponent,
  },
  {
    path: 'fabric8/toolsets',
    pathMatch: 'full',
    component: ToolsetComponent,
  },
];

@NgModule({
  declarations: [
    SWCanvasComponent,
    ToolbarComponent,
    Fabric8SettingsComponent,
    Fabric8ElementsComponent,
    ObjectsPanelComponent,
    ToolsetComponent,
    ContextmenuComponent,
    SwToolConfigurationComponent,
    ToolGroupComponent,
    ToolRowComponent,
    Fabric8NodeComponent,
    Fabric8GuidelineRowComponent,
    Fabric8ElementComponent,
    Fabric8GuidelineSegmentRowComponent,
    GetDataAttributesPipe,
    Fabric8GuidelineSegmentAttributesComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    AttributesModule,
    SharedModule,
    WTreeModule,
    DragDropModule,
  ],
  providers: [Fabric8SettingsService, Fabric8SketchupService],
  exports: [RouterModule],
})
export class Fabric8Module {}
