import { clone } from 'lodash';
import { Pt } from 'pts';
import { Fabric8Element, IFabric8Element } from '../../models/element.model';
import { Fabric8GuidelineSegment } from '../../models/guideline-segment.model';
import { Fabric8Node } from '../../models/node.model';
import { Fabric8EquallySpacedHolesParameters } from '../../models/smartgroup.model';
import { newNodeWithData } from '../nodes/newNodeWithData';

export function addEquallySpacedDrillHoles(
  node: Fabric8Node,
  data: Fabric8GuidelineSegment,
  oldIds: string[]
) {
  const params = data.smartObjectData
    .parameters as Fabric8EquallySpacedHolesParameters;

  const startPoint: Pt = data.points[0];
  const endPoint: Pt = data.points[1];
  const distance = params.distance;

  const direction = endPoint.$subtract(startPoint).unit();

  const segmentLength = startPoint.$subtract(endPoint).magnitude();
  const numberOfHoles = Math.floor(segmentLength / distance) + 1;

  var currentPoint = startPoint.clone();

  for (let i = 0; i < numberOfHoles; i++) {
    const drillHole = new Fabric8Element({
      [IFabric8Element]: true,
      tool: clone(data.smartObjectData.parameters.drillTool),
      isClosedPath: false,
      points: [currentPoint],
    });
    const drillHoleNode = newNodeWithData(drillHole, oldIds[i] || '');
    drillHoleNode.readOnly = true;
    node.children.push(drillHoleNode);

    currentPoint = currentPoint.$add(direction.$multiply(distance));
  }
}
