import { Injectable } from '@angular/core';
import { CanvasForm, Pt } from 'pts';
import { ContextMenuItem } from 'src/app/modules/fabric8/contextmenu/contextmenu.options';
import { CameraService } from '../camera.service';
import { ToolService } from '../tool.service';
import { Fabric8MouseEvent } from './models/Fabric8MouseEvent.model';
import { Fabric8ToolHandler } from './models/Fabric8ToolEvents.model';
import { ToolState } from './models/ToolState.model';

@Injectable({
  providedIn: 'root',
})
export class SelectTool implements Fabric8ToolHandler {
  state: ToolState = ToolState.idle;
  cursorPoint: Pt | undefined;

  constructor(
    private toolService: ToolService,
    private cameraService: CameraService
  ) {}

  private add(point: Pt) {
    if (this.state == ToolState.idle) {
      // const currentTool = this.toolService.getCurrentTool();
      // const depth = this.toolService.depth;
      // this.toolService.addElement({
      //   tool: clone(currentTool),
      //   depth: depth,
      //   points: [point]
      // });
    }
  }

  public snapCursor(event: Fabric8MouseEvent) {
    this.cursorPoint = event.objectSpacePoint;
  }

  public mouseClick(event: Fabric8MouseEvent) {}

  // handle mouse events
  public mouseDown(event: Fabric8MouseEvent) {
    this.add(this.cursorPoint);
  }

  public mouseDrag(event: Fabric8MouseEvent) {
    this.add(this.cursorPoint);
  }

  public mouseUp(event: Fabric8MouseEvent) {
    // console.log('mouseUp', event)
    // this.toolService.commitElement();
  }

  public mouseDrop(event: Fabric8MouseEvent) {
    // console.log('mouseDrop', event)
    // this.toolService.commitElement();
  }

  public mouseMove(event: Fabric8MouseEvent) {
    // console.log('mouseMove', event)
  }

  public keyDown(event: any) {
    console.log(event);
  }

  public reset() {
    this.state = ToolState.idle;
  }

  public draw(form: CanvasForm) {
    //
  }

  public getContextMenuItems(event: Fabric8MouseEvent): ContextMenuItem[] {
    // TODO
    return [];
  }

  public contextMenuAction(item: ContextMenuItem) {
    // TODO
  }
}
