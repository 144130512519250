import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from 'src/app/core/services/ui.service';
import { Project } from 'src/app/model/project.model';
import { ProjectFileLinkingService } from 'src/app/modules/project/services/project-file-linking.service';
import { SketchupService } from 'src/app/services/sketchup.service';

@Component({
  selector: 'sw-section-file-link',
  templateUrl: './file-link.component.html',
  styleUrls: ['./file-link.component.scss'],
})
export class FileLinkComponent {
  @Input() project: Project;
  @Output() projectChange = new EventEmitter<Project>();

  constructor(
    private uiService: UIService,
    public sketchupService: SketchupService,
    private fileLinkService: ProjectFileLinkingService,
    private translate: TranslateService
  ) {}

  hasSketchUpLinkedFile() {
    return Boolean(this.project.sketchUpFilePath);
  }

  hasLayOutLinkedFile() {
    return Boolean(this.project.layoutFilePath);
  }

  async unlinkFile(type: 'sketchup' | 'layout') {
    if (this.sketchupService.isSketchup == false) {
      return;
    }

    this.uiService.disableUI(false);
    // this.errorMessage = undefined

    try {
      await this.fileLinkService.unlink(this.project, type);
    } catch (e) {
      console.error("Can't unlink file from project");
      console.error(e);
    }

    this.uiService.enableUI();
  }

  async relinkSketchUpFile() {
    if (this.sketchupService.isSketchup == false) {
      return;
    }
    await this.unlinkFile('sketchup');
    await this.linkSketchUpFile();
  }

  async linkSketchUpFile() {
    if (this.sketchupService.isSketchup == false) {
      return;
    }

    try {
      await this.fileLinkService.linkSketchupFile(this.project);
    } catch (err) {
      if (err == 'SKETCHUP_FILE_NOT_SAVED') {
        let message = this.translate.instant(
          'SETTINGS_FILE_LINK.NOT_SAVED_WARNING'
        );
        alert(message);
      }
      let message = this.translate.instant(
        'SETTINGS_FILE_LINK.CANT_LINK_SKETCHUP'
      );
      this.uiService.showErrors(message);
      this.uiService.showErrors(err);
    }
  }

  async relinkLayOutFile() {
    if (this.sketchupService.isSketchup == false) {
      return;
    }
    await this.unlinkFile('layout');
    await this.linkLayOutFile();
  }

  async linkLayOutFile() {
    if (this.sketchupService.isSketchup == false) {
      return;
    }

    try {
      await this.fileLinkService.linkLayoutFile(this.project);
    } catch (err) {
      let message = this.translate.instant(
        'SETTINGS_FILE_LINK.CANT_LINK_LAYOUT'
      );
      this.uiService.showErrors(message);
      this.uiService.showErrors(err);
    }
  }
}
