import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModelManagerSettingsService } from './model-manager-settings.service';

@Component({
  selector: 'sw-model-manager-settings',
  templateUrl: './model-manager-settings.component.html',
  styleUrls: ['./model-manager-settings.component.scss'],
})
export class ModelManagerSettingsComponent implements OnInit {
  constructor(private router: Router, public mm: ModelManagerSettingsService) {}

  ngOnInit(): void {
    console.log('settings init');
  }

  close() {
    this.router.navigate(['/model-manager']);
  }
}
