import { Injectable } from '@angular/core';
import { CanvasForm, Group } from 'pts';
import { Fabric8Guideline } from '../../models/guideline.model';
import { Fabric8Node } from '../../models/node.model';
import { CameraService } from '../../services/camera.service';
import { HoverService } from '../../services/hover.service';
import { colors } from '../colors';

@Injectable({
  providedIn: 'root',
})
export class RenderGuidelinesService {
  constructor(
    private camera: CameraService,
    private hoverService: HoverService
  ) {}

  render(guidelineNodes: Fabric8Node[], form: CanvasForm) {
    if (!guidelineNodes) return;

    const nonHoveredGuidelineNodes: Fabric8Node[] = [];
    const hoveredGuidelineNodes: Fabric8Node[] = [];

    guidelineNodes.forEach((node) => {
      const isHovered =
        this.hoverService.mouseEvent.hoveredNodeDataPointIndices.find(
          (i) => i.id == node.id
        ) !== undefined;

      if (isHovered) {
        hoveredGuidelineNodes.push(node);
      } else {
        nonHoveredGuidelineNodes.push(node);
      }
    });

    nonHoveredGuidelineNodes.forEach((guideline) => {
      const segment = Group.fromPtArray(guideline.data?.points);
      const points = this.hoverService.extendLineToCanvasEdges(segment, form);

      if (points?.length > 1) {
        const guidelinePoints = this.camera.transform(points);
        form
          .strokeOnly(colors.guidelineColor, colors.guidelineThickness)
          .dash(colors.guidelineDash)
          .line(guidelinePoints);
        form.dash(false);
      }
    });

    hoveredGuidelineNodes.forEach((guideline) => {
      const segment = Group.fromPtArray(guideline.data?.points);
      const points = this.hoverService.extendLineToCanvasEdges(segment, form);

      if (points?.length > 1) {
        const guidelinePoints = this.camera.transform(points);
        form
          .strokeOnly(
            colors.guidelineHoveredColor,
            colors.guidelineThickness * 2
          )
          .dash(colors.guidelineDash)
          .line(guidelinePoints);
        form.dash(false);
      }
    });
  }
}
