import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { SearchQuery } from '../model/library-interfaces'
import { LibraryItem } from '../model/library-item.model'
import { LibraryLabel } from '../model/library-label.model'
import { LibraryLabelsService } from '../modules/library/library-labels.service'

@Injectable({
  providedIn: 'root'
})
export class SearchService implements OnDestroy {

  public nodeSearch$ = new BehaviorSubject<string>('')

  public query$ = new BehaviorSubject<SearchQuery>({
      labels: [],
      query: "",
      libraryItems: []
  })

  labelsSubscription: Subscription 
  allTheLabels = new BehaviorSubject<LibraryLabel[]>([])

  constructor(
    private libraryLabelsService: LibraryLabelsService,
  ) { 
    this.initSearch()
  }

  initSearch() {
    // TODO: if we're using library specific labels,
    // get all the child libraryLabelServices
    // combineLatest() -> publish allTheLabels
    this.labelsSubscription = this.libraryLabelsService.labels.subscribe(l => 
      this.allTheLabels.next(l)
    )

    // TODO: this endpoint is currently getting all the labels regardless of pluginID
    // this.libraryLabelsService.getAll('gardn8')
  }

  ngOnDestroy() {
    this.labelsSubscription?.unsubscribe()
  }

  setQuery(query: SearchQuery) {
    this.query$.next(query)
  }

  clearSearchQuery() {
    this.setQuery({ query: '', labels: [], libraryItems: []})
  }

  query(): SearchQuery {
    return this.query$.getValue()
  }

  addLibraryItem(item: LibraryItem) {
    const newQuery = this.query()
    newQuery.libraryItems = newQuery.libraryItems
                            .filter(l => l.id != item.id) // remove duplicates
    newQuery.libraryItems.push(item)
  
    this.setQuery(newQuery)
  }

  removeLibraryItem(item: LibraryItem) {
    const newQuery = this.query()
    newQuery.libraryItems = newQuery.libraryItems
                              .filter(l => l.id != item.id)

    this.setQuery(newQuery)   
  }

}
