import { Fabric8Node, SkpFabric8Node } from './node.model';
import { Pt } from 'pts';
import { Pt2skpPoint, SkpLine, skpPoint2Pt, SWLine } from './Pt2skpPoint';

export interface SkpFabric8Object {
  guid: string;
  name: string;

  lines: SkpLine[];
  rootNode: SkpFabric8Node;
}

export class Fabric8Object {
  guid: string;
  name: string;

  points: Pt[];
  lines: SWLine[];
  rootNode: Fabric8Node;

  constructor(data: SkpFabric8Object);
  constructor(json: string);
  constructor(arg: SkpFabric8Object | string) {
    if (typeof arg == 'string') {
      const data = JSON.parse(arg) as SkpFabric8Object;
      this.fromSkp(data);
    } else if (typeof arg == 'object') {
      this.fromSkp(arg);
    }

    return this;
  }

  public toSkp(): SkpFabric8Object {
    const data: SkpFabric8Object = {
      guid: this.guid,
      name: this.name,
      // transform from Pt to array
      lines: this.lines.map((line: SWLine) => [
        Pt2skpPoint(line[0]),
        Pt2skpPoint(line[1]),
      ]),
      rootNode: this.rootNode.toSkp(),
    };
    return data;
  }

  // Transform object to JSON
  public toJSON(): string {
    const data = this.toSkp();
    const json = JSON.stringify(data, null, 2);
    // console.log('json', json);
    return json;
  }

  // Transform object from API or SU to Fabric8Object
  private fromSkp(data: SkpFabric8Object): Fabric8Object {
    this.guid = data.guid;
    this.name = data.name;

    this.points = [];

    this.lines = data.lines.map((line) => {
      const p0 = skpPoint2Pt(line[0]);
      const p1 = skpPoint2Pt(line[1]);

      this.points.push(p0);
      this.points.push(p1);
      return [p0, p1];
    });

    // remove duplicate points
    this.points = this.points.filter(
      (point, index, self) =>
        index === self.findIndex((t) => t[0] === point[0] && t[1] === point[1])
    );

    this.rootNode = new Fabric8Node(data.rootNode);

    return this;
  }
}
