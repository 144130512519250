import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { getRandomString } from 'src/app/core/helpers/getRandomString';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { DataNode } from 'src/app/model/data-node.model';
import {
  SWMaterialItem,
  SWMaterialsService,
} from 'src/app/modules/materials/sw-materials.service';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { DetailPanelService } from 'src/app/services/detail-panel.service';

@Component({
  selector: 'sw-cabn8-material-presets',
  templateUrl: './cabn8-material-presets.component.html',
  styleUrls: ['./cabn8-material-presets.component.scss'],
})
export class Cabn8MaterialPresetsComponent {
  @Input() node: DataNode;
  @Output() nodeChange = new EventEmitter<DataNode>();

  constructor(
    public detailPanelService: DetailPanelService,
    private dataNodeService: DataNodeService,
    private materialsService: SWMaterialsService
  ) {}

  openMaterialPicker(attributeIndex: number) {
    this.detailPanelService?.showMaterials();

    this.materialsService.clearSubscribers();
    this.materialsService.onSelect.pipe(take(1)).subscribe((material) => {
      this.node.attributes[attributeIndex].value = material.name;
      this.node.attributes[attributeIndex].value_raw = material.name;
      this.updateNode();
    });
  }

  rotateMaterial(attributeIndex: number) {
    var attrValue = this.node.attributes[attributeIndex].value_raw;

    var attrValueArr = attrValue.split(';');

    if (attrValueArr.length > 1) {
      attrValue = attrValueArr[0];
    } else {
      attrValue = `${attrValue};rotate`;
    }

    this.node.attributes[attributeIndex].value_raw = attrValue;

    this.updateNode();
  }

  nameChange(i, newName: string) {
    let NAME_MAX_CHARS = 50;
    // remove spaces from name
    // newName = newName.replace(new RegExp(" ", 'g'), "")
    newName = newName.replace(/(?:\r\n|\r|\n)/g, '');
    this.node.attributes[i].name = newName.trim().substring(0, NAME_MAX_CHARS);
    this.updateNode();
  }

  updateNode() {
    this.dataNodeService.updateNodes([this.node]);
    // this.nodeChange.emit(this.node)
  }

  attrIndex(index, item) {
    return item.id;
  }

  nameLengthCheck(i, event: any) {
    if (event.target.textContent.length <= 30) return;
    if (event.which != 8) {
      event.preventDefault();
    }
  }
}
