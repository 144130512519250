<div id="library-container">
  <div class="wbox scrolly flex column" *ngIf="plugin">
    <sw-library-item-list> </sw-library-item-list>
  </div>
</div>

<ng-template [ngIf]="(detailPanelService.currentPanel | async) === 'labels'">
  <div id="library-extra-container">
    <div class="wbox scrolly flex column">
      <sw-library-labels-list> </sw-library-labels-list>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="(detailPanelService.currentPanel | async) === 'materials'">
  <div id="library-extra-container">
    <div class="wbox flex column">
      <sw-materials-list> </sw-materials-list>
    </div>
  </div>
</ng-template>
