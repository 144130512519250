import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Attribute,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DataAttribute,
  DataAttributeOptions,
} from 'src/app/model/data-attribute.model';
import { AttributeEditorService } from 'src/app/modules/attributes/attribute-editor.service';

@Component({
  selector: 'sw-attribute-editor-options',
  templateUrl: './attribute-options.component.html',
  styleUrls: ['./attribute-options.component.scss'],
})
export class AttributeOptionsComponent implements OnChanges {
  @Input() attrType: string; // passing in for change detection
  @Input() attr: DataAttribute;
  @Output() attrChange = new EventEmitter<DataAttribute>();

  constructor(
    private translate: TranslateService,
    private attributeEditorService: AttributeEditorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attrType.currentValue == 'dropdown') {
      this.ensureDropdownHasOption();
    }
  }

  ensureDropdownHasOption() {
    if (this.attr.type != 'dropdown') return;

    if (this.attr.options == undefined || this.attr.options.length == 0) {
      this.addOption();
    }
    this.attributeEditorService.forceOptionSelection(this.attr);
    this.attrChange.next(this.attr);
  }

  deleteOption(index) {
    this.attr.options.splice(index, 1);
    //    this.ensureDropdownHasOption()
    // if we're deleting the last option, switch to text
    if (this.attr.options.length == 0) {
      this.attr.type = 'text';
    }
    this.attrChange.next(this.attr);
  }

  addOption() {
    let newOptionName = this.translate.instant('ATTR_OPTIONS.NEW_OPTION_NAME');
    const newOption = new DataAttributeOptions(newOptionName, '');

    if (this.attr.options == undefined) {
      this.attr.options = [];
    }

    this.attr.options.push(newOption);
    this.attributeEditorService.forceOptionSelection(this.attr);
    this.attrChange.next(this.attr);
  }

  drop(event: CdkDragDrop<DataAttribute>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data.options,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data.options,
        event.container.data.options,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
