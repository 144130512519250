<div class="menu" (mouseleave)="isActive = false">
  <ng-template [ngIf]="isActive">
    <div class="middle">
      <div
        class="hover-group"
        [@panelInOut]
        [ngClass]="light ? 'white' : 'grey'"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </ng-template>
  <div
    class="hover-btn transparent"
    [ngClass]="light ? 'white' : 'grey'"
    [inlineSVG]="'icon/more.svg'"
    (click)="isActive = true"
  ></div>
</div>
