import { Component, Input, OnInit } from '@angular/core';
import { DataNode } from 'src/app/model/data-node.model';
import { ProjectData } from 'src/app/model/project-data.model';
import { SketchupService } from 'src/app/services/sketchup.service';
import { DataNodeService } from '../../project/services/data-node.service';
import { ProjectService } from '../../project/services/project.service';
import { SmartworksPluginComponent } from '../swplugin/swplugin.component';
import { ProjectFileLinkingService } from '../../project/services/project-file-linking.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'sw-plugin-fabric8',
  templateUrl: './fabric8.component.html',
  styleUrls: ['./fabric8.component.scss'],
})
export class Fabric8Component extends SmartworksPluginComponent {
  // inherited from SmartworksPluginComponent
  @Input() declare projectData: ProjectData;
  @Input() declare rootNode?: DataNode;

  constructor(
    public sketchUp: SketchupService,
    public dataNodeService: DataNodeService,
    public projectService: ProjectService,
    public projectFileLinkingService: ProjectFileLinkingService
  ) {
    super(sketchUp, dataNodeService, projectService);
  }

  openFabric8() {
    this.fileNameSubscriber = combineLatest([
      this.sketchupService.currentSketchUpFilePath,
      this.projectService.project,
    ]).subscribe(([filePath, project]) => {
      if (filePath == project.sketchUpFilePath) {
        this.sketchUp.bridge.call('openFabric8');
      } else {
        alert('The currently open file is not linked to this project.');
      }
    });
  }
}
