import { Fabric8Tool } from '../../models/tool.model';
import { Fabric8ToolType } from '../Fabric8ToolType';

export const drillToolSet: Fabric8Tool[] = [
  {
    name: '',
    type: Fabric8ToolType.DRILL,
    radius: 3 / 2,
    depth: 4,
    enabled: true,
  },
  {
    name: '',
    type: Fabric8ToolType.DRILL,
    depth: 4,
    radius: 25 / 2,
    enabled: true,
  },
];
