<ng-template [ngIf]="message">
  <div class="center spacer container">
    <div class="message">
      <div>
        <mat-icon [inline]="true">{{ icon }}</mat-icon>
      </div>
      <p class="text" [innerHtml]="message"></p>
      <div
        *ngIf="actionText"
        class="btn-primary grey center"
        (click)="onAction()"
      >
        {{ actionText }}
      </div>
    </div>
  </div>
</ng-template>
