import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Fabric8SettingsService } from './fabric8-settings.service';

@Component({
  selector: 'sw-fabric8-settings',
  templateUrl: './fabric8-settings.component.html',
  styleUrls: ['./fabric8-settings.component.scss']
})
export class Fabric8SettingsComponent implements OnInit {

  constructor(
    private router: Router,
    public mm: Fabric8SettingsService
  ) { }

  ngOnInit(): void {
    console.log("settings init")
  }

  close() {
    this.router.navigate(['/fabric8'])    
  }

}
