import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Fabric8Tool } from '../../models/tool.model';
import { Fabric8ToolbarService } from '../../toolbar/fabric8-toolbar.service';
import { Fabric8ToolType } from '../Fabric8ToolType';
import { ToolNameAutocompleteService } from '../tool-name-autocomplete.service';

@Component({
  selector: 'sw-tool-row',
  templateUrl: './tool-row.component.html',
  styleUrls: ['./tool-row.component.scss'],
})
export class ToolRowComponent implements AfterContentInit, OnDestroy {
  @Input() tool: Fabric8Tool;
  @Output() toolChange = new EventEmitter<Fabric8Tool>();

  CUT_GROUP = Fabric8ToolType.CUT;

  toolNamesSubscription = new Subscription();
  toolNames: string[] = [];

  constructor(
    public toolbarService: Fabric8ToolbarService,
    public toolNameAutocomplete: ToolNameAutocompleteService
  ) {}

  ngAfterContentInit(): void {
    this.subscribeToToolNames();
  }

  subscribeToToolNames() {
    this.toolNamesSubscription = this.toolNameAutocomplete.toolNames$.subscribe(
      (toolNames: Map<string, string[]>) => {
        this.toolNames = toolNames.get(this.tool.type);
      }
    );
  }

  ngOnDestroy(): void {
    this.toolNamesSubscription.unsubscribe();
  }

  toggleTool() {
    this.tool.enabled = !this.tool.enabled;
    this.updateTool();
  }

  getToolWidth(): string {
    return `${this.tool.radius * 2}${
      this.toolbarService.defaultLengthUnitSymbol
    }`;
  }

  updateToolWidth(toolWidth: string): void {
    const toolName = this.toolbarService
      .toolUnitConversion(toolWidth)
      .replace(/\s/g, '');

    this.tool.radius = parseFloat(toolName) / 2;
    this.updateTool();
  }

  getToolDepth(): string {
    const toolDepth = this.tool.depth ?? this.toolbarService.defaultDepth;
    return `${toolDepth}${this.toolbarService.defaultLengthUnitSymbol}`;
  }

  updateToolDepth(toolDepth: string): void {
    toolDepth = this.toolbarService.toolUnitConversion(toolDepth);
    this.tool.depth = parseFloat(toolDepth);
    this.updateTool();
  }

  editingName = false;

  enterNameEditingMode() {
    this.editingName = true;
  }

  updateToolName(toolName: string): void {
    this.editingName = false;
    // this.tool.name = toolName;
    this.updateTool();
  }

  updateTool() {
    const widthMM = `${this.tool.radius * 2}${
      this.toolbarService.defaultLengthUnitSymbol
    }`;
    const depthMM = `${this.tool.depth}${this.toolbarService.defaultLengthUnitSymbol}`;

    if (this.tool.name.trim() === '') {
      this.tool.tag = '';
    } else {
      this.tool.tag = `CNC_${this.tool.name}_${this.tool.type}_${widthMM}`;
      if (
        this.tool.type == Fabric8ToolType.MILLING ||
        this.tool.type == Fabric8ToolType.DRILL
      ) {
        this.tool.tag = this.tool.tag + `_${depthMM}`;
      }
    }

    this.toolChange.emit(this.tool);
  }
}
