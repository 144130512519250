import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { LibraryLabel } from 'src/app/model/library-label.model';
import { LibraryLabelsService } from '../../library-labels.service';

@Component({
  selector: 'sw-label-editor',
  templateUrl: './label-editor.component.html',
  styleUrls: ['./label-editor.component.scss'],
})
export class LabelEditorComponent implements OnInit {
  @Input() label: LibraryLabel;
  @Output() labelChange = new EventEmitter<LibraryLabel>();

  libraryLabel: LibraryLabel;
  labelInputName: string; // doing this because one-time binding (::label.name) produces an error for some reason

  constructor(public labelService: LibraryLabelsService) {}

  ngOnInit(): void {
    this.libraryLabel = cloneDeep(this.label);
    this.labelInputName = this.libraryLabel.name;
  }

  setColor(color: string) {
    this.libraryLabel.color = color;
    this.label.color = color;
  }

  setName(newName: string) {
    this.libraryLabel.name = newName;
  }

  save() {
    this.labelChange.emit(this.libraryLabel);
  }

  cancel() {
    this.labelChange.emit();
  }
}
