import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { SketchupService } from 'src/app/services/sketchup.service';
import { MaterialPathsService } from './material-paths.service';

export interface SWMaterialItem {
  name: string;
  thumbnail?: string;
  color?: string[];
  path?: string[]; //only used on Collection
  root?: boolean; //only used on Collection
}

export interface SWMaterialCollection extends SWMaterialItem {
  collections: SWMaterialCollection[];
  materials: SWMaterialItem[];
  path: string[]; // stores path components - example: ["", "Users", "me", "Documents", "Materials"]
  root?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SWMaterialsService implements OnDestroy {
  private rootCollection: SWMaterialCollection;

  materialItemCache: Map<string, SWMaterialItem | null> = new Map();
  theCollections$: BehaviorSubject<SWMaterialCollection> = new BehaviorSubject(
    undefined
  );
  onSelect: Subject<SWMaterialItem> = new Subject();

  collectionsLoadingState: BehaviorSubject<String> = new BehaviorSubject('');

  pathsSubscription: Subscription;

  constructor(
    private sketchup: SketchupService,
    private materialPathsService: MaterialPathsService
  ) {
    this.rootCollection = {
      collections: [],
      materials: [],
      path: ['Material Collections'],
      name: 'Material Collections',
      root: true,
    };

    this.materialItemCache.set('White', {
      name: 'White',
      color: ['#ffffff'],
    });

    this.subscribeToMaterialFolderPaths();
  }

  ngOnDestroy(): void {
    this.pathsSubscription?.unsubscribe();
  }

  private subscribeToMaterialFolderPaths() {
    this.pathsSubscription?.unsubscribe();

    if (!this.sketchup.isSketchup) {
      // fetch from API
      this.theCollections$.next(undefined);
      return;
    }

    console.info('subscribeToMaterialFolderPaths');

    this.pathsSubscription = this.materialPathsService.folderPaths$.subscribe(
      async (paths) => {
        this.collectionsLoadingState.next('UpdatingCollections');

        if (paths.length == 0) {
          this.theCollections$.next(undefined);
          return;
        }

        // find any new paths not in the rootCollection
        const newPaths = paths.filter((path) => {
          return !this.rootCollection.collections.find((collection) => {
            return collection.path[0] == path;
          });
        });

        // add new paths to the rootCollection
        for (const path of newPaths) {
          try {
            const newCollection = await this.sketchup.bridge.get(
              'getMaterialsJSON',
              path
            );
            newCollection.path = [path];
            newCollection.collections.forEach((c) =>
              this.insertRootCollectionPath(c, path)
            );
            this.rootCollection.collections.push(newCollection);
          } catch (e) {}
        }

        // find any paths in the rootCollection that are not in the paths
        const oldCollections = this.rootCollection.collections.filter(
          (collection) => !paths.includes(collection.path[0])
        );

        // remove old paths from the rootCollection
        for (const collection of oldCollections) {
          const index = this.rootCollection.collections.indexOf(collection);
          this.rootCollection.collections.splice(index, 1);
        }

        //TODO: due to the subscription being called while the collections are loading,
        // the collection might be read/inserted twice (the newCollection await call)

        // remove duplicate root collections (same path)
        const uniqueCollections = this.rootCollection.collections.filter(
          (collection, index, self) => {
            return (
              self.findIndex((c) => c.path[0] == collection.path[0]) == index
            );
          }
        );
        this.rootCollection.collections = uniqueCollections;

        this.theCollections$.next(this.rootCollection);
        this.collectionsLoadingState.next('CollectionsUpdated');
      }
    );
  }

  async getMaterialNamed(name: string) {
    // console.log("getMaterialNamed", name)
    return await this.sketchup.bridge.get('getMaterialNamed', name);
  }

  selectMaterial(material: SWMaterialItem) {
    this.onSelect.next(material);
  }

  clearSubscribers() {
    this.onSelect.observers?.forEach((o) => o.complete());
  }

  filterCollection(
    collection: SWMaterialCollection,
    search: string
  ): (SWMaterialItem | SWMaterialCollection)[] {
    var items: (SWMaterialItem | SWMaterialCollection)[] = [];

    // add the matching collection names
    items.push(
      ...collection.collections.filter((l) =>
        l.name.toLowerCase().includes(search)
      )
    );

    // add items/collections from subcollections
    const subitems = collection.collections.flatMap((c) =>
      this.filterCollection(c, search)
    );
    items.push(...subitems);

    items.push(
      ...collection.materials.filter((m) =>
        m.name.toLowerCase().includes(search)
      )
    );

    return items;
  }

  private insertRootCollectionPath(
    swMaterialCollection: SWMaterialCollection,
    rootPath: string
  ): SWMaterialCollection {
    if (!swMaterialCollection) return undefined;

    swMaterialCollection.path[0] = rootPath;
    // swMaterialCollection.path.unshift(rootPath)

    swMaterialCollection.collections.forEach((c) => {
      this.insertRootCollectionPath(c, rootPath);
    });
    return swMaterialCollection;
  }
}
