import { style, sequence, animate, query, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { DropListService } from './drop-list.service';

const enterAnimation = [
  style({ height: '0', outline: '2px solid black'}),
  sequence([
    animate(".10s ease", style({ height: '*', opacity: '1', outline: '2px solid black' })),
    animate(".50s ease", style({ height: '*', opacity: 1, outline: '2px solid transparent' }))
  ])
]

const leaveAnimation = [
  style({ height: '*', opacity: '1' }),
  sequence([
    animate(".25s ease", style({ height: '*', opacity: '.2'})),
    animate(".50s ease", style({ height: '0', opacity: 0 }))
  ])
]

@Component({
  selector: 'sw-drop-list',
  templateUrl: './drop-list.component.html',
  styleUrls: ['./drop-list.component.scss'],
  providers: [DropListService],
  animations: [
    trigger('parent', [
      transition(':enter', [])
    ]),
    trigger('inout', [
      transition(':leave', [
        query('.row', leaveAnimation)
      ]),
      transition(':enter', [
        query('.row', enterAnimation)
      ])
    ])
  ]
})
export class DropListComponent  {

  @Input() list: Array<any>
  @Input() listObjectType: string
  @Input() container: any
  @Input() indentLevel: number

  constructor() { }

  itemByID(index, item) {
    return item.id
  }
}
