import { Fabric8Tool, Fabric8ToolGroup } from '../models/tool.model';
import { cutToolSet } from './default/cutToolSet';
import { drillToolSet } from './default/drillToolSet';
import { measureTool } from './default/measureTool';
import { routerToolSet } from './default/routerToolSet';
import { segmentTool } from './default/segmentTool';
import { Fabric8ToolType } from './Fabric8ToolType';

// group tools by type
export const defaultFabric8ToolSetGroups: Fabric8ToolGroup[] = [
  {
    name: Fabric8ToolType.DRILL,
    tools: drillToolSet,
    selected: drillToolSet[0],
    toolkit_name: 'Drills',
    type: Fabric8ToolType.DRILL,
  },
  {
    name: Fabric8ToolType.MILLING,
    tools: routerToolSet,
    selected: routerToolSet[0],
    toolkit_name: 'Router Bits',
    type: Fabric8ToolType.MILLING,
  },
  {
    name: Fabric8ToolType.CUT,
    tools: cutToolSet,
    selected: cutToolSet[0],
    toolkit_name: 'Cut',
    type: Fabric8ToolType.CUT,
  },
  {
    name: Fabric8ToolType.GUIDE,
    tools: [measureTool, segmentTool],
    selected: measureTool,
    toolkit_name: 'Guide lines',
    type: Fabric8ToolType.GUIDE,
  },
];

export function getToolIcon(tool: Fabric8Tool): string {
  return `fabric8/${tool.type}.svg`;
}

export function getToolName(tool: Fabric8Tool): string {
  const unitName = 'mm';

  if (tool.type == Fabric8ToolType.GUIDE) {
    return 'line';
  }

  if (tool.type == Fabric8ToolType.GUIDE_SEGMENT) {
    return 'segment';
  }

  if (tool.type == Fabric8ToolType.CUT) {
    return tool.radius * 2 + unitName;
  }

  if (
    tool.type == Fabric8ToolType.MILLING ||
    tool.type == Fabric8ToolType.DRILL
  ) {
    var toolName = '';
    if (tool.name) {
      toolName = tool.name + ' ';
    }
    toolName = toolName + tool.radius * 2 + unitName;
    // add depth
    if (tool.depth) {
      toolName = toolName + ' / ' + tool.depth + unitName;
    }
  }
  return toolName;
}
