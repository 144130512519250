import { Injectable } from '@angular/core';
import { CanvasForm, Group } from 'pts';
import { Fabric8GuidelineSegment } from '../../models/guideline-segment.model';
import { Fabric8Node } from '../../models/node.model';
import { CameraService } from '../../services/camera.service';
import { ElementsService } from '../../services/elements.service';
import { HoverService } from '../../services/hover.service';
import { colors } from '../colors';

@Injectable({
  providedIn: 'root',
})
export class RenderGuidelineSegmentsService {
  constructor(
    private camera: CameraService,
    private elementsService: ElementsService,
    private hoverService: HoverService
  ) {}

  render(guidelineNodes: Fabric8Node[], form: CanvasForm) {
    if (!guidelineNodes) return;

    const nonHoveredGuidelineNodes: Fabric8Node[] = [];
    const hoveredGuidelineNodes: Fabric8Node[] = [];

    guidelineNodes.forEach((node) => {
      const isHovered =
        this.hoverService.mouseEvent.hoveredNodeDataPointIndices.find(
          (i) => i.id == node.id
        ) !== undefined;

      if (isHovered) {
        hoveredGuidelineNodes.push(node);
      } else {
        nonHoveredGuidelineNodes.push(node);
      }
    });

    nonHoveredGuidelineNodes.forEach((guideline) => {
      const points = Group.fromPtArray(guideline.data?.points);
      // const points = this.hoverService.extendLineToCanvasEdges(segment, form);

      if (points?.length > 1) {
        const guidelinePoints = this.camera.transform(points);
        const data = guideline.data as Fabric8GuidelineSegment;
        const color = data.smartObjectData
          ? colors.dimmedGuidelineColor
          : colors.guidelineColor;
        form
          .strokeOnly(color, colors.guidelineThickness)
          .dash(colors.guidelineDash)
          .line(guidelinePoints);
        form.dash(false);
      }
    });

    hoveredGuidelineNodes.forEach((guideline) => {
      const points = Group.fromPtArray(guideline.data?.points);
      // const points = this.hoverService.extendLineToCanvasEdges(segment, form);

      if (points?.length > 1) {
        const guidelinePoints = this.camera.transform(points);
        form
          .strokeOnly(
            colors.guidelineHoveredColor,
            colors.guidelineThickness * 2
          )
          .dash(colors.guidelineDash)
          .line(guidelinePoints);
        form.dash(false);
      }
    });
  }

  // drawNode(node: Fabric8Node, form: CanvasForm) {
  //   const current = this.elementsService.getCurrentElementPointIndex();
  //   const isCurrent = current?.elementId == node.id;
  //   const hovered =
  //     this.hoverService.mouseEvent.hoveredElementPointIndices.find(
  //       (i) => i.elementId == node.id
  //     ) != undefined;

  //   const element = node.data as Fabric8GuidelineSegment;
  //   if (!element) return;

  //   const points = this.camera.transform(element.points);
  //   const radius = element.tool.radius * this.camera.cameraScale;

  //   // draw drill hole
  //   if (element.tool.type == Fabric8ToolType.DRILL) {
  //     if (this.drawDrillCenterPoints) {
  //       // draw crosshair drill center
  //       for (let i = 0; i < 4; i++) {
  //         const crosshairPoints = [
  //           points[0],
  //           points[0].$add(
  //             Math.sin((i * Math.PI) / 2) * radius,
  //             Math.cos((i * Math.PI) / 2) * radius
  //           ),
  //         ];
  //         form.ctx.setLineDash(colors.centerPointDashPattern);

  //         form
  //           .strokeOnly(colors.guidelineColor, colors.guidelineThickness)
  //           .line(crosshairPoints);
  //         form.dash(false);
  //       }

  //       form
  //         .strokeOnly(colors.elementFillColor, colors.guidelineThickness)
  //         .dash(colors.guidelineDash)
  //         .point(points[0], radius, 'circle');
  //       form.dash(false);
  //     } else {
  //       form
  //         .fillOnly(colors.elementFillColor)
  //         .point(points[0], radius, 'circle');

  //       if (isCurrent || hovered) {
  //         form
  //           .strokeOnly(colors.elementHoleHoverStroke, 3)
  //           .point(points[0], radius, 'circle');
  //       }
  //     }
  //   }

  //   // draw router line
  //   if (
  //     element.tool.type == Fabric8ToolType.CUT ||
  //     element.tool.type == Fabric8ToolType.MILLING
  //   ) {
  //     const fill =
  //       isCurrent || hovered
  //         ? colors.routerElementsStrokeHover
  //         : colors.routerElementStroke;

  //     // draw single point
  //     if (points.length == 1) {
  //       form.fillOnly(fill).point(points[0], radius, 'circle');
  //     } else {
  //       // more than 1 point
  //       form
  //         .strokeOnly(
  //           fill,
  //           // element.isClosedPath ? 'black' : fill,
  //           radius * 2,
  //           'round',
  //           'round'
  //         )
  //         .line(points);
  //     }
  //   }

  //   if (isCurrent) {
  //     this.drawElementPoints(element, current.pointIndex, form);
  //   } else if (hovered) {
  //     this.drawElementPoints(element, -1, form);
  //   }
  // }

  // // const current = this.toolService.getCurrentElementPointIndex()
  // drawElementPoints(
  //   element: Fabric8GuidelineSegment,
  //   highlightIndex: number,
  //   form: CanvasForm
  // ) {
  //   if (element == undefined) return;

  //   const points = this.camera.transform(element.points);

  //   // draw point centers
  //   form.fillOnly(colors.routerElementStroke).points(points, 2, 'circle');

  //   // draw highlighted point
  //   if (highlightIndex > -1) {
  //     form
  //       .fillOnly(colors.routerElementStroke)
  //       .point(points[highlightIndex], 4, 'square');
  //   }

  //   // draw start point
  //   form.fillOnly(colors.routerElementStroke).point(points[0], 4, 'circle');
  // }
}
