import { Injectable, OnDestroy } from '@angular/core';
import { CanvasForm, Pt } from 'pts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Fabric8Tool, Fabric8ToolGroup } from '../models/tool.model';
import { Fabric8ToolHandler } from './toolHandlers/models/Fabric8ToolEvents.model';

import { Fabric8ContextMenuService } from '../contextmenu/fabric8-context-menu.service';
import { Fabric8ToolbarService } from '../toolbar/fabric8-toolbar.service';
import { getToolIcon, getToolName } from '../toolset/toolset';
import { ObjectsManagerService } from './objects-manager.service';
import { ToolHandlerService } from './tool-handler.service';
import { ToolState } from './toolHandlers/models/ToolState.model';

import { CurrentObjectService } from './current-object.service';
import { ElementsService } from './elements.service';
import { Fabric8MouseEvent } from './toolHandlers/models/Fabric8MouseEvent.model';
import { ContextMenuData } from '../contextmenu/contextmenu.options';
import { HoverService } from './hover.service';

import { cloneDeep } from 'lodash';
import { Fabric8ToolType } from '../toolset/Fabric8ToolType';
import { Fabric8NodeType } from '../models/Fabric8NodeType';

@Injectable({
  providedIn: 'root',
})
export class ToolService implements OnDestroy {
  getToolIcon = getToolIcon;
  getToolName = getToolName;

  currentTool = new BehaviorSubject<Fabric8Tool>(undefined);
  toolEventHandler: Fabric8ToolHandler;

  private depth: number = 4;

  constructor(
    private objectsManager: ObjectsManagerService,
    private currentObject: CurrentObjectService,
    private toolHandlerService: ToolHandlerService,
    private toolsetService: Fabric8ToolbarService,
    private contextMenuService: Fabric8ContextMenuService,
    private elementService: ElementsService
  ) {
    this.subscribeToCurrentObjectIndex();
    this.subscribeToCurrentNodeDataPointIndex();
  }

  ngOnDestroy(): void {
    this.currentObjectSubscription?.unsubscribe();
    this.currentNodeDataPointSubscription?.unsubscribe();
  }

  currentObjectSubscription: Subscription;
  currentNodeDataPointSubscription: Subscription;

  private subscribeToCurrentNodeDataPointIndex() {
    this.currentNodeDataPointSubscription =
      this.elementService.currentNodeDataPointIndex$.subscribe((idx) => {
        if (idx && idx.id) {
          const { node, data } = this.currentObject.getNodeDataWithId(idx.id);
          const tool = data?.tool;
          if (tool) {
            this.setCurrentTool(tool);
            return;
          }
        }
      });
  }

  private subscribeToCurrentObjectIndex() {
    this.currentObjectSubscription =
      this.objectsManager.currentObjectId.subscribe((index) => {
        if (index == undefined || index == null) {
          this.toolEventHandler?.reset();
          return;
        }
      });
  }

  getCurrentDrillTool(): Fabric8Tool {
    // const drillTools = this.toolService
    const toolGroup = this.toolsetService.getToolsetOfType(
      Fabric8ToolType.DRILL
    );
    return this.getTool(toolGroup);
  }

  selectToolsetOfType(type: Fabric8ToolType) {
    const toolGroup = this.toolsetService.getToolsetOfType(type);
    if (toolGroup) {
      const tool = this.getTool(toolGroup);
      this.setCurrentTool(tool);
    }
  }

  getTool(toolGroup: Fabric8ToolGroup, tool?: Fabric8Tool): Fabric8Tool {
    if (tool) {
      toolGroup.selected = tool;
    } else {
      tool = toolGroup.selected;
    }
    return tool;
  }

  setCurrentTool(tool: Fabric8Tool) {
    if (this.currentTool.getValue() === tool) return;
    const toolHandler = this.toolHandlerService.getToolHandler(tool);
    this.toolEventHandler?.reset();
    this.toolEventHandler = toolHandler;
    this.elementService.unsetCurrentNodeDataPointIndex();
    this.currentTool.next(tool);
  }

  getCurrentTool(): Fabric8Tool {
    return this.currentTool.getValue();
  }

  // getCurrentToolDepth(): number {
  //   return this.getCurrentTool().depth ?? this.depth;
  // }

  handleEvent(
    type: string,
    evt: any,
    fabric8MouseEvent: Fabric8MouseEvent
  ): Boolean {
    const idleToolStateOrNoToolSelected =
      this.toolEventHandler == undefined ||
      this.toolEventHandler.state === ToolState.idle;

    if (type === 'down' && idleToolStateOrNoToolSelected) {
      // if clicking while idle, select the element and
      // switch tool to the clicked element's tool

      // we're explicitly avoiding the guidelines,
      // as this would select them instead of starting
      // a new element on them
      const nonGuidelineHoveredElementIndices =
        fabric8MouseEvent.hoveredNodeDataPointIndices.filter(
          (s) => s.type !== Fabric8NodeType.Guideline
        );

      if (nonGuidelineHoveredElementIndices?.length > 0) {
        const eId = nonGuidelineHoveredElementIndices[0].id;
        const { element } = this.currentObject.getElementDataWithId(eId);
        if (element.tool !== this.getCurrentTool()) {
          this.setCurrentTool(element.tool);
        }
      }
    }

    var toolHandler = this.toolEventHandler;
    if (!toolHandler) return;

    if (!type.includes('key')) {
      toolHandler.snapCursor(fabric8MouseEvent);
    }

    switch (type) {
      case 'click':
        toolHandler.mouseClick(fabric8MouseEvent);
        break;
      case 'down':
        if (evt.button == 2) {
          // ignore right click
        } else {
          toolHandler.mouseDown(fabric8MouseEvent);
        }
        break;
      case 'drag':
        toolHandler.mouseDrag(fabric8MouseEvent);
        break;
      case 'up':
        if (evt.button == 2) {
          // Warning: Don't use the 'contextmenu' event, as it's eating up the mouseup event
          // show context menu
          if (fabric8MouseEvent.hoveredNodeDataPointIndices?.length > 0) {
            this.elementService.setCurrentNodeDataPointIndex(
              fabric8MouseEvent.hoveredNodeDataPointIndices[0]
            );

            const contextMenuData: ContextMenuData = {
              fabric8MouseEvent: fabric8MouseEvent,
              options: toolHandler.getContextMenuItems(fabric8MouseEvent),
            };
            this.contextMenuService.open(contextMenuData);
          }
        } else {
          toolHandler.mouseUp(fabric8MouseEvent);
        }
        break;
      case 'drop':
        toolHandler.mouseDrop(fabric8MouseEvent);
        evt.stopPropagation();
        evt.preventDefault();
        break;
      case 'keydown':
        toolHandler.keyDown(evt);
        if (evt.key == 'Escape') {
          this.contextMenuService.close();
        }
        break;
      case 'move':
        toolHandler.mouseMove(fabric8MouseEvent);
        break;
      case 'contextmenu':
        evt.preventDefault();
        break;
      default:
        return;
    }
  }

  drawTool(form: CanvasForm) {
    this.toolEventHandler?.draw(form);
  }
}
