import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UIService } from 'src/app/core/services/ui.service';
import { ProjectData } from 'src/app/model/project-data.model';
import { PluginService } from 'src/app/modules/plugins/plugin.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { SwConfirmDialogComponent } from 'src/app/shared/sw-confirm-dialog';
import { TranslateService } from '@ngx-translate/core';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { SaveAsTemplateDialogComponent } from './save-as-template-dialog';
import { SketchupService } from 'src/app/services/sketchup.service';
import { LibraryViewService } from '../../library/library-view.service';
import { AttributeEditorService } from '../../attributes/attribute-editor.service';
import { delay, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sw-project-data',
  templateUrl: './project-data.component.html',
  styleUrls: ['./project-data.component.scss'],
  providers: [DataNodeService, LibraryViewService, AttributeEditorService],
})
export class ProjectDataComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: ProjectData;
  @Output() dataChange = new EventEmitter<ProjectData>();

  expanded = true;
  plugin: SWPlugin;

  constructor(
    public sketchUp: SketchupService,
    private pluginService: PluginService,
    private attributeEditorService: AttributeEditorService,
    public projectService: ProjectService,
    private uiService: UIService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private libraryViewService: LibraryViewService,
    private dataNodeService: DataNodeService
  ) {}

  ngOnInit(): void {
    this.plugin = this.pluginService.plugins.find(
      (p) => p.id === this.data.pluginID
    );

    if (!this.plugin) {
      return;
    }

    this.attributeEditorService.setPlugin(this.data.pluginID);

    if (this.plugin.library) {
      this.libraryViewService.setPlugin(this.data.pluginID);
    }
  }

  ngOnDestroy(): void {
    this.customButtonSubscription?.unsubscribe();
  }

  customButtonSubscription: Subscription;
  @ViewChild('customButtonsContainer', {
    static: false,
    read: ViewContainerRef,
  })
  customBtnContainer: ViewContainerRef;
  ngAfterViewInit(): void {
    this.customButtonSubscription = this.dataNodeService.topButtonsTemplate
      .pipe(
        // first(t => t !== undefined),
        delay(0) // call on next render loop
      )
      .subscribe((theButtons) => {
        if (theButtons instanceof TemplateRef) {
          this.customBtnContainer.clear();
          this.customBtnContainer.createEmbeddedView(theButtons);
        }
      });
  }

  pluginName(): string {
    return this.plugin?.name || this.data.pluginID;
  }

  onClickRemove() {
    const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
      width: '300px',
      data: {
        title: this.translate.instant('REMOVE_PLUGIN.TITLE'),
        prompt: this.translate.instant('REMOVE_PLUGIN.MESSAGE', {
          name: this.pluginName(),
        }),
        cancelButton: this.translate.instant('GLOBAL.CANCEL'),
        actionButton: this.translate.instant('GLOBAL.OK'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
        this.removePlugin();
      } else {
        // closed
      }
    });
  }

  async removePlugin() {
    this.uiService.disableUI(true);
    await this.projectService.removeProjectDataWithRootNodeID(
      this.data.rootNodeID
    );
    this.uiService.enableUI();
  }

  showSaveAsTemplate() {
    const dialogRef = this.dialog.open(SaveAsTemplateDialogComponent, {
      width: '300px',
      data: { templateName: '' },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (Boolean(result)) {
        this.uiService.disableUI(true);
        await this.projectService.saveProjectDataAsTemplate(
          this.data,
          result.templateName
        );
        this.uiService.enableUI();
      } else {
        // closed
      }
    });
  }

  addNewItem() {
    if (this.plugin.library) {
      this.libraryViewService.toggleEditing(true);
      this.libraryViewService.addNewItemEvent.emit();
    } else {
      this.dataNodeService.addDataNodeChild.emit();
    }
  }

  licenseRequiredMessage() {
    return this.translate.instant('LICENSE.REQUIRED');
  }
}
