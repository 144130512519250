import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import { DataNode } from 'src/app/model/data-node.model';
import { ProjectData } from 'src/app/model/project-data.model';
import { AttributeEditorService } from 'src/app/modules/attributes/attribute-editor.service';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { SketchupService } from 'src/app/services/sketchup.service';
import { SmartworksPluginComponent } from '../swplugin/swplugin.component';
import { cloneDeep, isEqual } from 'lodash';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ProjectService } from '../../project/services/project.service';

import MaterialPresetsJSON from './material-presets.template.json';
import { SW_HIDDEN_PREFIX } from '../../project/data-node/data-node.component';
import { DetailPanelService } from 'src/app/services/detail-panel.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SWMaterialsService } from '../../materials/sw-materials.service';
import { UIService } from 'src/app/core/services/ui.service';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Cabn8MaterialsDialogComponent } from './cabn8-materials-dialog/cabn8-materials-dialog.component';
import { Cabn8MaterialsResetDialogComponent } from './cabn8-materials-reset-dialog/cabn8-materials-reset-dialog.component';

const MATERIAL_PRESETS_NAME = SW_HIDDEN_PREFIX + 'Material Presets';

@Component({
  selector: 'sw-plugin-cabn8',
  templateUrl: './cabn8.component.html',
  styleUrls: ['./cabn8.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(720deg)' })),
      transition('rotated => default', animate('2ms ease-out')),
      transition('default => rotated', animate('1500ms ease-out')),
    ]),
  ],
  // providers: [SWMaterialsService]
})
export class Cabn8Component
  extends SmartworksPluginComponent
  implements AfterContentInit, OnDestroy
{
  // inherited from SmartworksPluginComponent
  @Input() declare projectData: ProjectData;
  @Input() declare rootNode?: DataNode;

  disableImportMaterialPresets: boolean = true;

  private sketchUpFilePathSubscription: Subscription;

  constructor(
    public sketchUp: SketchupService,
    public dataNodeService: DataNodeService,
    public attributeEditorService: AttributeEditorService,
    public projectService: ProjectService,
    public detailPanelService: DetailPanelService,
    private ui: UIService,
    private dialog: MatDialog
  ) {
    super(sketchUp, dataNodeService, projectService);

    this.materialsTemplateDataNode.attributes.map((v) => {
      v.value = 'White';
      v.value_raw = 'White';
    });
  }

  // auto-update button state
  private _autoUpdateEnabled: boolean = Boolean(
    window.localStorage.getItem('CabN8-autoupdate')
  );
  get autoUpdateEnabled(): boolean {
    return this._autoUpdateEnabled;
  }
  set autoUpdateEnabled(value: boolean) {
    this._autoUpdateEnabled = value;
    window.localStorage.setItem('CabN8-autoupdate', String(value));
  }

  // auto-update animation
  autoUpdating: string = 'default';
  runAutoUpdateAnimation() {
    this.autoUpdating = 'default';
    setTimeout(() => {
      this.autoUpdating = 'rotated';
    }, 100);
  }

  ngAfterContentInit() {
    this.register();
    this.subscribeToDetailPanel();

    // set the window JS callback
    this.sketchUp.registerFunctions({
      getCabN8SelectedNode: () => {
        console.log('getSelected node called');
        console.warn(this.selectedNode());
        return this.selectedNode();
      },
    });

    this.sketchUpFilePathSubscription =
      this.sketchUp.currentSketchUpFilePath$.subscribe((openedFilePath) => {
        if (openedFilePath == undefined) return;

        this.checkMaterialPresetsSyncronization();
      });

    // this.windowRef.nativeWindow.getCabN8SelectedNode = () => {
    //   return this.selectedNode()
    // }
  }

  showMaterials = false;
  detailPanelSub: Subscription;

  subscribeToDetailPanel() {
    this.detailPanelSub = this.detailPanelService.currentPanel.subscribe(
      (m) => {
        this.showMaterials = m === 'materials';
        // console.warn("detailPanelService.currentPanel", m)
      }
    );
  }

  ngOnDestroy() {
    this.detailPanelSub?.unsubscribe();
    this.sketchUpFilePathSubscription?.unsubscribe();
    this.unregister();

    // overwrite the window JS callback
    this.sketchUp.registerFunctions({
      getCabN8SelectedNode: () => {
        return '';
      },
    });
  }

  private selectedNode(): any {
    // return this.rootNode
    let node = cloneDeep(this.attributeEditorService.currentNodeReference);
    if (!node) {
      return '';
    }

    // Remove children.
    // We're cloning the node to be able to remove the children.
    // We're doing this to stop downstream nodes from applying their attributes,
    // although, a better separation could be achieved by grouping
    // different cabinet properties under sub-nodes.
    // However, it's a client request, so that's why we're doing it.
    delete node['children'];
    return node;
  }

  async runAction(action: String): Promise<any> {
    if (action == 'getRootNode') {
      return this.rootNode;
    }
    return true;
  }

  skipUpdatingSketchupMaterials: boolean = false;

  async nodesUpdated(nodes: DataNode[]) {
    if (this.skipUpdatingSketchupMaterials) {
      this.skipUpdatingSketchupMaterials = false;
      return;
    }
    if (this.showCabn8MaterialPresets) {
      console.log('Calling exportMaterialPresets Callback');
      console.log(nodes);
      await this.exportMaterialPresets();
      return;
    }

    if (this.autoUpdateEnabled) {
      this.updateCabinets();
    }
  }

  async updateCabinets() {
    this.runAutoUpdateAnimation();
    await this.applyAttributes(this.selectedNode());
  }

  async applyAttributes(n: any): Promise<boolean> {
    try {
      // console.log(JSON.stringify(n))
      let r = await this.sketchUp.bridge.get('applyAttributes', n);
      return r;
    } catch (e) {
      return false;
    }
  }

  showCabn8MaterialPresets = false;
  presetDataNode?: DataNode;
  materialsTemplateDataNode: DataNode = MaterialPresetsJSON as DataNode;

  async toggleCabn8MaterialPresetView() {
    this.showCabn8MaterialPresets = !this.showCabn8MaterialPresets;

    // console.info(this.materialsTemplateDataNode);
    // console.info(this.rootNode.children);

    this.checkMaterialPresetsSyncronization();
  }

  async checkMaterialPresetsSyncronization(showDialog: boolean = false) {
    if (this.showCabn8MaterialPresets == false) return;

    //wait for the nodes to be loaded
    if (this.rootNode.childrenIDs.length !== this.rootNode.children.length) {
      // call the same function again in 500ms
      setTimeout(() => {
        this.checkMaterialPresetsSyncronization();
      }, 500);
      return;
    }

    await this.getOrCreatePresetDataNode();

    const materialPresets = await this.getMaterialPresets();
    const identicalMaterialPresets = isEqual(
      this.presetDataNode.attributes,
      materialPresets
    );

    if (!showDialog) {
      // push the materials to sketchup
      if (materialPresets == undefined || materialPresets == null) {
        this.exportMaterialPresets();
        this.syncDialogRef?.close();
        return;
      }

      if (!showDialog && identicalMaterialPresets) {
        this.syncDialogRef?.close();
        return;
      }
    }

    // const sketchUpFilePath = this.projectService.getProject().sketchUpFilePath;
    // const currentOpenFilePath =
    //   this.sketchUp.currentSketchUpFilePath.getValue();

    // if (sketchUpFilePath == currentOpenFilePath) {
    //   await this.importMaterials(materialPresets);
    //   this.syncDialogRef?.close();
    //   return;
    // }

    setTimeout(() => {
      this.showSyncMaterialsDialog(materialPresets);
    }, 200);
  }

  syncDialogRef: MatDialogRef<Cabn8MaterialsDialogComponent> | undefined;
  showSyncMaterialsDialog(materialPresets) {
    this.syncDialogRef?.close();
    this.syncDialogRef = this.dialog.open(Cabn8MaterialsDialogComponent, {
      width: '600px',
      disableClose: false,
    });

    this.syncDialogRef.afterClosed().subscribe((action) => {
      if (action === 'import') {
        this.importMaterials(materialPresets);
      } else if (action === 'export') {
        this.exportMaterialPresets();
      }
    });
  }

  resetDialogRef: MatDialogRef<Cabn8MaterialsResetDialogComponent> | undefined;
  showResetDialog() {
    this.resetDialogRef?.close();
    this.resetDialogRef = this.dialog.open(Cabn8MaterialsResetDialogComponent, {
      width: '600px',
      disableClose: false,
    });

    this.resetDialogRef.afterClosed().subscribe((action) => {
      if (action === 'reset') {
        this.resetMaterialPresets();
      }
      if (action === 'reset-sketchup') {
        this.resetMaterialPresetsInSketchUp();
      }
      if (action === 'reset-smartworks') {
        this.resetMaterialPresetsInSmartWorks();
      }
    });
  }

  async getOrCreatePresetDataNode() {
    this.presetDataNode = this.rootNode.children.find(
      (n) => n.name === MATERIAL_PRESETS_NAME
    );

    if (!this.presetDataNode) {
      //create preset from hardcoded template
      const newNode = await this.dataNodeService.createNode(
        this.materialsTemplateDataNode
      );
      newNode.attributes = cloneDeep(this.materialsTemplateDataNode.attributes);
      this.rootNode.children.push(newNode);
      await this.dataNodeService.updateNodes([this.rootNode, newNode]);

      this.presetDataNode = newNode;
      // console.log('created preset node');
      // console.info(this.presetDataNode);
    } else {
      // console.log('materials node exists');
      // console.log(this.presetDataNode);
    }

    this.attributeEditorService.currentNodeReference = this.presetDataNode;
    this.attributeEditorService.node$.next(this.presetDataNode);
  }

  async resetMaterialPresetsInSmartWorks() {
    this.ui.disableUI(false);
    this.presetDataNode.attributes = cloneDeep(
      this.materialsTemplateDataNode.attributes
    );
    this.skipUpdatingSketchupMaterials = true;
    await this.dataNodeService.updateNodes([this.presetDataNode]);
    this.ui.enableUI();
  }

  async resetMaterialPresets() {
    this.ui.disableUI(false);
    if (this.presetDataNode) {
      this.presetDataNode.attributes = cloneDeep(
        this.materialsTemplateDataNode.attributes
      );
      await this.dataNodeService.updateNodes([this.presetDataNode]);
    }
    this.ui.enableUI();
  }

  async resetMaterialPresetsInSketchUp() {
    this.ui.disableUI(false);
    this.runAutoUpdateAnimation();
    await this.sketchUp.bridge.get(
      'setCabN8MaterialPresets',
      this.materialsTemplateDataNode.attributes
    );
    this.ui.enableUI();
  }

  async exportMaterialPresets() {
    if (this.presetDataNode) {
      this.runAutoUpdateAnimation();
      await this.sketchUp.bridge.get(
        'setCabN8MaterialPresets',
        this.presetDataNode.attributes
      );
    }
  }

  addNewItem() {
    this.dataNodeService.addDataNodeChild.emit();
  }

  private async importMaterials(materialPresets) {
    if (materialPresets == undefined) return;

    this.presetDataNode.attributes = materialPresets;
    this.attributeEditorService.currentNodeReference = this.presetDataNode;
    await this.dataNodeService.updateNodes([this.presetDataNode]);
    await this.updateCabinets();
  }

  private async getMaterialPresets() {
    try {
      const materialPresets = await this.sketchUp.bridge.get(
        'getCabN8MaterialPresets'
      );

      materialPresets.find((preset) => {
        if (preset.options == null) {
          preset.options = undefined;
        }
      });

      return materialPresets;
    } catch (e) {
      return null;
    }
  }
}
