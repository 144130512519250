import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { UnitConversionService } from 'src/app/modules/project/services/unit-conversion.service';
import { DetailPanelService } from 'src/app/services/detail-panel.service';
import { SWMaterialsService } from '../../materials/sw-materials.service';

@Component({
  selector: 'sw-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss'],
})
export class AttributeListComponent implements OnInit {
  @Input() attributes: DataAttribute[];
  @Output() attributesChange = new EventEmitter<DataAttribute[]>();
  @Input() readonly: boolean = false;

  constructor(
    private unitConversion: UnitConversionService,
    @Optional() private detailPanelService: DetailPanelService,
    private materialsService: SWMaterialsService
  ) {}

  ngOnInit() {
    if (this.attributes == undefined) {
      console.error('provide attributes to <sw-attribute-list>');
    }
  }

  async updateAttribute(index: number) {
    this.attributes[index].value_raw = this.unitConversion.rawValueFromInput(
      this.attributes[index]
    );

    this.attributes[index].value = this.unitConversion.valueFromRawValueAndUnit(
      this.attributes[index]
    );

    this.attributesChange.emit(this.attributes);
  }

  openMaterialPicker(attributeIndex: number) {
    if (this.readonly) return;

    this.detailPanelService?.showMaterials();

    this.materialsService.clearSubscribers();
    this.materialsService.onSelect.pipe(take(1)).subscribe((material) => {
      this.attributes[attributeIndex].value = material.name;
      this.attributes[attributeIndex].value_raw = material.name;
      this.attributesChange.emit(this.attributes);
    });
  }

  getClass(attributeName: string) {
    // extract <font color=red> from the name
    const color = attributeName.match(/<font color=([^>]*)>/);
    if (color) {
      return 'color-' + color[1];
    }
    return '';
  }

  getName(attributeName: string) {
    // remove html tags from the name
    return attributeName.replace(/<[^>]*>/g, '');
  }
}
