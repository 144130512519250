<ng-template #customTopButtons>
  <div
    class="icon plus"
    *ngIf="!showCabn8MaterialPresets"
    [inlineSVG]="'icon/plus.svg'"
    (click)="addNewItem()"
    matTooltip="{{ 'PROJECT_EDIT.NEW_ITEM_TOOLTIP' | translate }}"
    matTooltipPosition="above"
  ></div>

  <div
    class="icon"
    matTooltip="{{ 'CABN8.MATERIAL_PRESETS_TOOLTIP' | translate }}"
    matTooltipPosition="above"
    (click)="toggleCabn8MaterialPresetView()"
  >
    <mat-icon [inline]="true" class="material-picker">apps</mat-icon>
  </div>
</ng-template>

<ng-template [ngIf]="showCabn8MaterialPresets">
  <ng-template [ngIf]="presetDataNode" [ngIfElse]="loading">
    <div class="row flex buttons">
      <div class="spacer"></div>
      <div
        class="btn-primary"
        [ngClass]="{
          disabled: attributeEditorService.currentNodeReference === undefined
        }"
        (click)="checkMaterialPresetsSyncronization(true)"
      >
        <div class="icon" [inlineSVG]="'icon/sketchup.svg'"></div>
        {{ "CABN8.UPDATE_MATERIALS_BUTTON" | translate }}
      </div>
      <div class="spacer"></div>
      <div class="btn-primary" (click)="showResetDialog()">
        {{ "CABN8.CLEAR_MATERIALS_BUTTON" | translate }}
      </div>
      <div class="spacer"></div>
    </div>
    <div id="preset-attributes">
      <sw-cabn8-material-presets [(node)]="presetDataNode">
        <!-- (nodeChange)="presetNodeChanged($event)"> -->
      </sw-cabn8-material-presets>
    </div>
  </ng-template>
</ng-template>

<ng-template #loading>
  <div class="center loader">
    <mat-spinner class="loading-spinner" [diameter]="20"></mat-spinner>
  </div>
</ng-template>

<ng-template [ngIf]="!showCabn8MaterialPresets">
  <!-- buttons -->
  <ng-template [ngIf]="sketchUp.isSketchup" [ngIfElse]="notSketchup">
    <div class="row flex buttons">
      <div class="spacer"></div>
      <div
        class="btn-primary"
        [ngClass]="{
          disabled: attributeEditorService.currentNodeReference === undefined
        }"
        (click)="updateCabinets()"
      >
        <div class="icon" [inlineSVG]="'icon/sketchup.svg'"></div>
        {{ "CABN8.UPDATE_CABINETS_BUTTON" | translate }}
      </div>
      <div class="spacer"></div>
      <div
        id="refresh"
        class="icon"
        [ngClass]="{ disabled: !autoUpdateEnabled }"
        (click)="autoUpdateEnabled = !autoUpdateEnabled"
        [@rotatedState]="autoUpdating"
        matTooltip="{{ 'CABN8.AUTO_UPDATE_TOOLTIP' | translate }}"
        matTooltipPosition="above"
        [inlineSVG]="'icon/refresh.svg'"
      ></div>
    </div>
  </ng-template>

  <ng-template #notSketchup>
    <div class="row flex buttons">
      <div class="spacer"></div>
      <small
        class="hint"
        [innerHTML]="'CABN8.WEB_SKETCHUP_MESSAGE' | translate"
      >
      </small>
      <div class="spacer"></div>
    </div>
  </ng-template>

  <div swTreeGroup class="nodes">
    <sw-data-node [parent]="undefined" [(node)]="rootNode"></sw-data-node>
  </div>
</ng-template>

<ng-template [ngIf]="showMaterials">
  <!-- <ng-template [ngIf]="(detailPanelService.currentPanel | async) === 'materials'"> -->
  <div id="extra-container">
    <div class="wbox flex column">
      <sw-materials-list> </sw-materials-list>
    </div>
  </div>
</ng-template>
