import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UIService } from 'src/app/core/services/ui.service';
import { LibraryItem } from 'src/app/model/library-item.model';
import { LibraryLabel } from 'src/app/model/library-label.model';
import { sha1 } from 'object-hash';
import { PluginActionsService } from '../plugin-actions.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { AttributeEditorComponent } from '../../attributes/editor/attribute-editor.component';
import { AttributeEditorService } from '../../attributes/attribute-editor.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SwConfirmDialogComponent } from 'src/app/shared/sw-confirm-dialog';
import { Subscription } from 'rxjs';
import { LibraryViewService } from '../library-view.service';
import { LibraryService } from '../library.service';
import { SearchService } from 'src/app/services/search.service';
import { filter, take } from 'rxjs/operators';
import { DetailPanelService } from 'src/app/services/detail-panel.service';

@Component({
  selector: 'sw-library-item-row',
  templateUrl: './library-item-row.component.html',
  styleUrls: ['./library-item-row.component.scss'],
})
export class LibraryItemRowComponent implements OnInit, OnChanges, OnDestroy {
  @Input() item: LibraryItem;
  @Output() itemChange = new EventEmitter<LibraryItem>();

  isOpened: boolean = false;
  selectedItemSubscription: Subscription;

  @Input() editMode: boolean;

  clonedItem: LibraryItem = null;
  editing: 'thumbnail' | 'attributes' | 'sharing' | false;
  updating = false;
  loadingThumbnail: string = '';
  itemSHA: string;

  @ViewChild(AttributeEditorComponent) attributeEditorComponent;

  constructor(
    public libraryViewService: LibraryViewService,
    public detailPanelService: DetailPanelService,
    private libraryService: LibraryService,
    private ui: UIService,
    private pluginActionsService: PluginActionsService,
    private domSanitizer: DomSanitizer,
    private attributeEditorService: AttributeEditorService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.fetchThumbnail();

    this.selectedItemSubscription = this.libraryViewService.item.subscribe(
      (i) => {
        this.isOpened = i?.id === this.item.id;
      }
    );
  }

  ngOnDestroy(): void {
    this.selectedItemSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.loadThumbnailIfNeeded(changes.item);
      this.itemSHA = sha1(changes.item.currentValue);
    }
  }

  onDeleteClicked() {
    const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
      width: '300px',
      data: {
        title: this.translate.instant('DELETE_LIBRARY_ITEM.TITLE'),
        prompt: this.translate.instant(
          'DELETE_LIBRARY_ITEM.MESSAGE',
          this.item
        ),
        cancelButton: this.translate.instant('GLOBAL.CANCEL'),
        actionButton: this.translate.instant('GLOBAL.DELETE'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
        this.deleteItem();
      } else {
        // closed
      }
    });
  }

  async deleteItem() {
    this.ui.disableUI(true);
    await this.libraryService.deleteItem(this.item);
    this.ui.enableUI();
  }

  editAttributes() {
    this.clonedItem = cloneDeep(this.item);
    this.editing = 'attributes';
  }

  addAttribute() {
    this.attributeEditorComponent.addAttribute();
  }

  lockedTags() {
    return this.libraryService.getCachedCategoryAttributeTagsForPlugin(
      this.libraryViewService.plugin.id
    );
  }

  editSharing() {
    this.clonedItem = cloneDeep(this.item);
    this.editing = 'sharing';
  }

  cancelEditing() {
    // sharing and attributes editing
    this.clonedItem = undefined;
    this.editing = false;
  }

  onDuplicateClicked() {}

  actionIcon(): string {
    return 'icon/plugins/' + this.libraryViewService.plugin.id + '/action.svg';
  }

  actionButtonClicked(event: any) {
    this.pluginActionsService.itemActionForPlugin(
      this.libraryViewService.plugin.id,
      this.item
    );
    event.stopPropagation();
  }

  selectItem() {
    if (!this.isOpened) {
      this.libraryViewService.selectItem(this.item);
    } else {
      if (!this.editMode) {
        this.libraryViewService.selectItem(null);
      }
    }
  }

  deselectItem() {
    if (this.isOpened) {
      this.libraryViewService.selectItem(null);
    } else {
      this.libraryViewService.selectItem(this.item);
    }
  }

  editLabels(event: any) {
    if (this.editMode) {
      this.detailPanelService.showLabels();
      event.stopPropagation();
    }
  }

  async saveItem() {
    if (this.editing == 'attributes' || this.editing == 'sharing') {
      this.item = this.clonedItem;

      var attributes = this.item.attributes;
      attributes.forEach((a, index) => {
        attributes[index].tag =
          this.attributeEditorService.getTagOrMakeTagFromName(a);
      });

      this.editing = false;
      this.clonedItem = undefined;
    }

    if (sha1(this.item) == this.itemSHA) return;

    // Dim the view to indicate that the item is being saved
    // Wait for the incoming ULI
    this.updating = true;
    this.libraryService.updatedLibraryItemSignal
      .pipe(
        filter((updatedItem) => updatedItem.item.id == this.item.id),
        take(1)
      )
      .subscribe((updatedItem) => {
        this.updating = false;
      });

    this.libraryViewService.saveItem(this.item);
  }

  shortLabels(): LibraryLabel[] {
    var shortLabelList: LibraryLabel[] = [];
    var characterCount = 2; //left padding
    var moreCount = 0;
    this.item.labels.forEach((l) => {
      if (characterCount + l.name.length < 51) {
        shortLabelList.push(l);
        characterCount += l.name.length;
        characterCount += 5; // account for padding and space
      } else {
        moreCount += 1;
        characterCount += 8;
      }
    });
    if (moreCount > 0) {
      shortLabelList.push({
        id: null,
        pluginID: '',
        name: '+' + moreCount,
        access: [],
        color: '#C9C9C9',
      });
    }

    return shortLabelList;
  }

  addToSearch(event: any) {
    this.searchService.addLibraryItem(this.item);
    event.stopPropagation();
  }

  // thumbnail + placeholder

  editThumbnail() {
    if (this.editMode == false) return;
    this.editing = 'thumbnail';
  }

  async updateThumbnail(thumbnail: string) {
    this.ui.disableUI();
    this.item.thumbnail = thumbnail;
    await this.libraryService.updateThumbnail(this.item);
    this.ui.enableUI();
    this.editing = false;
  }

  thumbnailData(): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(this.item.thumbnail);
  }

  private loadThumbnailIfNeeded(item: SimpleChange) {
    if (item.isFirstChange()) {
      if (item.currentValue.thumbnailHash != null) {
        this.fetchThumbnail();
      } else {
        this.loadingThumbnail = '';
      }
      return;
    }

    var newItem = item.currentValue;
    var oldItem = item.previousValue;

    if (this.loadingThumbnail == newItem.thumbnailHash) {
      // already loading
      return;
    }

    if (oldItem.thumbnailHash == newItem.thumbnailHash) {
      // assign the old thumbnail
      newItem.thumbnail = oldItem.thumbnail;
      return;
    }

    this.fetchThumbnail();
  }

  async fetchThumbnail() {
    this.loadingThumbnail = this.item.thumbnailHash;
    try {
      var thumb = await this.libraryService.fetchThumbnail(this.item);
      this.item.thumbnail = thumb;
      this.itemSHA = sha1(this.item);
    } catch (err) {}
    this.loadingThumbnail = '';
  }

  shortName(): string {
    return this.item.name
      ?.split(' ')
      .map((n) => n.substring(0, 1))
      .join('')
      .toUpperCase();
  }

  placeholderBackgroundColor(): string {
    const hex = sha1(this.item.name).substring(1, 10);
    const index = parseInt(hex, 16) % this.bkgColors.length;
    return this.bkgColors[index];
  }

  bkgColors: string[] = [
    '#dc1916',
    '#b91411',
    '#e67a80',
    '#373655',
    '#49b18d',
    '#47cabe',
    '#3bb4b0',
    '#40a5af',
    '#8ba7bf',
    '#54698e',
    '#ba96ae',
    '#898cb6',
    '#4148a5',
    '#4e4181',
    '#873f70',
    '#bd4264',
    '#5d5855',
    '#52555d',
    '#7f8284',
  ];
}
