import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { DetailPanelService } from 'src/app/services/detail-panel.service';
import { SearchService } from 'src/app/services/search.service';
import { SketchupService } from 'src/app/services/sketchup.service';
import {
  SWMaterialsService,
  SWMaterialCollection,
  SWMaterialItem,
} from '../sw-materials.service';
import { MaterialPathsService } from '../material-paths.service';

@Component({
  selector: 'sw-materials-list',
  templateUrl: './materials-list.component.html',
  styleUrls: ['./materials-list.component.scss'],
  providers: [SearchService],
})
export class SWMaterialsListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  state: 'Loading' | 'ItemsLoaded' | 'NoResults' | 'NoFolder' | 'NotSketchup' =
    'Loading';

  currentPath: string = '';
  currentCollection: SWMaterialCollection;

  showSearch = false;
  searchSub: Subscription;
  filteredItems: (SWMaterialCollection | SWMaterialItem)[] = [];

  constructor(
    public materialsService: SWMaterialsService,
    public materialPathsService: MaterialPathsService,
    public searchService: SearchService,
    @Optional() private detailPanelService: DetailPanelService,
    private ui: UIService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private sketchup: SketchupService
  ) {}

  ngOnInit(): void {
    this.subscribeToMaterialCollection();
    this.subscribeToCollectionLoadingState();
  }

  subscribeToCollectionLoadingState() {
    this.collectionLoadingSubscription?.unsubscribe();
    this.collectionLoadingSubscription =
      this.materialsService.collectionsLoadingState.subscribe((state) => {
        if (state == 'UpdatingCollections') {
          this.state = 'Loading';
        }
        if (state == 'CollectionsLoaded') {
          this.state = 'ItemsLoaded';
        }
      });
  }

  collectionLoadingSubscription: Subscription;
  materialCollectionSubscription: Subscription;
  ngAfterViewInit(): void {
    if (this.sketchup.notSketchup) {
      this.state = 'NotSketchup';
      return;
    }

    this.loadMaterials();
  }

  async loadMaterials() {
    await this.materialPathsService.readLocalMaterialsFolderPaths();
  }

  subscribeToMaterialCollection() {
    this.materialCollectionSubscription?.unsubscribe();
    this.materialCollectionSubscription =
      this.materialsService.theCollections$.subscribe((c) => {
        this.currentCollection = c;
        if (c == undefined || c.collections.length == 0) {
          this.state = 'NoFolder';
          return;
        }

        if (c.collections.length + c.materials.length === 0) {
          this.state = 'NoResults';
          return;
        }

        this.state = 'ItemsLoaded';
        this.subscribeToSearch();
      });
  }

  ellipse(text: string, length: number) {
    text = text.split('/').pop();
    return text.length > length
      ? '...' + text.substring(text.length - (length - 3))
      : text;
  }

  selectMaterial(materialItem: SWMaterialCollection | SWMaterialItem) {
    if ((<SWMaterialCollection>materialItem).path) {
      //it's a collection
      this.currentCollection = materialItem as SWMaterialCollection;
      this.searchService.clearSearchQuery();
      this.showSearch = false;
      return;
    }

    // it's a material
    this.materialsService.selectMaterial(materialItem);
    this.detailPanelService.closeDetailPanel();
  }

  subscribeToSearch() {
    this.searchSub?.unsubscribe();
    this.searchSub = this.searchService.query$.subscribe((q) =>
      this.filterItems(q.query.trim().toLowerCase())
    );
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
    this.materialCollectionSubscription?.unsubscribe();
    this.collectionLoadingSubscription?.unsubscribe();
  }

  close() {
    this.detailPanelService.closeDetailPanel();
  }

  // tracking
  materialByID(index, item) {
    return item.id;
  }

  filterItems(search: string) {
    if (search.length) {
      this.filteredItems = this.materialsService.filterCollection(
        this.currentCollection,
        search
      );
    } else {
      this.filteredItems = [
        ...this.currentCollection.collections,
        ...this.currentCollection.materials,
      ];
    }
  }

  navigateToCollectionLevel(level: number) {
    if (level == -1) {
      this.currentCollection = this.materialsService.theCollections$.getValue();
      this.searchService.clearSearchQuery();
      this.showSearch = false;
      return;
    }

    const path = this.currentCollection.path.slice(0, level + 1);

    var cursor = this.materialsService.theCollections$.getValue();
    path.forEach((p) => {
      if (cursor.root) {
        // find by path
        cursor = cursor.collections.find((c) => c.path[0] === p);
      } else {
        // find by name
        cursor = cursor.collections.find((c) => c.name === p);
      }
      if (!cursor) {
        console.error('navigateToCollectionLevel: cursor is undefined');
        return;
      }
    });

    this.currentCollection = cursor;
    this.searchService.clearSearchQuery();
    this.showSearch = false;
  }

  async rescan(path: string[]) {
    await this.materialPathsService.removeLocalMaterialsFolder(path[0]);
    await this.materialPathsService.addLocalMaterialsFolder(path[0]);
  }

  async delete(path: string[]) {
    await this.materialPathsService.removeLocalMaterialsFolder(path[0]);
  }

  async pickFolder() {
    const path = await this.materialPathsService.pickLocalMaterialsFolder();
    if (path) {
      await this.materialPathsService.addLocalMaterialsFolder(path);
    }
  }

  // options
  folderOptionsTooltip(): string {
    if (this.sketchup.isSketchup) {
      return this.translate.instant('MATERIAL_PICKER.PICK_FOLDER_PROMPT');
    } else {
      return this.translate.instant('SETTINGS_FILE_LINK.OPEN_IN_SKETCHUP');
    }
  }

  openFolder(path: string) {
    //TODO: implement opening the folder in Explorer/Finder from Sketchup
  }
}
