export enum Fabric8ToolType {
  DRILL = 'DRILL',
  MILLING = 'MILLING',
  CUT = 'CUT',
  GUIDE = 'GUIDE',
  GUIDE_SEGMENT = 'GUIDE_SEGMENT',
  ZOOM_PAN = 'ZOOM_PAN',
}

export const Fabric8ToolTypeUtility = new Map<Fabric8ToolType, Boolean>([
  [Fabric8ToolType.DRILL, false],
  [Fabric8ToolType.MILLING, false],
  [Fabric8ToolType.CUT, false],
  [Fabric8ToolType.GUIDE, true],
  [Fabric8ToolType.GUIDE_SEGMENT, true],
]);
