import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SketchupService } from 'src/app/services/sketchup.service';
import { ModelManangerSettings } from '../models/MMSettings';

@Injectable({
  providedIn: 'root',
})
export class ModelManagerSettingsService {
  settings: ModelManangerSettings;
  names: Map<string, string> = new Map();

  constructor(
    private sketchUp: SketchupService,
    private translate: TranslateService
  ) {
    this.initNames();
    this.load();
  }

  initNames() {
    this.names.set('auto2d', 'MODEL_MANAGER.AUTOROTATE_2D');
    this.names.set('autoEmptyLOD', 'MODEL_MANAGER.AUTOEMPTY_LOD');
    // this.names.set('showLODNames', 'MODEL_MANAGER.SHOW_LOD_NAMES')
    this.names.set('enscapeProxy', 'MODEL_MANAGER.ENSCAPE_PROXY');
    this.names.set('showFPS', 'MODEL_MANAGER.SHOW_FPS');
  }

  async load() {
    // load from SU
    // if the settings are missing, set the default values
    var s = await this.sketchUp.localStorageGet('MODEL_MANAGER_SETTINGS');

    const defaultSettings: ModelManangerSettings = {
      auto2d: false,
      autoEmptyLOD: true,
      // showLODNames: false,
      enscapeProxy: false,
      showFPS: false,
    };

    this.settings = s || defaultSettings;

    // insert missing settings
    for (const [key, value] of Object.entries(defaultSettings)) {
      this.settings[key] = this.settings[key] || defaultSettings[key];
    }

    // if (!s) {
    await this.save();
    // }

    this.updateEnscapeProxy();
    this.updateFPS();
  }

  async toggle(key: string) {
    this.settings[key] = !this.settings[key];
    this.save();

    if (key == 'enscapeProxy') {
      await this.updateEnscapeProxy();
    }

    if (key == 'showFPS') {
      await this.updateFPS();
    }
  }

  async updateEnscapeProxy() {
    await this.sketchUp.bridge.call(
      'setEnscapeProxy',
      this.settings.enscapeProxy
    );
  }

  async updateFPS() {
    await this.sketchUp.bridge.call('showFPS', this.settings.showFPS);
  }

  async save() {
    await this.sketchUp.localStorageSet(
      'MODEL_MANAGER_SETTINGS',
      this.settings
    );
  }

  name(key: string) {
    return this.translate.instant(this.names.get(key));
  }
}
