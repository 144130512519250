import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ProjectGroupsManagerService } from 'src/app/modules/project-browser/groups-manager.service';
import { ProjectsGroup } from 'src/app/model/projects-group.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { UIService } from 'src/app/core/services/ui.service';
import { Project } from 'src/app/model/project.model';
import { SketchupService } from 'src/app/services/sketchup.service';
import { NewProjectDialogComponent } from './new-project-dialog';
import { ProjectFileLinkingService } from 'src/app/modules/project/services/project-file-linking.service';
import { TranslateService } from '@ngx-translate/core';

import packageJson from '../../../../../package.json';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sw-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit, OnDestroy {
  public activeItem = '';
  rootGroup?: ProjectsGroup = null;
  public version: string = packageJson.version;

  collapsed: boolean = true;

  constructor(
    private groupsManagerService: ProjectGroupsManagerService,
    private projectService: ProjectService,
    private uiService: UIService,
    private sketchupService: SketchupService,
    private projectFileLinkingService: ProjectFileLinkingService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  userSubscription: Subscription;

  ngOnInit(): void {
    this.userSubscription = this.authService.user$.subscribe((user) => {
      if (this.authService.loggedIn()) {
        this.loadRootGroup();
        this.openProjectIfSketchupFileIsLinked();
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  reloadApp() {
    location.reload();
  }

  async loadRootGroup() {
    try {
      this.rootGroup = await this.groupsManagerService.fetchRootGroup();
    } catch (err) {
      const message = "Couldn't load user's projects group";
      this.uiService.showErrors(message);
      console.error(err);
    }
  }

  onClickNewProject() {
    const dialogRef = this.dialog.open(NewProjectDialogComponent, {
      width: '300px',
      data: { projectName: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
        this.newProject(result.projectName);
      } else {
        // closed
      }
    });
  }

  async newProject(projectName) {
    this.uiService.disableUI(true);
    try {
      // create the project
      const newProject = await this.projectService.createProject(projectName);

      // add to selected group or root group
      var targetGroup =
        this.groupsManagerService.selectedGroup.value || this.rootGroup;
      await this.addProjectToGroup(newProject, targetGroup);

      this.projectService.openPlugins(newProject.id);
    } catch (err) {
      console.error(err);
      let message = this.translate.instant('NEW_PROJECT.CANT_CREATE');
      alert(message);
    }

    this.uiService.enableUI();
  }

  async addProjectToGroup(project: Project, group: ProjectsGroup) {
    group.projects.splice(0, 0, project);
    try {
      await this.groupsManagerService.updateGroup(group);
      group.expanded = true;
    } catch (err) {
      // the operation failed, remove the project from the group
      group.projects.splice(0, 1);
      throw err;
    }
  }

  deselectGroup() {
    this.groupsManagerService.selectGroup(undefined);
  }

  async onClickNewGroup() {
    const groupName = this.translate.instant('NEW_GROUP.NAME');

    this.uiService.disableUI(true);
    try {
      let g = await this.groupsManagerService.newGroup(groupName);
      const targetGroup =
        this.groupsManagerService.selectedGroup.value || this.rootGroup;
      await this.addGroupToGroup(g, targetGroup);
    } catch (err) {
      console.error(err);
      let message = this.translate.instant('NEW_GROUP.CANT_CREATE');
      this.uiService.showAlert(message);
    }
    this.uiService.enableUI();
  }

  async addGroupToGroup(
    group: ProjectsGroup,
    targetGroup: ProjectsGroup
  ): Promise<boolean> {
    targetGroup.groups.splice(0, 0, group);

    try {
      await this.groupsManagerService.updateGroup(targetGroup);
      return true;
    } catch (e) {
      // the operation failed. Remove the group.
      targetGroup.groups.splice(0, 1);
      throw e;
    }
  }

  public async openProjectIfSketchupFileIsLinked() {
    if (this.sketchupService.isSketchup == false) {
      return;
    }

    // read the open file path from SU
    var projectFilePaths =
      await this.projectFileLinkingService.getCurrentFilePath();

    // check if a projectID is linked with this filepath
    try {
      projectFilePaths = await this.projectFileLinkingService.queryFilePath(
        projectFilePaths
      );
    } catch (e) {
      // alert("file path not found " + projectFilePath.filepath)
    }

    if (projectFilePaths.projectID) {
      this.projectService.edit(projectFilePaths.projectID);
    }
  }
}
