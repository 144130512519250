<div id="grid" class="wbox">
  <div id="header" class="row">
    <mat-icon>tune</mat-icon>
    {{ "Fabric8 Settings" | translate }}
    <div class="spacer"></div>
    <mat-icon
      matTooltip="Reset to default settings"
      class="pointer"
      (click)="mm.resetSettings()"
      >refresh</mat-icon
    >
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div *ngIf="mm.settingsSubject | async as settings">
    <section id="settings" class="container scrolly">
      <div class="row">
        {{ mm.name("autoSave") | translate }}

        <div class="spacer"></div>

        <mat-icon class="check pointer" (click)="mm.toggle('autoSave')">{{
          settings.autoSave ? "check_box" : "check_box_outline_blank"
        }}</mat-icon>
      </div>
      <div class="row">
        {{ mm.name("drillCenterPoints") | translate }}

        <div class="spacer"></div>

        <mat-icon
          class="check pointer"
          (click)="mm.toggle('drillCenterPoints')"
          >{{
            settings.drillCenterPoints ? "check_box" : "check_box_outline_blank"
          }}</mat-icon
        >
      </div>
    </section>

    <!-- Grid settings -->
    <section>
      <div class="row">
        <mat-icon class="icon dark mr" [inline]="true">window</mat-icon>
        <div class="small-title">{{ "Grid" | translate }}</div>
      </div>

      <sw-attribute-list
        (attributesChange)="mm.saveGridSettings($event)"
        [attributes]="settings.grid"
      >
      </sw-attribute-list>
    </section>
  </div>

  <section id="footer" class="row center">
    <button mat-button class="btn-primary" (click)="close()">
      {{ "GLOBAL.OK" | translate }}
    </button>
  </section>
</div>
