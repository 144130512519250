import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import {
  DataAttribute,
  DataAttributeOptions,
} from 'src/app/model/data-attribute.model';
import { DataNode, DataNodeAPI } from 'src/app/model/data-node.model';
import { AttributeEditorService } from '../../attributes/attribute-editor.service';
import { UnitConversionService } from './unit-conversion.service';

@Injectable({
  providedIn: 'root',
})
export class DataNodeCoderService {
  constructor(
    private conversionService: UnitConversionService,
    private attributeEditorService: AttributeEditorService
  ) {}

  dataNodeAPI_to_DataNode(n: DataNodeAPI): DataNode {
    var dn = {
      id: n.id,
      name: n.name,
      attributes: n.attributes,
      childrenIDs: n.childrenIDs || [],
      children: [],
      treeTag: n.treeTag,
      libraryLinks: n.libraryLinks,
    };
    this.setOptionsFromList(dn);
    return dn;
  }

  dataNode_to_DataNodeAPI(node: DataNode): DataNodeAPI {
    const libraryLinks = node.libraryLinks?.map((l) => {
      delete l.item;
      return l;
    });

    var dn = {
      id: node.id,
      name: node.name,
      attributes: this.encodeAttributesForSketchUp(node.attributes),
      childrenIDs: this.getChildIDs(node),
      treeTag: node.treeTag,
      libraryLinks: libraryLinks ?? [],
    };
    return dn;
  }

  private getChildIDs(node: DataNode): string[] {
    if ((node.children || []).length == 0) return [];
    return node.children.map((child) => child.id);
  }

  private getAccessFromType(attr: DataAttribute) {
    if (attr.type == 'dropdown') return 'LIST';
    return 'TEXTBOX';
  }

  public setOptionsFromList(node: DataNode) {
    if (node === undefined) {
      return;
    }

    if (node.attributes == undefined) {
      return;
    }

    node.attributes.forEach((attr, index) => {
      node.attributes[index].type = this.getType(attr);
      node.attributes[index].options = this.preprocessOptions(attr);
    });
  }

  // prepares the attributes for SU
  private encodeAttributesForSketchUp(
    attributes: DataAttribute[]
  ): DataAttribute[] {
    // set access value
    return attributes.map((a) => {
      a = cloneDeep(a);
      if (a.access == undefined) {
        a.access = this.getAccessFromType(a);
      }

      a.units = this.conversionService.getUnitsFromType(a.type);
      a.tag = this.attributeEditorService.getTagOrMakeTagFromName(a);

      if (a.type == 'dropdown') {
        this.attributeEditorService.forceOptionSelection(a);
        a.list = this.encode(a.options);
        a.options = undefined;
      }

      return a;
    });
  }

  private getType(attr: DataAttribute) {
    if (attr.list) {
      return 'dropdown';
    }

    if (attr.tag == '_mat') {
      return 'material';
    }

    if (attr.type) return attr.type;

    return this.conversionService.getTypeFromUnits(attr.units);
  }

  private preprocessOptions(a: DataAttribute): DataAttributeOptions[] {
    if (a.list == undefined) {
      return undefined;
    }
    const b = unescape(a.list)
      .split('&')
      .filter((a) => a.length > 0)
      .map((a) => {
        const val = a.split('='); //.map(c => this.decode(c))
        return new DataAttributeOptions(val[0], val[1]);
      });
    return b;
  }

  private encode(options: DataAttributeOptions[]): string {
    var str = '';
    options.forEach((o) => {
      str += `&${escape(o.name)}=${escape(o.value)}`;
    });
    str += '&';
    return str;
  }

  private decode(str): string {
    var r = str;

    try {
      r = unescape(r);
    } catch {
      console.error('decodeURI failed with string: ' + str);
      r = '!!!' + str;
    } finally {
      return r;
    }
  }
}
