<div *ngIf="contextMenuService.data | async as data">
  <div
    class="contextmenu-backdrop"
    (click)="closeContextMenu($event)"
    (contextmenu)="closeContextMenu($event)"
    (mouseleave)="mouseLeaveAction($event)"
  ></div>

  <div
    class="sw-contextmenu"
    (contextmenu)="preventDefaultContextMenu($event)"
    [ngStyle]="{
      left: data.fabric8MouseEvent.canvasSpacePoint.x + 'px',
      top: data.fabric8MouseEvent.canvasSpacePoint.y + 'px'
    }"
  >
    <div
      class="sw-contextmenu-item"
      *ngFor="let item of data.options"
      (click)="selectedItem(item, data.meta)"
    >
      {{ item.title }}
    </div>
  </div>
</div>
