import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject } from 'rxjs';
import { DataAttribute } from '../../model/data-attribute.model';
import { DataNode } from '../../model/data-node.model';

import { sha1 } from 'object-hash';

@Injectable({
  providedIn: 'root',
})
export class AttributeEditorService {
  public currentNodeReference: DataNode;
  public node$ = new BehaviorSubject<DataNode>(undefined);

  public lockedAttributeNames = [];
  public lockedAttributeTags = [];

  constructor(private router: Router, private translate: TranslateService) {}

  setPlugin(id: string) {
    // set lockedAttributeNames
    if (id == 'sketchup-objects') {
      this.lockedAttributeNames = [
        'Name',
        'Summary',
        'Description',
        'ItemCode',
        'X',
        'inst__x',
        'Y',
        'inst__y',
        'Z',
        'inst__z',
        'RotX',
        'inst__rotx',
        'RotY',
        'inst__roty',
        'RotZ',
        'inst__rotz',
        'LenX',
        'LenY',
        'LenZ',
        'Material',
        'ScaleTool',
        'Hidden',
        'onClick',
        'Copies',
        'ImageURL',
        'DialogWidth',
        'DialogHeight',
      ].map((a) => a.toLowerCase());
    }

    // set lockedAttributeTags
    this.lockedAttributeTags = this.lockedAttributeNames.map(
      (n) => '_' + n.toLowerCase()
    );
  }

  private setNodeReference(node: DataNode) {
    if (this.currentNodeReference === node) {
      return;
    }

    this.currentNodeReference = node;
    // this.setOptionsFromList(node)
    this.node$.next(node);
  }

  getNodeValue(): DataNode {
    return this.node$.getValue();
  }

  setNodeValue(node) {
    if (this.currentNodeReference != undefined) {
      this.currentNodeReference.name = node.name;
      this.currentNodeReference.children = node.children;
      this.currentNodeReference.attributes = node.attibutes;
    }
  }

  edit() {
    // this.nav(["edit"])
  }

  open(node) {
    this.setNodeReference(node);
    // this.nav(["attr"])
  }

  deselectNode() {
    this.setNodeReference(undefined);
  }

  openPlugins(projectID: string) {
    this.nav([projectID, 'plugins']);
  }

  private nav(path: string[]) {
    const n = [
      {
        outlets: {
          detail: path,
        },
      },
    ];
    this.router.navigate(n);
  }

  forceOptionSelection(attr) {
    if (attr.options.length == 0) {
      throw 'No options on dropdown';
    }
    if (!attr.options.map((o) => o.value).includes(attr.value)) {
      attr.value = attr.options[0].value;
    }
  }

  getTagOrMakeTagFromName(attr: DataAttribute): string {
    if (attr.tag) return attr.tag;

    // add underscore prefix
    // trim name
    // replace spaces with _
    return (
      '_' + attr.name.trim().replace(new RegExp(' ', 'g'), '_').toLowerCase()
    );
  }

  getChangedAttributes(
    originalAttr: DataAttribute[],
    newAttr: DataAttribute[]
  ): DataAttribute[] {
    var originalHashes = originalAttr.map((a) => sha1(a));
    return newAttr.filter((a) => originalHashes.includes(sha1(a)) == false);
  }

  validateAttributes(
    attributes: DataAttribute[],
    lockedAttributeTags?: string[]
  ): boolean {
    // 1. detect if new fixed attributes exist
    // 2. pre-create tags from new attribute names; search for duplicate tags

    attributes.forEach((a, index) => {
      this.validateAttribute(attributes[index], lockedAttributeTags);
    });

    // check for duplicate names
    let existingNamesList = new Set();
    let duplicateNames = new Set();
    attributes
      .filter((a) => a.name.trim().length > 0)
      .forEach((a, index) => {
        if (existingNamesList.has(a.name)) duplicateNames.add(a.name);
        existingNamesList.add(a.name);
      });
    attributes
      .filter((a) => duplicateNames.has(a.name))
      .forEach((a) =>
        a.errors.push(this.translate.instant('ATTR.DUPLICATE_NAME'))
      );

    // check for duplicate tags
    let existingTagsList = new Set();
    let duplicateTags = new Set();
    attributes
      .map((a) => this.getTagOrMakeTagFromName(a))
      .filter((tag) => tag !== '_')
      .forEach((tag) => {
        if (existingTagsList.has(tag)) duplicateTags.add(tag);
        existingTagsList.add(tag);
      });
    attributes
      .filter((a) => duplicateTags.has(this.getTagOrMakeTagFromName(a)))
      .forEach((a) =>
        a.errors.push(this.translate.instant('ATTR.DUPLICATE_TAG'))
      );

    return attributes.find((attr) => attr.errors.length > 0) ? false : true;
  }

  validateAttribute(a: DataAttribute, lockedAttributeTags?: string[]) {
    if (!lockedAttributeTags) lockedAttributeTags = this.lockedAttributeTags;

    a.errors = [];

    if (!a.name || a.name.trim().length === 0) {
      a.errors.push(this.translate.instant('ATTR.EMPTY_NAME'));
    }

    if (this.lockedAttributeNames.includes(a.name.toLowerCase())) {
      a.errors.push(this.translate.instant('ATTR.RESERVED_NAME'));
    }

    var tag = this.getTagOrMakeTagFromName(a);

    if (lockedAttributeTags.includes(tag.toLowerCase())) {
      a.errors.push(this.translate.instant('ATTR.RESERVED_TAG'));
    }
  }
}
