import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UIService {
  // idle | disabled | modal | spinner

  UIdisabled$: BehaviorSubject<any> = new BehaviorSubject(false);
  spinner$: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() {}

  disableUI(spinner: boolean = false) {
    this.UIdisabled$.next(true);
    this.spinner$.next(spinner);
  }

  enableUI() {
    this.UIdisabled$.next(false);
  }

  showErrors(message: string) {
    console.error(message);
    // this.UIdisabled$.next(true)
    //TODO
  }

  showAlert(message: string) {
    alert(message);
  }
}
