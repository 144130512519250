import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { cloneDeep } from 'lodash';
import { environment } from 'src/environments/environment';
import { ProjectsGroup } from 'src/app/model/projects-group.model';
import { Project } from 'src/app/model/project.model';
import { UserService } from 'src/app/core/services/user.service';
import { ProjectService } from '../project/services/project.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectGroupsManagerService {
  constructor(private http: HttpClient, private userService: UserService) {}

  public selectedGroup = new BehaviorSubject<ProjectsGroup>(undefined);
  readonly selectedGroup$ = this.selectedGroup.asObservable();
  rootGroupReference: ProjectsGroup;

  selectGroup(group: ProjectsGroup | undefined) {
    if (group == undefined) {
      this.selectedGroup.next(undefined);
      return;
    }

    const g = this.selectedGroup.getValue();
    if (g == undefined || g.id != group.id) {
      this.selectedGroup.next(group);
    }
  }

  async loadChildren(group: ProjectsGroup): Promise<ProjectsGroup> {
    try {
      var theGroups = await this.fetchGroups(group.groupIDs);
      group.groups = theGroups.filter((g) => g !== null);
      var theProjects = await this.getProjectsMeta(group.projectIDs);
      group.projects = theProjects.filter((p) => p !== null);

      // console.log("loaded Children of group")
      // console.log(group.projects)
      return group;
    } catch (e) {
      throw e;
    }

    /*
    // quick and dirty:
    // load projects and groups one by one (multiple API calls)
    const getGroupPromises = group.groupIDs.map(gid => this.fetchGroups([gid]))
    const getProjectMetaPromises = group.projectIDs.map(pid => this.getProjectsMeta([pid]))
    try {
      var theGroups = (await Promise.all(getGroupPromises))[0]
      var theProjects = (await Promise.all(getProjectMetaPromises))[0]
      group.groups = theGroups.filter(g => g !== null) 
      group.projects = theProjects.filter(p => p !== null)
      return group
    } catch (e) {
      throw(e)
    }
    */
  }

  /* 
    Groups
  */

  async reloadGroup(group: ProjectsGroup): Promise<ProjectsGroup | null> {
    const freshGroups = await this.fetchGroups([group.id]);
    const freshGroup = freshGroups[0];
    group.name = freshGroup.name;
    group.projectIDs = freshGroup.projectIDs;
    group.groupIDs = freshGroup.groupIDs;
    return group;
  }

  async fetchGroups(groupIDs: string[]): Promise<ProjectsGroup[]> {
    const url = environment.apiUrl + '/group/get-groups';

    try {
      const body = { ids: groupIDs };
      const response = await this.http.post<any>(url, body).toPromise();
      return response.map((group) => this.APIResponseToProjectGroup(group));
    } catch (err) {
      if (err.status === 404) {
        return null;
      }
      console.error(err);
      throw err;
    }
  }

  async fetchRootGroup(): Promise<ProjectsGroup | null> {
    const url = environment.apiUrl + '/group/root';
    try {
      const resp = await this.http.get<any>(url).toPromise();
      const g = this.APIResponseToProjectGroup(resp);
      this.rootGroupReference = g;
      return g;
    } catch (err) {
      if (err.status == 404) return null;
      console.error(err);
      throw err;
    }
  }

  private isExpanded(groupID: string): boolean {
    // TODO: load/save group expanded/collapsed state from/to local storage
    return true;
  }

  private APIResponseToProjectGroup(
    groupAPIResponse: any
  ): ProjectsGroup | null {
    // TODO: we could trigger loadChildren here for expanded groups
    //      - the downside is that we don't get any intermediary visual feedback while loading the tree
    if (groupAPIResponse === undefined) {
      return null;
    }

    try {
      const group = new ProjectsGroup(
        groupAPIResponse.id,
        groupAPIResponse.name,
        this.isExpanded(groupAPIResponse.id),
        [],
        []
      );

      group.groupIDs = groupAPIResponse.groupIDs;
      group.projectIDs = groupAPIResponse.projectIDs;
      return group;
    } catch (e) {
      console.error("can't decode group");
      return null;
    }
  }

  async newGroup(name: string): Promise<ProjectsGroup> {
    const url = environment.apiUrl + '/group/create';
    const g = new ProjectsGroup(null, name, true, [], []);

    try {
      const newGroup = await this.http.post<any>(url, g).toPromise();
      g.id = newGroup.id;
      console.log('new group:', g);
      return g;
    } catch (err) {
      const message = "Can't create group";
      console.error(message, err);
      throw err;
    }
  }

  async updateGroups(groups: ProjectsGroup[]): Promise<boolean> {
    // serial resuests
    for (let g of groups) {
      await this.updateGroup(g);
    }

    // parallel requests - disabled for now
    /*
    const updatePromises = groups.map(g => this.updateGroup(g))
    await Promise.all(updatePromises)
    */

    return true;
  }

  async updateGroup(group: ProjectsGroup): Promise<boolean> {
    const url = environment.apiUrl + '/group';

    // create ordered groupIDs and projectIDs arrays
    // remove groups and projects
    const request = cloneDeep(group) as any;
    request.groups.map((g) => console.info(g));
    request.groupIDs = request.groups.map((g) => g.id);
    request.projectIDs = request.projects.map((p) => p.id);
    delete request['groups'];
    delete request['projects'];

    try {
      console.log(request);
      await this.http.put<boolean>(url, request).toPromise();
      // TODO: update local hashes after the backend returns
      group.groupIDs = request.groupIDs;
      group.projectIDs = request.projectIDs;
      if (group.groupIDs.length + group.projectIDs.length > 0) {
        group.expanded = true;
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async deleteGroup(id: string): Promise<boolean> {
    // TODO: check if the group is empty
    const url = environment.apiUrl + '/group/' + id;

    try {
      await this.http.delete(url).toPromise();

      return true;
    } catch (error) {
      console.error("Can't delete group");
      console.error(error);
      return false;
    }
  }

  /* 
    Projects
  */

  async getProjectsMeta(projectIDs): Promise<Project[]> {
    const url = environment.apiUrl + '/get-projects-meta/';
    try {
      const body = { ids: projectIDs };
      const response = await this.http.post<any>(url, body).toPromise();
      return response.map((project) => this.APIResponseToProject(project));
    } catch (err) {
      if (err.status === 404) {
        return null;
      }
      console.error(err);
      throw err;
    }
  }

  private APIResponseToProject(projectAPIresponse: any): Project | null {
    if (projectAPIresponse === undefined) {
      return null;
    }
    if (projectAPIresponse === '403') {
      return null;
    }

    try {
      const p = projectAPIresponse;
      const project = new Project(
        p.id,
        p.name,
        p.description,
        p.access,
        p.data
      );
      return project;
    } catch (e) {
      console.error("can't decode project");
      return null;
    }
  }

  /*
    Project - Group section
  */

  // Called when a project is loaded in another outlet (plugin/settings/edit project)
  // The project object reference must be the same, so that updates to the project (name) are seen in the group view
  updateProjectReference(project: Project, group?: ProjectsGroup) {
    if (group === undefined) {
      group = this.rootGroupReference;
    }

    if (group === undefined) {
      console.error('The root group is missing');
      return;
    }

    if (group.projects) {
      let index = group.projects.findIndex((p) => p.id == project.id);
      if (index !== -1) {
        group.projects[index] = project;
      }
    }

    group.groups?.forEach((g) => this.updateProjectReference(project, g));
  }

  findParentGroupOfProject(
    project: Project,
    group?: ProjectsGroup
  ): ProjectsGroup {
    if (group === undefined) {
      group = this.rootGroupReference;
    }

    if (group === undefined) {
      console.error('The root group is missing');
      return;
    }

    if (group.projectIDs) {
      let index = group.projectIDs.findIndex((pid) => pid == project.id);
      if (index !== -1) {
        return group;
      }
    }

    if (group.groups == undefined) {
      return undefined;
    }

    for (let i = 0; i < group.groups.length; i++) {
      var theGroup = this.findParentGroupOfProject(project, group.groups[i]);
      if (theGroup) {
        return theGroup;
      }
    }

    return undefined;
  }
}
