import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SaveAsTemplateDialogData {
  templateName: string;
}

@Component({
  selector: 'sw-save-template-dialog',
  templateUrl: 'save-as-template-dialog.html',
  styleUrls: ['../../../../scss/dialog-base.scss'],
})
export class SaveAsTemplateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SaveAsTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveAsTemplateDialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
