import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SketchupService } from '../../../services/sketchup.service';
import { environment } from 'src/environments/environment';
import { of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Project } from 'src/app/model/project.model';

export interface ProjectFilePath {
  projectID?: string;
  sketchUpFilePath?: string;
  layoutFilePath?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectFileLinkingService {
  constructor(private http: HttpClient, private sketchUp: SketchupService) {}

  public async getCurrentFilePath(): Promise<ProjectFilePath> {
    const path = await this.sketchUp.bridge.get('getCurrentFilePath');
    this.sketchUp.currentSketchUpFilePath.next(path);
    return {
      sketchUpFilePath: path,
    };
  }

  public async linkSketchupFile(project: Project): Promise<boolean> {
    const paths = await this.sketchUp.bridge.get(
      'linkSketchupFile',
      project.id
    );

    if (paths == 'SKETCHUP_FILE_NOT_SAVED') {
      throw new Error('SKETCHUP_FILE_NOT_SAVED');
    }
    project.sketchUpFilePath = paths.sketchUpFilePath;
    project.layoutFilePath = paths.layoutFilePath;
    return true;
  }

  public async linkLayoutFile(project: Project): Promise<boolean> {
    try {
      const paths = await this.sketchUp.bridge.get('linkToLayoutFile', project);

      if (paths == 'SKETCHUP_FILE_NOT_SAVED' || !paths) {
        throw new Error('SKETCHUP_FILE_NOT_SAVED');
      }
      project.sketchUpFilePath = paths.sketchUpFilePath;
      project.layoutFilePath = paths.layoutFilePath;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public async unlink(
    project: Project,
    type: 'sketchup' | 'layout'
  ): Promise<boolean> {
    await this.sketchUp.bridge.get('unlinkFilePath', project.id, type);

    //fetch the new state
    const pfp = await this.queryFilePath({ projectID: project.id });
    project.layoutFilePath = pfp.layoutFilePath;
    project.sketchUpFilePath = pfp.sketchUpFilePath;
    return true;
  }

  public async queryFilePath(
    request: ProjectFilePath
  ): Promise<ProjectFilePath> {
    return await this.sketchUp.bridge.get('queryFilePath', request);
  }
}
