<div class="attrbox" [ngClass]="{ editing: editing(), dim: updating }">
  <!-- <section id="header">
        <div class="row">
            <sw-editable id="node-name" class="title" [(value)]="node.name"
            (valueChange)="updateNodeTitle($event)"></sw-editable>
            <ng-template [ngIf]="!editing()" [ngIfElse]="isEditing">
                <div class="icon edit dark" (click)="onEdit()"></div>
                <mat-icon class="close" (click)="onClose()">close</mat-icon>
            </ng-template>
        </div>
    </section> -->
  <div *ngIf="node" class="container">
    <ng-template [ngIf]="editing()" [ngIfElse]="attributeList">
      <sw-attribute-editor
        class="top"
        [originalAttributes]="node.attributes"
        [(attributes)]="editingAttributes"
        [lockedAttributeTags]="lockedTags"
      >
        >
        <!-- (isEditingName)="isEditingAttributeActive = $event" -->
      </sw-attribute-editor>
      <div class="row buttons">
        <div class="btn-primary grey" (click)="cancelEditing()">
          {{ "GLOBAL.CANCEL" | translate }}
        </div>
        <div class="btn-primary" (click)="addAttribute()">
          <div class="icon" [inlineSVG]="'icon/plus.svg'"></div>
          <div>{{ "ATTR.ADD_ATTRIBUTE_BUTTON" | translate }}</div>
        </div>
        <div
          class="btn-primary"
          [ngClass]="{ 'grey click-disabled': isEditingAttributeActive }"
          (click)="doneEditing()"
        >
          {{ "GLOBAL.DONE" | translate }}
        </div>
      </div>
    </ng-template>
    <ng-template #attributeList>
      <ng-content></ng-content>
      <sw-attribute-list
        [attributes]="node.attributes"
        (attributesChange)="saveNode()"
      >
      </sw-attribute-list>

      <ng-template [ngIf]="!node.attributes || node.attributes.length === 0">
        <div class="row center">
          <div class="btn-primary" (click)="addAttribute()">
            <div class="icon" [inlineSVG]="'icon/plus.svg'"></div>
            <div>{{ "ATTR.ADD_ATTRIBUTE_BUTTON" | translate }}</div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
