import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[swObserveIntersection]',
})
export class ObserveIntersectionDirective
  implements OnDestroy, OnInit, AfterViewInit
{
  @Input() intersectionRootMargin = '0px';

  /// set steps to >100 to get updates on every pixel
  @Input() intersectionSteps = 1;

  // TODO: optional input - set specific thresholds
  // @Input() thresholds: number[] = 0;

  @Output() intersectionRatioChange = new EventEmitter<number>();

  /// convenience events
  @Output() onAppearing = new EventEmitter<number>();
  @Output() onDissapearing = new EventEmitter<number>();
  @Output() didAppear = new EventEmitter();
  @Output() didDisappear = new EventEmitter();

  private previousIntersectionRatio = 0;
  private observer: IntersectionObserver | undefined;
  private thresholds: number[] | number = 0.5;
  private appearThreshold = 0.5;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.thresholds = this.getThresholds();
    this.createObserver();
  }

  ngAfterViewInit(): void {
    this.startObservingElements();
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  }

  private getThresholds(): number[] | number {
    if (this.intersectionSteps > 99) {
      return 0;
    }

    const fractionalStep = 100 / this.intersectionSteps; // using 1 instead of 100 results in rounding errors.
    let c = 0;

    const thresholds = [];
    while (c < 100) {
      thresholds.push(c / 100);
      this.appearThreshold = c / 100;
      c += fractionalStep;
    }

    return thresholds;
  }

  private createObserver(): void {
    const options = {
      rootMargin: this.intersectionRootMargin,
      threshold: this.thresholds,
    };

    // console.log(options);

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        this.update(entry);
        this.previousIntersectionRatio = entry.intersectionRatio;
      });
    }, options);
  }

  private update(entry: IntersectionObserverEntry): void {
    this.intersectionRatioChange.emit(entry.intersectionRatio);

    const delta = entry.intersectionRatio - this.previousIntersectionRatio;

    (delta > 0 ? this.onAppearing : this.onDissapearing).emit(
      entry.intersectionRatio
    );

    if (delta !== 0) {
      // console.log(entry.intersectionRatio);
      if (entry.intersectionRatio >= this.appearThreshold) {
        this.didAppear.emit();
      }
      if (entry.intersectionRatio === 0) {
        this.didDisappear.emit();
      }
    }
  }

  private startObservingElements(): void {
    if (!this.observer) {
      console.error('intersection-observer: observer not initialized');
      return;
    }

    this.observer.observe(this.element.nativeElement);
  }
}
