<div class="library-item" [ngClass]="{ active: isOpened, dim: updating }">
  <div class="row" (click)="selectItem()">
    <!-- thumbnail -->
    <ng-template [ngIf]="item.thumbnail" [ngIfElse]="placeholderThumbnail">
      <div class="thumbnail" (click)="editThumbnail()">
        <img [src]="thumbnailData()" loading="lazy" />
      </div>
    </ng-template>
    <ng-template #placeholderThumbnail>
      <div
        class="thumbnail placeholder"
        (click)="editThumbnail()"
        [ngStyle]="{ 'background-color': placeholderBackgroundColor() }"
      >
        <ng-template [ngIf]="loadingThumbnail">
          <mat-spinner [diameter]="20"></mat-spinner>
        </ng-template>
        <ng-template [ngIf]="!loadingThumbnail">
          {{ shortName() }}
        </ng-template>
      </div>
    </ng-template>

    <!-- name -->
    <div class="details">
      <ng-template [ngIf]="isOpened && editMode" [ngIfElse]="viewMode">
        <input
          class="name"
          placeholder="Name"
          [(ngModel)]="item.name"
          (blur)="saveItem()"
        />
        <input
          class="subtitle"
          placeholder="Subtitle"
          [(ngModel)]="item.localName"
          (blur)="saveItem()"
        />
      </ng-template>

      <ng-template #viewMode>
        <div class="name">{{ item.name }}</div>
        <div class="subtitle">{{ item.localName }}</div>
      </ng-template>

      <ng-template [ngIf]="!isOpened">
        <div class="short-labels">
          <div
            *ngFor="let label of shortLabels()"
            class="sw-label"
            [ngStyle]="{
              'background-color': label.color,
              color: 'white'
            }"
          >
            {{ label.name }}
          </div>
        </div>
      </ng-template>
    </div>
    <div
      *ngIf="item.access && item.access.length > 1"
      class="icon shared"
      [ngClass]="{ 'hide-onhover': !isOpened }"
      matTooltip="{{ 'LIBRARY.SHARED' | translate }}"
      matTooltipPosition="above"
      (click)="editSharing()"
    >
      <mat-icon [inline]="true">people</mat-icon>
    </div>
    <ng-template [ngIf]="editMode === false" [ngIfElse]="nodeMenu">
      <div
        class="icon filter off"
        [inlineSVG]="'icon/filter_off.svg'"
        (click)="addToSearch($event)"
      ></div>
      <!-- <div class="icon counter">
                1
            </div> -->
      <div
        class="icon action"
        [inlineSVG]="actionIcon()"
        (click)="actionButtonClicked($event)"
      ></div>
    </ng-template>
  </div>

  <ng-container *ngIf="isOpened" [ngTemplateOutlet]="openedView">
  </ng-container>
</div>

<ng-template #openedView>
  <ng-template [ngIf]="editing === 'thumbnail'">
    <sw-image-uploader
      (base64Image)="updateThumbnail($event)"
      (cancel)="editing = false"
    ></sw-image-uploader>
  </ng-template>

  <ng-template [ngIf]="editing !== 'attributes' && editing !== 'sharing'">
    <div id="attributes">
      <sw-attribute-list
        [readonly]="!editMode"
        [attributes]="item.attributes"
        (attributesChange)="saveItem()"
      ></sw-attribute-list>
      <div class="attribute" (click)="editLabels($event)">
        <div class="label-name">Labels</div>
        <div class="full-labels">
          <ng-template [ngIf]="item.labels.length === 0">
            <div class="sw-label">+</div>
          </ng-template>
          <div
            *ngFor="let label of item.labels"
            class="sw-label"
            [ngStyle]="{
              'background-color': label.color,
              color: 'white'
            }"
          >
            {{ label.name }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngIf]="editing === 'attributes'">
    <div id="attributes">
      <sw-attribute-editor
        [originalAttributes]="item.attributes"
        [(attributes)]="clonedItem.attributes"
        [lockedAttributeTags]="lockedTags()"
      >
      </sw-attribute-editor>
    </div>
    <div class="row buttons spaced">
      <div class="btn-primary grey" (click)="cancelEditing()">
        {{ "GLOBAL.CANCEL" | translate }}
      </div>
      <div class="btn-primary" (click)="addAttribute()">
        <div>{{ "ATTR.ADD_ATTRIBUTE_BUTTON" | translate }}</div>
      </div>
      <div class="btn-primary" (click)="saveItem()">
        {{ "GLOBAL.SAVE" | translate }}
      </div>
    </div>
  </ng-template>

  <ng-template [ngIf]="editing === 'sharing'">
    <div id="sharing">
      <sw-sharing-view [(access)]="clonedItem.access"> </sw-sharing-view>
    </div>

    <div class="row buttons">
      <div class="btn-primary grey" (click)="cancelEditing()">
        {{ "GLOBAL.CANCEL" | translate }}
      </div>
      <div class="spacer"></div>
      <div class="btn-primary" (click)="saveItem()">
        {{ "GLOBAL.SAVE" | translate }}
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #nodeMenu>
  <ng-template [ngIf]="!clonedItem">
    <sw-dots-menu [itemID]="item.id" [ngClass]="{ 'show-onhover': !isOpened }">
      <div
        class="hover-btn red"
        [inlineSVG]="'icon/delete.svg'"
        (click)="onDeleteClicked()"
        matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
        matTooltipPosition="above"
      ></div>
      <div
        class="hover-btn"
        [inlineSVG]="'icon/edit.svg'"
        (click)="editAttributes()"
        matTooltip="{{ 'PROJECT_EDIT.EDIT_ATTRIBUTES' | translate }}"
        matTooltipPosition="above"
      ></div>
      <div
        class="hover-btn"
        [inlineSVG]="'icon/people.svg'"
        (click)="editSharing()"
        matTooltip="{{ 'LIBRARY.SHARE' | translate }}"
        matTooltipPosition="above"
      ></div>
      <!-- <div
        class="hover-btn" 
        [inlineSVG]="'icon/duplicate.svg'"
        (click)="onDuplicateClicked()"
        matTooltip="{{ 'GLOBAL.DUPLICATE' | translate }}"
        matTooltipPosition="above"
      ></div> -->
      <!-- <div
        class="hover-btn"
        [inlineSVG]="'icon/plus.svg'"
        (click)="addDataNodeChild()"
        matTooltip="{{ 'PROJECT_EDIT.NEW_ITEM_TOOLTIP' | translate }}"
        matTooltipPosition="above"
      ></div> -->
    </sw-dots-menu>
  </ng-template>
</ng-template>
