<ng-template [ngIf]="state === 'Loading'">
  <div class="center spacer">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</ng-template>

<ng-template [ngIf]="state === 'ItemsLoaded'">
  <div @parent>
    <div *ngFor="let item of items; let i = index; trackBy: itemByID" [@inout]>
      <!-- <div swDropZone
                class="inter"
                [wIndentLevel]="indentLevel + 1"

                [wDropTarget]="group"
                [wDropTargetList]="group.projects"
                [wDropTargetListIndex]="i"
                wDropAcceptType="PROJECT"
                
                [wDropDisabled]="isDragging()"
            ></div> -->
      <!-- [indentLevel]="indentLevel + 1" -->
      <sw-library-item-row [(item)]="items[i]" [editMode]="isEditing">
      </sw-library-item-row>
    </div>
  </div>
  <div class="spacer" (click)="deselectItem()"></div>
</ng-template>

<ng-template [ngIf]="state === 'NoResults'">
  <ng-template [ngIf]="isEditing" [ngIfElse]="notEditing">
    <sw-message [message]="'LIBRARY.NO_RESULTS' | translate" icon="smart_toy">
    </sw-message>
  </ng-template>

  <ng-template #notEditing>
    <sw-message
      [message]="'LIBRARY.NO_RESULTS' | translate"
      icon="smart_toy"
      actionText="{{ 'LIBRARY.EDIT' | translate }}"
      (action)="libraryViewService.toggleEditing(true)"
    >
    </sw-message>
  </ng-template>
</ng-template>
