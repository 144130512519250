import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibraryCategory } from 'src/app/model/library-category';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { LibraryService } from '../library.service';

export interface NewLibraryItemDialogData {
  itemName: string;
  plugin: SWPlugin;
  itemCategory: LibraryCategory;
}

@Component({
  selector: 'sw-library-item-dialog',
  templateUrl: 'new-library-item-dialog.html',
  styleUrls: ['new-library-item-dialog.scss'],
})
export class NewLibraryItemDialogComponent implements OnInit {
  categories: LibraryCategory[] = [];

  loadingCategories = true;
  pluginID: string;

  constructor(
    public libraryService: LibraryService,
    public dialogRef: MatDialogRef<NewLibraryItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewLibraryItemDialogData
  ) {
    this.pluginID = data.plugin.id;
  }

  ngOnInit(): void {
    this.fetchCategories();
  }

  async fetchCategories() {
    this.loadingCategories = true;
    try {
      this.categories = await this.libraryService.fetchCategories(
        this.pluginID
      );
      this.loadingCategories = false;
    } catch (err) {
      console.error(err);
    }
  }

  trackByID(index, item) {
    return item.id;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
