import { Injectable } from '@angular/core';
import { UserObject } from './auth.service';

export const TOKEN_KEY = 'auth-token';
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const USER_KEY = 'auth-user';
export const INSTALLATION_TOKEN_KEY = 'installation_token';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  clear(): void {
    window.localStorage.clear();
  }

  clearUserData(): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  public saveUser(user: UserObject | undefined): void {
    window.localStorage.removeItem(USER_KEY);
    if (user) {
      window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  }

  public getUser(): UserObject | undefined {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return undefined;
  }
}
