import { Injectable, OnDestroy } from '@angular/core';
import { Message } from '@stomp/stompjs';
import { Subject, Subscription } from 'rxjs';
import { Project } from 'src/app/model/project.model';
import { ProjectsGroup } from 'src/app/model/projects-group.model';
import {
  SelectDataNodeMessage,
  SelectProjectMessage,
  UDNSMessage,
  UpdateDataNodeMessage,
  UpdateGroupMessage,
} from './messages';
import { AttributeEditorService } from 'src/app/modules/attributes/attribute-editor.service';
import { ProjectGroupsManagerService } from 'src/app/modules/project-browser/groups-manager.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { UserPresenceService } from 'src/app/modules/user-presence-module/user-presence.service';
import { B64Service } from 'src/app/shared/b64.service';
import { MQService } from 'src/app/modules/rmq/mq.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectLiveUpdatesService implements OnDestroy {
  projectSubscription: Subscription;

  projectTopicSubscription: Subscription;
  groupTopicSubscription: Map<string, Subscription> = new Map();

  updateDataNodeSignal: Subject<UpdateDataNodeMessage> = new Subject();
  updateGroupSignal: Subject<UpdateGroupMessage> = new Subject();

  dataNodeSubscription: Subscription;
  SDNmessage: any = {};
  SDNtimer: any;

  SPmessage: any = {};
  SPtimer: any;

  constructor(
    private projectService: ProjectService,
    private mqService: MQService,
    private groupsManagerService: ProjectGroupsManagerService,
    private userPresenceService: UserPresenceService,
    private attributeEditorService: AttributeEditorService,
    private b64: B64Service
  ) {
    this.init();
  }

  init() {
    this.projectSubscription = this.projectService.project.subscribe(
      (project) => {
        this.switchedProject(project);
      }
    );
  }

  ngOnDestroy(): void {
    // rabbit mq subscriptions
    this.groupTopicSubscription?.forEach((s) => s.unsubscribe());
    this.groupTopicSubscription?.clear();
    this.projectTopicSubscription?.unsubscribe();

    // rxjs subscriptions
    this.projectSubscription?.unsubscribe();
    this.dataNodeSubscription?.unsubscribe();
  }

  public switchedProject(project: Project) {
    this.projectTopicSubscription?.unsubscribe();
    // publish deselection on last group channel
    this.publishProjectDeselection();
    this.publishDataNodeDeselection();

    if (Boolean(project) == false) {
      return;
    }
    this.startPublishingProjectSelection(project);

    this.initDataNodeSubscription();

    this.projectTopicSubscription = this.mqService
      .topic('project-' + project.id)
      .subscribe((message) => {
        console.log('R: ' + message.body);
        this.receivedProjectMessage(message);
      });
  }

  private startPublishingProjectSelection(project: Project) {
    // publish project selection to group channel
    const parentGroup =
      this.groupsManagerService.findParentGroupOfProject(project);
    if (parentGroup) {
      this.publishProjectSelection(parentGroup.id, project?.id);
    } else {
      console.error('The project is not found in any groups');
    }
  }

  private receivedProjectMessage(message: Message) {
    const m = message.body.split('>');

    if ('UDN' == m[0]) {
      const mc: UpdateDataNodeMessage = {
        nodeID: m[1],
        hash: m[2],
      };
      console.warn(message.body);
      this.updateDataNodeSignal.next(mc);
      message.ack();
      return;
    }

    if ('UDNS' == m[0]) {
      console.warn(message.body);

      const decodedNodesJSON = atob(m[1]);

      const nodes: UDNSMessage[] = JSON.parse(decodedNodesJSON);

      nodes.forEach((node: UDNSMessage) => {
        const mc: UpdateDataNodeMessage = {
          nodeID: node.node_id,
          hash: node.hash,
        };
        this.updateDataNodeSignal.next(mc);
      });

      message.ack();
      return;
    }

    if ('SDN' == m[0]) {
      // the message protocol used in SmartWorks is documented here:
      // https://docs.google.com/document/d/1B86PULeAkFRMO9ln0d8nLN83PzQwwJWIXIlpdB3BtiE/edit#heading=h.d5k9d6bnsfvq
      // SDN>{{nodeID}}>{{clientID}}
      // SDN>>{{clientID}}

      const mc: SelectDataNodeMessage = {
        nodeID: m[1],
        clientID: m[2],
      };
      this.userPresenceService.processDataNodeSelectionSignal(mc);
      message.ack();
      return;
    }
  }

  initDataNodeSubscription() {
    this.dataNodeSubscription?.unsubscribe();
    this.dataNodeSubscription = this.attributeEditorService.node$.subscribe(
      (node) => {
        // publish node selection to project channel
        const projectID = this.projectService.getProject().id;
        this.publishDataNodeSelection(projectID, node?.id);
      }
    );
  }

  public publishDataNodeSelection(projectID: string, dataNodeID: string = '') {
    // SDN>{{nodeID}}>{{clientID}}

    const message = ['SDN', dataNodeID, this.mqService.clientID].join('>');

    this.SDNmessage = {
      destination: '/topic/project-' + projectID,
      body: message,
      retryIfDisconnected: true,
    };

    this.publishSDN(Boolean(dataNodeID));
  }

  public publishDataNodeDeselection() {
    if (this.SDNmessage.body == undefined) {
      return;
    }
    //remove nodeID
    const message = this.SDNmessage.body.split('>');
    this.SDNmessage.body = [message[0], '', message[2]].join('>');
    this.publishSDN();

    this.attributeEditorService.node$.next(undefined);
  }

  private publishSDN(repeat: Boolean = false) {
    clearTimeout(this.SDNtimer);

    this.mqService.publish(this.SDNmessage);
    console.log('PUBLISH ' + this.SDNmessage.body);

    if (repeat) {
      this.SDNtimer = setTimeout(() => {
        this.publishSDN(repeat);
      }, 5000);
    }
  }

  /*
  public publishDataNodeUpdate(message: UpdateDataNodeMessage, projectID: string) {
    // the message protocol used in SmartWorks is documented here:
    // https://docs.google.com/document/d/1B86PULeAkFRMO9ln0d8nLN83PzQwwJWIXIlpdB3BtiE/edit#heading=h.d5k9d6bnsfvq
    // UDN>{{nodeID}}>{{hash}}

    const body = ["UDN", message.nodeID, message.hash].join(">")

    const m = {
      destination: '/topic/project-' + projectID,
      body: body,
      retryIfDisconnected: true
    }

    console.log("PUBLISH " + body)
    this.mqService.publish(m)
  }
  */

  public loadedGroup(group: ProjectsGroup) {
    this.groupTopicSubscription.get(String(group.id))?.unsubscribe();

    if (Boolean(group) == false) {
      return;
    }

    const sub = this.mqService
      .topic('group-' + group.id)
      .subscribe((message) => {
        // console.log("R: " + message.body)
        this.receivedGroupMessage(message, group);
      });
    this.groupTopicSubscription.set(String(group.id), sub);
  }

  private receivedGroupMessage(message: Message, group: ProjectsGroup) {
    const m = message.body.split('>');

    // select project message
    if ('SP' == m[0]) {
      // the message protocol used in SmartWorks is documented here:
      // https://docs.google.com/document/d/1B86PULeAkFRMO9ln0d8nLN83PzQwwJWIXIlpdB3BtiE/edit#heading=h.d5k9d6bnsfvq
      // SP>{{projectID}}>{{clientID}}
      // SP>>{{clientID}}
      const mc: SelectProjectMessage = {
        projectID: m[1],
        clientID: m[2],
      };
      this.userPresenceService.processGroupPresence(mc);
      message.ack();
      return;
    }

    // group updated message
    if ('GU' == m[0]) {
      console.log('R: ' + message.body);
      //trigger a group reload
      this.updateGroupSignal.next({
        groupID: group.id,
        hash: m[1],
      });
      message.ack();
      return;
    }

    if ('PU' == m[0]) {
      // Project Update
      console.warn(message.body);
      var updatedProject = JSON.parse(this.b64.decode(m[3]));

      if (m[1] == this.projectService.getProject()?.id) {
        // the current project was updated
        this.projectService.setProjectReference(updatedProject);
      } else {
        this.groupsManagerService.updateProjectReference(updatedProject);
      }
      return;
    }
  }

  public publishProjectSelection(groupID: string, projectID: string = '') {
    // SP>{{projectID}}>{{clientID}}

    const message = ['SP', projectID, this.mqService.clientID].join('>');

    this.SPmessage = {
      destination: '/topic/group-' + groupID,
      body: message,
      retryIfDisconnected: true,
    };

    this.publishSP(Boolean(projectID));
  }

  publishProjectDeselection() {
    if (this.SPmessage.body == undefined) {
      return;
    }
    //remove projectID
    const message = this.SPmessage.body.split('>');
    this.SPmessage.body = [message[0], '', message[2]].join('>');
    this.publishSP();
  }

  private publishSP(repeat: Boolean = false) {
    clearTimeout(this.SPtimer);

    this.mqService.publish(this.SPmessage);
    // console.log("PUBLISH " + this.SPmessage.body)

    if (repeat) {
      this.SPtimer = setTimeout(() => {
        this.publishSP(repeat);
      }, 5000);
    }
  }
}
