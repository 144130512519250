import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { WTreeModule } from '../wTree/w-tree.module';
import { UserPresenceModule } from '../user-presence-module/user-presence.module';
import { ProjectDataComponent } from './data/project-data.component';
import { ProjectDataListComponent } from './project-edit/project-data-list.component';
import { ProjectPluginsComponent } from './project-plugins/project-plugins.component';
import { FileLinkComponent } from './project-settings/file-link/file-link.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProjectLiveUpdatesService } from 'src/app/services/liveupdates/project-live-updates.service';
import { ProjectFileLinkingService } from 'src/app/modules/project/services/project-file-linking.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { PluginsModule } from '../plugins/plugins.module';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SaveAsTemplateDialogComponent } from './data/save-as-template-dialog';
import { LibraryModule } from '../library/library.module';
import { RabbitMQModule } from '../rmq/rabbit-mq.module';
import { DetailPanelService } from 'src/app/services/detail-panel.service';

const routes: Routes = [
  {
    outlet: 'project',
    path: ':id/settings',
    component: ProjectSettingsComponent,
  },
  {
    outlet: 'project',
    path: ':id/plugins',
    component: ProjectPluginsComponent,
  },
  {
    outlet: 'project',
    path: ':id/edit',
    component: ProjectDataListComponent,
  },
];

@NgModule({
  declarations: [
    ProjectSettingsComponent,
    ProjectPluginsComponent,
    ProjectDataComponent,
    ProjectDataListComponent,
    FileLinkComponent,
    SaveAsTemplateDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    SharedModule,
    WTreeModule,
    UserPresenceModule,
    DragDropModule,
    PluginsModule,
    // AttributesModule,
    LibraryModule,
    RabbitMQModule,
  ],
  exports: [RouterModule],
  providers: [
    ProjectService,
    ProjectFileLinkingService,
    ProjectLiveUpdatesService,
    DetailPanelService,
  ],
})
export class ProjectModule {}
