<div id="header">
  <div (click)="close()" id="icon" [inlineSVG]="'icon/label.svg'"></div>
  <ng-template [ngIf]="currentItem" [ngIfElse]="libraryTitle">
    <div class="title">{{ currentItem.name }}</div>
  </ng-template>
  <ng-template #libraryTitle>
    <!-- <div class="title">{{ plugin.name }} Labels</div> -->
    <div class="title">Labels</div>
  </ng-template>
  <div class="spacer"></div>
  <div (click)="onClickAddItem()" class="btn-primary">
    <div class="icon" [inlineSVG]="'icon/plus.svg'"></div>
    {{ "LIBRARY.NEW_ITEM_BUTTON" | translate }}
  </div>
  <mat-icon (click)="close()" class="pointer">close</mat-icon>
</div>

<sw-search-box [hideLabels]="true"> </sw-search-box>

<ng-template [ngIf]="state === 'Loading'">
  <div class="center spacer">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</ng-template>

<ng-template [ngIf]="state === 'ItemsLoaded'">
  <div @parent>
    <div
      *ngFor="let label of filteredLabels(); let i = index; trackBy: labelByID"
      [@inout]
    >
      <!-- <div swDropZone
                class="inter"
                [wIndentLevel]="indentLevel + 1"

                [wDropTarget]="group"
                [wDropTargetList]="group.projects"
                [wDropTargetListIndex]="i"
                wDropAcceptType="PROJECT"
                
                [wDropDisabled]="isDragging()"
            ></div> -->
      <!-- [indentLevel]="indentLevel + 1" -->
      <ng-template [ngIf]="editingLabel !== label" [ngIfElse]="editLabel">
        <div class="label row">
          <ng-template [ngIf]="currentItem">
            <mat-icon
              class="check"
              [ngStyle]="{ color: label.color }"
              (click)="clickBox(label)"
              >{{
                currentItem.labelIDs.includes(label.id)
                  ? "check_box"
                  : "check_box_outline_blank"
              }}</mat-icon
            >
          </ng-template>
          <div
            class="sw-label"
            (dblclick)="onClickEditLabel(label)"
            [ngStyle]="{
              'background-color': label.color,
              color: 'white'
            }"
          >
            {{ label.name }}
          </div>
          <div class="spacer"></div>
          <div
            *ngIf="label.access && label.access.length > 1"
            class="icon shared"
            matTooltip="{{ 'LABELS.SHARED' | translate }}"
            matTooltipPosition="above"
            (click)="onClickEditLabel(label)"
          >
            <mat-icon [inline]="true">people</mat-icon>
          </div>
          <sw-dots-menu [itemID]="label.id">
            <div
              class="hover-btn red"
              [inlineSVG]="'icon/delete.svg'"
              (click)="onDeleteClicked(label)"
              matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
              matTooltipPosition="above"
            ></div>
            <div
              class="hover-btn"
              [inlineSVG]="'icon/edit.svg'"
              (click)="onClickEditLabel(label)"
              matTooltip="{{ 'PROJECT_EDIT.EDIT_ATTRIBUTES' | translate }}"
              matTooltipPosition="above"
            ></div>
          </sw-dots-menu>
        </div>
      </ng-template>

      <ng-template #editLabel>
        <div id="editor" class="label">
          <sw-label-editor
            [label]="label"
            (labelChange)="endLabelEditing($event)"
          ></sw-label-editor>
        </div>
      </ng-template>
    </div>
    <div class="spacer" (click)="deselectItem()"></div>
  </div>
  <div class="spacer"></div>
</ng-template>

<ng-template [ngIf]="state === 'NoResults'">
  <sw-message
    [message]="'LABELS.NO_LABELS' | translate"
    icon="smart_toy"
    actionText="{{ 'GLOBAL.CLOSE' | translate }}"
    (action)="close()"
  >
  </sw-message>
</ng-template>

<ng-template [ngIf]="state !== 'NoResults'">
  <div class="row buttons">
    <!-- <div class="btn-primary grey" (click)="cancel()">{{ 'GLOBAL.CANCEL' | translate }}</div> -->
    <div class="spacer"></div>
    <div class="btn-primary" (click)="close()">
      {{ "GLOBAL.DONE" | translate }}
    </div>
    <div class="spacer"></div>
  </div>
</ng-template>
