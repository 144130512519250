<div
  swDrag
  (wDragStarted)="dragStarted($event)"
  (wDragReleased)="dragReleased($event)"
  (wDrop)="dropped($event)"
  [wDragNode]="project"
  [wDragObjectType]="'PROJECT'"
  [wDragParent]="parent"
  [wDragParentList]="parent.projects"
>
  <div
    class="project row"
    swDragHandle
    [ngClass]="{
      dragging: dragging,
      'dragging-parent': parentIsDragged,
      selected: isActive,
      hide: isHidden
    }"
  >
    <sw-tree-indent [level]="indentLevel"></sw-tree-indent>

    <div (click)="onClickProject()" class="name">{{ project.name }}</div>

    <sw-user-presence [projectID]="project.id"></sw-user-presence>

    <div (click)="onClickProject()" class="spacer"></div>

    <div
      *ngIf="project.access && project.access.length > 1"
      class="icon shared"
      matTooltip="{{ 'PROJECT.SHARED' | translate }}"
      matTooltipPosition="above"
    >
      <mat-icon [inline]="true">people</mat-icon>
    </div>

    <sw-dots-menu [itemID]="project.id">
      <div
        class="hover-btn red"
        [inlineSVG]="'icon/delete.svg'"
        (click)="onClickProjectRemove()"
        matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
        matTooltipPosition="above"
      ></div>
      <div
        class="hover-btn"
        [inlineSVG]="'icon/duplicate.svg'"
        (click)="onClickProjectDuplicate()"
        matTooltip="{{ 'GLOBAL.DUPLICATE' | translate }}"
        matTooltipPosition="above"
      ></div>
    </sw-dots-menu>
  </div>
</div>
