import { Injectable, NgZone } from '@angular/core';
import { clone } from 'lodash';
import { CanvasForm, Line, Pt } from 'pts';
import { ContextMenuItem } from 'src/app/modules/fabric8/contextmenu/contextmenu.options';
import { Fabric8Element, IFabric8Element } from '../../models/element.model';
import { Fabric8NodeType } from '../../models/Fabric8NodeType';
import { Fabric8Node } from '../../models/node.model';
import { colors } from '../../swcanvas/colors';
import { Fabric8ToolType } from '../../toolset/Fabric8ToolType';
import { CameraService } from '../camera.service';
import { CurrentObjectService } from '../current-object.service';
import { ElementsService } from '../elements.service';
import { ToolHandlerService } from '../tool-handler.service';
import { ToolService } from '../tool.service';
import { Fabric8MouseEvent } from './models/Fabric8MouseEvent.model';
import { Fabric8NodeDataPointIndex } from './models/Fabric8NodeDataPointIndex.model';
import { Fabric8ToolHandler } from './models/Fabric8ToolEvents.model';
import { ToolState } from './models/ToolState.model';
import { SnapService, SnapTo } from './snap';

@Injectable({
  providedIn: 'root',
})
export class RouterTool implements Fabric8ToolHandler {
  state: ToolState = ToolState.idle;
  cursorPoint: Pt | undefined;
  cursorSnapped?: SnapTo;

  isDragging = false;
  addedPoint = false;

  constructor(
    private toolService: ToolService,
    private elementsService: ElementsService,
    private currentObject: CurrentObjectService,
    private cameraService: CameraService,
    private snap: SnapService,
    toolHandlerService: ToolHandlerService
  ) {
    toolHandlerService.registerTool(Fabric8ToolType.MILLING, this);
    toolHandlerService.registerTool(Fabric8ToolType.CUT, this);
  }

  private newElementAt(): Fabric8Node {
    const currentTool = this.toolService.getCurrentTool();
    // const depth = this.toolService.getCurrentToolDepth();

    const newElement = new Fabric8Element({
      [IFabric8Element]: true,
      tool: clone(currentTool),
      isClosedPath: false,
      points: [],
      // depth: depth,
    });
    const newNode = this.elementsService.addNewNodeWithData(newElement);
    return newNode;
  }

  public snapCursor(event: Fabric8MouseEvent) {
    const object = this.currentObject.get();

    const s = this.snap.to(object, event, [
      SnapTo.guidelinesIntersections,
      SnapTo.guidelineToObjectIntersections,
      SnapTo.guidelines,
      SnapTo.elementPoints,
      SnapTo.objectPoints,
      SnapTo.objectLines,
      SnapTo.grid,
      SnapTo.roundUnits,
    ]);

    this.cursorSnapped = s.snapped;
    this.cursorPoint = s.point;
  }

  // handle mouse events
  public mouseDown(event: Fabric8MouseEvent) {
    this.isDragging = false;
    const { closedOrJoined } = this.processMouseDownOrDrop(event);
    if (closedOrJoined) {
      this.elementsService.commitCurrentNodeData();
      this.reset();
      return;
    }
    const firstHoveredNodeIdx = this.getHoveredNodeIndex(event);

    // select the point if it is hovered

    if (this.state !== ToolState.drawing && firstHoveredNodeIdx) {
      this.elementsService.setCurrentNodeDataPointIndex(firstHoveredNodeIdx);
      this.state == ToolState.editing;
    }

    // add new point
    var numberOfPoints = 0;
    if (this.state == ToolState.idle) {
      const newNode = this.newElementAt();
      numberOfPoints = this.elementsService.addAndSelectPoint(
        this.cursorPoint,
        newNode
      );
      this.state = ToolState.drawing;
      this.addedPoint = true;
    } else if (this.state == ToolState.drawing) {
      numberOfPoints = this.elementsService.addAndSelectPoint(
        this.cursorPoint,
        this.elementsService.getCurrentNode()
      );
      this.addedPoint = true;
    }

    this.currentObject.update(true);
  }

  public mouseClick(event: Fabric8MouseEvent) {
    // console.info("mouseClick")
  }

  public mouseDrag(event: Fabric8MouseEvent) {
    this.isDragging = true;
    this.elementsService.updateCurrentPoint(this.cursorPoint);
    this.currentObject.update(false);
  }

  private getHoveredNodeIndex(
    event: Fabric8MouseEvent
  ): Fabric8NodeDataPointIndex | undefined {
    const nonGuidelineHoveredElementIndices =
      event.hoveredNodeDataPointIndices?.filter(
        (s) => s.type !== Fabric8NodeType.Guideline
      ) || [];

    const firstHoveredNodeIdx = nonGuidelineHoveredElementIndices[0];
    return firstHoveredNodeIdx;
  }

  public mouseUp(event: Fabric8MouseEvent) {
    if (this.isDragging) {
      // never called
      console.error('isDragging but mouseUp');
      this.elementsService.commitCurrentNodeData();
      this.reset();
      return;
    }

    const firstHoveredNodeIdx = this.getHoveredNodeIndex(event);

    // attempt to select the first or last point of a hovered router path
    if (firstHoveredNodeIdx) {
      // if the current point index is the first or last point on a router element
      // enter drawing mode

      if (!this.addedPoint) {
        const { element: hoveredElement } =
          this.currentObject.getElementDataWithId(firstHoveredNodeIdx.id);

        const isHoveringRouterLine =
          hoveredElement.tool.type == Fabric8ToolType.MILLING ||
          hoveredElement.tool.type == Fabric8ToolType.CUT;
        const isClosedPath = hoveredElement.isClosedPath;
        if (!isHoveringRouterLine || isClosedPath) {
          this.state = ToolState.editing;
          return;
        }

        const hoveringFirstPoint = firstHoveredNodeIdx.pointIndex == 0;
        const hoveringLastPoint =
          firstHoveredNodeIdx.pointIndex == hoveredElement.points.length - 1;

        if (!(hoveringFirstPoint || hoveringLastPoint)) {
          this.state = ToolState.editing;
          return;
        }

        // enter drawing mode

        if (hoveringFirstPoint) {
          // first point
          // reverse element points
          hoveredElement.points.reverse();
          firstHoveredNodeIdx.pointIndex = hoveredElement.points.length - 1;
        } else if (hoveringLastPoint) {
          // last point
        }
      }

      this.addedPoint = false;
      this.state = ToolState.drawing;
      this.elementsService.setCurrentNodeDataPointIndex(firstHoveredNodeIdx);
    }
  }

  // returns true if the path was closed or joined
  public mouseDrop(event: Fabric8MouseEvent) {
    const { closedOrJoined } = this.processMouseDownOrDrop(event);
    this.isDragging = false;

    if (closedOrJoined) {
      // end editing
      this.elementsService.commitCurrentNodeData();
      this.reset();
      return;
    } else {
      // continue drawing or editing
      this.currentObject.update(true);

      if (this.state !== ToolState.drawing) {
        this.elementsService.commitCurrentNodeData();
        this.reset();
      }
    }
  }

  public mouseMove(event: Fabric8MouseEvent) {
    // this.toolService.updateCurrentPoint(this.cursorPoint);
    // console.log('mouseMove', event)
  }

  private processMouseDownOrDrop(event: Fabric8MouseEvent): {
    closedOrJoined: boolean;
  } {
    const firstHoveredNodeIdx = this.getHoveredNodeIndex(event);

    if (firstHoveredNodeIdx) {
      if (this.state == ToolState.idle) this.state = ToolState.editing;

      // try to close the current path
      if (this.tryClosingPath(event)) return { closedOrJoined: true };

      // try to join the current path with another path
      if (this.tryJoiningPaths(event)) return { closedOrJoined: true };
    }
    return { closedOrJoined: false };
  }

  public keyDown(event: any) {
    if (event.key == 'Delete' || event.key == 'Backspace') {
      const alsoDeletedTheLastPoint = this.elementsService.deleteCurrentPoint();
      if (alsoDeletedTheLastPoint) this.reset();
    } else if (event.key == 'Enter' || event.key == 'Escape') {
      this.elementsService.commitCurrentNodeData();
      this.reset();
    }
  }

  public reset() {
    this.isDragging = false;
    this.addedPoint = false;
    this.state = ToolState.idle;
  }

  private canJoinOrClosePath(): Fabric8Element {
    // if we are not in drawing or editing mode,
    // then we are not joining paths
    const isEditDrop = this.state == ToolState.editing && this.isDragging;
    if (!(this.state === ToolState.drawing || isEditDrop)) {
      return;
    }

    // if the current element is not a router path, then we are not joining paths
    const currentElement = this.elementsService.getCurrentElement();
    if (!currentElement || currentElement.tool.type !== Fabric8ToolType.MILLING)
      return;

    return currentElement;
  }

  private tryJoiningPaths(event: Fabric8MouseEvent): Boolean {
    // console.log('tryJoiningPaths');
    const currentElement = this.canJoinOrClosePath();
    if (!currentElement) return false;

    const currentElementPointIndex =
      this.elementsService.getCurrentNodeDataPointIndex();

    const isFirstPoint = currentElementPointIndex.pointIndex == 0;
    const isLastPoint =
      currentElementPointIndex.pointIndex == currentElement.points.length - 1;
    // if it's not the first or last point of the current element, don't join
    if (!isFirstPoint && !isLastPoint) return false;

    // if the current element is a closed path, then we are not joining paths
    if (currentElement.isClosedPath) return false;

    // evaluate each hovered point
    for (let i = 0; i < event.hoveredNodeDataPointIndices.length; i++) {
      const hoveredElementPointIndex = event.hoveredNodeDataPointIndices[i];
      // skip all dataNodes that are not Elements
      if (hoveredElementPointIndex.type !== Fabric8NodeType.Element) continue;

      // if the hovered element is the current element, skip the element
      const isHoveringCurrentElement =
        hoveredElementPointIndex.id == currentElementPointIndex.id;
      if (isHoveringCurrentElement) continue;

      // get the hovered element and node
      const { element: hoveredElement, node: hoveredNode } =
        this.currentObject.getElementDataWithId(hoveredElementPointIndex.id);
      if (hoveredElement.tool.type !== Fabric8ToolType.MILLING) continue;

      // if the hovered element is a closed path, skip the element
      const hoveringClosedPath = hoveredElement.isClosedPath;
      if (hoveringClosedPath) continue;

      // if the hovered element is a router element,
      // and the point is the first or last element
      // append all the element points to the current element
      // and delete the hovered element

      const joinStartPoint = hoveredElementPointIndex.pointIndex == 0;
      const joinEndPoint =
        hoveredElementPointIndex.pointIndex == hoveredElement.points.length - 1;

      // if we are not joining the start or end point, skip the element
      if (!(joinStartPoint || joinEndPoint)) continue;

      const reverseSourcePoints = isFirstPoint;
      const reverseTargetPoints = Boolean(joinEndPoint);

      this.elementsService.joinElementPointsToCurrentElement(
        hoveredNode,
        reverseSourcePoints,
        reverseTargetPoints
      );

      return true;
    }

    return false;
  }

  private tryClosingPath(event: Fabric8MouseEvent): Boolean {
    // console.log('tryClosingPath');
    const currentElement = this.canJoinOrClosePath();
    if (!currentElement) return false;

    const currentElementPointIndex =
      this.elementsService.getCurrentNodeDataPointIndex();
    const isCurrentElementLastPoint =
      currentElementPointIndex.pointIndex == currentElement.points.length - 1;

    // const minPoints = this.state === ToolState.drawing ? 2 : 3;
    // to allow closing the path, we need at least 3 points

    if (
      !currentElement ||
      !isCurrentElementLastPoint ||
      currentElement.points.length < 3
      // currentElementPointIndex.pointIndex < minPoints
    )
      return false;

    // evaluate each hovered point
    for (let i = 0; i < event.hoveredNodeDataPointIndices.length; i++) {
      const hoveredElementPointIndex = event.hoveredNodeDataPointIndices[i];
      // skip all dataNodes that are not Elements
      if (hoveredElementPointIndex.type !== Fabric8NodeType.Element) continue;

      // if the hovered element is the current element, skip the element
      const isHoveringCurrentElement =
        hoveredElementPointIndex.id == currentElementPointIndex.id;
      if (!isHoveringCurrentElement) continue;

      // get the hovered element and node
      const { element: hoveredElement, node: hoveredNode } =
        this.currentObject.getElementDataWithId(hoveredElementPointIndex.id);
      if (hoveredElement.tool.type !== Fabric8ToolType.MILLING) continue;

      // and the hovered point is the first point in the current element
      // set the current element to closed
      const isEditDrop = this.state == ToolState.editing && this.isDragging;

      const isHoveringStartPoint = hoveredElementPointIndex.pointIndex == 0;
      if (isHoveringStartPoint) {
        if (this.state === ToolState.drawing && !isEditDrop) {
          this.elementsService.addAndSelectPoint(
            this.cursorPoint,
            this.elementsService.getCurrentNode()
          );
        }
        currentElement.isClosedPath = true;

        return true;
      }
    }
  }

  public draw(form: CanvasForm) {
    if (this.cursorPoint && this.cursorSnapped) {
      const p = this.cameraService.transform([this.cursorPoint]);
      var shape = 'circle';
      switch (this.cursorSnapped) {
        case SnapTo.objectPoints:
        case SnapTo.elementPoints:
        case SnapTo.guidelinesIntersections:
        case SnapTo.guidelineToObjectIntersections:
          shape = 'square';
      }

      if (
        this.cursorSnapped == SnapTo.grid ||
        this.cursorSnapped == SnapTo.roundUnits
      ) {
        //don't draw
      } else {
        form.fillOnly(colors.activeTool).point(p[0], 5, shape);
      }
    }

    if (this.state == ToolState.drawing) {
      // draw line from last point to cursor
      const currentElement = this.elementsService.getCurrentElement();
      if (currentElement) {
        const points = this.cameraService.transform(currentElement.points);
        const lastPoint = points[points.length - 1];
        if (this.cursorPoint) {
          const cursorPoint = this.cameraService.transform([
            this.cursorPoint,
          ])[0];
          form.strokeOnly(colors.activeTool, 1).line([lastPoint, cursorPoint]);
        }
      }
    }
  }

  public getContextMenuItems(event: Fabric8MouseEvent): ContextMenuItem[] {
    return [];
  }

  public contextMenuAction(item: ContextMenuItem, meta: any) {
    return;
  }
}
