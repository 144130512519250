<ng-template #noUser>
  <ng-template
    [ngIf]="(authService.loggingIn$ | async) === true"
    [ngIfElse]="theOutlet"
  >
    <div class="fullscreen center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <ng-template #theOutlet>
    <div id="login-modal" class="fullscreen">
      <div class="router-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template
  [ngIf]="(authService.user$ | async) !== undefined"
  [ngIfElse]="noUser"
>
  <div class="grid-container" [ngClass]="{ inSketchup: sketchUp.isSketchup }">
    <sw-app-top-header *ngIf="!sketchUp.isSketchup"></sw-app-top-header>

    <div id="router-outlets">
      <div id="router-projectslist">
        <router-outlet></router-outlet>
      </div>
      <div id="router-project">
        <router-outlet name="project"></router-outlet>
      </div>
      <div id="router-library">
        <router-outlet name="library"></router-outlet>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="uiService.UIdisabled$ | async">
  <div class="fullscreen grey ontop">
    <ng-template [ngIf]="uiService.spinner$ | async">
      <mat-progress-bar
        mode="indeterminate"
        style="width: 100%"
      ></mat-progress-bar>
    </ng-template>
  </div>
</ng-template>

<ng-template [ngIf]="notificationsService.notification$ | async">
  <sw-notification></sw-notification>
</ng-template>

<!-- <sw-cursors style="position:absolute; top:0; left:0"></sw-cursors> -->

<sw-mq-connection-status></sw-mq-connection-status>

<div class="version" *ngIf="version">{{ version }}</div>
