<div class="attrbox">
  <div
    class="trow preset"
    *ngFor="let attr of node.attributes; let aIndex = index; trackBy: attrIndex"
  >
    <div class="name-and-tag">
      <!-- [attr.contenteditable]="!isEditLocked(a)" -->
      <div
        #nameInput
        class="attribute-name show-placeholder"
        [attr.contenteditable]="true"
        (keydown)="nameLengthCheck(aIndex, $event)"
        (keydown.enter)="nameInput.blur()"
        (blur)="nameChange(aIndex, nameInput.textContent)"
        [attr.placeholder]="attr.tag"
        [innerHTML]="node.attributes[aIndex].name"
      ></div>
      <div
        class="tag-name show-placeholder"
        [innerHTML]="attr.tag"
        *ngIf="attr.name.length > 0 && attr.name !== attr.tag"
      ></div>
      <!-- (blur)="tagChange(aIndex, $event.target.textContent)" -->
      <!-- [attr.contenteditable]="!isEditLocked(a)" -->
      <!-- (keydown.enter)="$event.target.blur()" -->
      <!-- [attr.placeholder]="tagPlaceholder(aIndex)" -->
    </div>

    <div class="right pointer" (click)="openMaterialPicker(aIndex)">
      {{ attr.value }}
      <ng-container *ngIf="attr.value | materialThumbnail as mat; else maticn">
        <img *ngIf="mat.thumbnail" class="thumbnail" [src]="mat.thumbnail" />
        <sw-color-block
          *ngIf="!mat.thumbnail"
          class="thumbnail"
          [colors]="mat.color"
        ></sw-color-block>
      </ng-container>
      <ng-template #maticn>
        <i class="material-icons small">apps</i>
      </ng-template>
    </div>
    <div class="rotate-material-texture">
      <div class="btn-primary rotate-btn" (click)="rotateMaterial(aIndex)">
        <mat-icon class="icon">rotate_90_degrees_ccw</mat-icon>
      </div>
    </div>
  </div>
</div>
