import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { UnitConversionService } from '../../project/services/unit-conversion.service';

interface ModalConfigurationData {
  title: string | undefined;
  label: string;
}

interface ToolConfigurationData {
  drillCount?: number;
  distance?: any;
}

@Component({
  selector: 'sw-tool-configuration',
  templateUrl: './sw-tool-configuration.component.html',
  styleUrls: ['./sw-tool-configuration.component.scss'],
})
export class SwToolConfigurationComponent implements OnInit {
  defaultUnit: string;
  toolConfig: ToolConfigurationData = {
    drillCount: 3,
    distance: '20 mm',
  };

  constructor(
    private unitConversion: UnitConversionService,
    private dialogRef: MatDialogRef<SwToolConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalConfigurationData
  ) {}

  ngOnInit(): void {
    this.defaultUnit = this.unitConversion.getUnitsFromType('length');
  }

  convertDistance(): void {
    const dataAttr: DataAttribute = {
      name: '',
      value: String(this.toolConfig.distance),
      value_raw: undefined,
      units: this.defaultUnit,
      type: 'length',
      list: null,
      tag: '',
      access: null,
      options: null,
      errors: [],
    };
    const rawValue = this.unitConversion.rawValueFromInput(dataAttr);

    this.toolConfig.distance = this.unitConversion.valueFromRawValueAndUnit({
      ...dataAttr,
      value_raw: rawValue,
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
