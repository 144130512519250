<div
  class="container"
  [ngClass]="{ active: isActive, selected: isSelected, deleting: deleting }"
>
  <div class="row hub-row">
    <ng-template [ngIf]="rebuilding" [ngIfElse]="refreshIcon">
      <mat-spinner [diameter]="20"> </mat-spinner>
    </ng-template>
    <ng-template #refreshIcon>
      <mat-icon
        inline
        class="icon pointer eye rebuild"
        [ngClass]="{ 'is-hidden': !hub.state?.needsRefresh }"
        (click)="refresh()"
      >
        refresh
      </mat-icon>
    </ng-template>
    <div class="hub-cell grow pointer" (click)="toggleSelection()">
      <span id="name">
        {{ hub.name }}
      </span>
      <span class="instances">
        {{ hub.instance_count }}
      </span>
    </div>
    <div class="hub-cell" (click)="mm.selectHub(hub)">
      <ng-template [ngIf]="deleting">
        <mat-spinner [diameter]="20"></mat-spinner>
      </ng-template>
      <div
        *ngFor="let s of LODstates"
        class="icon lod-icon pointer"
        [ngClass]="{ active: activeLODType() === s, 'is-hidden': hub.hidden }"
        [inlineSVG]="'mm/i-' + s + '.svg'"
        (click)="onSetLODType(s)"
      ></div>
      <div
        class="icon pointer eye"
        [ngClass]="{ 'is-hidden': hub.hidden }"
        (click)="toggleVisibility()"
        [inlineSVG]="hub.hidden ? 'mm/eye-off.svg' : 'mm/eye.svg'"
      ></div>
      <sw-dots-menu>
        <div
          class="hover-btn red"
          [inlineSVG]="'icon/delete.svg'"
          (click)="onClickDeleteHub()"
          matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
          matTooltipPosition="above"
        ></div>
      </sw-dots-menu>
    </div>
  </div>
  <ng-template [ngIf]="rebuilding">
    <div id="details">
      <span class="small-text">{{
        "MODEL_MANAGER.REBUILDING" | translate
      }}</span>
    </div>
  </ng-template>
  <ng-template [ngIf]="isActive && !deleting && !rebuilding">
    <!-- <pre>{{ hub | json }}</pre> -->
    <div id="details">
      <ng-template [ngIf]="activeLODType() === 'empty'">
        <span class="small-text">{{
          "MODEL_MANAGER.EMPTY_LOD" | translate
        }}</span>
      </ng-template>
      <ng-template [ngIf]="activeLODType() === 'full'">
        <span class="small-text">{{
          "MODEL_MANAGER.FULL_LOD" | translate
        }}</span>
      </ng-template>
      <ng-template [ngIf]="activeLODType() === 'proxy'">
        <div class="flex column center">
          <sw-segmented-buttons
            [buttons]="['low', 'medium', 'high']"
            [allowMultipleSelection]="false"
            [allowEmptySelection]="true"
            [selection]="[hub.state.proxyDensity]"
            (selectionChanged)="onSetLODType(MMLODType.Proxy, $event[0])"
          >
          </sw-segmented-buttons>
          <ng-template
            [ngIf]="missingLODtype === undefined && creatingLOD === false"
          >
            <div class="small-text">
              {{ "MODEL_MANAGER.CHANGE_DENSITY_MESSAGE" | translate }}
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template [ngIf]="activeLODType() === '2d'">
        <sw-segmented-buttons
          [buttons]="['box', 'center', 'top', 'side', 'front']"
          [allowMultipleSelection]="false"
          [allowEmptySelection]="true"
          [selection]="[mm.get2DLODType(hub.active_lod)]"
          (selectionChanged)="onSetLODType(MMLODType.TwoD, $event[0])"
        >
        </sw-segmented-buttons>
        <div class="row top-padding" *ngIf="missingLODtype === undefined">
          <mat-icon
            class="check pointer"
            [ngClass]="{ selected: autoRotateEnabled() }"
            (click)="toggleAutoRotate()"
            >{{ autoRotateEnabled() ? "check_box" : "check_box_outline_blank" }}
          </mat-icon>
          {{ "MODEL_MANAGER.AUTOROTATE_2D" | translate }}
        </div>
      </ng-template>

      <ng-template [ngIf]="creatingLOD === true">
        <div class="row top-padding">
          <mat-spinner [diameter]="20"></mat-spinner>
          <span class="small-text">
            {{ "MODEL_MANAGER.GENERATING_LOD" | translate }}
          </span>
        </div>
      </ng-template>

      <ng-template
        [ngIf]="missingLODtype !== undefined && creatingLOD === false"
      >
        <div class="small-text top-padding">
          {{ "MODEL_MANAGER.MISSING_LOD_MESSAGE" | translate }}
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
