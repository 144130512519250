<div class="row box">
  <!-- <div class="i"><mat-icon [inline]="true">search</mat-icon></div> -->
  <mat-form-field appearance="standard">
    <div matPrefix class="i"><mat-icon [inline]="true">search</mat-icon></div>

    <mat-chip-list #chipList aria-label="Library labels">
      <mat-basic-chip
        class="sw-label removable"
        *ngFor="let item of selectedLibraryItems"
        [removable]="true"
        [ngStyle]="{ 'background-color': 'black', color: 'white' }"
      >
        <div class="name">{{ item.name }}</div>
        <mat-icon
          class="sw-label-remove"
          [inline]="true"
          (click)="removeItem(item)"
          >close</mat-icon
        >
      </mat-basic-chip>
      <mat-basic-chip
        class="sw-label removable"
        *ngFor="let label of selectedLabels"
        [removable]="true"
        [ngStyle]="{ 'background-color': label.color, color: 'white' }"
      >
        <div class="name">{{ label.name }}</div>
        <mat-icon
          class="sw-label-remove"
          [inline]="true"
          (click)="remove(label)"
          >close</mat-icon
        >
      </mat-basic-chip>
      <input
        placeholder="Search"
        #searchInput
        [formControl]="searchCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onAutocompleteSelected($event)"
    >
      <mat-option *ngFor="let label of filteredLabels | async" [value]="label">
        <span
          class="sw-label"
          [ngStyle]="{
            'background-color': label.color,
            color: 'white'
          }"
        >
          {{ label.name }}
        </span>
      </mat-option>
      <!-- <mat-option *ngFor="let option of options" [value]="option">
                {{option}}
            </mat-option> -->
    </mat-autocomplete>
  </mat-form-field>
  <div class="i pointer">
    <mat-icon
      [inline]="true"
      (click)="clearInput()"
      *ngIf="searchQueryNotEmpty()"
      >close</mat-icon
    >
  </div>
</div>
