import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  INSTALLATION_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  LocalStorageService,
  TOKEN_KEY,
} from './local-storage.service';
import { Router } from '@angular/router';
import { SketchupService } from 'src/app/services/sketchup.service';
import { UIService } from './ui.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const jwtHelper = new JwtHelperService();

export interface UserObject {
  email: string;
  id: string;
  uid: string;
  userLabelID: string;
  accessToken: string;
  refreshToken: string;
  username: string;
  licenses: string[];
  language: string;
}

export interface UserCredentials {
  username?: string;
  password?: string;
  installation_token?: string;
}

export interface RefreshTokenRequest {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: BehaviorSubject<UserObject | undefined> = new BehaviorSubject<
    UserObject | undefined
  >(undefined);
  loggingIn$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    public sketchUp: SketchupService,
    private ui: UIService
  ) {
    this.autoLogin();
  }

  loggedIn(): boolean {
    return this.user$.getValue() != null;
  }

  async autoLogin() {
    this.loggingIn$.next(true);
    if (await this.installationTokenLogin()) {
      this.loggingIn$.next(false);
      return;
    }

    // 1. check if the USER object is already stored.
    const user = this.localStorageService.getUser();
    if (user) {
      //logged in

      // additional check, to force a relogin
      if (Boolean(user.userLabelID) == false) {
        this.gotoLogin();
        this.loggingIn$.next(false);
        return;
      }
      // additional check end

      this.setUser(user);
      this.loggingIn$.next(false);
      return;
    }

    this.gotoLogin();
    this.loggingIn$.next(false);
  }

  setUser(user: UserObject | undefined) {
    this.localStorageService.saveUser(user);
    this.sketchUp.localStorageSet(TOKEN_KEY, user?.accessToken);
    this.sketchUp.localStorageSet(REFRESH_TOKEN_KEY, user?.refreshToken);
    this.user$.next(user);
  }

  async installationTokenLogin(): Promise<boolean> {
    // 2. if the user is not logged in / no USER obj is present:
    //     2a. If running outside SketchUp, show the login screen
    //     2b. If running from SketchUp, try an installation_token login
    if (this.sketchUp.isSketchup == false) {
      return false;
    }

    var installation_token = await this.sketchUp.localStorageGet(
      INSTALLATION_TOKEN_KEY
    );
    try {
      await this.login({ installation_token: installation_token });
      return true;
    } catch (err) {
      return false;
    }
  }

  async login(credentials: UserCredentials): Promise<any> {
    const response = await this.http
      .post<any>(environment.apiUrl + '/auth/signin', credentials, httpOptions)
      .toPromise();
    this.setUser(response.data);
    return response;
  }

  // called by the interceptor on non `auth/` or `assets/` urls
  public async getOrRefreshToken(): Promise<string> {
    // we add a 30 min offset to the token expiration time, to avoid the token expiring while the request is in flight
    var offsetSeconds = 30 * 60; // 30 min
    var user: UserObject = this.user$.getValue();
    if (!user) return '';
    const accessTokenExpired = jwtHelper.isTokenExpired(
      user.accessToken,
      offsetSeconds
    );
    if (!accessTokenExpired) {
      return user?.accessToken;
    }

    user = await this.refreshToken(user);
    return user?.accessToken || '';
  }

  async refreshToken(user: UserObject): Promise<UserObject | undefined> {
    const request = {
      access_token: user?.accessToken,
      refresh_token: user?.refreshToken,
    };
    try {
      const obj = await this.http
        .post<RefreshTokenRequest>(
          environment.apiUrl + '/auth/refresh',
          request,
          httpOptions
        )
        .toPromise();
      user.accessToken = obj.accessToken;
      user.refreshToken = obj.refreshToken;
      this.setUser(user);
      return user;
    } catch (err) {
      return undefined;
    }
  }

  gotoLogin() {
    this.setUser(undefined);
    this.router.navigate(['/login']);
  }

  logout() {
    console.log('logged out');
    this.localStorageService.clearUserData();
    this.setUser(undefined);
    setTimeout((_t: any) => {
      this.ui.enableUI();
    }, 100);
  }
}
