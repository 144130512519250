<ng-template #customTopButtons>
  <ng-container
    *ngIf="{ value: libraryViewService.isEditing$ | async } as isEditing"
  >
    <div
      *ngIf="sketchUp.isSketchup"
      class="icon switch"
      [ngClass]="{ active: isEditing.value }"
      matTooltip="{{ 'LIBRARY.EDIT' | translate }}"
      matTooltipPosition="above"
      [inlineSVG]="
        isEditing.value ? 'icon/switch_on.svg' : 'icon/switch_off.svg'
      "
      (click)="libraryViewService.toggleEditing(undefined)"
    ></div>
  </ng-container>
  <div
    class="icon"
    [ngClass]="{
      active: (detailPanelService.currentPanel | async) === 'labels'
    }"
    matTooltip="{{ 'LIBRARY.LABELS' | translate }}"
    matTooltipPosition="above"
    [inlineSVG]="'icon/label.svg'"
    (click)="detailPanelService.toggleShowingLabels()"
  ></div>

  <div
    class="icon plus"
    [inlineSVG]="'icon/plus.svg'"
    (click)="addNewItem()"
    matTooltip="{{ 'PROJECT_EDIT.NEW_ITEM_TOOLTIP' | translate }}"
    matTooltipPosition="above"
  ></div>
</ng-template>

<div class="center">
  <div *ngIf="sketchUp.isSketchup" class="btn-primary" (click)="uproot()">
    <div class="icon" [inlineSVG]="'icon/plugins/gardn8/uproot.svg'"></div>
    {{ "GARDN8.UPROOT" | translate }}
  </div>

  <div
    *ngIf="sketchUp.notSketchup"
    class="btn-primary disabled"
    matTooltipPosition="above"
    matTooltip="{{ 'GARDN8.WEB_SKETCHUP_MESSAGE' | translate }}"
  >
    <div class="icon" [inlineSVG]="'icon/plugins/gardn8/uproot.svg'"></div>
    {{ "GARDN8.UPROOT" | translate }}
  </div>
</div>

<sw-app-library></sw-app-library>
