import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Fabric8Tool, Fabric8ToolGroup } from '../../models/tool.model';
import { Fabric8ToolbarService } from '../../toolbar/fabric8-toolbar.service';
import { Fabric8ToolTypeUtility } from '../Fabric8ToolType';
import { getToolIcon } from '../toolset';

@Component({
  selector: 'sw-tool-group',
  templateUrl: './tool-group.component.html',
  styleUrls: ['./tool-group.component.scss'],
})
export class ToolGroupComponent implements OnInit {
  toolGroup: Fabric8ToolGroup;
  @Input() toolGroupIndex: number;

  getToolIcon = getToolIcon;

  constructor(
    private toolbarService: Fabric8ToolbarService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.toolGroup =
      this.toolbarService.toolsetSubject.getValue()[this.toolGroupIndex];
  }

  isUtilityGroup(): Boolean {
    return Fabric8ToolTypeUtility.get(this.toolGroup.type);
  }

  async save() {
    this.toolbarService.updateToolGroup(this.toolGroupIndex, this.toolGroup);
  }

  async toolChanged(tool: Fabric8Tool, index: number): Promise<void> {
    this.toolGroup[index] = tool;
    await this.save();
  }

  async addTool(): Promise<void> {
    await this.toolbarService.addTool(this.toolGroupIndex);
  }

  async deleteTool(toolIndex: number): Promise<void> {
    await this.toolbarService.deleteTool(this.toolGroupIndex, toolIndex);
  }

  translateToolName(name: string): string {
    name = name.replace('-', '_');
    name = name.toUpperCase();

    return `FABRIC8.${name}`;
  }
}
