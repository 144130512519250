import { Fabric8NodeType } from '../models/Fabric8NodeType';
import { Fabric8Object } from '../models/object.model';
import { Fabric8Objects } from './fabric8-sketchup.service';

export function getStaticTestObjects(): Fabric8Objects {
  const obj1: Fabric8Object = new Fabric8Object(
    JSON.stringify({
      name: '<Component#17>',
      guid: '0NXk5dSC3fH8gLOtkmtCFW',
      rootNode: {
        name: 'Root',
        id: 'lmmxihsu',
        type: Fabric8NodeType.Group,
        children: [
          {
            name: 'Group',
            id: 'lsojgnsgdojngsdmmxihsu',
            children: [],
            type: 'group',
          },
        ],
      },
      lines: [
        [
          [0, 0, 0],
          [0, 179.38750000000005, 0],
        ],
        [
          [179.38750000000005, 1.127986593019159e-14, 0],
          [0, 0, 0],
        ],
        [
          [179.38750000000005, 179.38750000000005, 0],
          [179.38750000000005, 1.127986593019159e-14, 0],
        ],
        [
          [0, 179.38750000000005, 0],
          [179.38750000000005, 179.38750000000005, 0],
        ],
      ],
    })
  );

  const obj2 = new Fabric8Object(
    JSON.stringify({
      name: 'Side Panel',
      guid: '0NXk5dSC3fgLOtkmtCFW',
      rootNode: {
        // [IFabric8Node]: true,
        id: 'uhfxifgun',
        name: 'Root',
        children: [],
        type: Fabric8NodeType.Group,
      },
      lines: [
        [
          [0, 0, 0],
          [0, 79.38750000000005, 0],
        ],
        [
          [79.38750000000005, 1.127986593019159e-14, 0],
          [0, 0, 0],
        ],
        [
          [79.38750000000005, 179.38750000000005, 0],
          [79.38750000000005, 1.127986593019159e-14, 0],
        ],
        [
          [0, 179.38750000000005, 0],
          [79.38750000000005, 179.38750000000005, 0],
        ],
      ],
    })
  );

  return {
    projectID: '0000',
    objects: [obj1, obj2],
  };
}
