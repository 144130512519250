import { NgModule } from '@angular/core';
import { ProjectsGroupComponent } from './group/projects-group.component';
import { ProjectsListComponent } from './list/projects-list.component';
import { ProjectRowComponent } from './project-row/project-row.component';
import { SharedModule } from '../../shared/shared.module';
import { WTreeModule } from '../wTree/w-tree.module';
import { UserPresenceModule } from '../user-presence-module/user-presence.module';
import { NewProjectDialogComponent } from './list/new-project-dialog';
import { ProjectGroupsManagerService } from './groups-manager.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ProjectsListComponent,
    ProjectsGroupComponent,
    ProjectRowComponent,
    NewProjectDialogComponent,
  ],
  imports: [
    SharedModule,
    WTreeModule,
    UserPresenceModule,
    TranslateModule.forChild(),
  ],
  providers: [ProjectGroupsManagerService],
})
export class ProjectBrowserModule {}
