<ng-template [ngIf]="notification">
  <div class="fullscreen grey flex center">
    <div class="wbox flex column">
      <sw-message
        [message]="notification.message"
        icon="warning"
        actionText="Dismiss"
        (action)="dismiss()"
      ></sw-message>
    </div>
  </div>
</ng-template>
