import { Injectable } from '@angular/core';
import { Bound, CanvasForm, Group, Pt } from 'pts';
import { CameraService } from '../../services/camera.service';
import { Fabric8SettingsService } from '../../settings/fabric8-settings.service';
import { colors } from '../colors';

interface GridBoundData {
  upperLeft: Pt;
  lowerRight: Pt;
  size: Pt;
}

@Injectable({
  providedIn: 'root',
})
export class RenderGridService {
  gridPoints: Group = new Group();

  constructor(
    private camera: CameraService,
    private settingsService: Fabric8SettingsService
  ) {}

  computeGridBounds(canvasBound: Bound, gridSize: number): GridBoundData {
    const upperLeft = this.camera.inverseTransform([canvasBound.topLeft])[0];
    const lowerRight = this.camera.inverseTransform([
      canvasBound.bottomRight,
    ])[0];

    upperLeft.x = Math.floor(upperLeft.x / gridSize) * gridSize;
    upperLeft.y = Math.floor(upperLeft.y / gridSize) * gridSize;

    lowerRight.x = Math.ceil(lowerRight.x / gridSize) * gridSize;
    lowerRight.y = Math.ceil(lowerRight.y / gridSize) * gridSize;

    const canvasSize = lowerRight.$subtract(upperLeft);

    return {
      upperLeft: upperLeft,
      lowerRight: lowerRight,
      size: canvasSize,
    };
  }

  makeGrid(gridSize: number, canvasBound: Bound): Group {
    const gridPoints = new Group();

    var gridBoundData = this.computeGridBounds(canvasBound, gridSize);

    // scale the gridSize to limit the number of points
    while (gridSize * this.camera.cameraScale < 20) {
      gridSize *= 2;
      gridBoundData = this.computeGridBounds(canvasBound, gridSize);
    }

    for (
      let x = gridBoundData.upperLeft.x;
      x < gridBoundData.lowerRight.x;
      x += gridSize
    ) {
      for (
        let y = gridBoundData.lowerRight.y;
        y <= gridBoundData.lowerRight.y - gridBoundData.size.y;
        y += gridSize
      ) {
        gridPoints.push(new Pt(x, y));
      }
    }

    return gridPoints;
  }

  refreshGrid(form: CanvasForm) {
    var gridSize = this.settingsService.getGridSize();
    this.gridPoints = this.makeGrid(gridSize, form.space.innerBound);
    this.gridPoints = Group.fromPtArray(this.camera.transform(this.gridPoints));
  }

  render(form: CanvasForm) {
    const gridPoints = this.gridPoints;
    if (gridPoints == undefined || gridPoints.length < 1) {
      return;
    }

    var pointScale = 1;
    if (gridPoints[0].y - gridPoints[1].y > 35) {
      pointScale = 2;
    }

    form
      .fillOnly(colors.gridPoints)
      .points(gridPoints, pointScale / form.space.pixelScale, 'square');
  }
}
