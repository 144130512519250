import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { Bound, CanvasForm, CanvasSpace, IPlayer, Pt, Space } from 'pts';
import { Subscription } from 'rxjs';
import { Fabric8ContextMenuService } from '../contextmenu/fabric8-context-menu.service';
import { SwToolConfigurationComponent } from '../modal/sw-tool-configuration.component';
import { Fabric8Element } from '../models/element.model';
import { Fabric8NodeType } from '../models/Fabric8NodeType';
import { Fabric8Object } from '../models/object.model';
import { CameraService } from '../services/camera.service';
import { CurrentObjectService } from '../services/current-object.service';
import { ObjectsManagerService } from '../services/objects-manager.service';
import { ToolHandlerService } from '../services/tool-handler.service';
import { ToolService } from '../services/tool.service';
import { UndoService } from '../services/undo.service';
import { HoverService } from '../services/hover.service';
import { Fabric8ToolType } from '../toolset/Fabric8ToolType';
import { colors } from './colors';
import { RenderElementsService } from './render/renderElements';
import { RenderGridService } from './render/renderGrid';
import { RenderGuidelinesService } from './render/renderGuidelines';
import { RenderObjectService } from './render/renderObject';
import { RenderGuidelineSegmentsService } from './render/renderGuidelineSegments';

const ZOOM_FACTOR = 1.4;

@Component({
  selector: 'sw-swcanvas',
  templateUrl: './swcanvas.component.html',
  styleUrls: ['./swcanvas.component.scss'],
})
export class SWCanvasComponent
  implements OnInit, OnDestroy, AfterViewInit, OnDestroy
{
  @ViewChild('fabric8-canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  canvasSpace: CanvasSpace;
  canvasForm: CanvasForm;

  // shape data
  objectsSubscription: Subscription;
  currrentObjectIndexSubscription: Subscription;
  contextMenuSubscription: Subscription;

  currentElementSubscription: Subscription;
  // objectDoneSubscription: Subscription
  isPanZooming: Boolean = false;

  debouncedResize = _.debounce(() => {
    this.ngZone.run(() => {
      this.setCameraBoundsToFitCurrentObject();
    });
  }, 40);

  constructor(
    public toolService: ToolService,
    private toolHandlerService: ToolHandlerService,
    private ngZone: NgZone,
    public currentObject: CurrentObjectService,
    private objectsManager: ObjectsManagerService,
    public hoverService: HoverService,
    private camera: CameraService,
    private renderElementsService: RenderElementsService,
    private renderObjectService: RenderObjectService,
    private renderGuidelinesService: RenderGuidelinesService,
    private renderGuidelineSegmentsService: RenderGuidelineSegmentsService,
    private renderGridService: RenderGridService,
    public contextMenuService: Fabric8ContextMenuService,
    private undoService: UndoService
  ) {}

  player: IPlayer = {
    start: (bound: Bound, space: Space): void => {
      this.setCameraBoundsToFitCurrentObject();
    },
    resize: (bound: Bound, evt?: Event): void => {
      //debounce resize
      this.debouncedResize();
    },
    animate: (time?: number, frameTime?: number, currentSpace?: any): void => {
      // if (this.settings == undefined) return;
      this.renderGridService.render(this.canvasForm);

      const currentObject = this.currentObject.get();
      if (currentObject) {
        this.renderObjectService.render(currentObject, this.canvasForm);

        this.renderElementsService.render(
          this.currentObject.getObjectNodesFlatList([Fabric8NodeType.Element]),
          this.canvasForm
        );

        this.renderGuidelinesService.render(
          this.currentObject.getObjectNodesFlatList([
            Fabric8NodeType.Guideline,
          ]),
          this.canvasForm
        );

        this.renderGuidelineSegmentsService.render(
          this.currentObject.getObjectNodesFlatList([
            Fabric8NodeType.GuidelineSegment,
          ]),
          this.canvasForm
        );
      }

      this.toolService.drawTool(this.canvasForm);
    },
    action: (type: string, px: number, py: number, evt: Event): void => {
      this.canvasAction(type, px, py, evt);
    },
  };

  async ngOnInit(): Promise<void> {
    this.canvasSpace = new CanvasSpace('fabric8-canvas');
  }

  ngAfterViewInit(): void {
    this.initCanvas();
    this.subscribeToObjects();
    this.subscribeToContextMenu();
  }

  initCanvas() {
    this.canvasSpace.setup({ retina: true, resize: true, pixelDensity: 2 });
    this.canvasSpace.background = colors.background;
    this.canvasSpace.refresh(true);
    this.canvasForm = this.canvasSpace.getForm();

    this.canvasSpace.add(this.player);
    this.canvasSpace.bindMouse();
    this.canvasSpace.bindKeyboard();
  }

  ngOnDestroy(): void {
    this.objectsSubscription?.unsubscribe();
    this.currentElementSubscription?.unsubscribe();
    // this.objectDoneSubscription?.unsubscribe()
    this.contextMenuSubscription?.unsubscribe();
  }

  subscribeToContextMenu() {
    this.contextMenuSubscription = this.contextMenuService.data.subscribe(
      (contextMenuData) => {
        if (contextMenuData) {
          this.canvasSpace.bindMouse(false);
        } else {
          this.canvasSpace.bindMouse(false);
          this.canvasSpace.bindMouse(true);
        }
      }
    );
  }

  scrollWheel(event) {
    if (this.isPanZooming) return;

    const zoomSpeed = 1 - event.deltaY / 800;
    const factor = zoomSpeed;

    this.camera.zoom(factor, new Pt(event.offsetX, event.offsetY));
    this.renderGridService.refreshGrid(this.canvasForm);
  }

  canvasAction(type, px, py, evt) {
    if (this.currentObjectID == undefined) return;
    // translate coordinates to object coords
    var point = new Pt(px, py);

    if (type == 'keydown') {
      var mousePoint = new Pt(this.canvasSpace.pointer);
      switch (evt.key.toLowerCase()) {
        case 't':
          // switch to guideline tool
          this.toolService.selectToolsetOfType(Fabric8ToolType.GUIDE);
          break;
        case 'd':
          // switch to drill tool
          this.toolService.selectToolsetOfType(Fabric8ToolType.DRILL);
          break;
        case 'r':
          // switch to router tool
          this.toolService.selectToolsetOfType(Fabric8ToolType.MILLING);
          break;
        case 'g':
          this.hoverService.addEmptyGroupNextToCurrentElement();
          break;
        case 'c':
          this.toolService.selectToolsetOfType(Fabric8ToolType.CUT);
          // switch to cut tool
          break;
        case 'f':
          this.camera.fitCurrentObject(
            this.currentObject.get(),
            this.canvasSpace
          );
          break;
        case 'z':
          // on windows ctrl-z is undo, on mac cmd-z is undo
          if (evt.ctrlKey || evt.metaKey) {
            this.undoService.redo(this.currentObject.get());
            this.currentObject.export();
          } else {
            this.undoService.undo(this.currentObject.get());
            this.currentObject.export();
          }
          break;
        case '=':
        case '+':
          // zoom in
          this.camera.zoom(ZOOM_FACTOR, mousePoint);
          this.renderGridService.refreshGrid(this.canvasForm);
          break;
        case '-':
        case '_':
          // zoom out
          this.camera.zoom(1 / ZOOM_FACTOR, mousePoint);
          this.renderGridService.refreshGrid(this.canvasForm);
          break;
      }
    }

    // left mouse button = 0
    // middle mouse button = 1
    // right mouse button = 2
    // additionally, evt.buttons is a bitmask of the buttons that are currently pressed

    if (evt.button == 1) {
      // middle mouse button
      var mousePoint = new Pt(this.canvasSpace.pointer);
      evt.pointer = mousePoint;
      if (type == 'down') {
        this.isPanZooming = true;
        this.toolHandlerService.zoomPanTool.mouseDown(evt);
      } else if (type == 'move' || type == 'drag') {
        this.toolHandlerService.zoomPanTool.mouseDrag(evt);
      } else if (type == 'up' || type == 'drop') {
        this.isPanZooming = false;
        this.toolHandlerService.zoomPanTool.mouseUp(evt);
      }
      return;
    }

    if (this.isPanZooming) {
      evt.pointer = new Pt(this.canvasSpace.pointer);
      this.toolHandlerService.zoomPanTool.mouseDrag(evt);
      this.renderGridService.refreshGrid(this.canvasForm);
      return;
    }

    // translate coordinates to object coords
    // this.ngZone.run(() => {
    const mouseEvent = this.hoverService.setPoint(point, this.canvasForm);
    this.toolService.handleEvent(type, evt, mouseEvent);
    // });
  }

  subscribeToObjects(): void {
    this.objectsSubscription = this.objectsManager.objects$.subscribe(
      (objects: Fabric8Object[]) => {
        this.renderGridService.refreshGrid(this.canvasForm);
        this.canvasSpace.play();
      }
    );

    this.currrentObjectIndexSubscription =
      this.objectsManager.currentObjectId.subscribe((objectId: string) => {
        if (this.currentObjectID !== objectId) {
          this.setCameraBoundsToFitCurrentObject();
          this.currentObjectID = objectId;
        }
      });
  }

  currentObjectID: string = undefined;

  setCameraBoundsToFitCurrentObject() {
    const currentObject = this.currentObject.get();

    if (currentObject == undefined) {
      this.camera.objectSize = new Pt(100, 100);
      this.camera.resetMatrix();
      // this.camera.fitBounds(currentObject, this.canvasSpace);
    } else {
      this.camera.fitCurrentObject(currentObject, this.canvasSpace);
    }

    this.renderGridService.refreshGrid(this.canvasForm);
  }

  selectedElement: Fabric8Element;
  selectElement(element: Fabric8Element) {
    this.selectedElement = element;
  }
}
