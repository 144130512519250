import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { ProjectsGroup } from 'src/app/model/projects-group.model';
import { wDragDropListEvent } from 'src/app/modules/wTree/tree-node.model';

@Injectable({
  providedIn: 'root',
})
export class DropHelperService {
  constructor() {}

  processDrop(event: wDragDropListEvent): ProjectsGroup[] {
    var groupsToUpdate: ProjectsGroup[] = [];
    if (event.from.sourceList === event.to.targetList) {
      moveItemInArray(event.to.targetList, event.fromIndex, event.toIndex);

      groupsToUpdate = [event.to.target as ProjectsGroup];
    } else {
      transferArrayItem(
        event.from.sourceList,
        event.to.targetList,
        event.fromIndex,
        event.toIndex
      );

      groupsToUpdate = [
        event.to.target as ProjectsGroup,
        event.from.source as ProjectsGroup,
      ];
    }
    return groupsToUpdate;
  }
}
