<div *ngIf="guideline" class="element row" [ngClass]="{ selected: false }">
  <!-- (mouseenter)="hoverGuideline()" -->
  <div class="icon" inlineSVG="fabric8/GUIDE.svg"></div>
  <div class="name">{{ "FABRIC8.GUIDELINE_TOOL_NAME" | translate }}</div>
  <div class="guideline-direction">
    <span
      class="img"
      [inlineSVG]="'fabric8/depth.svg'"
      [style.transform]="transform()"
    ></span>
    <span class="value">{{ guideline.distance | number : "1.2-2" }}</span>
  </div>
  <!-- <div class="points">
    <div *ngFor="let p of guideline.points; let j = index" class="point">
      <div class="coord">
        x:
        <span class="digit">
          {{ p[0] | number : "1.2-2" }}
        </span>
      </div>
      <div class="coord">
        y:
        <span class="digit">
          {{ p[1] | number : "1.2-2" }}
        </span>
      </div>
    </div>
  </div> -->
  <div class="spacer"></div>
</div>
