import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { Project } from 'src/app/model/project.model';
import { environment } from 'src/environments/environment';
import { ProjectGroupsManagerService } from '../../project-browser/groups-manager.service';
import { UserService } from 'src/app/core/services/user.service';
import { ProjectData } from 'src/app/model/project-data.model';
import { SketchupService } from '../../../services/sketchup.service';
import {
  ProjectFileLinkingService,
  ProjectFilePath,
} from './project-file-linking.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService implements OnDestroy {
  public project = new BehaviorSubject<Project>(null);

  sketchUpFileSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private router: Router,
    private groupsManagerService: ProjectGroupsManagerService,
    private userService: UserService,
    private fileLinkService: ProjectFileLinkingService,
    private sketchUp: SketchupService,
    private ngZone: NgZone
  ) {
    this.subscribeToSketchUpFilePath();
  }

  subscribeToSketchUpFilePath() {
    if (this.sketchUp.isSketchup == false) return;

    //subscribe to file opening events
    this.sketchUpFileSubscription = this.sketchUp.currentSketchUpFilePath$
      .pipe(skip(1))
      .subscribe((path) => {
        // this.sketchUpFileSubscription = this.sketchUp.currentSketchUpFilePath$.subscribe(path => {
        this.ngZone.run(() => {
          this.sketchupFileDidOpen(path);
        });
      });
  }

  ngOnDestroy(): void {
    this.sketchUpFileSubscription?.unsubscribe();
  }

  getProject(): Project {
    return this.project.getValue();
  }

  // used for switching to another project
  public setProjectReference(
    project: Project,
    updateGroup: boolean = false
  ): void {
    if (project == undefined) {
      this.project.next(undefined);
      return;
    }

    // init project data if it's undefined (new project)
    if (project.data == undefined) {
      project.data = [];
    }

    if (updateGroup) {
      this.groupsManagerService.updateProjectReference(project);
    }

    this.project.next(project);

    this.sketchUp.setProjectReference(project);
  }

  public async createProject(name: string): Promise<Project> {
    const url = environment.apiUrl + '/project/create';

    var newProject = new Project(
      null,
      name,
      'description',
      [this.userService.makeUserOwner()],
      []
    );

    try {
      const response = await this.http.post<any>(url, newProject).toPromise();
      newProject.id = response.id;
      newProject.name = response.name;
      return newProject;
    } catch (err) {
      const message = "Can't create project";
      console.error(message, err);
      throw err;
    }
  }

  public getDefaultLengthUnit() {
    return 'MILLIMETERS';
  }

  public getDefaultWeightUnit() {
    return 'KILOGRAMS';
  }

  public async updateProjectMetadata(
    updatedProject: Project
  ): Promise<Project> {
    console.log('updateProjectMetadata');
    const url = environment.apiUrl + '/project/' + updatedProject.id;

    const request = {
      id: updatedProject.id,
      name: updatedProject.name,
      description: updatedProject.description,
      access: updatedProject.access,
    };

    try {
      await this.http.put<Project>(url, request).toPromise();
      return updatedProject;
    } catch (err) {
      const message = "Couldn't update project";
      // this.messages.showErrors(message);
      // alert(message);
      console.error(message, err);
      throw err;
    }
  }

  public async fetchProject(id: string): Promise<Project> {
    console.log('fetching project id:' + id);

    const url = environment.apiUrl + '/project/' + id;
    var project: any = await this.http.get(url).toPromise();

    if (project.data === undefined) {
      project.data = [];
    }

    // get file path
    var path: ProjectFilePath;
    try {
      console.log('fileLinkService.queryFilePath:' + id);
      path = await this.fileLinkService.queryFilePath({ projectID: id });
      console.info(path);
      console.info(path.sketchUpFilePath);
    } catch (e) {
      path = { sketchUpFilePath: undefined, layoutFilePath: undefined };
    }
    project.sketchUpFilePath = path.sketchUpFilePath;
    project.layoutFilePath = path.layoutFilePath;

    console.log('fetched project id:' + id);
    console.log(project);

    return project;
  }

  /// remove a plugin from a project by ProjectData id
  public async removeProjectDataWithRootNodeID(
    projectDataRootNodeID: string
  ): Promise<any> {
    let currentProject = this.getProject();
    let updatedProject: Project = cloneDeep(this.getProject());
    let index = updatedProject.data.findIndex(
      (p) => p.rootNodeID == projectDataRootNodeID
    );
    updatedProject.data.splice(index, 1);
    return await this.updateTemplates(currentProject, updatedProject);
  }

  /// add templates or remove a plugin from a project
  public async updateTemplates(
    currentProject: Project,
    updatedProject: Project
  ): Promise<any> {
    // all ProjectData items with the isTemplate flag set are new
    const addPluginData = updatedProject.data.filter((pd) =>
      Boolean(pd.templateType)
    );

    // find existing ProjectData items that are missing from the new project
    if (currentProject == undefined) {
      console.error('updateTemplates: project is not set');
      console.error('updateTemplate FAILED');
      return;
    }
    let currentProjectDataRootNodeIDs = currentProject.data.map(
      (pd) => pd.rootNodeID
    );
    let updatedProjectDataRootNodeIDs = updatedProject.data.map(
      (pd) => pd.rootNodeID
    );

    let removePluginDataRootNodeIDs = currentProjectDataRootNodeIDs.filter(
      (cid) => {
        // return true if the id is missing from the updated list
        let found = updatedProjectDataRootNodeIDs.includes(cid);
        return !found;
      }
    );

    let pluginOrder = updatedProject.data.map((pd) => pd.pluginID);

    let data = {
      projectID: currentProject.id,
      removePluginDataRootNodeIDs: removePluginDataRootNodeIDs,
      addTemplateData: addPluginData,
      order: pluginOrder,
    };

    try {
      var response = await this.http
        .put<any>(environment.apiUrl + '/project/plugins', data)
        .toPromise();
      // update the project
      this.setProjectReference(updatedProject);
      return updatedProject;
    } catch (err) {
      const message = "Couldn't update templates";
      // this.messages.showErrors(message);
      // alert(message);
      console.error(message, err);
      throw err;
    }
  }

  public async reorderProjectData(project: Project): Promise<any> {
    let pluginOrder = project.data.map((pd) => pd.pluginID);
    let data = {
      projectID: project.id,
      order: pluginOrder,
    };
    // console.warn(data)

    return this.http
      .put<any>(environment.apiUrl + '/project/plugins', data)
      .toPromise();
  }

  async saveProjectDataAsTemplate(
    projectData: ProjectData,
    templateName: string
  ): Promise<ProjectData> {
    const url = environment.apiUrl + '/templates/create';
    const request = {
      sourceProjectData: projectData,
      newTemplateName: templateName,
    };
    const newTemplateData = await this.http.post<any>(url, request).toPromise();
    newTemplateData.templateType = 'user';
    return newTemplateData;
  }

  async duplicateProject(project: Project): Promise<Project> {
    const url = environment.apiUrl + '/project/duplicate/';
    const request = { projectID: project.id };
    const newProject = await this.http.post<any>(url, request).toPromise();
    const newProjectID = newProject.id;
    const duplicatedProject = await this.fetchProject(newProjectID);
    this.groupsManagerService.updateProjectReference(duplicatedProject);
    return duplicatedProject;
  }

  async deleteProject(id: string): Promise<boolean> {
    const url = environment.apiUrl + '/project/' + id;
    try {
      await this.http.delete(url).toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }

  // navigation
  public openSettings(projectID: string) {
    this.nav([projectID, 'settings']);
  }

  public openSharing(projectID: string) {
    this.nav([projectID, 'settings']);
  }

  public edit(projectID: string) {
    this.nav([projectID, 'edit']);
  }

  public closeProject() {
    this.nav(null);
  }

  openPlugins(projectID: string) {
    this.nav([projectID, 'plugins']);
  }

  private nav(path: string[] | null) {
    const n = [
      {
        outlets: {
          project: path,
        },
      },
    ];

    this.router.navigate(n);
  }

  private sketchupFileDidOpen(path: string | undefined) {
    console.info('sketchupFileDidOpen: ' + path);
    if (Boolean(path) == false) {
      return;
    }

    let currentProject = this.getProject();

    if (currentProject && currentProject.sketchUpFilePath == path) {
      // the project is already opened in Smartworks web - no need to do anything
      return;
    }

    this.tryOpeningProjectLinkedWithPath(path);
  }

  private async tryOpeningProjectLinkedWithPath(path: string) {
    // check if the current file path is linked with a project and open that project
    var pathInfo: ProjectFilePath;
    try {
      pathInfo = await this.fileLinkService.queryFilePath({
        sketchUpFilePath: path,
      });
      // console.error('tryOpeningProjectLinkedWithPath');
      // console.error(pathInfo);
      if (Boolean(pathInfo.projectID)) {
        setTimeout((t) => {
          this.edit(pathInfo.projectID);
        }, 0);
      }
    } catch (e) {
      //not found/linked or some other error occured
      console.error(e);
    }
  }
}
