import {
  style,
  sequence,
  animate,
  trigger,
  transition,
  query,
} from '@angular/animations';

const enterAnimation = [
  style({ height: '0', outline: '2px solid black' }),
  sequence([
    animate(
      '.10s ease',
      style({ height: '*', opacity: '1', outline: '2px solid black' })
    ),
    animate(
      '.50s ease',
      style({ height: '*', opacity: 1, outline: '2px solid transparent' })
    ),
  ]),
];

const leaveAnimation = [
  style({ height: '*', opacity: '1' }),
  sequence([
    animate('.25s ease', style({ height: '*', opacity: '.2' })),
    animate('.50s ease', style({ height: '0', opacity: 0 })),
  ]),
];

export const animations = [
  trigger('parent', [transition(':enter', [])]),
  trigger('inout', [
    transition(':leave', [query('.project.row', leaveAnimation)]),
    transition(':enter', [query('.project.row', enterAnimation)]),
  ]),
  trigger('inoutgroup', [
    transition(':leave', [query('.group-row.row', leaveAnimation)]),
    transition(':enter', [query('.group-row.row', enterAnimation)]),
  ]),
];
