import { DataAttribute } from 'src/app/model/data-attribute.model';
import { ONE_INCH } from '../../constants';
import {
  Fabric8DistributedHolesParameters,
  Fabric8EquallySpacedHolesParameters,
  Fabric8SmartObjectData,
  Fabric8SmartObjectType,
} from '../../models/smartgroup.model';
import { Pipe, PipeTransform } from '@angular/core';
import { UnitConversionService } from 'src/app/modules/project/services/unit-conversion.service';

@Pipe({
  name: 'getDataAttributes',
  pure: true,
})
export class GetDataAttributesPipe implements PipeTransform {
  constructor(private unitConversionService: UnitConversionService) {}

  transform(data: Fabric8SmartObjectData): DataAttribute[] {
    const attributes: DataAttribute[] = [];

    if (data.type == Fabric8SmartObjectType.distributedHoles) {
      const params = data.parameters as Fabric8DistributedHolesParameters;
      const countAttr: DataAttribute = {
        name: 'Count',
        value: params.count.toString(),
        type: 'int',
        tag: 'count',
        value_raw: params.count.toString(),
        units: 'INTEGER',
        access: 'TEXTBOX',
        list: undefined,
        options: undefined,
        errors: undefined,
      };
      attributes.push(countAttr);
    }

    if (data.type == Fabric8SmartObjectType.equallySpacedHoles) {
      const params = data.parameters as Fabric8EquallySpacedHolesParameters;
      const distanceAttr: DataAttribute = {
        name: 'Distance',
        value: '',
        type: 'length',
        tag: 'distance',
        value_raw: (params.distance / ONE_INCH).toString(),
        units: 'MILLIMETERS',
        access: 'TEXTBOX',
        list: undefined,
        options: undefined,
        errors: undefined,
      };

      distanceAttr.value =
        this.unitConversionService.valueFromRawValueAndUnit(distanceAttr);

      attributes.push(distanceAttr);
    }

    console.log('attributes', attributes);
    return attributes;
  }
}
