<h1 mat-dialog-title>{{ "CABN8.MATERIAL_PRESETS_TOOLTIP" | translate }}</h1>
<div mat-dialog-content>
  <p>
    {{ "CABN8.RESET_MATERIALS_TEXT" | translate }}
  </p>
</div>
<div mat-dialog-actions class="column">
  <div class="btn-primary" [mat-dialog-close]="'reset-sketchup'">
    <div class="icon" [inlineSVG]="'icon/sketchup.svg'"></div>
    {{ "CABN8.RESET_SKETCHUP_MATERIALS" | translate }}
  </div>
  <div class="spacer"></div>
  <div class="btn-primary" [mat-dialog-close]="'reset-smartworks'">
    {{ "CABN8.RESET_SMARTWORKS_MATERIALS" | translate }}
  </div>
  <div class="spacer"></div>
  <div class="btn-primary" [mat-dialog-close]="'reset'">
    {{ "CABN8.RESET_ALL_MATERIALS" | translate }}
  </div>
  <div class="spacer"></div>
  <div class="btn-primary" (click)="onNoClick()">
    {{ "GLOBAL.CANCEL" | translate }}
  </div>
</div>
