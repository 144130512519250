<ng-container *ngTemplateOutlet="parent ? theNode : childNodes"></ng-container>

<ng-template #theNode>
  <div
    swDrag
    (wDragStarted)="dragStarted($event)"
    (wDragReleased)="dragReleased($event)"
    (wDrop)="dropped($event)"
    [wDragNode]="node"
    wDragObjectType="NODE"
    [wDragParent]="parent"
    [wDragParentList]="parent.children"
    [wDragDisabled]="node.readOnly"
  >
    <ng-container [ngTemplateOutlet]="nodeRow"></ng-container>

    <!-- the attributes go here -->
    <!-- smartgroup settings go here -->
    <ng-template [ngIf]="expanded && isGuidelineSegment()">
      <sw-fabric8-guideline-segment-attributes
        [parent]="node"
        [indentLevel]="indentLevel + 1"
      ></sw-fabric8-guideline-segment-attributes>
    </ng-template>

    <div class="children" [hidden]="!(hasChildren() && expanded)">
      <ng-container [ngTemplateOutlet]="childNodes"></ng-container>
    </div>
    <!-- last child dropzone -->

    <div
      swDropZone
      *ngIf="hasChildren() && expanded"
      class="inter last-child-dropzone"
      [wDropTarget]="node"
      [wDropTargetList]="node.children"
      [wDropTargetListIndex]="node.children.length"
      [wDropAcceptType]="'NODE'"
      [wIndentLevel]="indentLevel + 1"
      [wDropDisabled]="isDragging()"
    ></div>
  </div>
</ng-template>

<ng-template #nodeRow>
  <div
    class="node row"
    swDragHandle
    [ngClass]="{
      dragging: isDragging(),
      opened: isOpened(),
      hide: isHidden,
      selected: false,
      hovered: isHovered
    }"
    (mouseenter)="hoverNode(node)"
    (mouseleave)="hoverNode(undefined)"
  >
    <div [swIndentSpacer]="indentLevel" (click)="toggleAttributes()"></div>

    <!-- expand/collapse arrow -->
    <div
      id="arrow"
      *ngIf="hasChildren()"
      class="hover-btn transparent"
      [inlineSVG]="
        expanded ? 'icon/arrow-expanded.svg' : 'icon/arrow-collapsed.svg'
      "
      [ngClass]="{
        collapsed: !expanded,
        expanded: expanded
      }"
      (click)="expanded = !expanded"
    ></div>

    <div class="name">
      <ng-template [ngIf]="isGroup()">
        <!-- <sw-fabric8-smartgroup
          [smartGroup]="node.smartGroup"
          [parent]="node"
        ></sw-fabric8-smartgroup> -->
        <div class="icon" [inlineSVG]="'/fabric8/group.svg'"></div>
        <div
          #n
          class="edit-group"
          [attr.contenteditable]="true"
          [attr.spellcheck]="false"
          [attr.data-placeholder]="'Group' | translate"
          (blur)="endEditingName(n.textContent)"
          (keydown.enter)="n.blur()"
          (keydown.esc)="n.blur()"
          [innerHTML]="node.name"
        ></div>
      </ng-template>

      <!-- element -->
      <ng-template [ngIf]="isElement()">
        <sw-fabric8-element [parent]="node"></sw-fabric8-element>
      </ng-template>

      <!-- guideline -->
      <ng-template [ngIf]="isGuideline()">
        <sw-fabric8-guideline-row [parent]="node"></sw-fabric8-guideline-row>
      </ng-template>

      <!-- guideline segment -->
      <ng-template [ngIf]="isGuidelineSegment()">
        <sw-fabric8-guideline-segment-row
          [parent]="node"
        ></sw-fabric8-guideline-segment-row>
      </ng-template>

      <!-- <sw-user-presence [dataNodeID]="node.id"></sw-user-presence> -->

      <div
        *ngIf="isGroup()"
        swDropZone
        [wDropDisabled]="isDragging()"
        [wDropTarget]="node"
        [wDropTargetList]="node.children"
        [wDropTargetListIndex]="0"
        [wDropAcceptType]="'NODE'"
      ></div>
    </div>

    <div class="spacer" (click)="toggleAttributes()"></div>

    <ng-container [ngTemplateOutlet]="nodeMenu"></ng-container>
  </div>
</ng-template>

<ng-template #nodeMenu>
  <sw-dots-menu [itemID]="node.id" class="show-onhover">
    <div
      class="hover-btn red destructive"
      [inlineSVG]="'icon/delete.svg'"
      matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
      matTooltipPosition="above"
      (click)="deleteNode.emit()"
    ></div>
  </sw-dots-menu>
</ng-template>

<ng-template #childNodes>
  <ng-template [ngIf]="state === 'LoadingChildren'">
    <!-- <mat-spinner [diameter]="20"></mat-spinner> -->
    <ng-container [ngTemplateOutlet]="childNodesPlaceholders"></ng-container>
  </ng-template>
  <ng-template [ngIf]="state === 'FailedLoadingChildren'">
    <div class="flex center">
      <mat-icon>cloud_off</mat-icon>&nbsp;
      {{ "PROJECT_EDIT.FAILED_LOADING_CHILDREN" | translate }}
    </div>
  </ng-template>
  <ng-template [ngIf]="state === 'ChildrenLoaded'">
    <div @parent>
      <ng-container [ngTemplateOutlet]="childNodesLoaded"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #childNodesPlaceholders>
  <div *ngFor="let childID of node.children" class="node row node-placeholder">
    <div [swIndentSpacer]="indentLevel + 1"></div>
    <mat-progress-bar
      mode="indeterminate"
      style="width: 4rem"
    ></mat-progress-bar>
  </div>
</ng-template>

<ng-template #childNodesLoaded>
  <div
    *ngFor="let child of node.children; let i = index; trackBy: nodeByID"
    [@inout]
  >
    <div
      swDropZone
      class="inter"
      [wIndentLevel]="indentLevel + 1"
      [wDropTarget]="node"
      [wDropTargetList]="node.children"
      [wDropTargetListIndex]="i"
      [wDropAcceptType]="'NODE'"
      [wDropDisabled]="isDragging()"
    ></div>
    <sw-fabric8-node
      [indentLevel]="indentLevel + 1"
      [parentIsDragged]="isDragging()"
      [parent]="node"
      (deleteNode)="deleteChildAtIndex(i)"
      [(node)]="node.children[i]"
    ></sw-fabric8-node>
  </div>
</ng-template>
