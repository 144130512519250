import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Project } from 'src/app/model/project.model';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';

import { units } from './units';
import { cloneDeep } from 'lodash';
import { delay } from 'rxjs/operators';
import { SketchupService } from 'src/app/services/sketchup.service';

@Component({
  selector: 'sw-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  project: Project;
  errorMessage: string;

  units = cloneDeep(units);

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private projectService: ProjectService,
    private uiService: UIService,
    public sketchupService: SketchupService
  ) {}

  routeSubscriber: Subscription;

  ngOnInit(): void {
    // observer for `id` param in route
    this.reloadProject();
  }

  reloadProject() {
    this.routeSubscriber?.unsubscribe();
    this.routeSubscriber = this.route.paramMap
      .pipe(delay(0))
      .subscribe((params) => this.updateRoute(params.get('id')));
  }

  async updateRoute(id) {
    this.uiService.disableUI(false);
    this.errorMessage = undefined;
    try {
      const currentProject = await this.projectService.fetchProject(id);
      this.project = cloneDeep(currentProject);
      // this.projectService.setProjectReference(this.project, false)
    } catch (e) {
      this.errorMessage = "Can't load project";
      console.error(e);
      this.uiService.showErrors(e);
    }

    this.uiService.enableUI();
  }

  ngOnDestroy() {
    this.routeSubscriber?.unsubscribe();
  }

  async onDone() {
    this.uiService.disableUI(true);

    try {
      const updatedProject = await this.projectService.updateProjectMetadata(
        this.project
      );
      this.projectService.setProjectReference(updatedProject, true);
      this.projectService.edit(this.project.id);
    } catch (err) {
      throw err;
    }
    this.uiService.enableUI();
  }

  onCancel() {
    this.location.back();
    this.uiService.enableUI();
  }

  lastFileSync(project: Project) {
    return 'Synced x minutes ago';
  }

  setUnits(type: 'metric' | 'imperial') {}
}
