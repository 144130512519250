<div class="row element">
  <!-- [ngClass]="{ selected: false, hover: isElementHovered(element, i) }" 
  (mouseenter)="hoverElement(i)> -->
  <div class="icon" [inlineSVG]="toolService.getToolIcon(element.tool)"></div>
  <div class="name">{{ toolService.getToolName(element.tool) }}</div>
  <!-- <div class="depth">
    <span class="img" [inlineSVG]="'fabric8/depth.svg'"></span>
    <sw-editable
      matTooltip="Depth: {{ element.depth.toString() }} {{ defaultUnitSymbol }}"
      matTooltipPosition="above"
      (dblclick)="enableEditingDepth()"
      [value]="getDepthValue()"
      (endEditing)="updateDepth($event)"
    >
    </sw-editable>
  </div> -->
  <div class="points" *ngIf="!isEditingDepthValue">
    <!-- <div *ngFor="let p of element.points; let j = index" class="point">
      <div class="coord">
        x:
        <span class="digit">
          {{ p[0] | number : "1.2-2" }}
        </span>
      </div>
      <div class="coord">
        y:
        <span class="digit">
          {{ p[1] | number : "1.2-2" }}
        </span>
      </div>
    </div> -->
    <div
      *ngIf="element.isClosedPath"
      class="icon"
      [inlineSVG]="'fabric8/closed-path.svg'"
    ></div>
  </div>
</div>
