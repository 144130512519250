import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.production) {
      return next.handle(req);
    }

    if (req.url.includes('/assets')) {
      return next.handle(req);
    }

    // const cloned = req.clone({ url: req.url + "?XDEBUG_SESSION_START=PHPSTORM" })
    // console.info(cloned.method + ' ' + cloned.url)
    // return next.handle(cloned)

    return next.handle(req);
  }
}
