import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from 'src/app/core/services/ui.service';
import { DataAttribute } from 'src/app/model/data-attribute.model';

import { DataNode } from 'src/app/model/data-node.model';
import { AttributeEditorService } from 'src/app/modules/attributes/attribute-editor.service';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { AttributeEditorComponent } from './editor/attribute-editor.component';
import { cloneDeep } from 'lodash';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sw-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss'],
})
export class AttributesComponent implements OnInit {
  // node copy, used for editing
  editingAttributes: DataAttribute[] = undefined;
  isEditingAttributeActive: boolean = false;
  updating = false;

  // current node
  @Input() node: DataNode;
  @Output() nodeChange = new EventEmitter<DataNode>();

  lockedTags = [];

  @ViewChild(AttributeEditorComponent) attributeEditorComponent;

  constructor(
    private translate: TranslateService,
    public dataNodeService: DataNodeService,
    private attributeEditorService: AttributeEditorService,
    private uiService: UIService
  ) {}

  ngOnInit(): void {
    this.lockedTags = this.attributeEditorService.lockedAttributeTags;
  }

  // onClose(node) {
  //   this.nodeChange.next(this.node)
  // this.close.emit()
  // }

  editing(): boolean {
    return this.editingAttributes !== undefined;
  }

  onEdit() {
    // clone the attributes for editing
    if (this.node.attributes == undefined) {
      this.editingAttributes = [];
    } else {
      this.editingAttributes = cloneDeep(this.node.attributes);
    }
    this.uiService.disableUI(false);
  }

  addAttribute() {
    if (this.editing()) {
      this.attributeEditorComponent.addAttribute();
    } else {
      this.onEdit();
      setTimeout(() => {
        this.addAttribute();
      });
    }
  }

  cancelEditing() {
    this.editingAttributes = undefined;
    this.uiService.enableUI();
  }

  async doneEditing() {
    //skip validating attributes that weren't changed
    const changedAttributes = this.attributeEditorService.getChangedAttributes(
      this.node.attributes,
      this.editingAttributes
    );

    if (
      this.attributeEditorService.validateAttributes(changedAttributes) == false
    ) {
      return;
    }
    this.setTags(this.editingAttributes);
    this.node.attributes = this.editingAttributes;
    await this.saveNode();
    this.editingAttributes = undefined;
    this.nodeChange.next(this.node);
    this.uiService.enableUI();
  }

  setTags(attributes: DataAttribute[]) {
    attributes.forEach((a, index) => {
      attributes[index].tag =
        this.attributeEditorService.getTagOrMakeTagFromName(a);
    });
  }

  updateNode(node: DataNode) {
    this.node = node;
  }

  async saveNode() {
    try {
      // Dim the view to indicate that the item is being saved
      // Wait for the incoming ULI
      this.updating = true;
      this.dataNodeService.nodesUpdatedEvent
        .pipe(
          filter((updatedNodes) =>
            updatedNodes.map((n) => n.id).includes(this.node.id)
          ),
          take(1)
        )
        .subscribe((updatedItem) => {
          this.updating = false;
        });

      await this.dataNodeService.updateNodes([this.node]);
    } catch (e) {
      console.error(e);
      alert("Can't save node");
    }
  }

  async updateNodeTitle(component: string) {
    this.uiService.disableUI(true);
    await this.dataNodeService.updateNodes([this.node]);
    this.uiService.enableUI();
  }
}
