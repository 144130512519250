import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { Fabric8Guideline } from '../../models/guideline.model';
import { Fabric8Node } from '../../models/node.model';
import { CurrentObjectService } from '../../services/current-object.service';
import { ToolService } from '../../services/tool.service';
import { HoverService } from '../../services/hover.service';

@Component({
  selector: 'sw-fabric8-guideline-row',
  templateUrl: './fabric8-guideline-row.component.html',
  styleUrls: ['../css/elements.scss', '../fabric8-elements.component.scss'],
})
export class Fabric8GuidelineRowComponent implements OnInit {
  constructor(
    public toolService: ToolService,
    private hoverService: HoverService,
    private currentObject: CurrentObjectService
  ) {}

  ngOnInit(): void {
    this.guideline = this.parent?.data as Fabric8Guideline;
  }

  @Input() parent: Fabric8Node;
  guideline: Fabric8Guideline;

  transform() {
    const angle = -this.guideline.perpendicularAngle - Math.PI / 2;
    return `rotate(${angle}rad) scaleX(-1)`;
  }
  // deleteGuideline() {
  //   this.currentObject.deleteNodeById(this.parent.id);

  //   _.delay(() => {
  //     this.currentObject.update(true);
  //   }, 500);
  // }

  // isGuidelineHovered() {
  //   return false;
  // }
}
