export class DataAttribute {
  name: string;
  value: string;
  list: string;
  tag: string;
  units: string;

  value_raw: string;
  access: undefined | 'LIST' | 'TEXTBOX' | 'VIEW';

  // SmartWorks only attributes
  type?: string; // "text" | "dropdown" | "file"
  public options: DataAttributeOptions[] = [];
  errors: string[] = [];

  constructor(name: string, value: string, list: string) {
    this.name = name;
    this.value = value;
    this.list = list;
  }
}

export class DataAttributeOptions {
  constructor(public name: string, public value: string) {}
}

export let types = [
  'text', // units: STRING
  'dropdown', // units: STRING,
  'length', // length or position (vector)
  // units: DEFAULT | INCHES | FEET | YARDS | MILLIMETERS | CENTIMETERS | METERS
  'degrees', // units: DEGREES
  'weight', // units: POUNDS | KILOGRAMS
  'int', // units: INTEGER
  'float', // units: FLOAT
  'percent', // units: PERCENT
  'bool', // units: BOOLEAN
  'currency', // units: DOLLARS | EUROS | YEN

  'material',
];

export let lengthUnits = [
  'DEFAULT',
  'INCHES',
  'FEET',
  'YARDS',
  'MILLIMETERS',
  'CENTIMETERS',
  'METERS',
];
