import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { MMProxyDensity } from '../models/MMLOD.model';
import { ModelManagerHelperService } from './model-manager-helper.service';

@Component({
  selector: 'sw-model-manager-helper',
  templateUrl: './model-manager-helper.component.html',
  styleUrls: ['./model-manager-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelManagerWizardComponent implements AfterViewInit, OnDestroy {
  sliderVal: number = 30;
  loading: boolean = true;
  proxyOptions = Object.values(MMProxyDensity);

  generatingPaused: boolean = true;
  generatingInProgress: boolean = false;
  generatingDone: boolean = false;
  processingSubscription: Subscription;

  viewMode: string;
  viewModeSubscription: Subscription;

  @ViewChild('filterByInput') filterByInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    public helper: ModelManagerHelperService,
    public uiService: UIService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.processingSubscription?.unsubscribe();
    this.viewModeSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.initProcessingSubscription();
    this.subscribeToViewMode();
    this.helper.setMode('definitions');
    setTimeout(() => {
      this.getCandidates();
    }, 10);
  }

  subscribeToViewMode() {
    this.viewModeSubscription = this.helper.mode$.subscribe(
      (m) => (this.viewMode = m)
    );
  }

  initProcessingSubscription() {
    this.processingSubscription = this.helper.processedItem.subscribe(
      (proc) => {
        if (proc === 'generating') {
          this.generatingInProgress = true;
        } else {
          this.generatingInProgress = false;
        }

        if (proc === 'wait' && this.generatingPaused == false) {
          setTimeout(() => {
            this.helper.processNext();
          }, 40);
        }

        if (proc === 'done') {
          this.generatingDone = true;
        }

        this.changeDetector.detectChanges();
      }
    );
  }

  updateProxySelection(selection: string) {
    this.helper.createOptions.createProxy = selection as MMProxyDensity;
  }

  close() {
    this.router.navigate(['/model-manager']);
  }

  async getCandidates() {
    this.loading = true;
    this.uiService.disableUI(false);
    try {
      await this.helper.getCandidates();
    } catch (e) {
      console.error(e);
      alert("Can't load Model Manager Report");
    }

    this.loading = false;
    this.uiService.enableUI();

    this.changeDetector.detectChanges();
  }

  expVal(value: number) {
    return (
      (Math.pow(value, 2.3) / Math.pow(100, 2.3)) * this.helper.maxFaceCount
    );
  }

  formatLabel(value: number) {
    value = this.expVal(value);
    if (value >= 1000 && value < 2500) {
      return Math.round(value / 100) / 10 + 'k';
    } else if (value > 2500) {
      return Math.round(value / 1000) + 'k';
    }

    return Math.round(value);
  }

  setFacesTreshold($event) {
    const exp = this.expVal($event.value);
    this.helper.setFacesTreshold(exp);
  }

  guid(index, item) {
    return item.indentLevel + '.' + item.guid;
  }

  getModeIconName(): string {
    switch (this.viewMode) {
      case 'tree':
        return 'account_tree';
      case 'definitions':
        return 'list_all';
    }
  }

  countModeIcon(): string {
    switch (this.helper.countMode) {
      case 'instance':
        return 'filter_center_focus';
      case 'totalInstances':
        return 'workspaces'; //scatter_plot
    }
  }

  startGeneratingProxies() {
    this.generatingDone = false;
    this.generatingPaused = false;
    this.helper.addSelectedToQueue();
    this.helper.processNext();
  }

  pause() {
    this.generatingPaused = true;
    this.generatingDone = false;
  }

  async selectAllVisible(select: boolean) {
    const allCandidates = this.helper.candidates.getValue();
    for (let index = 0; index < allCandidates.length; index++) {
      const element = allCandidates[index];
      try {
        await this.helper.updateSelection(element, select, false);
      } catch (e) {
        console.error('----');
        console.warn(e);
      }
    }

    await this.helper.updateSketchupSelection();
  }

  toggleExpansionAll() {
    const exp = !this.helper.allCandidatesTree[0].expanded;

    this.helper.allCandidatesTree.forEach((c) => (c.expanded = exp));

    this.helper.filter();
  }

  expandedIconAll(): string {
    const exp = this.helper.allCandidatesTree[0].expanded;
    let name = exp ? 'expanded' : 'collapsed';
    return 'arrow-' + name + '.svg';
  }

  sortIcon(): string {
    const order = this.helper.sortOrder;
    return order == 1 ? 'arrow_upward' : 'arrow_downward';
  }

  search(event: any) {
    this.helper.filterBy.next(this.filterByInput.nativeElement.value);
    this.helper.filter();
  }

  clearSearch() {
    this.filterByInput.nativeElement.value = '';
    this.helper.filterBy.next('');
    this.helper.filter();
  }
}
