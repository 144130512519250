<ng-template [ngIf]="rootNode || plugin.library" [ngIfElse]="loading">
  <ng-template [ngIf]="projectData.pluginID === 'cabn8'">
    <sw-plugin-cabn8 [rootNode]="rootNode" [projectData]="projectData">
    </sw-plugin-cabn8>
  </ng-template>

  <ng-template [ngIf]="projectData.pluginID === 'pap-basic'">
    <sw-plugin-pap-basic [rootNode]="rootNode" [projectData]="projectData">
    </sw-plugin-pap-basic>
  </ng-template>

  <ng-template [ngIf]="projectData.pluginID === 'sketchup-objects'">
    <sw-plugin-sketchup-objects
      [rootNode]="rootNode"
      [projectData]="projectData"
    >
    </sw-plugin-sketchup-objects>
  </ng-template>

  <ng-template [ngIf]="projectData.pluginID === 'gardn8'">
    <sw-plugin-gardn8 [rootNode]="rootNode" [projectData]="projectData">
    </sw-plugin-gardn8>
  </ng-template>

  <ng-template [ngIf]="projectData.pluginID === 'fabric8'">
    <sw-plugin-fabric8 [rootNode]="rootNode" [projectData]="projectData">
    </sw-plugin-fabric8>
  </ng-template>
</ng-template>

<ng-template #loading>
  <div class="center">
    <mat-spinner class="loading-spinner" [diameter]="20"></mat-spinner>
  </div>
</ng-template>
