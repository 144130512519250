import { Injectable, OnDestroy } from '@angular/core';
import { Project } from '../model/project.model';
import { BehaviorSubject } from 'rxjs';
import Bridge from 'sketchup-bridge';
import { SmartworksPluginComponent } from '../modules/plugins/swplugin/swplugin.component';
import { WindowRef } from '../shared/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class SketchupService {
  sketchup: any | undefined;
  public isSketchup: boolean;
  public notSketchup: boolean;

  bridge: any;
  currentSketchUpFilePath = new BehaviorSubject<string | undefined>(undefined);
  readonly currentSketchUpFilePath$ =
    this.currentSketchUpFilePath.asObservable();

  constructor(private windowRef: WindowRef) {
    this.init();
  }

  init() {
    this.sketchup = this.windowRef.nativeWindow['sketchup'];
    this.isSketchup = !(this.sketchup === undefined);
    this.notSketchup = this.sketchup === undefined;

    if (this.isSketchup) {
      this.bridge = Bridge;
      this.registerWindowFunctions();
    } else {
      // fake the bridge
      this.bridge = {
        get(fn: string) {
          return Promise.resolve(undefined);
        },
        call(fn: string) {
          return Promise.resolve(undefined);
        },
      };
    }
  }

  private registerWindowFunctions() {
    this.windowRef.nativeWindow.sketchupFileDidOpen = (path: string) => {
      this.currentSketchUpFilePath.next(path);
    };

    this.windowRef.nativeWindow.getCabN8SelectedNode = () => {
      // this placeholder is overwritten by the CabN8 custom interface
      // see: /app/plugins/cabn8/cabn8.component.ts
      return '';
    };
  }

  public registerFunctions(functions: any) {
    Object.assign(this.windowRef.nativeWindow, functions);
  }

  // called when the current project changes
  public setProjectReference(project: Project) {
    if (this.notSketchup) {
      return;
    }

    this.bridge.call('setProjectReference', project);

    // If the filepath property exists (project was linked with a file)
    // try opening the file in SU
    if (Boolean(project.sketchUpFilePath)) {
      this.bridge.call('openFileRequest', project.sketchUpFilePath);
    }
  }

  pluginComponents: SmartworksPluginComponent[] = [];
  registerActionHandler(component: SmartworksPluginComponent) {
    this.pluginComponents.push(component);
  }

  unregisterActionHandler(component: SmartworksPluginComponent) {
    const index: number = this.pluginComponents.indexOf(component);
    if (index !== -1) {
      this.pluginComponents.splice(index, 1);
    }
  }

  async runAction(pluginID: string, action: string): Promise<any> {
    for (let plugin of this.pluginComponents) {
      if (plugin.projectData && plugin.projectData.pluginID == pluginID) {
        return await plugin.runAction(action);
      }
    }
    return false;
  }

  async localStorageSet(key, value) {
    return await this.bridge.call('localStorageSet', key, value);
  }

  async localStorageGet(key) {
    try {
      return await this.bridge.get('localStorageGet', key);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
