import { Injectable, Injector } from '@angular/core';

import { DrillTool } from './toolHandlers/DrillTool';
import { GuideLineTool } from './toolHandlers/GuidelineTool';
import { RouterTool } from './toolHandlers/RouterTool';
import { SelectTool } from './toolHandlers/SelectTool';

import { Fabric8Tool } from '../models/tool.model';
import { Fabric8ToolHandler } from './toolHandlers/models/Fabric8ToolEvents.model';
import { ZoomPanTool } from './toolHandlers/ZoomPanTool';
import { GuidelineSegmentTool } from './toolHandlers/GuidelineSegmentTool';
import { Fabric8ToolType } from '../toolset/Fabric8ToolType';

@Injectable({
  providedIn: 'root',
})
export class ToolHandlerService {
  drillTool: DrillTool;
  routerTool: RouterTool;
  selectTool: SelectTool;
  guideLineTool: GuideLineTool;
  zoomPanTool: ZoomPanTool;
  guidelineSegmentTool: GuidelineSegmentTool;

  private tools: Map<Fabric8ToolType, Fabric8ToolHandler> = new Map();

  constructor(injector: Injector) {
    setTimeout(() => {
      this.drillTool = injector.get(DrillTool);
      this.routerTool = injector.get(RouterTool);
      this.selectTool = injector.get(SelectTool);
      this.guideLineTool = injector.get(GuideLineTool);
      this.zoomPanTool = injector.get(ZoomPanTool);
      this.guidelineSegmentTool = injector.get(GuidelineSegmentTool);
    });
  }

  registerTool(type: Fabric8ToolType, handler: Fabric8ToolHandler) {
    this.tools.set(type, handler);
  }

  getToolHandler(tool: Fabric8Tool): Fabric8ToolHandler {
    console.log(this.tools);
    console.log('tool.type: ' + tool.type);
    return this.tools.get(tool.type);
  }
}
