import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class B64Service {

  constructor() { }

  /*
  * Function to convert from UTF8 to Base64 solving the Unicode Problem
  * Requires: window.btoa and window.encodeURIComponent functions
  * More info: http://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  * Samples:
  *      b64EncodeUnicode('✓ à la mode'); // "4pyTIMOgIGxhIG1vZGU="
  *      b64EncodeUnicode('\n'); // "Cg=="
  */
  public encode(str: string): string {
    if (window
        && "btoa" in window
        && "encodeURIComponent" in window) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
            return String.fromCharCode(("0x" + p1) as any);
        }));
    } else {
        console.warn("b64EncodeUnicode requirements: window.btoa and window.encodeURIComponent functions");
        return null;
    }

  }

  /*
  * Function to convert from Base64 to UTF8 solving the Unicode Problem
  * Requires window.atob and window.decodeURIComponent functions
  * More info: http://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  * Samples:
  *      b64DecodeUnicode('4pyTIMOgIGxhIG1vZGU='); // "✓ à la mode"
  *      b64DecodeUnicode('Cg=='); // "\n"
  */
  public decode(str: string): string {
    if (window
        && "atob" in window
        && "decodeURIComponent" in window) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
    } else {
        console.warn("b64DecodeUnicode requirements: window.atob and window.decodeURIComponent functions");
        return null;
    }
  }

  // // That is a specific funtion to upload the object to SharePoint (Office 365)
  // public static JSONObjectToSPFileBinary(jsonObject: any): SP.Base64EncodedByteArray {
  //   // Convert JSON object to File
  //   // 1. Convert JSON object to string
  //   const jsonString = JSON.stringify(jsonObject, null, 0);
  //   // 2. Convert string to Base64 Encoded Byte Array
  //   const base64EncodedArray = Utils.b64EncodeUnicode(jsonString);
  //   // 3. Crate SP.Base64EncodedByteArray with the content
  //   return new SP.Base64EncodedByteArray(base64EncodedArray);
  //   // Reverse: b64DecodeUnicode(content.toBase64String())
  //   // End Convert JSON object to File
  // }
}
