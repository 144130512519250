<ng-template #loadingOrError>
  <div class="wbox scrolly flex column">
    <sw-message
      *ngIf="errorMessage"
      [message]="errorMessage"
      icon="warning"
      actionText="{{ 'GLOBAL.RETRY' | translate }}"
      (action)="reloadProject()"
    >
    </sw-message>
    <div *ngIf="!errorMessage" class="center spacer">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="project" [ngIfElse]="loadingOrError">
  <div class="wbox scrolly flex column">
    <section>
      <sw-editable
        id="project-name"
        class="title"
        [(value)]="project.name"
        [alwaysEditing]="true"
      ></sw-editable>
      <mat-form-field id="description">
        <mat-label>{{ "PROJECT_EDIT.DESCRIPTION" | translate }}</mat-label>
        <textarea
          cdkTextareaAutosize
          matInput
          [(ngModel)]="project.description"
        >
        </textarea>
      </mat-form-field>
    </section>
    <hr />
    <sw-section-file-link [(project)]="project"> </sw-section-file-link>
    <section hidden="true" id="units">
      <hr />
      <div class="row">
        <mat-icon class="icon dark mr" [inline]="true">tune</mat-icon>
        <div class="small-title">{{ "SETTINGS_UNITS.TITLE" | translate }}</div>
        <div class="spacer"></div>
        <div class="btn-primary" (click)="setUnits('metric')">
          {{ "SETTINGS_UNITS.METRIC" | translate }}
        </div>
        <div class="btn-primary grey" (click)="setUnits('imperial')">
          {{ "SETTINGS_UNITS.IMPERIAL" | translate }}
        </div>
      </div>
      <div *ngFor="let u of units" class="row">
        <strong class="unit-name">{{ u.name }}</strong>
        <div class="spacer"></div>
        <mat-form-field class="unit-dropdown">
          <mat-select [(value)]="u.selected">
            <mat-option
              *ngFor="let option of u.options"
              value="{{ option.short }}"
              >{{ option.name }} ({{ option.short }})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </section>
    <hr />
    <section id="sharing">
      <sw-sharing-view [(access)]="project.access"> </sw-sharing-view>
    </section>

    <div class="spacer"></div>

    <div class="row">
      <div class="btn-primary grey" (click)="onCancel()">
        <div>{{ "GLOBAL.CANCEL" | translate }}</div>
      </div>
      <div class="spacer"></div>
      <div class="btn-primary save" (click)="onDone()">
        <div>{{ "GLOBAL.DONE" | translate }}</div>
      </div>
    </div>
  </div>
</ng-template>
