<h1 mat-dialog-title>{{ 'NEW_LIBRARY_ITEM.TITLE' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'NEW_LIBRARY_ITEM.MESSAGE' | translate }}</p>
  <mat-form-field>
    <mat-label>{{ 'NEW_LIBRARY_ITEM.NAME_LABEL' | translate }}</mat-label>
    <input matInput [(ngModel)]="data.itemName" cdkFocusInitial />
  </mat-form-field>
  <p>{{ 'NEW_LIBRARY_ITEM.CATEGORY_LABEL' | translate}}</p>

  <ng-template [ngIf]="loadingCategories" [ngIfElse]="categoriesTemplate">
    <mat-spinner [diameter]="20"></mat-spinner>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button class="btn-primary grey" (click)="onNoClick()">
    {{ 'GLOBAL.CANCEL' | translate | uppercase }}
  </button>
  <div class="spacer"></div>
  <button
    mat-button
    class="btn-primary"
    [mat-dialog-close]="data"
    [disabled]="data.itemName.length === 0 || data.itemCategory === null"
  >
    {{ 'GLOBAL.OK' | translate }}
  </button>
</div>

<ng-template #categoriesTemplate>
  <div class="categories">
    <div
      class="category"
      *ngFor="let cat of categories; let i = index; trackBy: trackByID "
      [ngClass]="{ 'active' : data.itemCategory === cat }"
      (click)="data.itemCategory = cat"
    >
      <div
        class="image"
        [inlineSVG]="'icon/plugins/' + data.plugin.id + '/library-category/' + cat.icon"
      ></div>
      <div class="name">
        <h4>{{ cat.name }}</h4>
        <small>{{ cat.description }}</small>
      </div>
    </div>
  </div>
</ng-template>
