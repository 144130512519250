<mat-icon class="check pointer" (click)="toggleTool()">
  {{ tool.enabled ? "check_box" : "check_box_outline_blank" }}
</mat-icon>
<span class="setting-icon width" [inlineSVG]="'fabric8/width.svg'"></span>
<sw-editable
  [matTooltip]="'FABRIC8.TOOL_WIDTH' | translate"
  matTooltipPosition="above"
  [value]="getToolWidth()"
  (endEditing)="updateToolWidth($event)"
>
</sw-editable>

<ng-template [ngIf]="tool.type !== CUT_GROUP">
  <span class="setting-icon depth" [inlineSVG]="'fabric8/depth.svg'"></span>
  <sw-editable
    [matTooltip]="'FABRIC8.TOOL_DEPTH' | translate"
    matTooltipPosition="above"
    [value]="getToolDepth()"
    (endEditing)="updateToolDepth($event)"
  >
  </sw-editable>
  <span
    (click)="enterNameEditingMode()"
    class="setting-icon tag"
    [inlineSVG]="'fabric8/tag.svg'"
  ></span>
  <sw-editable
    [matTooltip]="'FABRIC8.TOOL_NAME' | translate"
    matTooltipPosition="above"
    [editing]="editingName"
    [placeholder]="'FABRIC8.TOOL_NAME' | translate"
    [(value)]="tool.name"
    (endEditing)="updateToolName($event)"
    [autocompleteSource]="toolNames"
  >
  </sw-editable>
</ng-template>
