import { Injectable } from '@angular/core';
import { CanvasForm, Pt } from 'pts';
import { Fabric8Object } from '../../models/object.model';
import { CameraService } from '../../services/camera.service';
import { HoverService } from '../../services/hover.service';
import { colors } from '../colors';

@Injectable({
  providedIn: 'root',
})
export class RenderObjectService {
  constructor(
    private camera: CameraService,
    private hoverService: HoverService
  ) {}

  render(object: Fabric8Object, form: CanvasForm) {
    if (object == undefined || !object.lines) return;

    // draw unhovered lines
    object.lines
      .filter(
        (line, lineIndex) =>
          this.hoverService.mouseEvent.hoveredLinePointIndices.find(
            (i) => i.lineIndex == lineIndex
          ) == undefined
      )
      .map((line: Pt[]) => {
        // camera transform
        line = this.camera.transform(line);

        const lineColor = colors.edge;
        const lineThickness = 1;

        form.strokeOnly(lineColor, lineThickness).line(line);

        const vertexColor = colors.vertex;
        const vertexSize = 3;

        form.fillOnly(vertexColor).points(line, vertexSize, 'square');
      });

    // draw hovered lines
    object.lines
      .filter(
        (line, lineIndex) =>
          this.hoverService.mouseEvent.hoveredLinePointIndices.find(
            (i) => i.lineIndex == lineIndex
          ) !== undefined
      )
      .map((line: Pt[]) => {
        line = this.camera.transform(line);

        const lineColor = colors.edgeHover;
        const lineThickness = 3;

        form.strokeOnly(lineColor, lineThickness).line(line);

        const vertexColor = colors.vertexHover;
        const vertexSize = 4;

        form.fillOnly(vertexColor).points(line, vertexSize, 'square');
      });
  }
}
