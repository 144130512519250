import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { SketchupService } from 'src/app/services/sketchup.service';
import { ONE_INCH } from '../constants';
import { Fabric8Settings } from '../models/Fabric8Settings.model';
import packageJson from '../../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class Fabric8SettingsService {
  public version: string = packageJson.version;

  private settings: Fabric8Settings;
  settingsSubject: BehaviorSubject<Fabric8Settings> = new BehaviorSubject(
    undefined
  );
  names: Map<string, string> = new Map();

  private FABRIC8_SETTINGS_KEY: string = '';

  constructor(
    private sketchUp: SketchupService,
    private translate: TranslateService
  ) {
    this.FABRIC8_SETTINGS_KEY = 'FABRIC8_SETTINGS_' + this.version;
    // replace all dots with underscores
    this.FABRIC8_SETTINGS_KEY = this.FABRIC8_SETTINGS_KEY.replace(/\./g, '_');

    this.initNames();
    this.load();
  }

  initNames() {
    this.names.set('autoSave', 'FABRIC8.AUTO_SAVE');
    this.names.set('drillCenterPoints', 'FABRIC8.DRILL_CENTER_POINTS');
    this.names.set('grid', 'FABRIC8.GRID');
    this.names.set('gridSnap', 'FABRIC8.GRID_SNAP');
    this.names.set('gridSize', 'FABRIC8.GRID_SIZE');
  }

  getDefaultSettings(): Fabric8Settings {
    return {
      autoSave: true,
      drillCenterPoints: true,
      grid: [
        {
          name: 'Grid Size',
          tag: 'gridSize',
          type: 'length',
          value: '10 mm',
          value_raw: String(10 / ONE_INCH),
          units: 'MILLIMETERS',
          access: 'TEXTBOX',
          list: undefined,
          options: undefined,
          errors: undefined,
        },
        {
          name: 'Snap',
          tag: 'snap',
          type: 'dropdown',
          value: 'grid',
          value_raw: 'grid',
          units: 'STRING',
          access: 'LIST',
          list: undefined,
          options: [
            {
              name: 'Units',
              value: 'unit', // guide lines, object
            },
            {
              name: 'Grid',
              value: 'grid',
            },
            {
              name: 'Off',
              value: 'off',
            },
          ],
          errors: undefined,
        },
      ],
    };
  }

  async load() {
    // load from SU
    // if the settings are missing, set the default values
    var s = await this.sketchUp.localStorageGet(this.FABRIC8_SETTINGS_KEY);

    if (typeof s !== 'object') {
      s = undefined;
    }

    const defaultSettings = this.getDefaultSettings();
    this.settings = s || defaultSettings;

    // translate grid.option.name
    await this.translate.get('FABRIC8.SNAP_GRID').toPromise();

    const gridSnapSettings = this.settings.grid.find((g) => g.tag == 'snap');

    if (gridSnapSettings != undefined) {
      gridSnapSettings.options.forEach((o, index) => {
        gridSnapSettings.options[index].name = this.translate.instant(
          'FABRIC8.SNAP_' + o.value.toUpperCase()
        );
      });
    }

    // insert missing settings
    for (const [key, value] of Object.entries(defaultSettings)) {
      this.settings[key] = this.settings[key] || defaultSettings[key];
    }

    await this.save();
  }

  async toggle(key: string) {
    this.settings[key] = !this.settings[key];
    this.save();
  }

  async save() {
    await this.sketchUp.localStorageSet(
      this.FABRIC8_SETTINGS_KEY,
      this.settings
    );
    this.settingsSubject.next(this.settings);
  }

  name(key: string) {
    if (key == undefined) return 'NO KEY';
    return this.translate.instant(this.names.get(key));
  }

  saveGridSettings(gridSettings: DataAttribute[]) {
    this.settings.grid = gridSettings;
    this.save();
  }

  resetSettings() {
    this.settings = this.getDefaultSettings();
    this.save();
  }

  //getters

  getGridSize(): number {
    const d = 10; // 10 mm

    if (this.settings == undefined) {
      return d;
    }

    const gridSizeAttr = this.settings.grid.find((f) => f.tag == 'gridSize');
    if (gridSizeAttr == undefined) return d;

    return parseFloat(gridSizeAttr.value_raw) * ONE_INCH;
  }

  getGridSnap(): boolean {
    if (this.settings == undefined) return false;

    const snap = this.settings.grid.find((f) => f.tag == 'snap');
    return snap.value == 'grid';
  }

  getUnitSnap(): boolean {
    if (this.settings == undefined) return false;

    const snap = this.settings.grid.find((f) => f.tag == 'snap');
    return snap.value == 'unit';
  }
}
