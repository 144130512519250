import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { DataNode } from 'src/app/model/data-node.model';
import { ProjectData } from 'src/app/model/project-data.model';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { SketchupService } from 'src/app/services/sketchup.service';
import { ProjectService } from '../../project/services/project.service';

export interface SmartworksPlugin {
  projectData: ProjectData;
  rootNode?: DataNode;
  runAction(action: String): Promise<any>;
  nodesUpdated(nodes: DataNode[]);
}

@Component({
  selector: 'sw-app-swplugin',
  template: `<p>Subclass SmartworksPluginComponent</p>`,
  styleUrls: ['./swplugin.component.scss'],
})
export class SmartworksPluginComponent
  implements AfterViewInit, AfterContentInit, OnDestroy, SmartworksPlugin
{
  constructor(
    public sketchupService: SketchupService,
    public dataNodeService: DataNodeService,
    public projectService: ProjectService
  ) {}

  @ViewChild('customTopButtons') customButtons;
  ngAfterViewInit(): void {
    this.dataNodeService.topButtonsTemplate.next(
      this.customButtons || 'noCustomButtons'
    );
    // we emit noCustomButtons just to send something != undefined, to close the subscriber
  }

  projectData: ProjectData;
  rootNode?: DataNode;
  nodesUpdatedSubscription: Subscription;

  fileNameSubscriber: Subscription;
  isCurrentFile: boolean;

  runAction(action: string): Promise<any> {
    throw new Error('Plugin action method not implemented.');
  }

  nodesUpdated(nodes: DataNode[]) {
    throw new Error('Method not implemented.');
  }

  ngAfterContentInit(): void {
    this.register();
  }

  register() {
    this.sketchupService.registerActionHandler(this);
    this.nodesUpdatedSubscription =
      this.dataNodeService.nodesUpdatedEvent.subscribe((nodes) => {
        this.nodesUpdated(nodes);
      });

    this.fileNameSubscriber = combineLatest([
      this.sketchupService.currentSketchUpFilePath,
      this.projectService.project,
    ]).subscribe(([filePath, project]) => {
      // console.log(filePath, project)
      this.isCurrentFile = filePath == project.sketchUpFilePath;
    });
  }

  unregister() {
    this.fileNameSubscriber?.unsubscribe();
    this.sketchupService.unregisterActionHandler(this);
    this.nodesUpdatedSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unregister();
  }
}
