import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPresenceComponent } from '../../components/user-presence/user-presence.component';
import { CursorsComponent } from '../../services/liveupdates/cursors/cursors.component';
import { SharedModule } from "../../shared/shared.module"
import { UserPresenceService } from './user-presence.service';
import { RabbitMQModule } from '../rmq/rabbit-mq.module';

@NgModule({
  declarations: [
    CursorsComponent,
    UserPresenceComponent,
  ],
  imports: [
    SharedModule,
    RabbitMQModule
  ],
  exports: [
    UserPresenceComponent
  ],
  providers: [ 
    UserPresenceService,
  ]
})
export class UserPresenceModule { }
