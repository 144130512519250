import { ElementRef, HostListener, Input, NgZone } from '@angular/core'
import { AfterViewInit, Directive, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { TreeGroupService } from './tree-group.service'

@Directive({
  selector: '[swDropZone]'
})
export class DropzoneDirective  implements OnDestroy, OnInit, AfterViewInit {

  @Input() wDropTarget: any
  @Input() wDropTargetList: Array<any>
  @Input() wDropTargetListIndex = 0
  @Input() wDropAcceptType: string

  @Input() wDropDisabled = false
  @Input() wIndentLevel: number

  constructor(private treeGroupService: TreeGroupService,
              private element: ElementRef,
              private renderer: Renderer2,
              private _ngZone: NgZone,
              ) { }

  ngOnInit() {
    if (this.wDropTarget === undefined) {
      console.error("swDropZone needs a wDropTarget")
    }
    if (this.wDropTargetList === undefined) {
      console.error("swDropZone needs a wDropTargetList")
    }
    if (this.wDropAcceptType === undefined) {
      console.error("swDropZone needs wDropAcceptType")
    }

    this.treeGroupService.register(this)
    this.renderer.addClass(this.element.nativeElement, 'drop-zone')
    this.disablePointerEvents()
  }

  ngAfterViewInit() {
    this.setIndent()
    this.initIndicator()

    // this.renderer.setStyle(this.element.nativeElement, 'background-color', 'red')
  }

  private setIndent() {
    if (this.wIndentLevel !== undefined) {
      const leftMargin = this.wIndentLevel - 0.8
      this.renderer.setStyle(this.element.nativeElement, 'margin-left', leftMargin + 'rem')
    }
  }

  private initIndicator() {
    const indicator = this.renderer.createElement('div')
    this.renderer.addClass(indicator, 'indicator')
    this.renderer.appendChild(this.element.nativeElement, indicator)

    this._ngZone.runOutsideAngular(() => {
      this.element.nativeElement.addEventListener(
        'dragover',
        (e) => { 
          if (this.wDropDisabled) {
            return false
          } else {
            e.preventDefault()
            return true    
          }
        })
    })
  }

  acceptsObjectType(objType: string): boolean {
    // return this.wDropAcceptType.includes(objType)
    return (this.wDropAcceptType == objType)
  }

  enablePointerEvents(forObjectType: string) {
    if (this.wDropDisabled) { return }
    if (this.acceptsObjectType(forObjectType) == false) { return }

    this.renderer.setStyle(this.element.nativeElement, 'z-index', 1 + this.wIndentLevel || 0)
    this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'inherit')
  }

  disablePointerEvents() {
    this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'none')
    this.renderer.setStyle(this.element.nativeElement, 'z-index', -1)
    // this.renderer.setStyle(this.element.nativeElement, 'border', '1px solid red')
  }

  // @HostListener('dragover', ['$event'])
  // dragOver(event) {
  //   return (this.wDropDisabled == false) //return false if the drop is enabled
  // }


  @HostListener('dragenter', ['$event'])
  dragEnter(event) {
    /* Workaround: when the node is starting the drag,
      wDropDisabled is set to false, but angular sets this
      only after treeGroupService enables the dropzones.
    */
    if (this.wDropDisabled) {
      this.disablePointerEvents()
      return
    }
    this.renderer.addClass(this.element.nativeElement, 'w-drag-over')
    event.preventDefault()
  }

  @HostListener('dragleave', ['$event'])
  dragLeave(event) {
    this.renderer.removeClass(this.element.nativeElement, 'w-drag-over')
  }

  @HostListener('drop', ['$event'])
  drop(event) {
      this.renderer.removeClass(this.element.nativeElement, 'w-drag-over')
      this.treeGroupService.drop(this)
  }

  ngOnDestroy() {
    this.element = undefined
    this.treeGroupService.unregister(this)
  }

}
