import { NgModule } from '@angular/core';
import { AttributesComponent } from './attributes.component';
import { AttributeOptionsComponent } from './editor-options/attribute-options.component';
import { AttributeEditorComponent } from './editor/attribute-editor.component';
import { AttributeListComponent } from './list/attribute-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DataNodeComponent } from '../project/data-node/data-node.component';
import { WTreeModule } from '../wTree/w-tree.module';
import { UserPresenceModule } from '../user-presence-module/user-presence.module';
import { ColorBlockComponent } from '../materials/color-block/color-block.component';
import { MaterialThumbnailPipe } from '../materials/material-thumbnail.pipe';

const components = [
  AttributeListComponent,
  AttributeEditorComponent,
  AttributesComponent,
  AttributeOptionsComponent,
  DataNodeComponent,

  ColorBlockComponent,
  MaterialThumbnailPipe,
];

@NgModule({
  declarations: [...components],
  imports: [
    SharedModule,
    TranslateModule,
    DragDropModule,
    WTreeModule,
    UserPresenceModule,
  ],
  exports: [...components],
})
export class AttributesModule {}
