import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { SketchupService } from 'src/app/services/sketchup.service';
import { ModelManagerSketchUpService } from '../model-manager-sketch-up.service';
import {
  MMHubCandidate,
  MMHubCandidateBuildState,
} from '../models/MMHubCandidate.model';
import { MMLODType, MMProxyDensity } from '../models/MMLOD.model';
import { NewHubDialogData } from '../new-hub-dialog/new-hub-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ModelManagerHelperService {
  facesTreshold: number = 100;
  maxFaceCount = 0;

  candidates: BehaviorSubject<MMHubCandidate[]> = new BehaviorSubject([]);
  readonly candidates$ = this.candidates.asObservable();
  allCandidates: MMHubCandidate[] = [];

  allCandidatesTree: MMHubCandidate[];
  allCandidateDefinitions: MMHubCandidate[];

  selectedDefinitions: Map<string, boolean> = new Map();

  // flat - grouped by definitions, no hierarchy
  // tree - show all the object tree
  mode: BehaviorSubject<'definitions' | 'tree' | 'building'> =
    new BehaviorSubject('definitions');
  readonly mode$ = this.mode.asObservable();

  sortOrder: number = 1;
  countMode: 'instance' | 'totalInstances' = 'totalInstances';

  filterBy: BehaviorSubject<string> = new BehaviorSubject('');
  readonly filterBy$ = this.filterBy.asObservable();

  processedItem: BehaviorSubject<'idle' | 'wait' | 'done' | 'generating'> =
    new BehaviorSubject('idle');
  createOptions: NewHubDialogData = {
    hubName: '',
    definition: '',
    create2d: true,
    createProxy: MMProxyDensity.Medium,
  };

  constructor(
    private sketchUp: SketchupService,
    private mm: ModelManagerSketchUpService
  ) {}

  setFacesTreshold(i: number) {
    this.facesTreshold = i;
    this.filter();
  }

  async getCandidates() {
    this.allCandidatesTree = undefined;
    this.allCandidateDefinitions = undefined;

    try {
      this.allCandidates =
        (await this.sketchUp.bridge.get('GetHubCandidates')) || [];
      this.allCandidates.forEach((c) => this.setParent(c));
    } catch (e) {
      this.allCandidates = [];
    }

    this.filter();
  }

  setParent(candidate: MMHubCandidate) {
    candidate.children.forEach((c) => {
      c.parent = candidate;
      this.setParent(c);
    });
  }

  async getCandidatesFromSelection(): Promise<MMHubCandidate[]> {
    try {
      return (await this.sketchUp.bridge.get('GetHubCandidates', true)) || [];
    } catch (e) {
      return [];
    }
  }

  selectByHUBName(name) {
    this.sketchUp.bridge.call('selectByHUBName', name);
  }

  isSelected(definition: string) {
    return this.selectedDefinitions.has(definition);
  }

  async updateSelection(
    candidate: MMHubCandidate,
    select: boolean,
    updateSketchupSelection = true
  ) {
    if (select) {
      // if a hub parent is found, don't select it
      if (this.hasHubParent(candidate)) throw 'has-hub-parent';

      // if the selection contains a hub descendant, don't select it
      const c = this.findCandidateRecursively(candidate, this.allCandidates);
      if (this.hasHubChild(c)) throw 'has-hub-child';

      this.selectedDefinitions.set(candidate.definition, true);

      this.allCandidatesTree
        .filter((c) => c.definition == candidate.definition)
        .forEach((c) => {
          this.deselectParents(c);
        });

      this.deselectChildren(candidate.definition);
    } else {
      this.selectedDefinitions.delete(candidate.definition);
    }

    if (updateSketchupSelection) await this.updateSketchupSelection();
  }

  async updateSketchupSelection() {
    await this.mm.multiSelectByDefinition([...this.selectedDefinitions.keys()]);
  }

  hasHubParent(candidate: MMHubCandidate) {
    if (!candidate.parent) return false;
    if (candidate.parent.lod || candidate.parent.hub) return true;
    return this.hasHubParent(candidate.parent);
  }

  hasHubChild(candidate: MMHubCandidate) {
    if (candidate.lod || candidate.hub) return true;

    if (!candidate.children?.length) return false;

    for (let i = 0; i < candidate.children.length; i++) {
      if (this.hasHubChild(candidate.children[i])) {
        return true;
      }
    }
    return false;
  }

  findCandidateRecursively(candidate: MMHubCandidate, list: MMHubCandidate[]) {
    for (let i = 0; i < list.length; i++) {
      const c = list[i];
      if (c.guid == candidate.guid) {
        return c;
      }
    }

    list = list.filter((c) => c.children?.length);
    for (let i = 0; i < list.length; i++) {
      const children = list[i].children;
      if (children) {
        const f = this.findCandidateRecursively(candidate, children);
        if (f) return f;
      }
    }

    return false;
  }

  // Pass a non-flattened child (from allCandidates)
  deselectParents(candidate: MMHubCandidate) {
    if (!candidate) {
      return;
    }
    this.selectedDefinitions.delete(candidate.parent?.definition);
    this.deselectParents(candidate.parent);
  }

  deselectChildren(definition: string) {
    var currentLevel = 1000;
    this.allCandidatesTree.forEach((c) => {
      if (c.indentLevel == currentLevel) {
        currentLevel = 1000;
      }

      if (c.definition === definition) {
        currentLevel = c.indentLevel;
      }

      if (c.indentLevel > currentLevel) {
        this.selectedDefinitions.delete(c.definition);
      }
    });
  }

  toggleExpansion(candidate: MMHubCandidate) {
    candidate.expanded = !candidate.expanded;
    this.filter();
  }

  toggleMode() {
    switch (this.mode.getValue()) {
      case 'tree':
        this.setMode('definitions');
        break;
      case 'definitions':
        this.setMode('tree');
        break;
    }
  }

  setMode(mode: 'definitions' | 'tree' | 'building') {
    this.mode.next(mode);
    this.filter();
  }

  switchSorting() {
    this.sortOrder *= -1;
    this.allCandidateDefinitions = undefined;
    this.filter();
  }

  switchCountMode() {
    this.countMode =
      this.countMode == 'instance' ? 'totalInstances' : 'instance';
    this.allCandidateDefinitions = undefined;
    this.filter();
  }

  polyCount(a: MMHubCandidate) {
    if (this.countMode == 'totalInstances') {
      return a.polyCount * a.repetition;
    } else {
      return a.polyCount;
    }
  }

  processSources() {
    const mode = this.mode.getValue();

    if (!this.allCandidateDefinitions) {
      // if ("definitions" === mode && !this.allCandidateDefinitions) {
      var unique: Map<string, MMHubCandidate> = new Map();

      // flatten tree, extract unique component instances
      cloneDeep(this.allCandidates)
        .map((c) => this.flatMap(c, 0))
        .flat()
        .forEach((c) => {
          c.flat = true;
          unique.set(c.definition, c);
        });

      const sorted = [...unique.values()].sort(
        (a, b) => this.polyCount(b) - this.polyCount(a)
      );

      this.allCandidateDefinitions =
        this.sortOrder > 0 ? sorted : sorted.reverse();

      // get max poly count
      this.maxFaceCount = 0;
      this.allCandidateDefinitions.forEach((c) => {
        if (this.polyCount(c) > this.maxFaceCount) {
          this.maxFaceCount = this.polyCount(c);
        }
      });
    }

    if (!this.allCandidatesTree) {
      // if ("tree" === mode && !this.allCandidatesTree) {

      this.allCandidatesTree = cloneDeep(this.allCandidates)
        .map((c) => this.flatMap(c, 0))
        .flat();
    }
  }

  filter() {
    const mode = this.mode.getValue();
    this.processSources();

    var source: MMHubCandidate[];

    if ('building' === mode) {
      source = this.allCandidateDefinitions.filter((c) =>
        this.isSelected(c.definition)
      );
    }

    if ('definitions' === mode) {
      source = this.allCandidateDefinitions;
    }

    if ('tree' === mode) {
      var hiddenIndentLevel = 10000;
      source = this.allCandidatesTree.filter((c) => {
        if (c.indentLevel > hiddenIndentLevel) {
          return false;
        }
        if (c.indentLevel <= hiddenIndentLevel) {
          if (c.expanded) {
            hiddenIndentLevel = 10000;
            return true;
          } else {
            hiddenIndentLevel = c.indentLevel;
            return true;
          }
        }
      });
    }

    const filtered = source.filter((c) => {
      if (c.indentLevel == 0) {
        return c.polyCount * c.repetition >= this.facesTreshold;
      } else {
        return c.polyCount >= this.facesTreshold;
      }
    });

    const searchString = this.filterBy.getValue().trim().toLowerCase();
    if (searchString.length == 0) {
      this.candidates.next(filtered);
    } else {
      this.candidates.next(
        filtered.filter((c) => c.name.toLowerCase().includes(searchString))
      );
    }
  }

  flatMap(c: MMHubCandidate, level: number): MMHubCandidate[] {
    if (c.children) {
      var children = c.children;
      c.indentLevel = level;
      c.hasChildren = c.children.length > 0;
      c.children = [];
      children = children.map((c) => this.flatMap(c, level + 1)).flat();
      return [c, ...children];
    } else {
      return [c];
    }
  }

  hubCandidateName(item: MMHubCandidate): string {
    var name: any = item.name.split('<').filter((n) => n.length);
    if (name.length > 1) {
      name = name[0];
    } else {
      if (
        name[0].startsWith('Group') == false &&
        name[0].startsWith('Component') == false
      ) {
        name = name[0];
        // name = name[0].split("#").filter(n => n.length)[0]
      } else {
        name = name[0];
      }
    }
    return name.replaceAll('>', '') || '';
  }

  async generateProxy(item: MMHubCandidate) {
    var name = this.hubCandidateName(item);
    if (name == '') {
      item.buildingState = MMHubCandidateBuildState.error;
      return;
    }

    try {
      this.createOptions.hubName = name;
      await this.mm.newHubFromCandidate(item, this.createOptions, () => {
        this.processedItem.next('generating');
      });
    } catch (e) {
      console.error(e);
      item.buildingState = MMHubCandidateBuildState.error;
    }
  }

  async processNext() {
    var item = this.candidates
      .getValue()
      .find((c) => c.buildingState == MMHubCandidateBuildState.queued);

    if (item) {
      this.processedItem.next('generating');
      await this.generateProxy(item);
      this.processedItem.next('wait');
    } else {
      this.processedItem.next('done');
    }
    this.candidates.next(this.candidates.getValue());
  }

  addSelectedToQueue() {
    var queue = this.candidates
      .getValue()
      .filter((c) => this.isSelected(c.definition))
      .filter((c) => c.buildingState !== MMHubCandidateBuildState.done);

    queue.forEach((c) => (c.buildingState = MMHubCandidateBuildState.queued));

    this.candidates.next(queue);
  }
}
