<ng-template [ngIf]="!editing">
  <span (dblclick)="editing = true">{{ value }}</span>
</ng-template>

<ng-template [ngIf]="editing && autocompleteSource === undefined">
  <input
    type="text"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    focusable
    (ngModelChange)="valueChange.next(value)"
    (keydown.enter)="doneEditing($event)"
    (clickOutside)="doneEditing($event)"
    (blur)="doneEditing($event)"
  />
</ng-template>

<ng-template [ngIf]="editing && autocompleteSource">
  <div class="sw-autocomplete-input">
    <input
      type="text"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      focusable
      (clickOutside)="doneEditingAutocomplete($event)"
      (blur)="doneEditingAutocomplete($event)"
      (ngModelChange)="valueChange.next(value)"
      (keydown.enter)="doneEditing($event)"
    />
    <div class="sw-autocomplete-box">
      <div
        class="sw-autocomplete-option"
        *ngFor="let option of filteredOptions"
        (click)="selectOption(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</ng-template>
