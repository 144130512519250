import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { DetailPanelService } from 'src/app/services/detail-panel.service';
import { LibraryNavigationService } from './library-navigation.service';
import { LibraryViewService } from './library-view.service';
import {
  NewLibraryItemDialogComponent,
  NewLibraryItemDialogData,
} from './new-library-item/new-library-item-dialog';

@Component({
  selector: 'sw-app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnInit, OnDestroy {
  plugin: SWPlugin;

  constructor(
    public libraryNav: LibraryNavigationService,
    public libraryViewService: LibraryViewService,
    public detailPanelService: DetailPanelService,
    public dialog: MatDialog,
    private ui: UIService,
    private translate: TranslateService
  ) {}

  addItemEventSubscription: Subscription;

  ngOnInit(): void {
    this.plugin = this.libraryViewService.plugin;

    this.addItemEventSubscription =
      this.libraryViewService.addNewItemEvent.subscribe((s) => {
        this.onClickAddItem();
      });
  }

  ngOnDestroy(): void {
    this.addItemEventSubscription?.unsubscribe();
  }

  closeLibrary() {
    if (this.detailPanelService.currentPanel.getValue() != false) {
      this.detailPanelService.currentPanel.next(false);
      return;
    }

    this.libraryNav.closeLibrary();
  }

  onClickAddItem(): void {
    const data: NewLibraryItemDialogData = {
      itemName: '',
      plugin: this.libraryViewService.plugin,
      itemCategory: null,
    };
    const dialogRef = this.dialog.open(NewLibraryItemDialogComponent, {
      width: '400px',
      data: data,
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: NewLibraryItemDialogData) => {
        if (Boolean(result) == false) {
          return;
        }

        this.ui.disableUI(true);
        try {
          await this.libraryViewService.createItem(result);
        } catch (err) {
          console.error(err);
          let message = this.translate.instant('NEW_LIBRARY_ITEM.CANT_CREATE');
          alert(message);
        }
        this.ui.enableUI();
      });
  }
}
