import { Injectable } from '@angular/core';
import { CanvasSpace, Geom, Mat, Pt } from 'pts';
import { Fabric8Object } from '../models/object.model';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  public cameraScale: number = 1;
  public cameraOffset: Pt = new Pt(0, 0);
  public cameraMatrix: Mat = new Mat();
  public inverseCameraMatrix: Mat = new Mat();
  // camera vars
  public objectSize: Pt = new Pt(1, 1);

  constructor() {}

  fitCurrentObject(currentObject: Fabric8Object, canvasSpace: CanvasSpace) {
    const box = Geom.boundingBox(currentObject.points);
    this.objectSize = box[1].$subtract(box[0]);
    const objScale = 1.5;

    // canvas bounds
    const canvasSize = canvasSpace.outerBound.size;
    const scaleAxis =
      this.objectSize.x / this.objectSize.y > canvasSize.x / canvasSize.y
        ? 'x'
        : 'y';
    this.cameraScale =
      canvasSize[scaleAxis] / this.objectSize[scaleAxis] / objScale;

    const boxCenter = box.centroid();

    this.cameraMatrix.reset();
    this.cameraMatrix.translate2D(boxCenter.$multiply(-1));
    this.cameraMatrix.scale2D(new Pt(1, -1)); // flip y
    this.cameraMatrix.scale2D(new Pt(this.cameraScale, this.cameraScale));
    this.cameraMatrix.translate2D(canvasSize.$divide(2));

    this.inverseCameraMatrix.reset();
    this.inverseCameraMatrix.translate2D(canvasSize.$divide(2).$multiply(-1));
    this.inverseCameraMatrix.scale2D(
      new Pt(1 / this.cameraScale, 1 / this.cameraScale)
    );
    this.inverseCameraMatrix.scale2D(new Pt(1, -1)); // flip y
    this.inverseCameraMatrix.translate2D(boxCenter);
  }

  transform(input: Pt[]): Pt[] {
    return input.map((p) => Mat.transform2D(p, this.cameraMatrix.value));
  }

  inverseTransform(input: Pt[]): Pt[] {
    return input.map((p) => Mat.transform2D(p, this.inverseCameraMatrix.value));
  }

  resetMatrix() {
    this.cameraMatrix.reset();
    this.inverseCameraMatrix.reset();
  }

  zoom(factor: number, point: Pt) {
    this.cameraScale *= factor;

    const z = new Pt(factor, factor);
    this.cameraMatrix.translate2D(point.$multiply(-1));
    this.cameraMatrix.scale2D(z);
    this.cameraMatrix.translate2D(point);

    const zi = new Pt(1 / factor, 1 / factor);
    var mat = new Mat();
    mat.translate2D(point.$multiply(-1));
    mat.scale2D(zi);
    mat.translate2D(point);

    this.inverseCameraMatrix['_33'] = Mat.multiply(
      mat.value,
      this.inverseCameraMatrix.value
    );
  }

  pan(vector: Pt) {
    this.cameraMatrix.translate2D(vector);

    const mat = new Mat();
    mat.translate2D(vector.$multiply(-1));
    this.inverseCameraMatrix['_33'] = Mat.multiply(
      mat.value,
      this.inverseCameraMatrix.value
    );
  }
}
