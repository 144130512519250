import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { defer } from 'lodash';

@Component({
  selector: 'sw-segmented-buttons',
  templateUrl: './segmented-buttons.component.html',
  styleUrls: ['./segmented-buttons.component.scss'],
})
export class SegmentedButtonsComponent implements OnInit {
  @Input() buttons: string[] = [];
  @Input() selection: string[] = [];
  @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter();

  @Input() allowMultipleSelection: boolean = false;
  @Input() allowEmptySelection: boolean = true;

  constructor() {}

  ngOnInit(): void {
    if (this.buttons.length == 0) {
      throw "<sw-segmented-buttons> you must supply some button values to 'buttons'";
    }

    this.checkSelection();
  }

  checkSelection() {
    // remove invalid values from selection
    this.selection = this.selection.filter((option) =>
      this.buttons.includes(option)
    );

    if (!this.allowMultipleSelection && this.selection.length > 0) {
      this.selection = [this.selection[0]];
    }

    if (!this.allowEmptySelection && this.selection.length == 0) {
      // nothing is selected, but an empty selection is not allowed
      this.selection = [this.buttons[0]];
    }
  }

  clicked(button: string) {
    const selectionIndex = this.selection.indexOf(button);

    if (selectionIndex > -1) {
      // was already selected
      if (!this.allowEmptySelection && this.selection.length == 1) {
        // can't deselect
      } else {
        // deselect
        this.selection.splice(selectionIndex);
      }
    } else {
      // wasn't selected
      if (!this.allowMultipleSelection) {
        this.selection = [button];
      } else {
        this.selection.push(button);
      }
    }

    console.log(this.selection);
    this.selectionChanged.next(this.selection);
  }
}
