import { ProjectData } from './project-data.model';

export class SWPlugin {
  constructor(
    public id: string,
    public name: string,
    public available: boolean,
    public templates: ProjectData[],
    public library: boolean
  ) {}
}
