import { Component, Input, OnInit } from '@angular/core';
import { DataNode } from 'src/app/model/data-node.model';
import { ProjectData } from 'src/app/model/project-data.model';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { DataNodeService } from '../../project/services/data-node.service';

@Component({
  selector: 'sw-plugin-custom-ui',
  templateUrl: './smartworks-plugin-custom-ui.component.html',
  styleUrls: ['./smartworks-plugin-custom-ui.component.scss'],
})
export class SmartworksPluginCustomUiComponent implements OnInit {
  @Input() plugin: SWPlugin;
  @Input() projectData: ProjectData;
  rootNode?: DataNode;

  constructor(private dataNodeService: DataNodeService) {}

  async ngOnInit(): Promise<void> {
    if (this.plugin.library) {
      return;
    }

    // not library
    try {
      this.rootNode = await this.dataNodeService.getNode(
        this.projectData.rootNodeID
      );
    } catch (e) {
      console.error(e);
    }
  }
}
