import { Injectable, OnDestroy } from '@angular/core';
import { MMHub } from './models/MMHub.model';
import { SketchupService } from 'src/app/services/sketchup.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModelManagerSettingsService } from './model-manager-settings/model-manager-settings.service';
import { LODstates, MM2DType, MMLODType, MMProxyDensity } from './models/MMLOD.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MMHubCandidate, MMHubCandidateBuildState } from './models/MMHubCandidate.model';
import { NewHubDialogData } from './new-hub-dialog/new-hub-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

export enum MMState {
  Init,
  Loading,
  Empty,
  Loaded,
  NotSketchup,
  NoFile
} 

@Injectable({
  providedIn: 'root'
})
export class ModelManagerSketchUpService implements OnDestroy {

  hubs: BehaviorSubject<MMHub[]> = new BehaviorSubject([])
  state: BehaviorSubject<undefined | MMState> = new BehaviorSubject(MMState.Init)
  state$: Observable<undefined | MMState> = this.state.pipe(debounceTime(200))
  currentHubID: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined)
  currentHubID$: Observable<string | undefined> = this.currentHubID.pipe(debounceTime(50))

  selectedHubs: BehaviorSubject<MMHubCandidate[]> = new BehaviorSubject([]);
  selectedHubs$: Observable<MMHubCandidate[]> = this.selectedHubs
          .pipe(debounceTime(150),
                distinctUntilChanged())

  isBuilding: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined)

  constructor(
    private sketchUp: SketchupService,
    private mms: ModelManagerSettingsService,
    private translate: TranslateService,
  ) { 
    if (this.sketchUp.notSketchup) {
      console.error("Not Sketchup")
      this.state.next(MMState.NotSketchup)
      return
    }
    this.initCallbacks()
  }

  loadingData = false
  reloadInterval: any
  startReloadTimer() {
    this.stopReloadTimer()
    this.reloadInterval = setInterval(async () => { 
      if (this.loadingData) return
      this.loadingData = true
      await this.reload()
      this.loadingData = false
    }, 2020)
  }

  stopReloadTimer() {
    clearInterval(this.reloadInterval)
  }

  ngOnDestroy(): void {
    this.stopReloadTimer()
  }

  initCallbacks() {
    this.sketchUp.registerFunctions({
      ModelOpened: () => { this.reload() },
      userSelectionChanged: (selectedHubCandidates) => { 
          this.selectedHubs.next(selectedHubCandidates) 
      }
    })
  }
 
  reset() {
    this.hubs.next([])
    this.state.next(MMState.Init)
    this.currentHubID.next(undefined)
  }

  initHubState(hub: any) {
    const defaultValues = {
      last2DType: undefined,
      proxyDensity: undefined,
      autoRotate2D: this.mms.settings.auto2d,
      needsRefresh: false
    }

    hub.state = { ...defaultValues, ...hub.state }
  }

  async reload() {
    // this.state.next(MMState.Loading)    
    var t1 = new Date()  // debug 
    try {
      const hubs = await this.sketchUp.bridge.get('HubsGet')
      hubs.forEach(hub => {
        this.initHubState(hub)
      }) 
      this.hubs.next(hubs)
      if (this.hubs.getValue().length == 0) {
        this.state.next(MMState.Empty)
      } else {
        this.state.next(MMState.Loaded)
      }
    } catch (e) {
      if (e == "no-file") {
        this.state.next(MMState.NoFile)
      }
    }

    // debug only
    var t2 = new Date()
    var dif = t1.getTime() - t2.getTime();  
    var Seconds_from_T1_to_T2 = dif / 1000;
    var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
    // console.log("reloaded Hubs in " + Seconds_Between_Dates)  

  }

  async setGlobalLODType(type: MMLODType) {
    const hubs = this.hubs.getValue()
    
    for (let index = 0; index < hubs.length; index++) {
      const hub = hubs[index]
      try {
        await this.setLODType(hub, type)
      } catch(e) {
        // lod is probably missing, so we just skip it
      }
    }
  }

  async setHidden(hub: MMHub) {
    await this.sketchUp.bridge
      .get('HubSetHidden', hub.name, hub.hidden)
  }

  async setHUBAutoRotate(hub: MMHub, autoRotate: boolean): Promise<boolean> {
    return await this.sketchUp.bridge
        .get('HubSetAutorotate', hub.name, autoRotate)
  }

  async setLODType(hub: MMHub, type: MMLODType, options?: any): Promise<boolean> {
    var theLOD = undefined

    if (type === MMLODType.TwoD) {
      console.log("switching to 2d")
      if (options) {
        // set last selected 2d type
        hub.state.last2DType = options
        await this.setHubState(hub)
      } else {
        // get last selected 2d type 
        // or default to all
        options = hub.state.last2DType ?? "box"
      }      
      var switchToLODName = this.stripSuffix(hub.active_lod) + "_2D_" + options;
      console.log("switching to 2d ", switchToLODName)
      theLOD = hub.lods.find(lodName => lodName === switchToLODName)

    } else if (type === MMLODType.Proxy) {
      console.log("switching to proxy")
      if (options && options !== hub.state.proxyDensity) {
        throw("proxy-different-density")
        // also sets state
      } else {
        // just try switching to the existing proxy (if it exists)
        theLOD = hub.lods.find(lod => this.getLODType(lod) === type)
      }
    } else {
      console.log("switching to", type)
      theLOD = hub.lods.find(lod => this.getLODType(lod) === type)
    }

    if (! theLOD) {
      throw("lod-type-missing")
    }

    await this.sketchUp.bridge
        .get('HubSwitchLOD', 
              hub.name,
              theLOD) //theLOD.name

    return true
  }

  async newHub(name: string, definition: string): Promise<MMHub> {
    var hub = await this.sketchUp.bridge.get(
                                  'HubCreateFromDefinition', 
                                  name,
                                  definition,
                                  this.mms.settings.autoEmptyLOD)   
    hub.state = {
      last2DType: undefined,
      proxyDensity: undefined  
    }
    await this.setHubState(hub)

    var hubs = this.hubs.getValue()
    hubs.push(hub)

    this.hubs.next(hubs)
    return hub
  }

  updateHub(hub: MMHub) {
    var hubs = this.hubs.getValue()
    const hubIndex = hubs.findIndex(h => h.name == hub.name)
    if (hubIndex) {
      hubs[hubIndex] = hub
    } else {
      hubs.push(hub)
    }
    this.hubs.next(hubs)
  }

  async rebuildHubs() {
    var hubsToRefresh = this.hubs.getValue().filter(h => h.state.needsRefresh)

    if (hubsToRefresh.length == 0) {
      hubsToRefresh = this.hubs.getValue()
    }
    
    for (let i = 0; i < hubsToRefresh.length; i++) {
      var hub = hubsToRefresh[i]
      await this.refreshLODs(hub)
    }

  }

  async refreshLODs(hub: MMHub): Promise<boolean> {
    const prevHub = cloneDeep(hub)
    const prevLODType = this.getLODType(prevHub.active_lod)

    this.isBuilding.next(hub.name)

    var newHub: MMHub
    // recreate the empty LOD
    newHub = await this.createLod(hub, MMLODType.Empty)

    // if the HUB has a 2d LOD
    if (prevHub.state.last2DType) {
      try {
        newHub = await this.createLod(hub, MMLODType.TwoD, prevHub.state.last2DType)
      } catch (e) {
        if (e == "error-2d-flat-invalid") {
          alert(this.translate.instant('MODEL_MANAGER.ERROR_2D_FLAT'))
        }
      }
    }

    // if the HUB has a proxy LOD
    if (prevHub.state.proxyDensity) {
      newHub = await this.createLod(hub, MMLODType.Proxy, prevHub.state.proxyDensity)      
    }

    await this.setLODType(newHub, prevLODType)

    newHub.state.needsRefresh = false
    await this.setHubState(newHub)
    
    this.isBuilding.next(undefined)
    return true
  }
  
  async newHubFromCandidate(
      item: MMHubCandidate, 
      options: NewHubDialogData,
      callback: () => void
  ) : Promise<MMHub> 
  {
    if (this.hubs.getValue().find(h => h.name === options.hubName)) {
      item.buildingState = MMHubCandidateBuildState.error
      item.error = this.translate.instant("MODEL_MANAGER.DUPLICATE_NAME")
      callback()
      return 
    }

    item.buildingState = MMHubCandidateBuildState.empty
    callback()

    var addEmpty = this.mms.settings.autoEmptyLOD
    var hub = await this.sketchUp.bridge.get(
                      'HubCreateFromDefinition', 
                      options.hubName,
                      item.definition, 
                      addEmpty)

    hub.state = {
      last2DType: undefined,
      proxyDensity: undefined  
    }
    await this.setHubState(hub)

    var hubs = this.hubs.getValue()
    hubs.push(hub)
    this.hubs.next(hubs)

    var cannotCreate2dWarning = false
    if (options.create2d) {
      item.buildingState = MMHubCandidateBuildState['2d']
      callback()
      try {
        await this.createLod(hub, MMLODType.TwoD)
      } catch (e) {
        if (e == "error-2d-flat-invalid") {
          cannotCreate2dWarning = true
        } else {
          item.buildingState = MMHubCandidateBuildState.error
          item.error = e
          callback()
          return
        }
      }
    }
    if (options.createProxy !== MMProxyDensity.None) {
      item.buildingState = MMHubCandidateBuildState.proxy
      callback()
      await this.createLod(hub, MMLODType.Proxy, options.createProxy)
    }

    if (cannotCreate2dWarning == true) {
      item.buildingState = MMHubCandidateBuildState.warning
      item.error = this.translate.instant("MODEL_MANAGER.ERROR_2D_FLAT")  
    } else {
      item.buildingState = MMHubCandidateBuildState.done 
    }

    callback()
  }

  async setHubState(hub): Promise<boolean> {
    await this.sketchUp.bridge.get("HubSetState", hub.name, hub.state)
    return true
  }

  async deleteHub(hub: MMHub): Promise<boolean> {
    await this.sketchUp.bridge.get('HubDelete', hub.name)
    const newHubs = this.hubs.getValue().filter(h => h.name != hub.name)
    this.hubs.next(newHubs)
    return true
  }

  selectHub(hub: MMHub | undefined) {
    this.currentHubID.next(hub ? hub.name : undefined)
  }

  async createLod(hub: MMHub, type: MMLODType, options?: any) : Promise<MMHub> {

    var originalName = this.stripSuffix(hub.active_lod)
    var updatedHub: MMHub

    switch (type) {
      case 'empty':      
        updatedHub = await this.sketchUp.bridge.get(
          'LODCreateEmpty',
          hub.name,
          originalName
        )
        break
      case '2d':      
        updatedHub = await this.sketchUp.bridge.get(
          'LODCreate2D', 
          hub.name, 
          originalName, 
          this.mms.settings.auto2d, 
          options
          // originalName + "_2D_" + options
        )
        this.initHubState(updatedHub)
        if (! updatedHub.state.last2DType) {
          updatedHub.state.last2DType = options || MM2DType.Box
          await this.setHubState(updatedHub)
        }
        break
      case 'proxy':
        updatedHub = await this.sketchUp.bridge.get(
            'LODCreateProxy', 
            hub.name, 
            originalName, 
            options)
        this.initHubState(updatedHub)
        updatedHub.state.proxyDensity = options
        await this.setHubState(updatedHub)
        break
      // case 'lod':
      //   await this.sketchUp.bridge.get('LODCreateSelected', hub.name, originalName)
      //   return true
    }

    this.updateHub(updatedHub)
    return updatedHub
  }

  getLODType(lod_name: string): MMLODType {
    if (lod_name === null) return MMLODType.Empty
    if (lod_name.endsWith("_2D_box")) return MMLODType.TwoD
    if (lod_name.endsWith("_2D_top")) return MMLODType.TwoD
    if (lod_name.endsWith("_2D_side")) return MMLODType.TwoD
    if (lod_name.endsWith("_2D_front")) return MMLODType.TwoD
    if (lod_name.endsWith("_2D_center")) return MMLODType.TwoD

    const type = LODstates.find(state => lod_name.endsWith("_" +state))
    return type ? (type as MMLODType) : MMLODType.Original
  }

  get2DLODType(lod_name: string): MM2DType {
    if (lod_name.endsWith("_2D_box")) return MM2DType.Box
    if (lod_name.endsWith("_2D_top")) return MM2DType.Top
    if (lod_name.endsWith("_2D_side")) return MM2DType.Side
    if (lod_name.endsWith("_2D_front")) return MM2DType.Front
    if (lod_name.endsWith("_2D_center")) return MM2DType.Center
  }

  stripSuffix(lod_name: string) {
    if (! lod_name) {
      console.error("stripSuffix ", lod_name)
    }

    const suffixes = [...LODstates, "2D_box", "2D_side", "2D_front", "2D_top", "2D_center"]

    suffixes.forEach(state => {
      lod_name = lod_name.replace("_" + state, "")
    })
    return lod_name
  }

  async multiSelectByDefinition(def: string[]) {
    await this.sketchUp.bridge.get('multiSelectByDefinition', def)
  }

}
