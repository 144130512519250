import { Fabric8NodeType } from '../../models/Fabric8NodeType';
import { Fabric8GuidelineSegment } from '../../models/guideline-segment.model';
import { Fabric8Node } from '../../models/node.model';
import { Fabric8SmartObjectType } from '../../models/smartgroup.model';
import { addDistributedDrillHoles } from './addDistributedDrillHoles';
import { addEquallySpacedDrillHoles } from './addEquallySpacedDrillHoles';

export function updateChildrenNodesForGuidelineSegment(node: Fabric8Node) {
  if (!node) return;
  if (node.type !== Fabric8NodeType.GuidelineSegment) return;
  const data = node.data as Fabric8GuidelineSegment;

  const oldIds = node.children.map((c) => c.id);
  node.children = [];
  switch (data.smartObjectData?.type) {
    case Fabric8SmartObjectType.distributedHoles:
      addDistributedDrillHoles(node, data, oldIds);
      break;
    case Fabric8SmartObjectType.equallySpacedHoles:
      addEquallySpacedDrillHoles(node, data, oldIds);
      break;
    default:
      break;
  }
}
