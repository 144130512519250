import { Fabric8Tool } from '../../models/tool.model';
import { Fabric8ToolType } from '../Fabric8ToolType';

export const routerToolSet: Fabric8Tool[] = [
  {
    name: '',
    type: Fabric8ToolType.MILLING,
    depth: 4,
    radius: 8 / 2,
    enabled: true,
  },
  {
    name: '',
    type: Fabric8ToolType.MILLING,
    depth: 4,
    radius: 6 / 2,
    enabled: true,
  },
  {
    name: '',
    radius: 4 / 2,
    depth: 4,
    type: Fabric8ToolType.MILLING,
    enabled: true,
  },
];
