import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SketchupService } from 'src/app/services/sketchup.service';
import { Fabric8Object } from '../models/object.model';
import { getStaticTestObjects } from './fabric8-sketchup-test-objects';

export interface Fabric8Objects {
  projectID: string;
  objects: Fabric8Object[];
}

@Injectable({
  providedIn: 'root',
})
export class Fabric8SketchupService {
  constructor(private sketchUp: SketchupService) {
    this.initCallbacks();
  }

  userSelection = new BehaviorSubject<any>(undefined);

  initCallbacks() {
    this.sketchUp.registerFunctions({
      userSelectionChanged: (selection) => {
        this.userSelection.next(selection);
      },
    });
  }

  async selectObject(object_guid: string) {
    try {
      await this.sketchUp.bridge.get('SelectObject', object_guid);
    } catch (e) {
      console.error(e);
    }
  }

  async addSelectedObjectToFabric8(): Promise<string> {
    try {
      const importedGuid = await this.sketchUp.bridge.get(
        'AddSelectedObjectsToFabric8'
      );
      return importedGuid;
      // await this.exportFabric8Object(this.preProcessObject(geom))
    } catch (e) {
      console.error(e);
    }
  }

  async loadSUObjects(): Promise<Fabric8Object[]> {
    try {
      // get objects from sketchup
      var geom = await this.sketchUp.bridge.get('ExportFabric8Objects');

      if (!geom || geom.projectID == undefined) {
        geom = getStaticTestObjects();
      }

      const objects = geom.objects.map((object) => new Fabric8Object(object));
      return objects;
    } catch (e) {
      console.error(e);
    }
  }

  async exportFabric8Object(object: Fabric8Object) {
    try {
      const exp = object.toJSON();
      await this.sketchUp.bridge.get('ImportFabric8Object', exp);
    } catch (e) {
      console.error(e);
    }
  }

  async removeFabric8Data(object_guid: string) {
    try {
      await this.sketchUp.bridge.get('RemoveFabric8Data', object_guid);
    } catch (e) {
      console.error(e);
    }
  }
}
