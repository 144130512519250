import { Component } from '@angular/core';
import { Pt } from 'pts';
import { ToolService } from '../services/tool.service';
import { ContextMenuItem } from './contextmenu.options';
import { Fabric8ContextMenuService } from './fabric8-context-menu.service';

@Component({
  selector: 'sw-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss'],
})
export class ContextmenuComponent {
  constructor(
    public contextMenuService: Fabric8ContextMenuService,
    private toolService: ToolService
  ) {}

  selectedItem(item: ContextMenuItem, meta?: any): void {
    // const data: any = {...item, meta}
    this.toolService.toolEventHandler.contextMenuAction(item, meta);
    this.closeContextMenu();
  }

  closeContextMenu(event?): void {
    event?.preventDefault();
    this.contextMenuService.close();
  }

  preventDefaultContextMenu(event?): void {
    event?.preventDefault();
  }

  mouseLeaveAction(evt: any): void {
    if (evt.layerX < 0 || evt.layerY < 0) {
      this.closeContextMenu(evt);
    }
  }
}
