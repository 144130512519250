<ng-template [ngIf]="object.rootNode.children.length > 0">
  <div swTreeGroup class="nodes">
    <sw-fabric8-node
      [parent]="undefined"
      [(node)]="object.rootNode"
      (deleteNode)="deleteNode($event)"
    >
    </sw-fabric8-node>
    <div>&nbsp;</div>
  </div>
</ng-template>
