<ng-container
  *ngTemplateOutlet="swHiddenNode ? noNode : parent ? theNode : childNodes"
></ng-container>

<ng-template #noNode></ng-template>

<ng-template #theNode>
  <div
    swDrag
    (wDragStarted)="dragStarted($event)"
    (wDragReleased)="dragReleased($event)"
    (wDrop)="dropped($event)"
    [wDragNode]="node"
    [wDragObjectType]="'NODE'"
    [wDragParent]="parent"
    [wDragParentList]="parent.children"
  >
    <ng-container [ngTemplateOutlet]="nodeRow"></ng-container>

    <ng-template [ngIf]="isOpened()">
      <div id="attributes">
        <sw-attributes [(node)]="node">
          <div
            class="row library-item-info"
            *ngFor="let link of node.libraryLinks"
          >
            <span>{{ "Library Item" | translate }}</span>
            <div class="spacer"></div>
            <div *ngIf="link.item?.thumbnail" class="item-thumbnail">
              <img [src]="thumbnailData(link.item?.thumbnail)" />
            </div>
            <span>{{ trimName(link.item?.name) }}</span>
            <span class="link-icon" [inlineSVG]="linkPluginIcon(link)"
              >link.pluginID</span
            >
          </div>
        </sw-attributes>
      </div>
    </ng-template>

    <div class="children" [hidden]="!(hasChildren() && expanded)">
      <ng-container [ngTemplateOutlet]="childNodes"></ng-container>
    </div>
    <!-- last child dropzone -->
    <div
      swDropZone
      *ngIf="hasChildren() && expanded"
      class="inter last-child-dropzone"
      [wDropTarget]="node"
      [wDropTargetList]="node.children"
      [wDropTargetListIndex]="node.children.length"
      [wDropAcceptType]="'NODE'"
      [wIndentLevel]="indentLevel + 1"
      [wDropDisabled]="isDragging()"
    ></div>
  </div>
</ng-template>

<ng-template #nodeRow>
  <div
    class="node row"
    swDragHandle
    [ngClass]="{ dragging: isDragging(), opened: isOpened(), hide: isHidden }"
  >
    <div [swIndentSpacer]="indentLevel" (click)="toggleAttributes()"></div>

    <!-- expand/collapse arrow -->
    <div
      id="arrow"
      *ngIf="hasChildren()"
      class="hover-btn transparent"
      [inlineSVG]="
        expanded ? 'icon/arrow-expanded.svg' : 'icon/arrow-collapsed.svg'
      "
      [ngClass]="{ collapsed: !expanded, expanded: expanded }"
      (click)="expanded = !expanded"
    ></div>

    <div class="name" (click)="nameClick()">
      <sw-editable
        id="project-name"
        [(value)]="node.name"
        (endEditing)="endEditingName()"
      ></sw-editable>

      <sw-user-presence [dataNodeID]="node.id"></sw-user-presence>

      <div
        swDropZone
        [wDropDisabled]="isDragging()"
        [wDropTarget]="node"
        [wDropTargetList]="node.children"
        [wDropTargetListIndex]="0"
        [wDropAcceptType]="'NODE'"
      ></div>
    </div>

    <div class="spacer" (click)="toggleAttributes()"></div>

    <ng-template [ngIf]="hasPrice(node) && !isOpened()">
      <div *ngFor="let link of node.libraryLinks">
        <span class="price hide-onhover">{{ itemPrice(node) }}</span>
        <span class="link-icon hide-onhover" [inlineSVG]="linkPluginIcon(link)"
          >link.pluginID</span
        >
      </div>
    </ng-template>

    <ng-container [ngTemplateOutlet]="nodeMenu"></ng-container>
  </div>
</ng-template>

<ng-template #nodeMenu>
  <sw-dots-menu [itemID]="node.id" class="show-onhover">
    <div
      class="hover-btn red"
      [inlineSVG]="'icon/delete.svg'"
      (click)="onDelete.emit()"
      matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
      matTooltipPosition="above"
    ></div>
    <div
      class="hover-btn"
      [inlineSVG]="'icon/edit.svg'"
      (click)="editAttributes()"
      matTooltip="{{ 'PROJECT_EDIT.EDIT_ATTRIBUTES' | translate }}"
      matTooltipPosition="above"
    ></div>
    <div
      class="hover-btn"
      [inlineSVG]="'icon/duplicate.svg'"
      (click)="onDuplicate.emit()"
      matTooltip="{{ 'GLOBAL.DUPLICATE' | translate }}"
      matTooltipPosition="above"
    ></div>
    <div
      class="hover-btn"
      [inlineSVG]="'icon/plus.svg'"
      (click)="addDataNodeChild()"
      matTooltip="{{ 'PROJECT_EDIT.NEW_ITEM_TOOLTIP' | translate }}"
      matTooltipPosition="above"
    ></div>
  </sw-dots-menu>
</ng-template>

<ng-template #childNodes>
  <ng-template [ngIf]="state === 'LoadingChildren'">
    <!-- <mat-spinner [diameter]="20"></mat-spinner> -->
    <ng-container [ngTemplateOutlet]="childNodesPlaceholders"></ng-container>
  </ng-template>
  <ng-template [ngIf]="state === 'FailedLoadingChildren'">
    <div class="flex center">
      <mat-icon>cloud_off</mat-icon>&nbsp;
      {{ "PROJECT_EDIT.FAILED_LOADING_CHILDREN" | translate }}
    </div>
  </ng-template>
  <ng-template [ngIf]="state === 'ChildrenLoaded'">
    <div @parent>
      <ng-container [ngTemplateOutlet]="childNodesLoaded"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #childNodesPlaceholders>
  <div
    *ngFor="let childID of node.childrenIDs"
    class="node row node-placeholder"
  >
    <div [swIndentSpacer]="indentLevel + 1"></div>
    <mat-progress-bar
      mode="indeterminate"
      style="width: 4rem"
    ></mat-progress-bar>
  </div>
</ng-template>

<ng-template #childNodesLoaded>
  <div
    *ngFor="let child of node.children; let i = index; trackBy: nodeByID"
    [@inout]
  >
    <div
      swDropZone
      class="inter"
      [wIndentLevel]="indentLevel + 1"
      [wDropTarget]="node"
      [wDropTargetList]="node.children"
      [wDropTargetListIndex]="i"
      [wDropAcceptType]="'NODE'"
      [wDropDisabled]="isDragging()"
    ></div>
    <sw-data-node
      [indentLevel]="indentLevel + 1"
      [parentIsDragged]="isDragging()"
      [parent]="node"
      (onDelete)="deleteChildAtIndex(i)"
      (onDuplicate)="duplicateChildAtIndex(i)"
      [(node)]="node.children[i]"
    ></sw-data-node>
  </div>
</ng-template>
