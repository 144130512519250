import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { Message } from '@stomp/stompjs';
import { map, sampleTime, tap } from 'rxjs/operators';
import { UserCursorMessage } from './cursors.component';
import { MQService } from 'src/app/modules/rmq/mq.service';

const CURSORS_TOPIC = 'cursors';

@Injectable({
  providedIn: 'root',
})
export class CursorsLiveUpdatesService implements OnDestroy {
  cursorsTopicSubscription: Subscription;
  cursors$: BehaviorSubject<Map<string, UserCursorMessage>> =
    new BehaviorSubject(new Map());

  constructor(private mqService: MQService) {
    this.cursorsTopicSubscription = mqService
      .topic(CURSORS_TOPIC)
      .subscribe((message: Message) => {
        this.processUserCursor(message.body);
        message.ack();
      });

    this.initMouseEvents();
  }

  processUserCursor(userCursorMessage: string) {
    var incomingCursor: UserCursorMessage = JSON.parse(userCursorMessage);

    // ignore cursor for self
    if (incomingCursor.u == this.mqService.clientID) {
      return;
    }

    var cursors = this.cursors$.value;
    cursors.set(incomingCursor.u, incomingCursor);
    this.cursors$.next(cursors);
  }

  initMouseEvents() {
    fromEvent(document.body, 'mousemove')
      .pipe(
        map((e: MouseEvent): UserCursorMessage => {
          return {
            u: this.mqService.clientID,
            transform: 'translate(' + e.pageX + 'px, ' + e.pageY + 'px)',
          };
        }),
        sampleTime(60)
      )
      .subscribe((m) => {
        this.mqService.sendMessage(JSON.stringify(m), CURSORS_TOPIC);
      });
  }

  ngOnDestroy(): void {
    this.cursorsTopicSubscription.unsubscribe();
  }
}
