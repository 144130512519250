import { NgModule } from '@angular/core';
import { ModelManagerComponent } from './model-manager.component';
import { ModelManagerSettingsComponent } from './model-manager-settings/model-manager-settings.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MmHubComponent } from './mm-hub/mm-hub.component';
import { ModelManagerSettingsService } from './model-manager-settings/model-manager-settings.service';
import { NewHubDialogComponent } from './new-hub-dialog/new-hub-dialog.component';
import { ModelManagerWizardComponent } from './model-manager-helper/model-manager-helper.component';
import { ModelManagerHelperRowComponent } from './model-manager-helper-row/model-manager-helper-row.component';
import { ModelManagerHelperService } from './model-manager-helper/model-manager-helper.service';
import { ScrollingModule } from '@angular/cdk/scrolling';

const routes: Routes = [
  {
    path: 'model-manager',
    pathMatch: 'full',
    component: ModelManagerComponent,
  },
  {
    path: 'model-manager/settings',
    pathMatch: 'full',
    component: ModelManagerSettingsComponent,
  },
  {
    path: 'model-manager/wizard',
    pathMatch: 'full',
    component: ModelManagerWizardComponent,
  },
];

@NgModule({
  declarations: [
    ModelManagerSettingsComponent,
    ModelManagerComponent,
    MmHubComponent,
    NewHubDialogComponent,
    ModelManagerWizardComponent,
    ModelManagerHelperRowComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    // WTreeModule,
    // DragDropModule,
    // AttributesModule,
    TranslateModule.forChild(),
    ScrollingModule,
  ],
  exports: [RouterModule],
  providers: [ModelManagerSettingsService, ModelManagerHelperService],
})
export class ModelManagerModule {}
