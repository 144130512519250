import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PostformService {
  constructor() {}

  openWindowWithPost(url, data) {
    var form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';

    for (var key in data) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}
