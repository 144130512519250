import { Injectable } from '@angular/core';
import { CanvasForm } from 'pts';
import { Fabric8Element } from '../../models/element.model';
import { Fabric8Node } from '../../models/node.model';
import { CameraService } from '../../services/camera.service';
import { ElementsService } from '../../services/elements.service';
import { HoverService } from '../../services/hover.service';
import { Fabric8SettingsService } from '../../settings/fabric8-settings.service';
import { Fabric8ToolType } from '../../toolset/Fabric8ToolType';
import { colors } from '../colors';

@Injectable({
  providedIn: 'root',
})
export class RenderElementsService {
  constructor(
    private camera: CameraService,
    private elementsService: ElementsService,
    private hoverService: HoverService,
    private settingsService: Fabric8SettingsService
  ) {}

  drawDrillCenterPoints = false;

  render(elementNodes: Fabric8Node[], form: CanvasForm) {
    if (!elementNodes) return;
    const elements = elementNodes.map((node) => node.data as Fabric8Element);

    if (elements == undefined) return;

    this.drawDrillCenterPoints =
      this.settingsService.settingsSubject.getValue().drillCenterPoints;

    elementNodes
      .filter((n) => n)
      .forEach((n) => {
        this.drawNode(n, form);
      });
  }

  drawNode(node: Fabric8Node, form: CanvasForm) {
    const current = this.elementsService.getCurrentNodeDataPointIndex();
    const isCurrent = current?.id == node.id;
    const hovered =
      this.hoverService.mouseEvent.hoveredNodeDataPointIndices.find(
        (i) => i.id == node.id
      ) != undefined;

    const element = node.data as Fabric8Element;
    if (!element) return;

    const points = this.camera.transform(element.points);
    const radius = element.tool.radius * this.camera.cameraScale;

    // draw drill hole
    if (element.tool.type == Fabric8ToolType.DRILL) {
      if (this.drawDrillCenterPoints) {
        // draw crosshair drill center
        for (let i = 0; i < 4; i++) {
          const crosshairPoints = [
            points[0],
            points[0].$add(
              Math.sin((i * Math.PI) / 2) * radius,
              Math.cos((i * Math.PI) / 2) * radius
            ),
          ];
          form.ctx.setLineDash(colors.centerPointDashPattern);

          form
            .strokeOnly(colors.guidelineColor, colors.guidelineThickness)
            .line(crosshairPoints);
          form.dash(false);
        }

        form
          .strokeOnly(colors.elementFillColor, colors.guidelineThickness)
          .dash(colors.guidelineDash)
          .point(points[0], radius, 'circle');
        form.dash(false);
      } else {
        form
          .fillOnly(colors.elementFillColor)
          .point(points[0], radius, 'circle');

        if (isCurrent || hovered) {
          form
            .strokeOnly(colors.elementHoleHoverStroke, 3)
            .point(points[0], radius, 'circle');
        }
      }
    }

    // draw router line
    if (
      element.tool.type == Fabric8ToolType.CUT ||
      element.tool.type == Fabric8ToolType.MILLING
    ) {
      const fill =
        isCurrent || hovered
          ? colors.routerElementsStrokeHover
          : colors.routerElementStroke;

      // draw single point
      if (points.length == 1) {
        form.fillOnly(fill).point(points[0], radius, 'circle');
      } else {
        // more than 1 point
        form
          .strokeOnly(
            fill,
            // element.isClosedPath ? 'black' : fill,
            radius * 2,
            'round',
            'round'
          )
          .line(points);
      }
    }

    if (isCurrent) {
      this.drawElementPoints(element, current.pointIndex, form);
    } else if (hovered) {
      this.drawElementPoints(element, -1, form);
    }
  }

  // const current = this.toolService.getCurrentElementPointIndex()
  drawElementPoints(
    element: Fabric8Element,
    highlightIndex: number,
    form: CanvasForm
  ) {
    if (element == undefined) return;

    const points = this.camera.transform(element.points);

    // draw point centers
    form.fillOnly(colors.routerElementStroke).points(points, 2, 'circle');

    // draw highlighted point
    if (highlightIndex > -1) {
      form
        .fillOnly(colors.routerElementStroke)
        .point(points[highlightIndex], 4, 'square');
    }

    // draw start point
    form.fillOnly(colors.routerElementStroke).point(points[0], 4, 'circle');
  }
}
