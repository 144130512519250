<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>{{ data.label }}</mat-label>
    <input matInput type="number" [(ngModel)]="toolConfig.drillCount" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Distance</mat-label>
    <input
      matInput
      matTextSuffix="mm"
      type="text"
      [(ngModel)]="toolConfig.distance"
      (keydown.enter)="convertDistance()"
      (clickOutside)="convertDistance()"
      (blur)="convertDistance()"
    />
  </mat-form-field>
</div>

<div mat-dialog-actions class="buttons">
  <button mat-button class="btn-primary" (click)="onCancel()">
    {{ "GLOBAL.CANCEL" | translate | uppercase }}
  </button>
  <div class="spacer"></div>
  <button mat-button class="btn-primary" [mat-dialog-close]="toolConfig">
    {{ "GLOBAL.OK" | translate }}
  </button>
</div>
