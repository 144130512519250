<ng-template [ngIf]="attr.type === 'dropdown'">
  <div
    class="panel"
    cdkDropList
    [cdkDropListData]="attr"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="row"
      *ngFor="let a of attr.options; let i = index"
      cdkDrag
      [cdkDragData]="a"
    >
      <mat-icon cdkDragHandle class="drag">more_vert</mat-icon>
      <input
        #nameInput
        class="name"
        [(ngModel)]="a.name"
        placeholder="{{ 'ATTR_OPTIONS.NAME' | translate }}"
        (keydown.enter)="nameInput.blur()"
      />
      <input
        #valueInput
        [(ngModel)]="a.value"
        placeholder="{{ 'ATTR_OPTIONS.VALUE' | translate }}"
        (keydown.enter)="valueInput.blur()"
      />
      <mat-icon (click)="deleteOption(i)" class="delete">close</mat-icon>
    </div>

    <div class="center new">
      <div class="btn-primary" (click)="addOption()">
        <div class="icon" [inlineSVG]="'icon/plus.svg'"></div>
        <div>{{ "ATTR_OPTIONS.NEW_OPTION_BUTTON" | translate }}</div>
      </div>
    </div>
  </div>
</ng-template>
