import { Injectable } from '@angular/core';
import { CanvasForm, Group, Line, Pt, Rectangle } from 'pts';
import { ContextMenuItem } from 'src/app/modules/fabric8/contextmenu/contextmenu.options';
import {
  Fabric8Guideline,
  IFabric8Guideline,
} from '../../models/guideline.model';
import { Fabric8Node, IFabric8Node } from '../../models/node.model';
import { Fabric8NodeType } from '../../models/Fabric8NodeType';
import { colors } from '../../swcanvas/colors';
import { Fabric8ToolType } from '../../toolset/Fabric8ToolType';
import { CameraService } from '../camera.service';
import { CurrentObjectService } from '../current-object.service';
import { newNodeWithData } from '../nodes/newNodeWithData';
import { ToolHandlerService } from '../tool-handler.service';
import { ToolService } from '../tool.service';
import { HoverService } from '../hover.service';
import { Fabric8MouseEvent } from './models/Fabric8MouseEvent.model';
import { Fabric8ToolHandler } from './models/Fabric8ToolEvents.model';
import { ToolState } from './models/ToolState.model';
import { SnapService, SnapTo } from './snap';

@Injectable({
  providedIn: 'root',
})
export class GuideLineTool implements Fabric8ToolHandler {
  // toolService: ToolService;
  state: ToolState = ToolState.idle;
  textInput: string = '';
  cursorPoint: Pt | undefined;
  cursorPointRaw: Pt | undefined;
  cursorSnapped?: SnapTo;

  currentGuideline: Fabric8Guideline | undefined;
  anchorLine: Pt[];

  constructor(
    private toolService: ToolService,
    private cameraService: CameraService,
    private toolHandlerService: ToolHandlerService,
    private currentObject: CurrentObjectService,
    private hoverService: HoverService,
    private snap: SnapService
  ) {
    this.toolHandlerService.registerTool(Fabric8ToolType.GUIDE, this);
  }

  private newGuidelineAt() {
    const line = this.anchorLine;
    const lineVector = line[0].$subtract(line[1]);
    const side = Line.sideOfPt2D(line, this.cursorPointRaw) > 0 ? -1 : 1;
    const perpendicularAngle = lineVector.angle() + side * (Math.PI / 2);

    this.currentGuideline = new Fabric8Guideline({
      [IFabric8Guideline]: true,
      anchor: this.cursorPoint,
      lineVector: lineVector,
      perpendicularAngle: perpendicularAngle,
      distance: 0,
      points: [],
      tool: this.toolService.getCurrentTool(),
    });

    this.anchorLine = line;

    this.state = ToolState.editing;
  }

  private addGuideline() {
    const o = this.currentObject.get();
    if (!o) return;

    const newNode = newNodeWithData(this.currentGuideline);
    o.rootNode.children.push(newNode);
    this.currentObject.update(true);
    this.reset();
  }

  editGuideline(guideline: Fabric8Guideline) {}

  public snapCursor(event: Fabric8MouseEvent) {
    this.cursorPointRaw = event.objectSpacePoint;

    const object = this.currentObject.get();
    const s = this.snap.to(object, event, [
      SnapTo.objectLines,
      SnapTo.elementLines,
      SnapTo.guidelines,
      SnapTo.elementPoints,
      SnapTo.grid,
      SnapTo.roundUnits,
    ]);

    this.cursorSnapped = s.snapped;
    this.cursorPoint = s.point;
  }

  public mouseClick(event: Fabric8MouseEvent) {}

  // handle mouse events
  public mouseDown(event: Fabric8MouseEvent) {
    if (
      Array.isArray(event.hoveredLinePointIndices) &&
      event.hoveredLinePointIndices[0] &&
      this.state == ToolState.idle
    ) {
      const object = this.currentObject.get();
      const line = object.lines[event.hoveredLinePointIndices[0].lineIndex];

      this.anchorLine = line;
      this.newGuidelineAt();
    } else {
      // can't start a guideline without an anchor line to snap to
    }
  }

  public mouseDrag(event: Fabric8MouseEvent) {
    if (this.currentGuideline) {
      const line = this.anchorLine;
      const lineVector = line[0].$subtract(line[1]);
      const side = Line.sideOfPt2D(line, this.cursorPointRaw) > 0 ? -1 : 1;
      const perpendicularAngle = lineVector.angle() + side * (Math.PI / 2);
      this.currentGuideline.perpendicularAngle = perpendicularAngle;
      this.currentGuideline.distance = Line.distanceFromPt(
        this.anchorLine,
        this.cursorPoint
      );
      this.textInput = '';
    }
  }

  public mouseUp(event: Fabric8MouseEvent) {
    // console.log('mouseUp', event)
    // this.state = ToolState.editing;
    // this.toolService.commitElement();
    // this.reset();
  }

  public mouseDrop(event: Fabric8MouseEvent) {
    // console.log('mouseDrop', event)
    // this.state = ToolState.editing;
    // this.elementsService.commitElement();
  }

  public mouseMove(event: Fabric8MouseEvent) {
    // console.log('mouseMove', event)
  }

  public keyDown(event: any) {
    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    if (event.key == '.') {
      if (this.textInput.includes('.')) {
        // do not add a second "."
        return;
      }

      if (this.textInput.length == 0) {
        this.textInput = '0';
      }

      this.textInput += '.';
      this.currentGuideline.distance = parseFloat(this.textInput);
    }

    if (numbers.includes(event.key)) {
      this.textInput += event.key;
      this.currentGuideline.distance = parseFloat(this.textInput);
    }

    if (event.key == 'Delete' || event.key == 'Backspace') {
      if (this.textInput.length == 1) {
        this.textInput = '';
        this.currentGuideline.distance = 0;
      } else if (this.textInput.length > 1) {
        this.textInput = this.textInput.slice(0, -1);
        this.currentGuideline.distance = parseFloat(this.textInput);
      } else {
        // remove the guideline
        this.currentGuideline = undefined;
        this.reset();
      }
      return;
    }

    if (event.key == 'Enter') {
      if (this.currentGuideline) this.addGuideline();
    }
  }

  public reset() {
    this.state = ToolState.idle;
    this.currentGuideline = undefined;
    this.textInput = '';
  }

  public draw(form: CanvasForm) {
    if (this.cursorPoint && this.cursorSnapped) {
      const p = this.cameraService.transform([this.cursorPoint]);
      var shape = 'circle';
      switch (this.cursorSnapped) {
        case SnapTo.objectPoints:
        case SnapTo.objectLines:
        case SnapTo.elementPoints:
          shape = 'square';
          form.fillOnly(colors.activeTool).point(p[0], 5, shape);
        default:
          break;
      }
    }

    if (this.currentGuideline) {
      const targetVector = new Pt(this.currentGuideline.distance, 0).rotate2D(
        this.currentGuideline.perpendicularAngle
      );
      const targetPoint = this.currentGuideline.anchor.$add(targetVector);

      const perpendicularLine = this.cameraService.transform([
        this.currentGuideline.anchor,
        targetPoint,
      ]);
      form.strokeOnly(colors.activeTool, 1).line(perpendicularLine);

      //draw arrows
      const startArrow = Line.marker(
        perpendicularLine,
        new Pt(4, 10),
        'arrow',
        false
      );
      const endArrow = Line.marker(
        perpendicularLine,
        new Pt(4, 10),
        'arrow',
        true
      );
      form.fillOnly(colors.activeTool).polygon(startArrow).polygon(endArrow);

      // draw distance label
      const centerPoint = perpendicularLine[0]
        .$add(perpendicularLine[1])
        .$divide(2);
      const distanceText = this.currentGuideline.distance.toFixed(2).toString();
      this.drawTextBox(
        form,
        centerPoint,
        distanceText,
        colors.activeTool,
        'white'
      );

      // draw guideline
      this.currentGuideline.points = this.anchorLine.map((p) =>
        p.$add(targetVector)
      );

      const points = this.hoverService.extendLineToCanvasEdges(
        Group.fromPtArray(this.currentGuideline.points),
        form
      );
      if (points?.length > 1) {
        form
          .stroke(colors.activeTool, colors.guidelineThickness)
          .dash(colors.guidelineDash)
          .line(this.cameraService.transform(points));
        form.dash(false);
      }
    }
  }

  private drawTextBox(
    form: CanvasForm,
    centerPoint: Pt,
    text: string,
    backgroundColor: string,
    textColor: string,
    fontSize: number = 12,
    padding: number = 5
  ) {
    form.font(fontSize, 'bold', null, 1, 'Roboto').fontWidthEstimate(true);
    const textWidth = form.getTextWidth(text).valueOf();

    const textBoxRectangle = Rectangle.fromCenter(
      centerPoint,
      textWidth + padding * 2,
      fontSize + padding * 2
    );

    const textBox = Rectangle.fromCenter(
      centerPoint,
      textWidth + 0.1,
      fontSize + padding * 2
    );

    form.fillOnly(backgroundColor).rect(textBoxRectangle);

    form.fillOnly(textColor).textBox(textBox, text, 'middle', null);
  }

  public getContextMenuItems(event: Fabric8MouseEvent): ContextMenuItem[] {
    // TODO
    return [];
  }

  public contextMenuAction(item: ContextMenuItem) {
    // TODO
  }
}
