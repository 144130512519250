<div class="row" [ngClass]="{ 'object-selected': isObjectSelected }">
  <div
    class="indent"
    [ngStyle]="{ width: data.flat ? 0 : data.indentLevel + 'rem' }"
  ></div>

  <div
    *ngIf="viewMode === 'tree' && data.hasChildren"
    class="expand"
    (click)="toggleExpansion()"
    [inlineSVG]="'icon/' + expandedIcon()"
  ></div>

  <ng-template [ngIf]="data.hub || data.lod" [ngIfElse]="notHub">
    <span
      class="icon is-hub pointer"
      [ngClass]="{ 'is-lod': data.lod }"
      [matTooltip]="data.hub"
      matTooltipPosition="above"
      [inlineSVG]="'mm/i-full.svg'"
    >
    </span>
  </ng-template>

  <ng-template #notHub>
    <mat-icon *ngIf="data.buildingState === s.queued" class="check pointer">
      more_horiz
    </mat-icon>

    <mat-icon
      *ngIf="data.buildingState === s.error"
      class="check pointer red"
      [matTooltip]="data.error"
      matTooltipPosition="above"
    >
      error
    </mat-icon>

    <mat-icon
      *ngIf="data.buildingState === s.warning"
      class="check pointer yellow"
      [matTooltip]="data.error"
      matTooltipPosition="above"
    >
      warning
    </mat-icon>

    <mat-spinner
      [diameter]="20"
      *ngIf="
        data.buildingState === s['2d'] ||
        data.buildingState === s.proxy ||
        data.buildingState === s.empty
      "
      class="icon is-hub pointer"
    >
    </mat-spinner>

    <span
      *ngIf="data.buildingState === s.done"
      class="icon is-hub pointer"
      [inlineSVG]="'mm/i-full.svg'"
    >
    </span>

    <mat-icon
      *ngIf="data.buildingState === undefined"
      class="check pointer"
      [ngClass]="{ selected: isSelected() }"
      (click)="toggleSelection()"
      >{{ isSelected() ? "check_box" : "check_box_outline_blank" }}
    </mat-icon>
  </ng-template>

  <span id="name" class="pointer" (click)="toggleSelection()">
    {{ data.buildingState ? helper.hubCandidateName(data) : data.name }}
  </span>

  <span
    class="instances pointer"
    (click)="toggleSelection()"
    matTooltip="{{ 'MODEL_MANAGER.INSTANCES' | translate }}"
    matTooltipPosition="above"
  >
    {{ data.repetition }}
  </span>

  <div class="spacer"></div>

  <span
    class="faces"
    *ngIf="data.buildingState === undefined && !data.hub"
    matTooltip="{{ 'MODEL_MANAGER.POLY_COUNT' | translate }}"
    matTooltipPosition="below"
  >
    {{ polyCount() }}
  </span>
</div>
<!-- <ng-container *ngFor="let s of data.children; let cIndex = index; trackBy: guid">
    <ng-template [ngIf]="data.polyCount > helper.facesTreshold">
    <sw-model-manager-helper-row
    [(data)]="data.children[cIndex]"
    >
    </sw-model-manager-helper-row>
    </ng-template>
</ng-container> -->
