import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { SketchupService } from 'src/app/services/sketchup.service';
import packageJson from '../../../../../package.json';
import { UnitConversionService } from '../../project/services/unit-conversion.service';
import { Fabric8Tool, Fabric8ToolGroup } from '../models/tool.model';
import { defaultFabric8ToolSetGroups } from '../toolset/toolset';
import { Fabric8ToolType } from '../toolset/Fabric8ToolType';

@Injectable({
  providedIn: 'root',
})
export class Fabric8ToolbarService {
  private toolGroup: Fabric8ToolGroup[];
  private version: string = packageJson.version;
  private FABRIC8_TOOLSET_KEY = 'FABRIC8_TOOLSET' + this.version;
  private defaultLengthUnit: string;

  toolsetSubject: BehaviorSubject<Fabric8ToolGroup[]> = new BehaviorSubject(
    undefined
  );
  defaultDepth: number = 4;
  defaultLengthUnitSymbol: string;

  constructor(
    private sketchUp: SketchupService,
    private unitConversion: UnitConversionService
  ) {
    this.defaultLengthUnit = this.unitConversion.getUnitsFromType('length');
    this.defaultLengthUnitSymbol = this.unitConversion.getUnitSymbolFromUnits(
      this.defaultLengthUnit
    );

    this.load();
  }

  async load() {
    let toolset = await this.sketchUp.localStorageGet(this.FABRIC8_TOOLSET_KEY);
    this.toolGroup = toolset || defaultFabric8ToolSetGroups;

    await this.save();
  }

  async save() {
    await this.sketchUp.localStorageSet(
      this.FABRIC8_TOOLSET_KEY,
      this.toolGroup
    );
    this.toolsetSubject.next(this.toolGroup);
  }

  getToolsetOfType(type: Fabric8ToolType): Fabric8ToolGroup {
    return this.toolGroup.find((toolGroup) => toolGroup.type === type);
  }

  async updateToolGroup(toolGroupIndex: number, toolGroup: Fabric8ToolGroup) {
    this.toolGroup[toolGroupIndex] = toolGroup;
    await this.save();
  }

  async addTool(toolGroupIndex: number): Promise<void> {
    const defaultToolUnit =
      this.unitConversion.getUnitSymbolFromUnits('DEFAULT');
    const toolSetGroupFound = this.checkToolSetGroup(toolGroupIndex);

    if (toolSetGroupFound) {
      const fabric8ToolType: Fabric8ToolType =
        this.toolGroup[toolGroupIndex].type;
      const toolGroupName: string = this.toolGroup[toolGroupIndex].name;

      let newToolSet: Fabric8Tool = {
        name: '',
        // type: fabric8Type,
        depth: this.defaultDepth,
        tag: '',
        type: fabric8ToolType,
        radius: 1 / 2,
        enabled: false,
      };

      this.toolGroup[toolGroupIndex].tools.push(newToolSet);
      await this.save();
    }
  }

  async deleteTool(toolGroupIndex: number, toolsIndex: number): Promise<void> {
    const toolSetGroupFound = this.checkToolSetGroup(
      toolGroupIndex,
      toolsIndex
    );

    if (toolSetGroupFound) {
      this.toolGroup[toolGroupIndex].tools.splice(toolsIndex, 1);
    }

    await this.save();
  }

  private checkToolSetGroup(
    toolGroupIndex: number,
    toolsIndex: number = -1
  ): boolean {
    const toolGroup = this.toolGroup[toolGroupIndex];
    const tool = toolsIndex == -1 ?? toolGroup.tools[toolsIndex];
    return toolGroup !== undefined && tool != undefined;
  }

  public toolUnitConversion(value: string): string {
    const dataAttr: DataAttribute = {
      name: '',
      value: value,
      value_raw: undefined,
      units: this.defaultLengthUnit,
      type: 'length',
      list: null,
      tag: '',
      access: null,
      options: null,
      errors: [],
    };
    const rawValue = this.unitConversion.rawValueFromInput(dataAttr);

    return this.unitConversion.valueFromRawValueAndUnit({
      ...dataAttr,
      ...{ value_raw: rawValue },
    });
  }
}
