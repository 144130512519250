import { Injectable } from '@angular/core';
import { Pt } from 'pts';
import { BehaviorSubject } from 'rxjs';
import { Fabric8Element } from '../models/element.model';
import { Fabric8NodeType, getNodeType } from '../models/Fabric8NodeType';
import { Fabric8Node } from '../models/node.model';
import { Fabric8NodeData } from '../models/types/Fabric8NodeData.type';
import { CurrentObjectService } from './current-object.service';
import { newNodeWithData } from './nodes/newNodeWithData';
import { Fabric8NodeDataPointIndex } from './toolHandlers/models/Fabric8NodeDataPointIndex.model';

@Injectable({
  providedIn: 'root',
})
export class ElementsService {
  currentNodeDataPointIndex$ = new BehaviorSubject<Fabric8NodeDataPointIndex>({
    id: '',
    pointIndex: -1,
    type: Fabric8NodeType.Element,
  });

  constructor(private currentObject: CurrentObjectService) {}

  getCurrentNodeDataPointIndex() {
    return this.currentNodeDataPointIndex$.getValue();
  }
  setCurrentNodeDataPointIndex(index: Fabric8NodeDataPointIndex) {
    this.currentNodeDataPointIndex$.next(index);
  }
  unsetCurrentNodeDataPointIndex() {
    this.setCurrentNodeDataPointIndex({
      id: '',
      pointIndex: -1,
      type: Fabric8NodeType.Element,
    });
  }

  getCurrentNode(): Fabric8Node {
    const id = this.getCurrentNodeDataPointIndex()?.id;
    const { node } = this.currentObject.getElementDataWithId(id);
    return node;
  }

  canEditPoints(data: Fabric8NodeData): boolean {
    var nodeType = getNodeType(data);
    if (
      nodeType == Fabric8NodeType.Element ||
      nodeType == Fabric8NodeType.GuidelineSegment
    ) {
      return true;
    } else {
      return false;
    }
  }

  addNewNodeWithData(data: Fabric8NodeData): Fabric8Node {
    const newNode = this.currentObject.addNewNodeWithData(data);

    var nodeType = getNodeType(data);

    // we're only selecting elements or guideline segments
    if (this.canEditPoints(data)) {
      // select the new element
      this.setCurrentNodeDataPointIndex({
        id: newNode.id,
        pointIndex: data.points.length - 1,
        type: nodeType,
      });
    }

    return newNode;
  }

  getCurrentNodeData(): Fabric8NodeData {
    const idx = this.getCurrentNodeDataPointIndex();
    if (!idx) return;
    const { data } = this.currentObject.getNodeDataWithId(idx.id);
    return data;
  }

  commitCurrentNodeData() {
    const idx = this.getCurrentNodeDataPointIndex();
    const { data } = this.currentObject.getNodeDataWithId(idx.id);
    if (!data) return;

    this.unsetCurrentNodeDataPointIndex();
    this.currentObject.update(true);
  }

  // Points

  addAndSelectPoint(point: Pt, node: Fabric8Node): number {
    const data = node.data;
    if (!data) return;
    if (!this.canEditPoints(data)) return;

    data.points.push(point);

    this.setCurrentNodeDataPointIndex({
      id: node.id,
      pointIndex: data.points.length - 1,
      type: getNodeType(data),
    });

    return data.points.length;
  }

  updateCurrentPoint(point: Pt) {
    const data = this.getCurrentNodeData();
    if (!data) return;
    if (!this.canEditPoints(data)) return;

    const idx = this.getCurrentNodeDataPointIndex();
    data.points[idx.pointIndex] = point;

    this.updateCurrentPointClosedPath(point, data, idx);
  }

  private updateCurrentPointClosedPath(
    point: Pt,
    data: Fabric8NodeData,
    idx: Fabric8NodeDataPointIndex
  ) {
    if (getNodeType(data) !== Fabric8NodeType.Element) return;
    const dataElement = data as Fabric8Element;
    if (!dataElement.isClosedPath) return;

    //if we're updating the first point, update the last point too
    if (idx.pointIndex == 0) {
      dataElement.points[dataElement.points.length - 1] = point;
    }
    // if we're updating the last point, update the first point too
    if (idx.pointIndex == dataElement.points.length - 1) {
      dataElement.points[0] = point;
    }
  }

  // returns true if this was the last point and the current node was deleted
  deleteCurrentPoint(): boolean {
    const data = this.getCurrentNodeData();
    if (!data) return false;
    if (!this.canEditPoints(data)) return false;

    if (data.points.length == 1) {
      this.deleteCurrentNode();
      return true;
    }

    const idx = this.getCurrentNodeDataPointIndex();
    this.deleteClosedPathPoints(data, idx);

    data.points.splice(idx.pointIndex, 1);

    this.setCurrentNodeDataPointIndex({
      id: idx.id,
      pointIndex: idx.pointIndex - 1 < 0 ? 0 : idx.pointIndex - 1,
      type: getNodeType(data),
    });

    this.currentObject.update(true);

    return false;
  }

  private deleteCurrentNode() {
    const obj = this.currentObject.get();
    if (!obj) return;

    const idx = this.getCurrentNodeDataPointIndex();
    const currentNodeDataID = idx.id;

    // remove the element from the object
    this.currentObject.deleteNodeById(currentNodeDataID);

    this.currentObject.update(true);
    this.unsetCurrentNodeDataPointIndex();
  }

  private deleteClosedPathPoints(
    data: Fabric8NodeData,
    idx: Fabric8NodeDataPointIndex
  ) {
    if (getNodeType(data) !== Fabric8NodeType.Element) return;
    const dataElement = data as Fabric8Element;
    if (dataElement.isClosedPath) {
      // if the number of points is less than 4,
      if (dataElement.points.length <= 3) {
        dataElement.isClosedPath = false;
      }

      // deleting the first or last point will set closedPath to false
      if (
        idx.pointIndex == 0 ||
        idx.pointIndex == dataElement.points.length - 1
      ) {
        dataElement.isClosedPath = false;
      }
    }
  }

  getCurrentElement(): Fabric8Element {
    const idx = this.getCurrentNodeDataPointIndex();
    if (!idx) return;
    const { element } = this.currentObject.getElementDataWithId(idx.id);
    return element;
  }

  // only used by the router tool
  joinElementPointsToCurrentElement(
    targetNode: Fabric8Node,
    reverseSourcePoints: Boolean,
    reverseTargetPoints: Boolean
  ) {
    const element = this.getCurrentElement();
    if (!element) {
      console.log('missing element');
      return;
    }

    const idx = this.getCurrentNodeDataPointIndex();

    const targetNodeID = targetNode.id;
    const targetElement = targetNode.data as Fabric8Element;

    if (reverseSourcePoints) {
      element.points.reverse();
    }

    const newPoints = targetElement.points;
    if (reverseTargetPoints) {
      newPoints.reverse();
    }

    element.points = element.points.concat(newPoints);
    // remove consecutive points that are the same
    element.points = element.points.filter((point, index, array) => {
      if (index == 0) {
        return true;
      }
      return !point.equals(array[index - 1]);
    });

    this.currentObject.deleteNodeById(targetNodeID);

    this.setCurrentNodeDataPointIndex({
      id: idx.id,
      pointIndex: element.points.length - 1,
      type: getNodeType(element),
    });

    this.currentObject.update(true);
  }
}
