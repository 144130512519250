import { EventEmitter, Input } from '@angular/core';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { SketchupService } from 'src/app/services/sketchup.service';
import { DragService } from './drag-service.service';
import { TreeGroupService } from './tree-group.service';
import {
  wDragDropListEvent,
  wTreeListSource,
  wTreeNodeDragEvent,
} from './tree-node.model';

@Directive({
  selector: '[swDrag]',
})
export class DragDirective implements OnDestroy, OnInit, AfterViewInit {
  element: ElementRef;
  handle: HTMLElement;
  events = new Array<any>();

  @Input() wDragNode: any;
  // TODO: get parent node and list from parent wTreeList
  @Input() wDragParent: any;
  @Input() wDragParentList: Array<any>;
  @Input() wDragObjectType: string;
  @Input() wDragDisabled: boolean = false;

  @Output() wDragStarted: EventEmitter<wTreeNodeDragEvent> = new EventEmitter();
  @Output() wDragReleased: EventEmitter<wTreeNodeDragEvent> =
    new EventEmitter();
  @Output() wDrop: EventEmitter<wDragDropListEvent> = new EventEmitter();

  constructor(
    private treeGroupService: TreeGroupService,
    private el: ElementRef,
    private renderer: Renderer2,
    private _ngZone: NgZone,
    private dragService: DragService,
    private sketchUp: SketchupService
  ) {}

  ngOnInit(): void {
    this.element = this.el;
    if (this.wDragObjectType === undefined) {
      console.error('set wDragNodeType on swDrag instances');
    }
  }

  ngAfterViewInit(): void {
    if (this.wDragDisabled) {
      return;
    }
    this.setDraggable();
    this.createEvents();
  }

  ngOnDestroy(): void {
    this.events.forEach((e) => e());
    this.events = [];
  }

  private setDraggable(): void {
    this.renderer.setAttribute(
      this.element.nativeElement,
      'draggable',
      'false'
    );
    this.handle =
      this.element.nativeElement.querySelector('[swDragHandle]') ??
      this.element.nativeElement;
    this.renderer.setAttribute(this.handle, 'draggable', 'true');
  }

  private createEvents(): void {
    // ondragover must be attached and ran outside angular
    this._ngZone.runOutsideAngular(() => {
      // this.addEvent('mousemove', (e: DragEvent) => {
      //   // let cancelEvent = new PointerEvent('pointercancel')
      //   // this.handle.dispatchEvent(cancelEvent)
      //   return false
      // })
      // this.addEvent('dragstart', this.dragStart.bind(this))
      // this.addEvent('dragend', this.dragEnd.bind(this))
    });

    // this.addEvent('contextmenu', this.enableGardN8Tool.bind(this))
    this.addEvent('dragstart', this.dragStart.bind(this));
    this.addEvent('dragend', this.dragEnd.bind(this));
  }

  private addEvent(name: string, callback: (event: any) => boolean | void) {
    const e = this.renderer.listen(this.handle, name, callback);
    this.events.push(e);
  }

  private enableGardN8Tool(e: MouseEvent) {
    this.sketchUp.bridge.call('activateGardN8PlantTool', this.wDragNode);
    e.preventDefault();
    return false;
  }

  private dragStart(e: DragEvent) {
    e.dataTransfer.setDragImage(this.treeGroupService.dragImage, 20, 10);
    e.dataTransfer.dropEffect = 'move';
    this.renderer.addClass(this.element.nativeElement, 'w-drag-dragging');

    const container: wTreeListSource = {
      source: this.wDragParent,
      sourceList: this.wDragParentList,
      sourceObjectType: this.wDragObjectType,
    };

    const event = new wTreeNodeDragEvent(container, this.wDragNode, this);

    this.treeGroupService.startDragging(event);
    this.wDragStarted.emit(event);

    // this.dragService.nodeDragStarted(this.wDragNode)
    // this.dragService.setDataOn(e.dataTransfer)
  }

  private dragEnd(e: DragEvent) {
    // console.warn('dragEnd - in swDrag');
    // this.dragService.nodeDragEnded(e)

    this.wDragReleased.emit();
    this.treeGroupService.endDragging(this);
    this.renderer.removeClass(this.element.nativeElement, 'w-drag-dragging');
  }
}
