import { Component, Input, OnInit } from '@angular/core';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { Fabric8GuidelineSegment } from '../../models/guideline-segment.model';
import { Fabric8Node } from '../../models/node.model';
import {
  Fabric8SmartObjectType,
  Fabric8DistributedHolesParameters,
  Fabric8EquallySpacedHolesParameters,
} from '../../models/smartgroup.model';
import { CurrentObjectService } from '../../services/current-object.service';
import { updateChildrenNodesForGuidelineSegment } from '../../services/smartObject/updateChildrenNodesForGuidelineSegment';

@Component({
  selector: 'sw-fabric8-guideline-segment-attributes',
  templateUrl: './fabric8-guideline-segment-attributes.component.html',
  styleUrls: ['./fabric8-guideline-segment-attributes.component.scss'],
})
export class Fabric8GuidelineSegmentAttributesComponent implements OnInit {
  constructor(private currentObject: CurrentObjectService) {}
  @Input() parent: Fabric8Node;
  @Input() indentLevel = 0;
  guideline: Fabric8GuidelineSegment;

  ngOnInit(): void {
    this.guideline = this.parent?.data as Fabric8GuidelineSegment;
  }

  saveAttributes(attrs: DataAttribute[]): void {
    const data = this.guideline.smartObjectData;
    if (!data) return;

    if (data.type == Fabric8SmartObjectType.distributedHoles) {
      const params = data.parameters as Fabric8DistributedHolesParameters;
      const countAttr = attrs.find((a) => a.tag == 'count');
      if (countAttr) {
        params.count = parseInt(countAttr.value);
      }
    }

    if (data.type == Fabric8SmartObjectType.equallySpacedHoles) {
      const params = data.parameters as Fabric8EquallySpacedHolesParameters;
      const distanceAttr = attrs.find((a) => a.tag == 'distance');
      if (distanceAttr) {
        params.distance = parseFloat(distanceAttr.value);
      }
    }
    updateChildrenNodesForGuidelineSegment(this.parent);
    this.currentObject.update(true);
  }

  hasGuidelineSegmentSmartObjectData(): boolean {
    const data = this.parent.data as unknown as Fabric8GuidelineSegment;
    return data.smartObjectData !== undefined;
  }
}
