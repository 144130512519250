import { NgModule } from '@angular/core';
import { SWMaterialsListComponent } from './materials-list/materials-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AttributesModule } from '../attributes/attributes.module';
import { UserPresenceModule } from '../user-presence-module/user-presence.module';

const components = [SWMaterialsListComponent];

@NgModule({
  declarations: [...components],
  imports: [
    SharedModule,
    UserPresenceModule,
    TranslateModule.forChild(),
    AttributesModule,
  ],
  exports: [...components],
})
export class MaterialsModule {}
