import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { SelectDataNodeMessage, SelectProjectMessage } from 'src/app/services/liveupdates/messages';

export interface UserPresenceItem {
  clientID: string
  email: string
  shortName: string
  color: string
  selectedDataNodeID?: string
  selectedProjectID?: string
  lastseen: number
}

const OFFLINE_DELTA_TIME = 10000; //10 * 1000 (10seconds)

@Injectable({
  providedIn: 'root'
})
export class UserPresenceService implements OnDestroy {

  //              clientID, item
  activeUsers: Map<string, UserPresenceItem> = new Map()

  //               dataNodeID, userPresenceItem[]
  public dataNodeUsers: Map<string, UserPresenceItem[]> = new Map()

  //               projectID, userPresenceItem[]
  public projectUsers: Map<string, UserPresenceItem[]> = new Map()

  usersDataNodeSelectionUpdatedEvent: EventEmitter<string> = new EventEmitter()
  usersProjectSelectionUpdatedEvent: EventEmitter<string> = new EventEmitter()
  activeUsersUpdatedEvent: EventEmitter<UserPresenceItem[]> = new EventEmitter()

  cleanupInterval: any
  constructor() { 
    this.cleanupInterval = setInterval(() => {
      this.cleanupInactiveClients()
    }, 1000)
  }

  ngOnDestroy(): void {
    this.activeUsers.clear()
    this.dataNodeUsers.clear()
    this.projectUsers.clear()
    clearInterval(this.cleanupInterval)
  }

  private addOrUpdateClient(clientID: string): UserPresenceItem {
    var userPresenceItem = this.activeUsers.get(clientID)
    if (userPresenceItem == undefined) {      
      const email = clientID.split(":")[0] 
      userPresenceItem = {
        clientID: clientID,
        email: email,
        shortName: this.shortName(email),
        color: this.getAvailableColor(),
        lastseen: new Date().getTime()
      }      
      this.activeUsers.set(clientID, userPresenceItem)
      this.activeUsersUpdatedEvent.next(Array.from(this.activeUsers.values()))
    }

    // update lastseen time
    userPresenceItem.lastseen = new Date().getTime()
    return userPresenceItem
  }

  private removeUserPresenceItemWithClientID(clientID: string, array: UserPresenceItem[]): UserPresenceItem[] {
    return array.filter(upi => upi.clientID != clientID)
  }

  processDataNodeSelectionSignal(message: SelectDataNodeMessage) {
    const dataNodeID = message.nodeID
    const clientID = message.clientID

    var userPresenceItem = this.addOrUpdateClient(clientID)

    // is the datanode already selected by this client?
    if (dataNodeID == userPresenceItem.selectedDataNodeID) {
      return
    }

    // remove from previous datanodeUsers array
    var prevDataNodeID = userPresenceItem.selectedDataNodeID
    if (prevDataNodeID != undefined) {
      var previousDataNodeUsers = this.dataNodeUsers.get(String(prevDataNodeID))
      previousDataNodeUsers = this.removeUserPresenceItemWithClientID(clientID, previousDataNodeUsers)
      if (previousDataNodeUsers.length == 0) {
        this.dataNodeUsers.delete(prevDataNodeID)
      } else {
        this.dataNodeUsers.set(prevDataNodeID, previousDataNodeUsers)
      }
      this.usersDataNodeSelectionUpdatedEvent.next(userPresenceItem.selectedDataNodeID)
    }

    // add to datanode users array
    if (dataNodeID != undefined) {
      var currentDataNodeUsers = this.dataNodeUsers.get(String(dataNodeID))
      if (currentDataNodeUsers == undefined) {
        this.dataNodeUsers.set(dataNodeID, [userPresenceItem])
      } else {
        currentDataNodeUsers.push(userPresenceItem)
        this.dataNodeUsers.set(dataNodeID, currentDataNodeUsers)
      }
      // this.usersDataNodeSelectionUpdatedEvent.next(dataNodeID)
    }

    // set the new selected datanode
    userPresenceItem.selectedDataNodeID = dataNodeID
    this.usersDataNodeSelectionUpdatedEvent.next(dataNodeID)
  }

  processGroupPresence(message: SelectProjectMessage) {
    const projectID = message.projectID
    const clientID = message.clientID

    var userPresenceItem = this.addOrUpdateClient(clientID)

    // is the project already selected by this client?
    if (projectID == userPresenceItem.selectedProjectID) {
      return
    }

    // remove from previous projectUsers array
    var prevProjectID = userPresenceItem.selectedProjectID
    if (prevProjectID != undefined) {
      var prevProjectUsers = this.projectUsers.get(String(prevProjectID))
      prevProjectUsers = this.removeUserPresenceItemWithClientID(clientID, prevProjectUsers)
      if (prevProjectUsers.length == 0) {
        this.projectUsers.delete(prevProjectID)
      } else {
        this.projectUsers.set(prevProjectID, prevProjectUsers)
      }
      this.usersProjectSelectionUpdatedEvent.next(userPresenceItem.selectedProjectID)
    }

    // add to projectUsers array
    if (projectID != undefined) {
      var currentProjectUsers = this.projectUsers.get(String(projectID))
      if (currentProjectUsers == undefined) {
        this.projectUsers.set(projectID, [userPresenceItem])
      } else {
        currentProjectUsers.push(userPresenceItem)
        this.projectUsers.set(projectID, currentProjectUsers)
      }
      // this.usersProjectSelectionUpdatedEvent.next(projectID)
    }

    userPresenceItem.selectedProjectID = projectID
    this.usersProjectSelectionUpdatedEvent.next(projectID)
  }

  cleanupInactiveClients() {
    var toDelete = []
    var now = new Date().getTime()
    this.activeUsers.forEach((upi: UserPresenceItem, clientID: string) => {
      const delta = now - upi.lastseen;
      if (delta > OFFLINE_DELTA_TIME) {
        toDelete.push(upi)
      }
    })

    toDelete.forEach(upi => {
      //clean up client from activeUsers
      this.activeUsers.delete(upi.clientID)

      if (upi.selectedDataNodeID) {
        //clean up client from dataNodeUsers
        var dnusers = this.dataNodeUsers.get(String(upi.selectedDataNodeID))
        dnusers = this.removeUserPresenceItemWithClientID(upi.clientID, dnusers)
        this.dataNodeUsers.set(upi.selectedDataNodeID, dnusers)

        this.usersDataNodeSelectionUpdatedEvent.next(upi.selectedDataNodeID)
      }

      if (upi.selectedProjectID) {
        //clean up client from projectUsers
        var projectUsers = this.projectUsers.get(String(upi.selectedProjectID))
        projectUsers = this.removeUserPresenceItemWithClientID(upi.clientID, projectUsers)
        this.projectUsers.set(upi.selectedProjectID, projectUsers)        

        this.usersProjectSelectionUpdatedEvent.next(upi.selectedProjectID)
      }

    })


    if (toDelete.length > 0) {
      console.log('deletedClient')
      this.usersDataNodeSelectionUpdatedEvent.next("USERS")
    }

  }

  private shortName(name:string): string {
    return name.split("@")[0]
          .split(".")
          .map(n => n.substring(0,1))
          .join()
          .toUpperCase()
  }

  // user colors
  userColors: string[] = ["#dc1916", "#b91411", "#e67a80", "#373655",
                               "#49b18d", "#47cabe", "#3bb4b0", "#40a5af",
                               "#8ba7bf", "#54698e", "#ba96ae", "#898cb6",
                               "#4148a5", "#4e4181", "#873f70", "#bd4264",
                               "#5d5855", "#52555d", "#7f8284"]
  availableColors: Set<string> = new Set()

  private getAvailableColor(): string {
    if (this.availableColors.size == 0) {
      this.availableColors = new Set(this.userColors)
    }

    const colors = Array.from(this.availableColors)
    var color = colors[Math.floor(Math.random()* colors.length)];
    this.availableColors.delete(color)

    return color
  }
}
