import { Injectable } from '@angular/core';
import { DataAttribute } from '../../../model/data-attribute.model';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class UnitConversionService {
  constructor(private projectService: ProjectService) {}

  /*
  Sketchup units:        SW unit types

    DOLLARS	              currency
    YEN	
    EUROS	    

    DEFAULT               length
    INCHES	
    FEET	
    YARDS	
    METERS							
    MILLIMETERS	
    CENTIMETERS							

    INTEGER	              int
    FLOAT	                float
    PERCENT	              percent
    DEGREES	              degrees

    BOOLEAN	              bool
    STRING	              string

    KILOGRAMS   kg           weight
    POUNDS      lbs

  */

  extractUnitFromString(input: string) {
    if (input.startsWith('¥')) return 'YEN';
    if (input.startsWith('€')) return 'EUROS';
    if (input.startsWith('$')) return 'DOLLARS';

    if (input.toLowerCase().endsWith('cm')) return 'CENTIMETERS';
    if (input.toLowerCase().endsWith('mm')) return 'MILLIMETERS';
    if (input.toLowerCase().endsWith('m')) return 'METERS';
    if (input.toLowerCase().endsWith('"') || input.endsWith('in'))
      return 'INCHES';
    if (input.toLowerCase().endsWith("'")) return 'FEET';
    if (input.toLowerCase().endsWith('yd')) return 'YARDS';

    if (input.toLowerCase().endsWith('kg')) return 'KILOGRAMS';
    if (input.toLowerCase().endsWith('lbs')) return 'POUNDS';

    if (input.endsWith('%')) return 'PERCENT';
    if (input.endsWith('°') || input.toLowerCase().endsWith('deg'))
      return 'DEGREES';

    return false;
  }

  rawValueFromInput(attribute: DataAttribute): string {
    if (attribute.units == 'STRING') return attribute.value;

    //cleanup
    var inputValue = attribute.value.toLowerCase();
    var inputUnit =
      this.extractUnitFromString(inputValue) || attribute.units || 'DEFAULT';
    var inputType = this.getTypeFromUnits(inputUnit);

    var floatInput = parseFloat(inputValue) || 0;

    // extract raw value
    if (attribute.type == 'currency') {
      floatInput =
        parseFloat(
          inputValue.replace('¥', '').replace('€', '').replace('$', '')
        ) || 0;
      return String(floatInput);
    }
    if (attribute.units == 'INTEGER') return String(parseInt(inputValue) || 0);
    if (attribute.units == 'FLOAT') return String(floatInput);
    if (attribute.units == 'PERCENT') return String(floatInput / 100);
    if (attribute.units == 'DEGREES') return String(floatInput);

    if (attribute.units == 'BOOLEAN')
      return floatInput > 0 || inputValue == 'true' ? '1' : '0';

    if (attribute.type == 'weight') {
      if (inputUnit == 'DEFAULT')
        inputUnit = this.projectService.getDefaultWeightUnit();

      if (inputUnit == 'POUNDS') return String(floatInput);
      if (inputUnit == 'KILOGRAMS') return String(floatInput / 0.45359237);
      //other input types
      return String(floatInput);
    }

    if (attribute.type == 'length') {
      if (inputUnit == 'DEFAULT')
        inputUnit = this.projectService.getDefaultLengthUnit();

      switch (inputUnit) {
        case 'MILLIMETERS':
          return String(floatInput / 25.4);
        case 'CENTIMETERS':
          return String(floatInput / 2.54);
        case 'METERS':
          return String(floatInput / 0.0254);
        case 'INCHES':
          return String(floatInput);
        case 'FEET':
          return String(floatInput * 12);
        case 'YARDS':
          return String(floatInput * 36);
      }

      return inputValue;
    }
  }

  valueFromRawValueAndUnit(attribute: DataAttribute): string {
    // used for rendering the attribute
    var rawValue = attribute.value_raw;
    var units = attribute.units;

    if (units == 'DEFAULT') units = this.projectService.getDefaultLengthUnit();

    var parsedVal = parseFloat(rawValue) || 0;

    switch (units) {
      case 'STRING':
        return rawValue;

      case 'DOLLARS':
        return '$' + parsedVal.toFixed(2);
      case 'YEN':
        return '¥' + parsedVal.toFixed(2);
      case 'EUROS':
        return '€' + parsedVal.toFixed(2);

      case 'MILLIMETERS':
        return +(parsedVal * 25.4).toFixed(3) + ' mm';
      case 'CENTIMETERS':
        return +(parsedVal * 2.54).toFixed(3) + ' cm';
      case 'METERS':
        return +(parsedVal * 0.0254).toFixed(3) + ' m';
      case 'INCHES':
        return +parsedVal.toFixed(3) + '"';
      case 'FEET':
        return +(parsedVal / 12).toFixed(3) + "'";
      case 'YARDS':
        return +(parsedVal / 36).toFixed(3) + ' yd';

      case 'INTEGER':
        return String(parseInt(rawValue, 10));
      case 'FLOAT':
        return +parsedVal.toFixed(3) + '';
      case 'PERCENT':
        return +(parsedVal * 100).toFixed(3) + '%';
      case 'DEGREES':
        return +parsedVal.toFixed(3) + '°';
      case 'BOOLEAN':
        return parsedVal > 0 ? 'TRUE' : 'FALSE';

      case 'KILOGRAMS':
        return +(parsedVal * 0.45359237).toFixed(3) + ' kg';
      case 'POUNDS':
        return +parsedVal.toFixed(3) + ' lbs';
    }

    return rawValue;
  }

  getUnitsFromType(type: string): string {
    switch (type) {
      case 'dropdown':
        return 'STRING';
      case 'length':
        return this.projectService.getDefaultLengthUnit();
      case 'int':
        return 'INTEGER';
      case 'float':
        return 'FLOAT';
      case 'percent':
        return 'PERCENT';
      case 'degrees':
        return 'DEGREES';
      case 'bool':
        return 'BOOLEAN';
      case 'string':
        return 'STRING';
      case 'currency':
        return 'EUROS';
      case 'weight':
        return 'KILOGRAMS';
      case 'material':
        return 'STRING';
      default:
        return 'STRING';
    }
  }

  getTypeFromUnits(units: string): string {
    switch (units) {
      case 'DEFAULT':
      case 'MILLIMETERS':
      case 'CENTIMETERS':
      case 'METERS':
      case 'INCHES':
      case 'FEET':
      case 'YARDS':
        return 'length';

      case 'INTEGER':
        return 'int';
      case 'FLOAT':
        return 'float';
      case 'PERCENT':
        return 'percent';
      case 'DEGREES':
        return 'degrees';
      case 'BOOLEAN':
        return 'bool';
      case 'STRING':
        return 'text';

      case 'DOLLARS':
      case 'YEN':
      case 'EUROS':
        return 'currency';

      case 'KILOGRAMS':
      case 'POUNDS':
        return 'weight';
    }

    return 'text';
  }

  getUnitSymbolFromUnits(units: string): string {
    switch (units) {
      case 'DEFAULT':
        return this.getUnitSymbolFromUnits(
          this.projectService.getDefaultLengthUnit()
        );
      case 'MILLIMETERS':
        return 'mm';
      case 'CENTIMETERS':
        return 'cm';
      case 'METERS':
        return 'm';
      case 'INCHES':
        return '"';
      case 'FEET':
        return "'";
      case 'YARDS':
        return 'yd';

      case 'PERCENT':
        return '%';
      case 'DEGREES':
        return '°';

      case 'DOLLARS':
        return '$';
      case 'YEN':
        return '¥';
      case 'EUROS':
        return '€';

      case 'KILOGRAMS':
        return 'kg';
      case 'POUNDS':
        return 'lbs';
    }

    return '';
  }
}
