import { Injectable, OnDestroy } from '@angular/core';
import { DragService } from './drag-service.service';
import { DragDirective } from './drag.directive';
import { DropzoneDirective } from './dropzone.directive';
import {
  wDragDropListEvent,
  wTreeListSource,
  wTreeListTarget,
  wTreeNodeDragEvent,
} from './tree-node.model';

@Injectable({
  providedIn: 'root',
})

// One instance per tree
// Coordinates enabling the dropzones for the tree when the drag starts
export class TreeGroupService {
  private allZones = new Array<DropzoneDirective>();
  //  private observer: IntersectionObserver | undefined
  //  private thresholds = [0.1]

  id: string;
  dragImage = new Image();
  dragEvent: wTreeNodeDragEvent;

  constructor(private dragService: DragService) {}

  setDragImage(url: string) {
    this.dragImage.src = url;
  }

  register(dropzone: DropzoneDirective) {
    const index: number = this.allZones.indexOf(dropzone);
    if (index === -1) {
      this.allZones.push(dropzone);
    }
  }

  unregister(dropzone: DropzoneDirective) {
    const index: number = this.allZones.indexOf(dropzone);
    if (index !== -1) {
      this.allZones.splice(index, 1);
    }
  }

  startDragging(event: wTreeNodeDragEvent) {
    // console.log('Dropzones.SERVICE: startDragging', event)
    this.dragEvent = event;
    this.enableDropZones();
  }

  endDragging(drag: DragDirective) {
    // console.log('Dropzones.SERVICE: endDrag')
    this.disableDropZones();
    this.dragEvent = undefined;
  }

  private enableDropZones() {
    // enable dropzones on next tick
    setTimeout(() => {
      this.allZones.forEach((dropzone) => {
        // enable only the dropzones that accept the dragged type
        dropzone.enablePointerEvents(this.dragEvent.wDragRef.wDragObjectType);
      });
    }, 0);
  }

  private disableDropZones() {
    // disable dropzones on next tick
    setTimeout(() => {
      this.allZones.forEach((dropzone) => {
        dropzone.disablePointerEvents();
      });
    }, 0);
  }

  drop(dropzoneRef: DropzoneDirective) {
    // dropped on the tree
    // console.warn("DROP tree-group service")
    // this.dragService.nodeDragCancel()

    const draggedNode = this.dragEvent.node;

    const previousParent: wTreeListSource = this.dragEvent.container;
    const newParent: wTreeListTarget = {
      target: dropzoneRef.wDropTarget,
      targetList: dropzoneRef.wDropTargetList,
      targetAcceptType: dropzoneRef.wDropAcceptType,
    };

    if (newParent.targetList === undefined) {
      console.error('make sure the children array exists on the drop target');
    }

    const previousIndex = previousParent.sourceList.indexOf(draggedNode);

    const dropEvent: wDragDropListEvent = {
      to: newParent,
      from: previousParent,
      toIndex: dropzoneRef.wDropTargetListIndex,
      fromIndex: previousIndex,
    };

    this.fixIndex(dropEvent);

    // fire the event on the dragged wDragNode
    this.dragEvent.wDragRef.wDrop.emit(dropEvent);
    this.dragEvent = undefined;
    this.disableDropZones();
  }

  private fixIndex(event: wDragDropListEvent) {
    if (event.from.sourceList === event.to.targetList) {
      if (event.toIndex > event.fromIndex) {
        event.toIndex -= 1;
      }
    }
  }
}
