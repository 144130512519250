import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { Fabric8GuidelineSegment } from '../../models/guideline-segment.model';
import { Fabric8Node } from '../../models/node.model';
import { CurrentObjectService } from '../../services/current-object.service';
import { ToolService } from '../../services/tool.service';

@Component({
  selector: 'sw-fabric8-guideline-segment-row',
  templateUrl: './fabric8-guideline-segment-row.component.html',
  styleUrls: ['../css/elements.scss', '../fabric8-elements.component.scss'],
})
export class Fabric8GuidelineSegmentRowComponent implements OnInit {
  constructor(public toolService: ToolService) {}
  @Input() parent: Fabric8Node;
  guideline: Fabric8GuidelineSegment;

  ngOnInit(): void {
    this.guideline = this.parent?.data as Fabric8GuidelineSegment;
  }
}
