import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Fabric8Element } from '../models/element.model';
import { Fabric8Node } from '../models/node.model';
import { Fabric8NodeType } from '../models/Fabric8NodeType';
import { Fabric8Object } from '../models/object.model';
import { Fabric8SketchupService } from './fabric8-sketchup.service';
import { getNodesFlatList } from './nodes/getNodesFlatList';

@Injectable({
  providedIn: 'root',
})
export class ObjectsManagerService implements OnDestroy {
  constructor(private sketchupHelper: Fabric8SketchupService) {
    this.subscribeToSketchupUserSelection();
  }

  currentObjectId: BehaviorSubject<string> = new BehaviorSubject(undefined);
  objects$: BehaviorSubject<Fabric8Object[]> = new BehaviorSubject(undefined);

  currentObjectNodes = new BehaviorSubject<Fabric8Node[]>([]);

  userSelectionSubscription: Subscription;

  subscribeToSketchupUserSelection() {
    this.userSelectionSubscription =
      this.sketchupHelper.userSelection.subscribe((selection) => {
        console.log('userSelection', selection);
        if (selection?.length) {
          this.currentObjectId.next(selection[0]);
        }
      });
  }

  ngOnDestroy(): void {
    this.userSelectionSubscription.unsubscribe();
  }

  getObjects(): Fabric8Object[] {
    return this.objects$.getValue();
  }

  getObjectWithId(id: string): Fabric8Object {
    return this.getObjects()?.find((object) => object.guid == id);
  }

  setObjects(objects: Fabric8Object[]) {
    this.objects$.next(objects);

    const currentObjectIDExists = objects.some(
      (object) => object.guid == this.currentObjectId.getValue()
    );

    if (!objects || !currentObjectIDExists) {
      this.currentObjectId.next(undefined);
    }
  }

  updateObject(object: Fabric8Object) {
    const objects = this.getObjects();
    // console.log(object)
    const index = objects.findIndex((obj) => obj.guid == object.guid);
    if (index > -1) {
      objects[index] = object;
      this.setObjects(objects);
    }

    this.currentObjectId.next(object.guid);
  }

  uploadObject(object: Fabric8Object) {
    //TODO: convert object to nodes+attributes
    // call API
  }

  async loadObjects() {
    //TODO: get the nodes and convert them to Fabric8Objects
    const objects = await this.sketchupHelper.loadSUObjects();
    // console.log(JSON.stringify(objects, null, 2))
    this.setObjects(objects);
  }

  async importSelection(): Promise<string> {
    //TODO: import via live updates
    const importedGuid = await this.sketchupHelper.addSelectedObjectToFabric8();
    await this.loadObjects();
    return importedGuid;
  }

  async deleteObject(object: Fabric8Object) {
    const guid = object.guid;
    await this.removeFabric8Data(guid);

    const objects = this.getObjects();
    const index = objects.findIndex((obj) => obj.guid == object.guid);
    if (index > -1) {
      objects.splice(index, 1);
      this.setObjects(objects);
      const nextObjectIndex = index > 0 ? index - 1 : 0;
      const nextObject = objects.find((obj, i) => i == nextObjectIndex);
      this.currentObjectId.next(nextObject?.guid);
    }
  }

  async removeFabric8Data(guid: string) {
    await this.sketchupHelper.removeFabric8Data(guid);
  }

  // returns a flat list of all the elements in the object
  // used by autocomplete tool
  // used by hasNoMoreFabric8Data
  getElementsFlatListForObject(
    types: Fabric8NodeType[],
    object?: Fabric8Object
  ): Fabric8Element[] {
    const currentObject =
      object || this.getObjectWithId(this.currentObjectId.getValue());
    const rootNode = object?.rootNode || currentObject.rootNode;
    const filteredNodes = getNodesFlatList(types, rootNode);
    return filteredNodes.map((n) => n.data as Fabric8Element);
  }
}
