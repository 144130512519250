import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, sampleTime } from 'rxjs/operators';
import { CursorsLiveUpdatesService } from './cursors-live-updates.service';

export interface UserCursorMessage {
  u: string;
  transform: string;
}

@Component({
  selector: 'sw-cursors',
  templateUrl: './cursors.component.html',
  styleUrls: ['./cursors.component.scss'],
})
export class CursorsComponent implements OnInit, OnDestroy {
  cursors: UserCursorMessage[] = [];
  subscription: Subscription;

  constructor(
    private liveCursors: CursorsLiveUpdatesService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.subscription = this.liveCursors.cursors$
      .asObservable()
      .pipe(
        sampleTime(91),
        map((cursorsMap) => Array.from(cursorsMap.values()))
      )
      .subscribe((cursors: UserCursorMessage[]) => {
        this.ngZone.run((t) => {
          this.cursors = cursors;
        });
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  trackByUser(index, cursor) {
    return cursor.u;
  }
}
