import { HttpClient } from '@angular/common/http';
import { sha1 } from 'object-hash';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { sample } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LibraryLabel } from 'src/app/model/library-label.model';
import { UpdateLibraryLabelMessage } from 'src/app/services/liveupdates/messages';
import { environment } from 'src/environments/environment';
import { B64Service } from 'src/app/shared/b64.service';
import { UserService } from 'src/app/core/services/user.service';
import { LibraryLiveUpdateService } from 'src/app/services/liveupdates/library-lables-live-update.service';
import { getRandomString } from 'src/app/core/helpers/getRandomString';

@Injectable({
  providedIn: 'root',
})
export class LibraryLabelsService implements OnDestroy {
  public labels: BehaviorSubject<LibraryLabel[]> = new BehaviorSubject<
    LibraryLabel[]
  >([]);
  public updatedLabel = new Subject<LibraryLabel>();
  // public userUpdatedLabel = new Subject<LibraryLabel>()
  // public userDeletedLabel = new Subject<LibraryLabel>()
  // public createdLabel = new Subject<LibraryLabel>()

  liveUpdateLabelSubscription: Subscription;
  liveUpdateLabelDeleteSubscription: Subscription;

  public colors = [
    '#831717',
    '#B50000',
    '#DB6C05',
    '#EEC802',
    '#397709',
    '#088558',
    '#AAE2EE',
    '#60ACE2',
    '#0299D3',
    '#790845',
    '#A00B53',
  ];

  theLabels = [];

  sid = 'labels-service:' + getRandomString(3);

  constructor(
    private http: HttpClient,
    private b64: B64Service,
    private userService: UserService,
    private libraryLiveUpdateService: LibraryLiveUpdateService
  ) {
    console.warn(this.sid);
    this.liveUpdateLabelSubscription =
      this.libraryLiveUpdateService.updateLibraryLabelSignal.subscribe(
        (mc: UpdateLibraryLabelMessage) => {
          var isNewLabel = this.updateIfNeeded(mc);
          if (isNewLabel) {
            this.libraryLiveUpdateService.reloadLibrarySignal.next();
          }
        }
      );

    this.liveUpdateLabelDeleteSubscription =
      this.libraryLiveUpdateService.deleteLibraryLabelSignal.subscribe((id) => {
        this.removeLabel(id);
        this.libraryLiveUpdateService.reloadLibrarySignal.next();
      });
  }

  ngOnDestroy(): void {
    this.liveUpdateLabelSubscription?.unsubscribe();
    this.liveUpdateLabelDeleteSubscription?.unsubscribe();
  }

  public async getAll(pluginID: string): Promise<boolean> {
    const url = environment.apiUrl + '/label/fetch';
    const request = {
      plugin: pluginID,
    };
    const response = await this.http.post<any[]>(url, request).toPromise();

    this.theLabels = response;
    this.publishLabels();
    return true;
  }

  publishLabels() {
    this.labels.next(this.theLabels);
    this.libraryLiveUpdateService.updateLabelsForLibrary(
      this.theLabels,
      'global'
    );
  }

  public async createLabel(
    pluginID: string,
    labelName: string
  ): Promise<LibraryLabel> {
    var newLabelRequest = {
      pluginID: pluginID,
      name: labelName,
      color: sample(this.colors),
      access: [this.userService.makeUserOwner()],
    };

    const url = environment.apiUrl + '/label/create';
    const newLabel = await this.http
      .post<LibraryLabel>(url, newLabelRequest)
      .toPromise();
    return newLabel;
  }

  public async updateLabelUserAction(label: LibraryLabel) {
    var index = this.theLabels.findIndex((l) => l.id == label.id);
    if (index > -1) {
      this.theLabels[index].color = label.color;
      this.theLabels[index].name = '...';
    }

    try {
      const url = environment.apiUrl + '/label';
      await this.http.post<LibraryLabel>(url, label).toPromise();

      if (index > -1) {
        Object.assign(this.theLabels[index], label);
      }
      // this.userUpdatedLabel.next(label)
      this.publishLabels();
    } catch (err) {
      console.error(err);
    }
  }

  public async deleteLabel(label: LibraryLabel) {
    try {
      const url = environment.apiUrl + '/label/' + label.id;
      await this.http.delete<LibraryLabel>(url).toPromise();

      // this.userDeletedLabel.next(label)
    } catch (err) {
      console.error(err);
    }
  }

  public async removeLabel(labelID: string) {
    this.theLabels = this.theLabels.filter(
      (l) => String(l.id) != String(labelID)
    );
    this.publishLabels();
  }

  public getLabelsWithIDs(labelIDs: string[]) {
    return labelIDs
      .map((id) => this.labels.value.find((label) => label.id == id))
      .filter((l) => l); // remove invalid ids (labels that are not found)
  }

  updateIfNeeded(message: UpdateLibraryLabelMessage) {
    const currentLabelIndex = this.theLabels.findIndex(
      (l) => String(l.id) == message.labelID
    );

    if (
      currentLabelIndex > -1 &&
      sha1(this.theLabels[currentLabelIndex]) == message.hash
    ) {
      // the label is already up to date
      return;
    }

    const labelData = JSON.parse(this.b64.decode(message.base64));

    var newLabel = false;

    if (currentLabelIndex == -1) {
      // it's a new label
      this.theLabels.push(labelData);
      newLabel = true;
    } else {
      // update existing label
      Object.assign(this.theLabels[currentLabelIndex], labelData);
    }
    this.updatedLabel.next(labelData);
    this.publishLabels();

    return newLabel;
  }
}
