import { Injectable } from '@angular/core';
import { clone } from 'lodash';
import { CanvasForm, Pt } from 'pts';
import { ContextMenuItem } from 'src/app/modules/fabric8/contextmenu/contextmenu.options';
import { Fabric8Element, IFabric8Element } from '../../models/element.model';
import { Fabric8NodeType } from '../../models/Fabric8NodeType';
import { colors } from '../../swcanvas/colors';
import { Fabric8ToolType } from '../../toolset/Fabric8ToolType';
import { CameraService } from '../camera.service';
import { CurrentObjectService } from '../current-object.service';
import { ElementsService } from '../elements.service';
import { ToolHandlerService } from '../tool-handler.service';
import { ToolService } from '../tool.service';
import { Fabric8MouseEvent } from './models/Fabric8MouseEvent.model';
import { Fabric8ToolHandler } from './models/Fabric8ToolEvents.model';
import { ToolState } from './models/ToolState.model';
import { SnapService, SnapTo } from './snap';

@Injectable({
  providedIn: 'root',
})
export class DrillTool implements Fabric8ToolHandler {
  state: ToolState = ToolState.idle;
  cursorPoint: Pt | undefined;
  cursorSnapped?: SnapTo;

  constructor(
    private toolService: ToolService,
    private elementsService: ElementsService,
    private cameraService: CameraService,
    private currentObject: CurrentObjectService,
    private toolHandlerService: ToolHandlerService,
    private snap: SnapService
  ) {
    this.toolHandlerService.registerTool(Fabric8ToolType.DRILL, this);
  }

  private newElementAt(point: Pt) {
    const currentTool = this.toolService.getCurrentTool();
    // const depth = this.toolService.getCurrentToolDepth();

    const newElement = new Fabric8Element({
      [IFabric8Element]: true,
      tool: clone(currentTool),
      isClosedPath: false,
      points: [],
      // depth: depth,
    });
    const newNode = this.elementsService.addNewNodeWithData(newElement);
    this.elementsService.addAndSelectPoint(point, newNode);
    this.currentObject.update(true);
    this.state = ToolState.drawing;
  }

  public snapCursor(event: Fabric8MouseEvent) {
    const object = this.currentObject.get();

    const s = this.snap.to(object, event, [
      SnapTo.guidelineToObjectIntersections,
      SnapTo.guidelinesIntersections,
      SnapTo.guidelines,
      SnapTo.elementPoints,
      SnapTo.objectPoints,
      SnapTo.objectLines,
      SnapTo.grid,
      SnapTo.roundUnits,
    ]);

    this.cursorSnapped = s.snapped;
    this.cursorPoint = s.point;
  }

  // handle mouse events
  public mouseDown(event: Fabric8MouseEvent) {
    // first, we're trying to select the node of another element
    const nonGuidelineHoveredElementIndices =
      event.hoveredNodeDataPointIndices?.filter(
        (s) => s.type !== Fabric8NodeType.Guideline
      ) || [];

    const firstHoveredNodeIdx = nonGuidelineHoveredElementIndices[0];

    if (firstHoveredNodeIdx) {
      this.elementsService.setCurrentNodeDataPointIndex(firstHoveredNodeIdx);
      this.state == ToolState.editing;
      return;
    }

    if (this.state == ToolState.idle) {
      this.newElementAt(this.cursorPoint);
    }
  }

  public mouseClick(event: Fabric8MouseEvent) {}

  public mouseDrag(event: Fabric8MouseEvent) {
    this.elementsService.updateCurrentPoint(this.cursorPoint);
    this.currentObject.update(false);
  }

  public mouseUp(event: Fabric8MouseEvent) {
    // console.log('mouseUp', event)
    this.state = ToolState.idle;
    // this.toolService.commitElement();
  }

  public mouseDrop(event: Fabric8MouseEvent) {
    // console.log('mouseDrop', event)
    this.elementsService.commitCurrentNodeData();
    this.reset();
  }

  public mouseMove(event: Fabric8MouseEvent) {
    // console.log('mouseMove', event)
  }

  public keyDown(event: any) {
    if (event.key == 'Delete' || event.key == 'Backspace') {
      const alsoDeletedTheLastPoint = this.elementsService.deleteCurrentPoint();
      if (alsoDeletedTheLastPoint) this.reset();
    } else {
      this.elementsService.commitCurrentNodeData();
      this.reset();
    }
  }

  public reset() {
    this.state = ToolState.idle;
  }

  public draw(form: CanvasForm) {
    if (this.cursorPoint && this.cursorSnapped) {
      const p = this.cameraService.transform([this.cursorPoint]);
      var shape = 'circle';
      switch (this.cursorSnapped) {
        case SnapTo.objectPoints:
        case SnapTo.elementPoints:
        case SnapTo.guidelinesIntersections:
        case SnapTo.guidelineToObjectIntersections:
          shape = 'square';
          break;
        default:
          shape = 'circle';
          break;
      }

      if (
        this.cursorSnapped == SnapTo.grid ||
        this.cursorSnapped == SnapTo.roundUnits
      ) {
        //don't draw
      } else {
        form.fillOnly(colors.activeTool).point(p[0], 5, shape);
      }
    }
  }

  public getContextMenuItems(event: Fabric8MouseEvent): ContextMenuItem[] {
    return [];
  }

  public contextMenuAction(item: ContextMenuItem, meta: any) {
    console.error('DrillTool: contextMenuAction: Method not implemented.');
  }
}
