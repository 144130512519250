import { Pt } from 'pts';
import { Fabric8Tool } from './tool.model';
import { Pt2skpPoint, SkpPoint, skpPoint2Pt } from './Pt2skpPoint';

export const IFabric8Guideline = Symbol('IFabric8Guideline');
export interface IFabric8Guideline {
  [IFabric8Guideline]: boolean;
  anchor: Pt;
  lineVector: Pt;
  perpendicularAngle: number; // perpendicular to guideline and line
  distance: number;
  points: Pt[];

  tool: Fabric8Tool;
}

export const SkpFabric8Guideline = Symbol('SkpFabric8Guideline');
export interface SkpFabric8Guideline {
  [SkpFabric8Guideline]: true;
  anchor: SkpPoint;
  lineVector: SkpPoint;
  perpendicularAngle: number; // perpendicular to guideline and line
  distance: number;
  points: SkpPoint[];
}

export class Fabric8Guideline implements IFabric8Guideline {
  [IFabric8Guideline] = false;
  anchor: Pt;
  lineVector: Pt;
  perpendicularAngle: number; // perpendicular to guideline and line
  distance: number;
  points: Pt[] = [];
  tool: Fabric8Tool;

  constructor(data: IFabric8Guideline);
  constructor(data: SkpFabric8Guideline);
  constructor(data: string);
  constructor(data: IFabric8Guideline | SkpFabric8Guideline | string) {
    if (typeof data === 'string') {
      const e = JSON.parse(data);
      return this.fromSkp(e);
    }

    if (data[IFabric8Guideline]) {
      const e = data as IFabric8Guideline;
      this.anchor = e.anchor;
      this.lineVector = e.lineVector;
      this.perpendicularAngle = e.perpendicularAngle;
      this.distance = e.distance;
      this.points = e.points;
      this.tool = e.tool;
    } else {
      // SkpFabric8Guideline
      const e = data as SkpFabric8Guideline;
      this.fromSkp(e);
    }

    return this;
  }

  public toJSON(): string {
    const data = this.toSkp();
    return JSON.stringify(data, null, 2);
  }

  public toSkp(): SkpFabric8Guideline {
    const data: SkpFabric8Guideline = {
      [SkpFabric8Guideline]: true,
      anchor: Pt2skpPoint(this.anchor),
      lineVector: Pt2skpPoint(this.lineVector),
      perpendicularAngle: this.perpendicularAngle,
      distance: this.distance,
      points: this.points.map((point) => Pt2skpPoint(point)),
    };
    return data;
  }

  public fromSkp(data: SkpFabric8Guideline): Fabric8Guideline {
    this.anchor = skpPoint2Pt(data.anchor);
    this.lineVector = skpPoint2Pt(data.lineVector);
    this.perpendicularAngle = data.perpendicularAngle;
    this.distance = data.distance;
    this.points = data.points.map((point) => skpPoint2Pt(point));
    return this;
  }
}
