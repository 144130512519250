<div
  cdkDropList
  [cdkDropListData]="attributes"
  (cdkDropListDropped)="drop($event)"
>
  <ng-container
    *ngFor="let a of attributes; let aIndex = index; trackBy: attrTrack"
  >
    <!-- (cdkDragStarted)="select()" -->
    <div
      class="trow"
      [ngClass]="{ selected: selectedIndex === aIndex }"
      (mousedown)="select(aIndex)"
      cdkDrag
      [cdkDragDisabled]="selectedIndex === aIndex"
      [cdkDragData]="a"
    >
      <ng-template [ngIf]="a.errors && a.errors.length > 0">
        <div class="tcell error">
          <mat-icon matTooltip="{{ a.errors[0] }}">error</mat-icon>
        </div>
      </ng-template>
      <div class="name-and-tag">
        <div
          #n
          class="attribute-name show-placeholder"
          [attr.contenteditable]="!isEditLocked(a)"
          (keydown.enter)="n.blur()"
          (blur)="nameChange(aIndex, n.textContent)"
          [attr.placeholder]="'ATTR.NAME' | translate"
          [innerHTML]="attributes[aIndex].name"
        ></div>
        <div
          #t
          class="tag-name show-placeholder"
          [attr.contenteditable]="!isEditLocked(a)"
          (keydown.enter)="t.blur()"
          (blur)="tagChange(aIndex, t.textContent)"
          [attr.placeholder]="tagPlaceholder(aIndex)"
          [innerHTML]="attributes[aIndex].tag"
        ></div>
      </div>

      <div class="tcell type">
        <select
          [ngModel]="attributes[aIndex].type"
          [disabled]="isEditLocked(a)"
          (ngModelChange)="onChangeType(aIndex, $event)"
        >
          <!-- placeholder="{{ 'ATTR.SELECT' | translate }}" -->
          <option *ngFor="let t of types" value="{{ t }}">
            {{ t | titlecase }}
          </option>
        </select>
      </div>

      <div class="tcell menu">
        <ng-template [ngIf]="isEditLocked(a)" [ngIfElse]="dots">
          <mat-icon class="lock" [inline]="true">lock</mat-icon>
        </ng-template>
        <ng-template #dots>
          <sw-dots-menu [itemID]="'i' + aIndex">
            <div
              class="hover-btn red"
              [inlineSVG]="'icon/delete.svg'"
              (click)="onClickRemove(aIndex)"
              matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
              matTooltipPosition="above"
            ></div>
            <div
              class="hover-btn"
              [inlineSVG]="'icon/duplicate.svg'"
              (click)="onDuplicate(aIndex)"
              matTooltip="{{ 'GLOBAL.DUPLICATE' | translate }}"
              matTooltipPosition="above"
            ></div>
            <!-- <div class="hover-btn" 
                            (click)="onOptions(aIndex)"
                            matTooltip="{{ 'GLOBAL.DUPLICATE' | translate }}" matTooltipPosition="above"></div> -->
          </sw-dots-menu>
        </ng-template>
      </div>
    </div>
    <ng-template [ngIf]="selectedIndex === aIndex">
      <sw-attribute-editor-options
        [(attr)]="attributes[aIndex]"
        [attrType]="attributes[aIndex].type"
      >
      </sw-attribute-editor-options>
    </ng-template>
  </ng-container>
</div>
