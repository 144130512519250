import { Pt } from 'pts';
import { SkpPoint, Pt2skpPoint, skpPoint2Pt } from './Pt2skpPoint';
import { Fabric8Tool } from './tool.model';

export const IFabric8Element = Symbol('IFabric8Element');
export interface IFabric8Element {
  [IFabric8Element]: boolean;
  points: Pt[];
  isClosedPath: boolean;
  // depth: number;
  tool: Fabric8Tool;
}

export const SkpFabric8Element = Symbol('SkpFabric8Element');
export interface SkpFabric8Element {
  [SkpFabric8Element]: true;
  points: SkpPoint[];
  isClosedPath: boolean;
  // depth: number;
  tool: Fabric8Tool;
}

export class Fabric8Element implements IFabric8Element {
  [IFabric8Element] = false;
  points: Pt[] = [];
  isClosedPath: boolean = false;
  tool: Fabric8Tool;

  constructor(data: IFabric8Element);
  constructor(data: SkpFabric8Element);
  constructor(data: string);
  constructor(data: IFabric8Element | SkpFabric8Element | string) {
    if (typeof data === 'string') {
      const e = JSON.parse(data);
      return this.fromSkp(e);
    }
    if (data[IFabric8Element]) {
      const e = data as IFabric8Element;
      this.points = e.points;
      this.isClosedPath = e.isClosedPath;
      this.tool = e.tool;
    } else {
      // SkpFabric8Element
      this.fromSkp(data as SkpFabric8Element);
    }
  }

  public toJSON(): string {
    const data = this.toSkp();
    return JSON.stringify(data, null, 2);
  }

  public toSkp(): SkpFabric8Element {
    const data: SkpFabric8Element = {
      [SkpFabric8Element]: true,
      points: this.points.map((point) => Pt2skpPoint(point)),
      isClosedPath: this.isClosedPath,
      // depth: this.depth,
      tool: this.tool,
    };
    return data;
  }

  private fromSkp(data: SkpFabric8Element): Fabric8Element {
    this.points = data.points.map((point) => skpPoint2Pt(point));
    this.isClosedPath = data.isClosedPath;
    // this.depth = data.depth;
    this.tool = data.tool;
    return this;
  }
}
