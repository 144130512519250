import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { DataNode, DataNodeAPI } from 'src/app/model/data-node.model';

import { environment } from 'src/environments/environment';
import { BehaviorSubject, of } from 'rxjs';

import { sha1 } from 'object-hash';
import { switchMap } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { DataNodeCoderService } from './data-node-coder.service';
import { LibraryService } from '../../library/library.service';

@Injectable({
  providedIn: 'root',
})
export class DataNodeService {
  // the hashes dictionary holds [DataNodeID: Hash] pairs
  private hashes: { [id: string]: String } = {};

  addDataNodeChild = new EventEmitter();
  topButtonsTemplate = new BehaviorSubject<any>(undefined);

  nodesUpdatedEvent: EventEmitter<DataNode[]> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private dataNodeCoder: DataNodeCoderService,
    private libaryService: LibraryService
  ) {}

  async reloadNode(node: DataNode): Promise<DataNode> {
    const freshNode = await this.getNode(node.id);
    node.name = freshNode.name;
    node.attributes = freshNode.attributes;
    node.childrenIDs = freshNode.childrenIDs;
    node.treeTag = freshNode.treeTag;
    node.libraryLinks = freshNode.libraryLinks;
    node = await this.populateLibraryLinks(node);
    return node;
  }

  async populateLibraryLinks(node: DataNode): Promise<DataNode> {
    node.libraryLinks = await Promise.all(
      node.libraryLinks.map(async (l) => {
        l.item = await this.libaryService.getOrFetchItem(l.itemID, l.pluginID);
        return l;
      })
    );
    return node;
  }

  async getNodes(nodeIDs): Promise<DataNode[]> {
    const url = environment.apiUrl + '/node/get-nodes';
    const request = { ids: nodeIDs };
    const response = await this.http
      .post<DataNodeAPI[]>(url, request)
      .toPromise();
    // console.warn(cloneDeep(response))
    return Promise.all(
      response.map(async (n) => {
        this.hashes[n.id] = sha1(n);
        var dn = this.dataNodeCoder.dataNodeAPI_to_DataNode(n);
        return await this.populateLibraryLinks(dn);
      })
    );
  }

  async getNode(id: string): Promise<DataNode> {
    const n = await this.http
      .get<DataNodeAPI>(environment.apiUrl + '/node/' + id)
      .toPromise();
    this.hashes[id] = sha1(n);
    var dn = this.dataNodeCoder.dataNodeAPI_to_DataNode(n);
    return await this.populateLibraryLinks(dn);
  }

  async createNode(node: DataNode): Promise<DataNode> {
    // post the node on the backend
    var requestBody = this.dataNodeCoder.dataNode_to_DataNodeAPI(node);
    requestBody.id = undefined;

    try {
      const url = environment.apiUrl + '/node/create';
      var newNode = await this.http
        .post<DataNodeAPI>(url, requestBody)
        .toPromise();
      // console.log("newNode")
      // console.log(newNode)
      var n: DataNode = this.dataNodeCoder.dataNodeAPI_to_DataNode(newNode);
      this.hashes[n.id] = sha1(n);
      return n;
    } catch (e) {
      throw e;
    }
  }

  async loadChildren(node: DataNode): Promise<DataNode[]> {
    var theChildren = await this.getNodes(node.childrenIDs);
    const children = theChildren.filter((g) => g !== null);
    return children || [];
  }

  async duplicate(node: DataNode): Promise<DataNode> {
    const url = environment.apiUrl + '/node/duplicate/' + node.id;
    const requestBody = {
      nodeName: node.name + ' Copy',
    };

    const newNode = await this.http.post<any>(url, requestBody).toPromise();
    const n: DataNode = this.dataNodeCoder.dataNodeAPI_to_DataNode(newNode);

    return this.dataNodeCoder.dataNodeAPI_to_DataNode(n);
  }

  async updateNodes(nodes: DataNode[]): Promise<boolean> {
    // serial requests
    for (let n of nodes) {
      await this.setNode(n);
    }

    // parallel requests - disabled for now
    /*
    const promises = nodes.map(n => this.setNode(n))
    await Promise.all(promises)
    */
    this.nodesUpdatedEvent.next(nodes);
    return true;
  }

  private async setNode(node: DataNode): Promise<boolean> {
    const url = environment.apiUrl + '/node';
    const request: DataNodeAPI =
      this.dataNodeCoder.dataNode_to_DataNodeAPI(node);
    // console.warn('POST node')
    // console.warn(cloneDeep(request.attributes))
    let newNode = await of('update')
      .pipe(
        switchMap(() => this.http.post<DataNodeAPI>(url, request)),
        retryBackoff({ initialInterval: 100, maxRetries: 5 })
      )
      .toPromise();

    // Post update node with no retry
    // const newNode = await this.http.post<DataNode>(url, request).toPromise()

    // update local hashes after the backend returns
    this.hashes[node.id] = sha1(request);
    node.childrenIDs = request.childrenIDs;
    return true;
  }

  async deleteNode(node: DataNode): Promise<boolean> {
    try {
      const url = environment.apiUrl + '/node/' + node.id;
      await this.http.delete<boolean>(url, {}).toPromise();
      this.hashes[node.id] = undefined;
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
