<div *ngIf="isUtilityGroup() === false">
  <div class="tool row">
    <div class="icon-container center">
      <div class="icon" [inlineSVG]="getToolIcon(toolGroup.selected)"></div>
    </div>
    <span class="small-title">{{
      translateToolName(toolGroup.name) | translate | uppercase
    }}</span>
    <div class="spacer"></div>
    <div class="btn-primary" (click)="addTool()">
      <mat-icon class="icon">add</mat-icon>
      {{ "PLUGINS.ADD_BUTTON" | translate }}
    </div>
  </div>

  <div class="kit" *ngFor="let tool of toolGroup.tools; let toolIndex = index">
    <sw-tool-row
      [tool]="tool"
      (toolChange)="toolChanged($event, toolIndex)"
    ></sw-tool-row>

    <div class="spacer"></div>

    <sw-dots-menu [itemID]="'tm' + toolGroupIndex" [ngClass]="'toolset-menu'">
      <div
        class="hover-btn red"
        [inlineSVG]="'icon/delete.svg'"
        (click)="deleteTool(toolIndex)"
        matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
        matTooltipPosition="above"
      ></div>
    </sw-dots-menu>
  </div>
</div>
