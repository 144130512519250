import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { SWMaterialItem, SWMaterialsService } from './sw-materials.service';

/* It's a pipe that takes a material name and returns the thumbnail for that material */
@Pipe({
  name: 'materialThumbnail',
  pure: false,
})
export class MaterialThumbnailPipe implements PipeTransform {
  constructor(
    private materialsService: SWMaterialsService,
    private _ref: ChangeDetectorRef
  ) {}

  transform(name: any, args?: any): SWMaterialItem | '' {
    if (!name) return undefined;
    const materials = this.materialsService.materialItemCache;
    if (!materials.has(name)) {
      this.materialsService.materialItemCache.set(name, null);

      try {
        console.log('get material', name);
        this.materialsService.getMaterialNamed(name).then((m) => {
          this.materialsService.materialItemCache.set(name, m);
          this._ref.markForCheck();
        });
      } catch (e) {
        console.error(e);
        // this.materialsService.materialItemCache.set(name, null)
        // this._ref.markForCheck();
        return '';
      }
    }
    // console.log(name)
    // console.warn(materials.get(name))

    return materials.get(name) || undefined;
  }
}
