import { Injectable, OnDestroy } from '@angular/core';
import { CanvasForm, Group, Line, Pt, Rectangle, Vec } from 'pts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Fabric8Element } from '../models/element.model';
import { Fabric8Settings } from '../models/Fabric8Settings.model';
import { Fabric8Guideline } from '../models/guideline.model';
import { Fabric8Node } from '../models/node.model';
import { Fabric8NodeType, getNodeType } from '../models/Fabric8NodeType';
import { Fabric8SettingsService } from '../settings/fabric8-settings.service';
import { CameraService } from './camera.service';
import { CurrentObjectService } from './current-object.service';
import { ElementsService } from './elements.service';
import { Fabric8NodeDataPointIndex } from './toolHandlers/models/Fabric8NodeDataPointIndex.model';
import { Fabric8LinePointIndex } from './toolHandlers/models/Fabric8LinePointIndex.model';
import { Fabric8MouseEvent } from './toolHandlers/models/Fabric8MouseEvent.model';
import _ from 'lodash';

const CURSOR_RADIUS = 8;

@Injectable({
  providedIn: 'root',
})
export class HoverService implements OnDestroy {
  hoveredElementIndices$ = new BehaviorSubject<Fabric8NodeDataPointIndex[]>([]);

  mouseEvent: Fabric8MouseEvent = {
    hoveredNodeDataPointIndices: [],
    hoveredLinePointIndices: [],
    currentNodeDataPointIndex: undefined,
    canvasSpacePoint: undefined,
    objectSpacePoint: undefined,
  };

  settingsSubscription: Subscription;
  settings: Fabric8Settings;

  constructor(
    // private objectsManager: ObjectsManagerService,
    private camera: CameraService,
    private elementsService: ElementsService,
    private settingsService: Fabric8SettingsService,
    private currentObject: CurrentObjectService
  ) {
    this.subscribeToSettings();
  }

  private subscribeToSettings() {
    this.settingsSubscription = this.settingsService.settingsSubject.subscribe(
      (settings: Fabric8Settings) => {
        this.settings = settings;
      }
    );
  }

  ngOnDestroy(): void {
    this.settingsSubscription?.unsubscribe();
  }

  private cursorRadius() {
    return CURSOR_RADIUS / this.camera.cameraScale;
  }

  hoverNodes(nodes: Fabric8Node[]) {
    this.mouseEvent = {
      hoveredNodeDataPointIndices: nodes.map((node) => {
        return {
          id: node.id,
          pointIndex: -1,
          type: getNodeType(node.data),
        };
      }),
      hoveredLinePointIndices: [],
      currentNodeDataPointIndex: undefined,
      canvasSpacePoint: undefined,
      objectSpacePoint: undefined,
    };
  }

  // TODO: implement selectElements
  // selectElements(elements: Fabric8Element[]) {
  //   this.mouseEvent = {
  //     hoveredElementPointIndices: [],
  //     hoveredLinePointIndices: [],
  //     selectedElementPointIndex: undefined,
  //   };
  //   this.elementsService.selectElements(elements);
  // }

  setPoint(canvasSpacePoint: Pt, form: CanvasForm): Fabric8MouseEvent {
    const point = this.camera.inverseTransform([canvasSpacePoint])[0];

    const hoveredElements = this.getHoveredElementPointIndices(point) ?? [];
    const hoveredGuidelines = this.getHoveredGuideLines(point, form) ?? [];
    const hoveredLines = this.getHoveredLineIndices(point) ?? [];

    const hoveredNodeDataItems = hoveredElements.concat(hoveredGuidelines);

    this.mouseEvent = {
      hoveredNodeDataPointIndices: hoveredNodeDataItems,
      hoveredLinePointIndices: hoveredLines,
      currentNodeDataPointIndex:
        this.elementsService.getCurrentNodeDataPointIndex(),
      canvasSpacePoint: canvasSpacePoint,
      objectSpacePoint: point,
    };

    return this.mouseEvent;
  }

  updateHoveredElementIndices = _.throttle((e) => {
    this.hoveredElementIndices$.next(e);
  }, 100);

  private getHoveredElementPointIndices(
    point: Pt
  ): Fabric8NodeDataPointIndex[] {
    const e = this.findHoveredNodeDataPointIndices(point);
    this.updateHoveredElementIndices(e);
    return e;
  }

  private findHoveredNodeDataPointIndices(
    cursorPoint: Pt
  ): Fabric8NodeDataPointIndex[] {
    const currentObject = this.currentObject.get();
    if (!currentObject) return [];

    var hoveredElementsIndices: Fabric8NodeDataPointIndex[] = [];

    const filter = [Fabric8NodeType.Element, Fabric8NodeType.GuidelineSegment];

    this.currentObject
      .getObjectNodesFlatList(filter)
      .forEach((elementNode: Fabric8Node) => {
        // find the hovered point
        const el = elementNode.data as Fabric8Element;
        if (elementNode.readOnly) return;

        el.points.forEach((point: Pt, pointIndex: number) => {
          const radius = Math.max(el.tool.radius, this.cursorRadius());
          const distance = cursorPoint.$subtract(point);
          if (Vec.magnitude(distance) < radius) {
            hoveredElementsIndices.push({
              id: elementNode.id,
              pointIndex: pointIndex,
              type: Fabric8NodeType.Element,
            });
          }
        });

        if (hoveredElementsIndices.length) return hoveredElementsIndices;

        // check if we're hovering lines
        if (el.points.length > 1) {
          for (let i = 0; i < el.points.length - 1; i++) {
            const a = el.points[i];
            const b = el.points[i + 1];
            const line: Pt[] = [a, b];

            try {
              // projected point on line
              const projPoint = Line.perpendicularFromPt(line, cursorPoint);
              const lineDistance = Line.distanceFromPt(line, cursorPoint);
              const radius = Math.max(el.tool.radius, this.cursorRadius());

              if (
                lineDistance < radius &&
                a.$subtract(projPoint).magnitude() <
                  a.$subtract(b).magnitude() &&
                b.$subtract(projPoint).magnitude() < b.$subtract(a).magnitude()
              ) {
                hoveredElementsIndices.push({
                  id: elementNode.id,
                  pointIndex: -1,
                  type: Fabric8NodeType.Element,
                });
              }
            } catch (e) {
              console.log(JSON.stringify(line, null, 2));
              console.log(cursorPoint);
              console.log(e);
            }
          }
        }
      });

    // remove duplicate hoveredElementsIndices
    hoveredElementsIndices = hoveredElementsIndices.filter(
      (el, index, self) =>
        index ===
        self.findIndex((t) => t.id === el.id && t.pointIndex === el.pointIndex)
    );

    //sort currentElement points before other elements
    const currentElementPointIndex =
      this.elementsService.getCurrentNodeDataPointIndex();
    hoveredElementsIndices.sort((a, b) => {
      if (a.id === currentElementPointIndex?.id) return -1;
      if (b.id === currentElementPointIndex?.id) return 1;
      return 0;
    });

    // sort points before lines
    hoveredElementsIndices.sort((a, b) => {
      if (a.pointIndex === -1 && b.pointIndex !== -1) return 1;
      if (a.pointIndex !== -1 && b.pointIndex === -1) return -1;
      return 0;
    });

    //TODO: sort points by distance to cursor

    //TODO: sort identical points by vector angle to adjacent points
    //  eg. two points overlap, each is connected to at least another point
    //    sort by angle to adjacent points and angle to cursor

    return hoveredElementsIndices;
  }

  private getHoveredLineIndices(cursorPoint: Pt): Fabric8LinePointIndex[] {
    // Return s.lines that are hovered
    // Used for display and snapping

    const currentObject = this.currentObject.get();
    if (!currentObject) return [];

    var hoveredLinesIndices: Fabric8LinePointIndex[] = [];

    currentObject.lines.forEach((line: any[], lineIndex: number) => {
      // find the hovered point
      var hoverPointIndex = -1;
      line.forEach((point: Pt, pointIndex: number) => {
        const distance = cursorPoint.$subtract(point);
        if (Vec.magnitude(distance) < this.cursorRadius()) {
          hoverPointIndex = pointIndex;
        }
      });

      if (hoverPointIndex !== -1) {
        hoveredLinesIndices.push({
          lineIndex: lineIndex,
          pointIndex: hoverPointIndex,
        });
        return hoveredLinesIndices;
      }

      // check if we're hovering lines
      // projected point on line
      const projPoint = Line.perpendicularFromPt(line, cursorPoint);
      const lineDistance = Line.distanceFromPt(line, cursorPoint);
      if (
        lineDistance < this.cursorRadius() &&
        line[0].$subtract(projPoint).magnitude() <
          line[0].$subtract(line[1]).magnitude() &&
        line[1].$subtract(projPoint).magnitude() <
          line[1].$subtract(line[0]).magnitude()
      ) {
        hoveredLinesIndices.push({
          lineIndex: lineIndex,
          pointIndex: -1,
        });
        // break
      }
    });

    return hoveredLinesIndices;
  }

  // Only  Fabric8NodeType.Guideline
  private getHoveredGuideLines(
    cursorPoint: Pt,
    form: CanvasForm
  ): Fabric8NodeDataPointIndex[] {
    // Return guidelines and that are hovered
    // Used for snapping.
    const currentObject = this.currentObject.get();
    if (!currentObject) return [];

    const filter = [Fabric8NodeType.Guideline];

    var hoveredGuidelineIndices: Fabric8NodeDataPointIndex[] = [];

    this.currentObject
      .getObjectNodesFlatList(filter)
      .forEach((guidelineNode: Fabric8Node) => {
        const guideline = guidelineNode.data as Fabric8Guideline;

        const currentLine = Group.fromPtArray(guideline.points);
        if (guideline.points.length < 2) return;
        const extendedPoints = this.extendLineToCanvasEdges(currentLine, form);
        if (extendedPoints?.length !== 2) {
          return;
        }
        const lineDistance = Line.distanceFromPt(extendedPoints, cursorPoint);
        if (lineDistance < this.cursorRadius()) {
          // migrate to ids
          hoveredGuidelineIndices.push({
            id: guidelineNode.id,
            pointIndex: -1,
            type: Fabric8NodeType.Guideline,
          });
        }
      });

    return hoveredGuidelineIndices;
  }

  extendLineToCanvasEdges(segment: Group, form: CanvasForm) {
    const canvasBounds = form.space.innerBound;
    const canvasRect = Rectangle.fromTopLeft(
      canvasBounds.topLeft,
      canvasBounds.size
    );
    const canvasPoly = this.camera.inverseTransform(
      Rectangle.corners(canvasRect)
    );
    const points = Line.intersectPolygon2D(segment, canvasPoly, true);
    return points;
  }

  addEmptyGroupNextToCurrentElement(): Fabric8Node {
    const obj = this.currentObject.get();
    if (!obj) return;

    const currentElementNode = this.elementsService.getCurrentNode();
    // find the parent group
    const parentGroupNode = this.currentObject.findGroupNodeContainingNodeId(
      currentElementNode.id
    );

    const newNode = this.currentObject.addEmptyGroupOnNode(
      parentGroupNode || obj.rootNode
    );

    return newNode;
  }
}
