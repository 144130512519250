import { HttpClient } from '@angular/common/http';
import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataNodeService } from '../project/services/data-node.service';
import { LibraryService } from './library.service';

export interface NodeLinksResponse {
  links: NodeLibraryLink[];
}

export interface NodeLibraryLink {
  nodeID: string;
  libraryItemID: string;
}

@Injectable({
  providedIn: 'root',
})
export class NodeLinksService {
  //         plugin   links (node, libraryItemID)
  links: Map<string, NodeLibraryLink[]>;

  constructor(private http: HttpClient) {
    console.warn('NodeLinksService init');
  }

  async getLinks(projectID: string, pluginID: string) {
    const url = environment.apiUrl + '/platform/websw/get-library-node-links';

    const itemRequest = {
      projectID: projectID,
      pluginIDs: [pluginID],
    };

    try {
      const response = await this.http.post<any>(url, itemRequest).toPromise();

      var theLinks: NodeLibraryLink[] = response.links.map((l) => {
        return {
          nodeID: String(l[0]),
          libraryItemID: String(l[1]),
        };
      });

      this.links.set(pluginID, theLinks);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
