<ng-template [ngIf]="sketchUp.isSketchup" [ngIfElse]="notSketchup">
  <div class="row flex buttons">
    <div class="spacer"></div>
    <div
      class="btn-primary"
      [ngClass]="{ disabled: !isCurrentFile || importing }"
      (click)="exportAllSketchupObjects()"
    >
      <div
        *ngIf="!importing"
        class="icon"
        [inlineSVG]="'icon/sketchup.svg'"
      ></div>
      <mat-spinner class="icon" *ngIf="importing" [diameter]="20"></mat-spinner>
      {{ "SU_OBJECTS.IMPORT" | translate }}
    </div>
    <div class="spacer"></div>
  </div>
  <ng-template [ngIf]="!isCurrentFile">
    <small class="center"
      >The currently open file is not linked to this project.</small
    >
  </ng-template>
</ng-template>

<ng-template #notSketchup>
  <div class="row flex buttons">
    <div class="spacer"></div>
    <small
      class="hint"
      [innerHTML]="'SU_OBJECTS.WEB_SKETCHUP_MESSAGE' | translate"
    ></small>
    <div class="spacer"></div>
  </div>
</ng-template>

<div swTreeGroup class="nodes">
  <sw-data-node [parent]="undefined" [(node)]="rootNode"></sw-data-node>
</div>

<div id="total" class="row">
  <strong>TOTAL</strong>
  <div class="spacer"></div>
  {{ getTotalPrice() }}
</div>
