import { Injectable, NgZone } from '@angular/core';
import _, { clone } from 'lodash';
import { CanvasForm, Line, Pt } from 'pts';
import { Subscription } from 'rxjs';
import {
  ContextMenuData,
  ContextMenuItem,
} from 'src/app/modules/fabric8/contextmenu/contextmenu.options';
import { Fabric8NodeType } from '../../models/Fabric8NodeType';
import {
  Fabric8GuidelineSegment,
  IFabric8GuidelineSegment,
} from '../../models/guideline-segment.model';
import { Fabric8SmartObjectType } from '../../models/smartgroup.model';
import { colors } from '../../swcanvas/colors';
import { Fabric8ToolType } from '../../toolset/Fabric8ToolType';
import { CameraService } from '../camera.service';
import { CurrentObjectService } from '../current-object.service';
import { ElementsService } from '../elements.service';
import { ToolHandlerService } from '../tool-handler.service';
import { ToolService } from '../tool.service';
import { Fabric8MouseEvent } from './models/Fabric8MouseEvent.model';
import { Fabric8ToolHandler } from './models/Fabric8ToolEvents.model';
import { ToolState } from './models/ToolState.model';
import { SnapService, SnapTo } from './snap';
import { updateChildrenNodesForGuidelineSegment } from '../smartObject/updateChildrenNodesForGuidelineSegment';
import { Fabric8Node } from '../../models/node.model';
import { Fabric8NodeDataPointIndex } from './models/Fabric8NodeDataPointIndex.model';

@Injectable({
  providedIn: 'root',
})
export class GuidelineSegmentTool implements Fabric8ToolHandler {
  state: ToolState = ToolState.idle;
  cursorPoint: Pt | undefined;
  cursorSnapped?: SnapTo;

  isDragging = false;
  addedPoint = false;

  constructor(
    private toolService: ToolService,
    private elementsService: ElementsService,
    private currentObject: CurrentObjectService,
    private cameraService: CameraService,
    private snap: SnapService,
    toolHandlerService: ToolHandlerService
  ) {
    toolHandlerService.registerTool(Fabric8ToolType.GUIDE_SEGMENT, this);
  }

  private newElementAt(point: Pt): Fabric8Node {
    const currentTool = this.toolService.getCurrentTool();

    const newGuidelineSegment = new Fabric8GuidelineSegment({
      [IFabric8GuidelineSegment]: true,
      tool: clone(currentTool),
      points: [],
      smartObjectData: undefined,
    });

    const newNode =
      this.elementsService.addNewNodeWithData(newGuidelineSegment);
    return newNode;
  }

  public snapCursor(event: Fabric8MouseEvent) {
    const object = this.currentObject.get();

    const s = this.snap.to(object, event, [
      SnapTo.guidelinesIntersections,
      SnapTo.guidelineToObjectIntersections,
      SnapTo.guidelines,
      SnapTo.elementPoints,
      SnapTo.objectPoints,
      SnapTo.objectLines,
      SnapTo.grid,
      SnapTo.roundUnits,
    ]);

    this.cursorSnapped = s.snapped;
    this.cursorPoint = s.point;
  }

  // handle mouse events
  public mouseDown(event: Fabric8MouseEvent) {
    this.isDragging = false;

    const firstHoveredNodeIdx = this.getHoveredNodeIndex(event);

    if (this.state !== ToolState.drawing && firstHoveredNodeIdx) {
      // get the hovered point and set is as the current point
      this.elementsService.setCurrentNodeDataPointIndex(firstHoveredNodeIdx);
      this.state = ToolState.editing;
      return;
    }

    // add new point
    var numberOfPoints = 0;
    if (this.state == ToolState.idle) {
      const newNode = this.newElementAt(this.cursorPoint);
      numberOfPoints = this.elementsService.addAndSelectPoint(
        this.cursorPoint,
        newNode
      );
      this.state = ToolState.drawing;
      this.addedPoint = true;
    } else if (this.state == ToolState.drawing) {
      numberOfPoints = this.elementsService.addAndSelectPoint(
        this.cursorPoint,
        this.elementsService.getCurrentNode()
      );
      this.addedPoint = true;
    }

    this.currentObject.update(true);
  }

  public mouseClick(event: Fabric8MouseEvent) {
    // console.info("mouseClick")
  }

  public mouseDrag(event: Fabric8MouseEvent) {
    this.isDragging = true;
    this.elementsService.updateCurrentPoint(this.cursorPoint);
    const id = this.elementsService.getCurrentNodeDataPointIndex()?.id;
    this.throttledUpdateChildrenNodes(id);
    this.currentObject.update(false);
  }

  private getHoveredNodeIndex(
    event: Fabric8MouseEvent
  ): Fabric8NodeDataPointIndex | undefined {
    const nonGuidelineHoveredElementIndices =
      event.hoveredNodeDataPointIndices?.filter(
        (s) => s.type !== Fabric8NodeType.Guideline
      ) || [];

    const firstHoveredNodeIdx = nonGuidelineHoveredElementIndices[0];
    return firstHoveredNodeIdx;
  }

  private throttledUpdateChildrenNodes = _.throttle((id: string) => {
    const { node } = this.currentObject.getNodeDataWithId(id);
    updateChildrenNodesForGuidelineSegment(node);
    this.currentObject.update(false);
  }, 50);

  public mouseUp(event: Fabric8MouseEvent) {
    if (this.isDragging) {
      updateChildrenNodesForGuidelineSegment(
        this.elementsService.getCurrentNode()
      );
      this.elementsService.commitCurrentNodeData();
      this.reset();
      return;
    }

    if (this.state == ToolState.drawing && this.addedPoint) {
      // commit after adding the second point
      const result = this.pathHas2Points();
      if (result) {
        this.elementsService.commitCurrentNodeData();
        this.reset();
        return;
      } else {
        this.currentObject.update(true);
      }
    }

    // attempt to select the first or last point of a hovered guide segment
    const firstHoveredNodeIdx = this.getHoveredNodeIndex(event);

    if (firstHoveredNodeIdx) {
      // if the current point index is the first or last point on a router element
      // enter drawing mode
      // const { data: hoveredElement } = this.currentObject.getNodeDataWithId(
      //   hoveredElementPointIndex.id
      // );
      this.addedPoint = false;
      this.elementsService.setCurrentNodeDataPointIndex(firstHoveredNodeIdx);
    }
  }
  private pathHas2Points(): boolean {
    const data = this.elementsService.getCurrentNodeData();
    return data.points.length == 2;
  }

  public mouseDrop(event: Fabric8MouseEvent) {
    const closed = this.pathHas2Points();
    this.isDragging = false;
    if (closed) {
      this.elementsService.commitCurrentNodeData();
      this.reset();
      return;
    } else {
      this.currentObject.update(true);
    }
  }

  public mouseMove(event: Fabric8MouseEvent) {
    // this.toolService.updateCurrentPoint(this.cursorPoint);
    // console.log('mouseMove', event)
  }

  public keyDown(event: any) {
    if (event.key == 'Delete' || event.key == 'Backspace') {
      const alsoDeletedTheLastPoint = this.elementsService.deleteCurrentPoint();
      if (alsoDeletedTheLastPoint) this.reset();
    } else if (event.key == 'Enter' || event.key == 'Escape') {
      this.elementsService.commitCurrentNodeData();
      this.reset();
    }
  }

  public reset() {
    this.isDragging = false;
    this.state = ToolState.idle;
    this.addedPoint = false;
  }

  public draw(form: CanvasForm) {
    if (this.cursorPoint && this.cursorSnapped) {
      const p = this.cameraService.transform([this.cursorPoint]);
      var shape = 'circle';
      switch (this.cursorSnapped) {
        case SnapTo.objectPoints:
        case SnapTo.elementPoints:
        case SnapTo.guidelinesIntersections:
        case SnapTo.guidelineToObjectIntersections:
          shape = 'square';
      }

      if (
        this.cursorSnapped == SnapTo.grid ||
        this.cursorSnapped == SnapTo.roundUnits
      ) {
        //don't draw
      } else {
        form.fillOnly(colors.activeTool).point(p[0], 5, shape);
      }
    }

    if (this.state == ToolState.drawing) {
      // draw line from last point to cursor
      const currentElement = this.elementsService.getCurrentElement();
      if (currentElement) {
        const points = this.cameraService.transform(currentElement.points);
        const lastPoint = points[points.length - 1];
        if (this.cursorPoint) {
          const cursorPoint = this.cameraService.transform([
            this.cursorPoint,
          ])[0];
          form.strokeOnly(colors.activeTool, 1).line([lastPoint, cursorPoint]);
        }
      }
    }
  }

  public getContextMenuItems(event: Fabric8MouseEvent): ContextMenuItem[] {
    const hoveredElementId = event.hoveredNodeDataPointIndices[0].id;

    const { node, data } =
      this.currentObject.getNodeDataWithId(hoveredElementId);

    if (data.points.length !== 2) return [];
    return [
      {
        title: 'Distributed Drill Holes', // TODO: change to: Create Distributed drill holes
        action: Fabric8SmartObjectType.distributedHoles,
      },
      {
        title: 'Equally Spaced Drill Holes', // TODO: change to: Create Equally spaced drill holes
        action: Fabric8SmartObjectType.equallySpacedHoles,
      },
    ];
  }

  public contextMenuAction(item: ContextMenuItem, meta: any) {
    const idx = this.elementsService.getCurrentNodeDataPointIndex();

    var { node, data } = this.currentObject.getNodeDataWithId(idx.id);

    if (node?.type !== Fabric8NodeType.GuidelineSegment) return;
    data = data as Fabric8GuidelineSegment;

    const actionName = item.action;

    switch (actionName) {
      case Fabric8SmartObjectType.distributedHoles:
        data.smartObjectData = {
          type: Fabric8SmartObjectType.distributedHoles,
          parameters: {
            drillTool: this.toolService.getCurrentDrillTool(),
            count: 3,
          },
        };
        updateChildrenNodesForGuidelineSegment(node);
        this.elementsService.commitCurrentNodeData();
        break;
      case Fabric8SmartObjectType.equallySpacedHoles:
        data.smartObjectData = {
          type: Fabric8SmartObjectType.equallySpacedHoles,
          parameters: {
            drillTool: this.toolService.getCurrentDrillTool(),
            distance: 10,
          },
        };
        updateChildrenNodesForGuidelineSegment(node);
        this.elementsService.commitCurrentNodeData();
        break;
      default:
        break;
    }

    this.reset();
  }
}
