import { Fabric8Node } from '../../models/node.model';

export function getNodeById(
  elementId: string,
  node: Fabric8Node
): Fabric8Node | undefined {
  const traverse = (node: Fabric8Node): Fabric8Node | undefined => {
    if (node.data && node.id == elementId) {
      return node;
    }

    if (node.children) {
      for (const child of node.children) {
        const foundNode = traverse(child);
        if (foundNode) {
          return foundNode;
        }
      }
    }

    return undefined;
  };
  return traverse(node);
}
