import { Fabric8Node } from '../../models/node.model';

export function deleteNodeById(nodeId: string, rootNode: Fabric8Node) {
  const traverse = (node: Fabric8Node) => {
    node.children = node.children?.filter((child) => {
      return child.id != nodeId;
    });

    node.children?.forEach(traverse);
  };

  traverse(rootNode);
}
