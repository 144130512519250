import {
  AfterViewInit,
  Directive,
  ElementRef,
  TemplateRef,
} from '@angular/core';

@Directive({ selector: '[editMode]' })
export class EditModeDirective {
  constructor(public tpl: TemplateRef<any>) {}
}

@Directive({ selector: '[viewMode]' })
export class ViewModeDirective {
  constructor(public tpl: TemplateRef<any>) {}
}

@Directive({ selector: '[focusable]' })
export class FocusableDirective implements AfterViewInit {
  constructor(private host: ElementRef) {}
  ngAfterViewInit() {
    this.host.nativeElement.focus();
  }
}
