import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragHandleDirective } from './drag-handle.directive';
import { DragDirective } from './drag.directive';
import { DropzoneDirective } from './dropzone.directive';
import { IndentDirective } from './indent.directive';
import { TreeGroupDirective } from './tree-group.directive';
import { IndentComponent } from './indent/indent.component';
import { DropListComponent } from './drop-list/drop-list.component';
import { DropListService } from './drop-list/drop-list.service';
import { DragService } from './drag-service.service';
import { MatTooltipModule } from '@angular/material/tooltip';

const components = [
  // @components
  IndentComponent,
  DropListComponent,
];

const directives = [
  DropzoneDirective,
  DragDirective,
  DragHandleDirective,
  IndentDirective,
  TreeGroupDirective,
];

const services = [DropListService, DragService];

const modules = [CommonModule, MatTooltipModule];

@NgModule({
  declarations: [...components, ...directives],
  imports: [...modules],
  providers: [...services],
  exports: [...modules, ...components, ...directives],
})
export class WTreeModule {}
