export const units = [
  {
    name: 'Length',
    selected: 'mm',
    options: [
      { name: 'Millimeter', short: 'mm' },
      { name: 'Centimeter', short: 'cm' },
      { name: 'Meter', short: 'm' },
    ],
  },
  {
    name: 'Area',
    selected: 'm2',
    options: [
      { name: 'Sq meter', short: 'm2' },
      { name: 'Sq centimeter', short: 'cm2' },
    ],
  },
  {
    name: 'Volume',
    selected: 'm3',
    options: [{ name: 'Cubic meter', short: 'm3' }],
  },
  {
    name: 'Weight',
    selected: 't',
    options: [
      { name: 'Tonne', short: 't' },
      { name: 'Kilogram', short: 'kg' },
    ],
  },
  {
    name: 'Angle',
    selected: 'deg',
    options: [{ name: 'Degrees', short: 'deg' }],
  },
];
