<div class="pbox">
  <ng-template [ngIf]="project" [ngIfElse]="loadingOrError">
    <div class="project-grid">
      <div id="header">
        <div class="row">
          <mat-icon [inline]="true" class="back-button" (click)="closeProject()"
            >arrow_back_ios
          </mat-icon>

          <ng-template [ngIf]="showSearch">
            <sw-search-box></sw-search-box>
          </ng-template>

          <ng-template [ngIf]="!showSearch">
            <sw-editable
              id="project-name"
              class="title"
              [(value)]="project.name"
              (endEditing)="updateProject()"
            >
            </sw-editable>
          </ng-template>

          <div class="buttons">
            <ng-template [ngIf]="!showSearch">
              <div class="icon" (click)="startSearching()">
                <mat-icon [inline]="true">search</mat-icon>
              </div>
            </ng-template>

            <div
              class="icon"
              (click)="openPlugins()"
              matTooltip="{{ 'PROJECT.MENU_PLUGINS' | translate }}"
              matTooltipPosition="above"
            >
              <mat-icon [inline]="true">power</mat-icon>
            </div>

            <div
              class="icon dark"
              (click)="openSettings()"
              matTooltip="{{ 'PROJECT.MENU_SETTINGS' | translate }}"
              matTooltipPosition="above"
            >
              <mat-icon [inline]="true">tune</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="wbox container scrolly">
        <section id="plugins" *ngIf="project.data.length > 0">
          <div
            cdkDropList
            [cdkDropListData]="project.data"
            (cdkDropListDropped)="drop($event)"
          >
            <sw-project-data
              *ngFor="let pdata of project.data; let i = index"
              [(data)]="project.data[i]"
            >
              <!-- (nodeAdded)="addNode($event)" -->
            </sw-project-data>
          </div>
        </section>
        <section id="empty" class="spacer" *ngIf="project.data.length === 0">
          <sw-message
            message="{{ 'PROJECT.NO_PLUGINS' | translate }}"
            icon="power_off"
            actionText="{{ 'PROJECT.ADD_PLUGIN_BUTTON' | translate }}"
            (action)="openPlugins()"
          >
          </sw-message>
        </section>
      </div>
    </div>
  </ng-template>
  <!-- <div class="spacer"></div> -->
</div>

<ng-template #loadingOrError>
  <div class="wbox scrolly flex column">
    <sw-message
      *ngIf="errorMessage"
      [message]="errorMessage"
      icon="warning"
      actionText="{{ 'GLOBAL.CLOSE' | translate }}"
      (action)="closeProject()"
    >
    </sw-message>
    <div *ngIf="!errorMessage" class="spinner-container">
      <div class="center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</ng-template>
