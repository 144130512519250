import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { MaterialModule } from './material.module';
import { WindowRef } from './window-ref.service';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { DotsMenuComponent } from '../components/dots-menu/dots-menu.component';
import { FocusableDirective } from './editable/directives';
import { EditableComponent } from './editable/editable.component';
import { ErrorMessageComponent } from '../components/sw-message/sw-message.component';
import { SwConfirmDialogComponent } from './sw-confirm-dialog';
import { SearchBoxComponent } from '../components/search-box/search-box.component';
import { SharingComponent } from '../components/sharing/sharing.component';

// image uploader/cropper module
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig,
} from '@alyle/ui';
import { LyButtonModule } from '@alyle/ui/button';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { SwImageUploaderComponent } from './sw-image-uploader/sw-image-uploader.component';
import { LySliderModule } from '@alyle/ui/slider';
import { TranslateModule } from '@ngx-translate/core';
import { SegmentedButtonsComponent } from '../components/segmented-buttons/segmented-buttons.component';
// end image uploader/cropper imports

const components = [
  DotsMenuComponent,
  EditableComponent,
  FocusableDirective,
  SwConfirmDialogComponent,
  ErrorMessageComponent,
  SearchBoxComponent,
  SwImageUploaderComponent,
  SharingComponent,
  SegmentedButtonsComponent,
];

const modules = [
  // @angular
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  HttpClientModule,
  // @material
  MaterialModule,

  LyButtonModule,
  LySliderModule,
  LyImageCropperModule,
  HammerModule,
];

const inlineSVG = InlineSVGModule.forRoot({ baseUrl: '/assets/' });

@NgModule({
  declarations: [...components],
  imports: [...modules, inlineSVG, TranslateModule.forChild()],
  exports: [...components, ...modules, InlineSVGModule],
  providers: [
    WindowRef,
    inlineSVG.providers,
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
  ],
})
export class SharedModule {}
