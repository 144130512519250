import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ModelManagerHelperService } from '../model-manager-helper/model-manager-helper.service';
import { ModelManagerSketchUpService } from '../model-manager-sketch-up.service';
import {
  MMHubCandidate,
  MMHubCandidateBuildState,
} from '../models/MMHubCandidate.model';

@Component({
  selector: 'sw-model-manager-helper-row',
  templateUrl: './model-manager-helper-row.component.html',
  styleUrls: ['./model-manager-helper-row.component.scss'],
})
export class ModelManagerHelperRowComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() viewMode: string;
  @Input() data: MMHubCandidate;
  // @Output() selectionChanged = new EventEmitter<boolean>()

  s = MMHubCandidateBuildState;

  selectedHubsObserver: Subscription;
  isObjectSelected: boolean;

  constructor(
    public helper: ModelManagerHelperService,
    public mm: ModelManagerSketchUpService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.isObjectSelected = Boolean(
        this.mm.selectedHubs
          .getValue()
          .find((c) => c.definition == changes.data.currentValue.definition)
      );
    }
  }

  ngOnDestroy(): void {
    this.selectedHubsObserver?.unsubscribe();
  }

  ngOnInit(): void {
    this.selectedHubsObserver = this.mm.selectedHubs$.subscribe(
      (hubCandidates) => {
        this.isObjectSelected = Boolean(
          hubCandidates.find((c) => c.definition == this.data.definition)
        );
        this.ref.detectChanges();
      }
    );
  }

  isSelected(): boolean {
    return this.helper.isSelected(this.data.definition);
  }

  async toggleSelection() {
    try {
      await this.helper.updateSelection(this.data, !this.isSelected());
    } catch (e) {
      // console.warn("0000")
    }
  }

  toggleExpansion() {
    this.helper.toggleExpansion(this.data);
  }

  expandedIcon(): string {
    let name = this.data.expanded ? 'expanded' : 'collapsed';
    return 'arrow-' + name + '.svg';
  }

  polyCount() {
    var value = this.data.polyCount;
    // var value = this.data.polyCountPreview
    if (value >= 1000 && value < 2500) {
      return Math.round(value / 100) / 10 + 'k';
    } else if (value > 2500) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }
}
