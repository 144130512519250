<div id="grid" class="wbox">
  <div id="header">
    <div id="dark" class="row">
      <mat-icon>auto_fix_high</mat-icon>
      {{ "MODEL_MANAGER.MODEL_MANAGER_HELPER" | translate }}
      <div class="spacer"></div>
      <ng-template [ngIf]="viewMode !== 'building'">
        <mat-icon class="pointer" (click)="helper.toggleMode()">
          {{ getModeIconName() }}
        </mat-icon>
        <mat-icon class="pointer" (click)="getCandidates()">refresh</mat-icon>
      </ng-template>
      <mat-icon class="pointer" (click)="close()">close</mat-icon>
    </div>

    <div class="row box search-input">
      <div matPrefix class="i"><mat-icon [inline]="true">search</mat-icon></div>
      <input
        matInput
        placeholder="Search"
        #filterByInput
        (input)="search($event)"
      />
      <div *ngIf="filterByInput.value.length > 0" class="i pointer">
        <mat-icon [inline]="true" (click)="clearSearch()">close</mat-icon>
      </div>
    </div>

    <div class="row face-count">
      <ng-template [ngIf]="viewMode !== 'building'" [ngIfElse]="title">
        <div
          *ngIf="viewMode === 'tree'"
          class="expand top"
          (click)="toggleExpansionAll()"
          [inlineSVG]="'icon/' + expandedIconAll()"
          matTooltip="{{ 'MODEL_MANAGER.EXPAND_ALL' | translate }}"
          matTooltipPositon="above"
        ></div>
        <div *ngIf="viewMode !== 'tree'" class="expand space"></div>

        <span class="small-title">{{
          "MODEL_MANAGER.POLY_COUNT" | translate
        }}</span>
        <mat-slider
          thumbLabel
          [displayWith]="formatLabel.bind(this)"
          color="primary"
          [value]="sliderVal"
          (change)="setFacesTreshold($event)"
          min="0"
          max="100"
          aria-label="units"
        >
        </mat-slider>

        <div
          *ngIf="viewMode === 'definitions'"
          class="expand top"
          (click)="helper.switchCountMode()"
          matTooltip="{{
            'MODEL_MANAGER.COUNT_' + helper.countMode | uppercase | translate
          }}"
          matTooltipPositon="above"
        >
          <mat-icon inline>
            {{ countModeIcon() }}
          </mat-icon>
        </div>

        <div
          *ngIf="viewMode === 'definitions'"
          class="expand top"
          (click)="helper.switchSorting()"
          matTooltip="{{ 'MODEL_MANAGER.SORT' | translate }}"
          matTooltipPositon="above"
        >
          <mat-icon inline>
            {{ sortIcon() }}
          </mat-icon>
        </div>
      </ng-template>
      <ng-template #title>
        <span class="small-title">{{
          "MODEL_MANAGER.GENERATE_HUBS" | translate
        }}</span>
      </ng-template>
    </div>
  </div>

  <ng-template [ngIf]="loading" [ngIfElse]="loadedView">
    <div class="loader">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </ng-template>

  <ng-template #loadedView>
    <ng-template
      [ngIf]="(helper.candidates$ | async).length === 0"
      [ngIfElse]="list"
    >
      <div class="spacer flex" id="report">
        <sw-message
          [message]="'MODEL_MANAGER.NO_CANDIDATES' | translate"
          icon="smart_toy"
          actionText="{{ 'GLOBAL.CLOSE' | translate }}"
          (action)="close()"
        >
        </sw-message>
      </div>
    </ng-template>

    <ng-template #list>
      <cdk-virtual-scroll-viewport
        id="report"
        itemSize="20"
        class="container scrolly"
      >
        <sw-model-manager-helper-row
          *cdkVirtualFor="let s of helper.candidates$; trackBy: guid"
          [data]="s"
          [viewMode]="viewMode"
        >
        </sw-model-manager-helper-row>
        <!-- (selectionChanged)="updateSelection(s, $event)"> -->
      </cdk-virtual-scroll-viewport>

      <section id="footer">
        <ng-template [ngIf]="viewMode !== 'building'" [ngIfElse]="buildButtons">
          <div class="row justify">
            <button
              mat-button
              class="btn-primary grey"
              (click)="selectAllVisible(true)"
              (dblclick)="selectAllVisible(false)"
            >
              {{ "MODEL_MANAGER.SELECT_ALL" | translate }}
            </button>
            <button
              mat-button
              [disabled]="helper.selectedDefinitions.size < 1"
              class="btn-primary active"
              (click)="helper.setMode('building')"
            >
              {{ "MODEL_MANAGER.GENERATE_HUBS" | translate }}
            </button>
          </div>
        </ng-template>

        <ng-template #buildButtons>
          <div id="build-options">
            <!-- <section >
                        <p>Please select the proxies you want to generate.</p>
                    </section> -->
            <ng-template [ngIf]="generatingPaused === true">
              <section>
                <div class="small-title">
                  {{ "MODEL_MANAGER.NEW_HUB_2D_DECOY" | translate }}
                </div>

                <sw-segmented-buttons
                  [buttons]="['no', 'yes']"
                  [allowMultipleSelection]="false"
                  [allowEmptySelection]="false"
                  [selection]="[helper.createOptions.create2d ? 'yes' : 'no']"
                  (selectionChanged)="
                    helper.createOptions.create2d = $event[0] == 'yes'
                  "
                >
                </sw-segmented-buttons>
              </section>
              {{ helper.createOptions.create2d }}
              <section>
                <div class="small-title">
                  {{ "MODEL_MANAGER.NEW_HUB_PROXY" | translate }}
                </div>
                <sw-segmented-buttons
                  [buttons]="proxyOptions"
                  [allowMultipleSelection]="false"
                  [allowEmptySelection]="false"
                  [selection]="[helper.createOptions.createProxy]"
                  (selectionChanged)="updateProxySelection($event[0])"
                >
                </sw-segmented-buttons>
              </section>
            </ng-template>
          </div>
          <div
            class="row justify"
            *ngIf="generatingInProgress === false && generatingPaused === true"
          >
            <button
              mat-button
              class="btn-primary"
              (click)="helper.setMode('definitions')"
            >
              {{ "GLOBAL.BACK" | translate }}
            </button>

            <button
              mat-button
              class="btn-primary active"
              (click)="startGeneratingProxies()"
            >
              {{ "GLOBAL.CONFIRM" | translate }}
            </button>
          </div>
          <div
            class="row center"
            *ngIf="generatingPaused === false && generatingInProgress === true"
          >
            <button mat-button class="btn-primary" (click)="pause()">
              {{ "MODEL_MANAGER.PAUSE_BUTTON" | translate }}
            </button>
          </div>
          <div
            class="row center"
            *ngIf="generatingDone === true && generatingInProgress === false"
          >
            <button mat-button class="btn-primary" (click)="close()">
              {{ "GLOBAL.DONE" | translate }}
            </button>
          </div>
        </ng-template>
      </section>
    </ng-template>
  </ng-template>
</div>
