import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  ViewEncapsulation,
  HostBinding,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

// @UntilDestroy()
@Component({
  selector: 'sw-editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditableComponent implements OnInit, OnChanges {
  @Input() value: string;
  @Input() placeholder: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() endEditing = new EventEmitter<string>();
  @Input() alwaysEditing: boolean = false;

  @HostBinding('class') @Input('class') classList = '';

  @Input() editing = false;

  constructor(private host: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alwaysEditing) {
      this.editing = changes.alwaysEditing.currentValue ? true : false;
    }

    if (changes.value) {
      this.filteredOptions = changes.value.currentValue
        ? this.filterAutocompleteOptions(changes.value.currentValue)
        : this.autocompleteSource.slice();
    }

    if (changes.autocompleteSource) {
      this.filteredOptions = this.filterAutocompleteOptions(this.value);
    }
  }

  ngOnInit(): void {
    if (this.alwaysEditing) {
      this.editing = true;
    }
  }

  doneEditing(event: any) {
    this.editing = this.alwaysEditing;
    this.endEditing.next(this.value);
    this.valueChange.next(this.value);
  }

  // autocomplete
  @Input() autocompleteSource: string[];
  filteredOptions: string[];

  doneEditingAutocomplete(event: any) {
    // run on next tick to allow selecting an option
    setTimeout(() => {
      this.doneEditing(event);
    }, 250);
  }

  filterAutocompleteOptions(name: string) {
    return this.autocompleteSource?.filter(
      (option) => option.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  selectOption(option: string) {
    this.value = option;
    this.filteredOptions = [];
    this.doneEditingAutocomplete(option);
  }
}
