<div class="table">
  <ng-container *ngFor="let a of attributes; let aIndex = index">
    <div class="trow">
      <div class="tcell attribute-name">
        <div [ngClass]="getClass(a.name)">{{ getName(a.name) }}</div>
      </div>

      <div class="tcell value" [ngSwitch]="a.type">
        <ng-container *ngSwitchCase="'dropdown'">
          <select
            [attr.disabled]="readonly ? '' : null"
            [(ngModel)]="a.value"
            [value]="a.value"
            (change)="updateAttribute(aIndex)"
          >
            <option *ngFor="let option of a.options" value="{{ option.value }}">
              {{ option.name }}
            </option>
          </select>
        </ng-container>

        <ng-container *ngSwitchCase="'material'">
          <div class="right pointer" (click)="openMaterialPicker(aIndex)">
            {{ a.value }}
            <ng-container
              *ngIf="a.value | materialThumbnail as mat; else maticn"
            >
              <img
                *ngIf="mat.thumbnail"
                class="thumbnail"
                [src]="mat.thumbnail"
              />
              <sw-color-block
                *ngIf="!mat.thumbnail"
                class="thumbnail"
                [colors]="mat.color"
              ></sw-color-block>
            </ng-container>
            <ng-template #maticn>
              <i class="material-icons small">apps</i>
            </ng-template>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <input
            #inp
            [attr.disabled]="readonly ? '' : null"
            [(ngModel)]="a.value"
            (change)="updateAttribute(aIndex)"
            (keydown.enter)="inp.blur()"
          />
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
