import { Project } from './project.model';
import { UserAccess } from './user-access.model';

export class ProjectsGroup {
  projectIDs: string[]; // ordered array of projectIDs
  groupIDs: string[]; // ordered array of groupIDs
  constructor(
    public id: string,
    public name: string,
    public expanded: boolean,
    public projects: Project[],
    public groups: ProjectsGroup[]
  ) // public access: UserAccess[]
  {}
}
