import { Component, Input } from '@angular/core';

@Component({
  selector: 'sw-tree-indent',
  template: '<div *ngFor="let i of [].constructor(level)"></div>',
  styleUrls: ['./indent.component.scss'],
})
export class IndentComponent {
  @Input() level: number;

  constructor() {}
}
