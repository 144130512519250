import { Component, Input, OnInit } from '@angular/core';
import { DataNode } from 'src/app/model/data-node.model';
import { ProjectData } from 'src/app/model/project-data.model';
import { Project } from 'src/app/model/project.model';
import { DataNodeService } from 'src/app/modules/project/services/data-node.service';
import { ProjectFileLinkingService } from 'src/app/modules/project/services/project-file-linking.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { SketchupService } from 'src/app/services/sketchup.service';
import { SmartworksPluginComponent } from '../swplugin/swplugin.component';

@Component({
  selector: 'sw-plugin-pap-basic',
  templateUrl: './pap-basic.component.html',
  styleUrls: ['./pap-basic.component.scss'],
})
export class PapBasicComponent
  extends SmartworksPluginComponent
  implements OnInit
{
  @Input() declare projectData: ProjectData;
  @Input() declare rootNode?: DataNode;
  project: Project;

  constructor(
    public sketchUp: SketchupService,
    public dataNodeService: DataNodeService,
    public projectService: ProjectService,
    private fileLinkService: ProjectFileLinkingService
  ) {
    super(sketchUp, dataNodeService, projectService);
  }

  ngOnInit(): void {
    this.project = this.projectService.getProject();
  }

  async runAction(action: string): Promise<any> {
    alert('-');
    return false;
  }

  hasLayOutLinkedFile() {
    return Boolean(this.project.layoutFilePath);
  }

  // Invokes SketchUp's [send to layout] action.
  // This doesn't link the file.
  sendToLayout() {
    this.sketchUp.bridge.call('sendToLayout', this.project);
  }

  async linkToLayout() {
    //save layout file path in the db
    const linked = await this.fileLinkService.linkLayoutFile(this.project);
    if (!linked) {
      console.error('layout file was not linked');
    } else {
      this.updateLayout();
    }
  }

  async updateLayout() {
    await this.transferData();
    await this.sketchUp.bridge.get('updateLayout', this.project);
  }

  async nodesUpdated(nodes: DataNode[]) {
    await this.transferData();
  }

  async transferData(): Promise<any> {
    // export planAProject attributes
    let attr = this.extractAttributes(this.rootNode);

    // export cabN8 attributes
    let exportCabn8Data = true;
    if (exportCabn8Data) {
      let cabn8RootNode = await this.sketchupService.runAction(
        'cabn8',
        'getRootNode'
      );

      if (cabn8RootNode) {
        let cabn8Attr = this.extractAttributes(cabn8RootNode);
        cabn8Attr.forEach((value, key, map) => {
          attr.set('CabN8.' + key, value);
        });
      }
    }

    // attr.forEach((v, k, m) => console.log(k, v))
    let array = Array.from(attr).filter(
      (arr) => arr[0] !== null && arr[1] !== null
    );
    return this.planAProjectDataSet(array);
  }

  extractAttributes(node: DataNode): Map<string, string> {
    var tagValuePairs = new Map();

    node.attributes?.forEach((attr) => {
      if (Boolean(attr.tag) == false && Boolean(attr.value)) {
        alert("Warning: You are exporting attributes which don't have a tag!");
      }
      if (attr.type == 'dropdown') {
        var dropdownLabel = attr.options.find(
          (o) => o.value == attr.value
        ).name;
        tagValuePairs.set(attr.tag, dropdownLabel);
      } else {
        tagValuePairs.set(attr.tag, attr.value);
      }
    });

    node.children.forEach((child) => {
      const childTagValuePairs = this.extractAttributes(child);

      tagValuePairs = new Map([...tagValuePairs, ...childTagValuePairs]);
    });

    return tagValuePairs;
  }

  async planAProjectDataSet(data: any): Promise<boolean> {
    try {
      console.log(JSON.stringify(data));
      this.sketchUp.bridge.get('planAProjectDataSet', 'autotext', data);
      return true;
    } catch (e) {
      return false;
    }
  }

  addNewItem() {
    this.dataNodeService.addDataNodeChild.emit();
  }
}
