import {
  ImgCropperConfig,
  ImgCropperErrorEvent,
  ImgCropperEvent,
  LyImageCropper,
} from '@alyle/ui/image-cropper';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sw-image-uploader',
  templateUrl: './sw-image-uploader.component.html',
  styleUrls: ['./sw-image-uploader.component.scss'],
})
export class SwImageUploaderComponent {
  @Output() base64Image = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<string>();

  constructor() {}

  ready: boolean;
  scale: number;
  minScale: number;
  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 130, // Default `250`
    height: 130, // Default `200`
    type: 'image/png', // Or you can also use `image/jpeg`
    output: {
      width: 130,
      height: 130,
    },
  };
  onCropped(e: ImgCropperEvent) {
    // console.log('Cropped img: ', e)
    this.base64Image.emit(e.dataURL);
  }

  setScale(n: number | number[]) {
    this.scale = Array.isArray(n) ? n[0] : n;
  }

  onReady(e: ImgCropperEvent) {
    this.ready = true;
    // console.log('Image ready for cropper', e)
  }

  onError(e: ImgCropperErrorEvent) {
    // console.warn(`'${e.name}' is not a valid image`, e)
  }

  onLoaded(e: ImgCropperEvent) {
    // console.log('img loaded', e)
  }

  onDone() {
    var base64img = this.cropper.crop();
  }

  onCancel() {
    if (this.ready) {
      this.cropper.clean();
      return;
    }
    this.cancel.emit('');
  }
}
