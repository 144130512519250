import { Component } from '@angular/core';
import { Router } from '@angular/router';

import {
  AuthService,
  UserCredentials,
} from 'src/app/core/services/auth.service';

@Component({
  selector: 'sw-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  form: UserCredentials = {
    username: '',
    password: '',
  };

  isLoginFailed = false;
  errorMessage = '';

  loggingIn = false;

  constructor(public authService: AuthService, private router: Router) {}

  async onSubmit() {
    this.loggingIn = true;

    try {
      await this.authService.login(this.form);
      this.isLoginFailed = false;
      this.loggingIn = false;
      this.goHome();
    } catch (err) {
      console.log(err);
      this.errorMessage = err.error.message;
      this.isLoginFailed = true;
      this.loggingIn = false;
    }
  }

  goHome(): void {
    this.router.navigate(['']);
  }
}
