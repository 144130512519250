<ng-template #customTopButtons>
  <div
    class="icon plus"
    [inlineSVG]="'icon/plus.svg'"
    (click)="addNewItem()"
    matTooltip="{{ 'PROJECT_EDIT.NEW_ITEM_TOOLTIP' | translate }}"
    matTooltipPosition="above"
  ></div>
</ng-template>

<ng-template [ngIf]="sketchUp.isSketchup" [ngIfElse]="notSketchup">
  <div class="row full flex buttons">
    <div class="spacer"></div>

    <ng-template [ngIf]="hasLayOutLinkedFile()" [ngIfElse]="noLayoutFile">
      <div class="btn-primary" (click)="updateLayout()">
        <div class="icon" [inlineSVG]="'icon/layout.svg'"></div>
        {{ "PLANAPROJECT.UPDATE_LAYOUT_FILE" | translate }}
      </div>
    </ng-template>

    <ng-template #noLayoutFile>
      <div class="btn-primary" (click)="sendToLayout()">
        <div class="icon" [inlineSVG]="'icon/layout.svg'"></div>
        {{ "PLANAPROJECT.SEND_TO_LAYOUT" | translate }}
      </div>
      <div class="btn-primary" (click)="linkToLayout()">
        <div class="icon" [inlineSVG]="'icon/layout.svg'"></div>
        {{ "PLANAPROJECT.LINK_LAYOUT_FILE" | translate }}
      </div>
    </ng-template>

    <div class="spacer"></div>
  </div>
</ng-template>

<ng-template #notSketchup>
  <div class="row flex buttons">
    <div class="spacer"></div>
    <small
      class="hint"
      [innerHTML]="'PLANAPROJECT.WEB_SKETCHUP_MESSAGE' | translate"
    ></small>
    <div class="spacer"></div>
  </div>
</ng-template>

<div swTreeGroup class="nodes">
  <sw-data-node [parent]="undefined" [(node)]="rootNode"></sw-data-node>
</div>
