import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Fabric8Element } from '../models/element.model';
import { Fabric8Node, IFabric8Node } from '../models/node.model';
import { Fabric8NodeType } from '../models/Fabric8NodeType';
import { Fabric8NodeData } from '../models/types/Fabric8NodeData.type';
import { Fabric8Object } from '../models/object.model';
import { ObjectsManagerService } from './objects-manager.service';
import { Fabric8SketchupService } from './fabric8-sketchup.service';
import { Fabric8Guideline } from '../models/guideline.model';
import { UndoService } from './undo.service';
import { getNodesFlatList } from './nodes/getNodesFlatList';
import { getNodeById } from './nodes/getNodeById';
import { deleteNodeById } from './nodes/deleteNodeById';
import { newNodeWithData } from './nodes/newNodeWithData';

@Injectable({
  providedIn: 'root',
})
export class CurrentObjectService implements OnDestroy {
  currentObjectSubscription: Subscription;
  private theObject?: Fabric8Object;

  get() {
    return this.theObject;
  }

  constructor(
    private objectsManager: ObjectsManagerService,
    private sketchupHelper: Fabric8SketchupService,
    private undoService: UndoService
  ) {
    this.subscribeToCurrentObjectID();
  }

  ngOnDestroy(): void {
    this.currentObjectSubscription?.unsubscribe();
  }

  private subscribeToCurrentObjectID() {
    this.currentObjectSubscription =
      this.objectsManager.currentObjectId.subscribe((objectID) => {
        this.theObject = this.objectsManager.getObjectWithId(objectID);
      });
  }

  addEmptyGroupOnNode(node: Fabric8Node) {
    const newGroupNode = new Fabric8Node({
      [IFabric8Node]: true,
      id: '', // generate random id
      name: 'Group',
      type: Fabric8NodeType.Group,
      children: [],
      readOnly: false,
    });

    node.children.push(newGroupNode);
    return newGroupNode;
  }

  addNewNodeWithData(data: Fabric8NodeData): Fabric8Node {
    const newNode = this.addNewNodeWithDataToNode(
      data,
      this.theObject?.rootNode
    );
    return newNode;
  }

  addNewNodeWithDataToNode(data: Fabric8NodeData, node: Fabric8Node) {
    const newNode = newNodeWithData(data);
    node.children.push(newNode);
    return newNode;
  }

  deleteNodeById(nodeId: string) {
    deleteNodeById(nodeId, this.theObject?.rootNode);
  }

  getNodeById(elementId: string): Fabric8Node | undefined {
    return getNodeById(elementId, this.theObject?.rootNode);
  }

  getObjectNodesFlatList(types: Fabric8NodeType[]): Fabric8Node[] {
    return getNodesFlatList(types, this.theObject?.rootNode);
  }

  findGroupNodeContainingNodeId(nodeId: string): Fabric8Node | undefined {
    const nodes = this.getObjectNodesFlatList([
      Fabric8NodeType.Group,
    ]) as Fabric8Node[];
    return nodes.find((node) => {
      return node.children.find((child) => child.id === nodeId);
    });
  }

  export() {
    //TODO: do this over live updates
    this.sketchupHelper.exportFabric8Object(this.theObject);
    console.log('Export done');
  }

  update(shouldExport: boolean = false) {
    this.objectsManager.updateObject(this.theObject);
    // console.log('update object, should export: ' + shouldExport);
    if (shouldExport) {
      this.undoService.addStepForObject(this.theObject);
      this.export();
    }
  }

  // TODO: the guidelines need to be cached into a Set
  public getGuidelineDataWithId(id: string): {
    node: Fabric8Node;
    guideline: Fabric8Guideline;
  } {
    const node = this.getNodeById(id);
    const guideline = node && (node.data as Fabric8Guideline);
    return {
      node: node,
      guideline: guideline,
    };
  }

  // TODO: the elements need to be cached into a Set
  public getElementDataWithId(id: string): {
    node: Fabric8Node;
    element: Fabric8Element;
  } {
    const node = this.getNodeById(id);
    const element = node && (node.data as Fabric8Element);
    return {
      node: node,
      element: element,
    };
  }

  public getNodeDataWithId(id: string): {
    node: Fabric8Node;
    data: Fabric8NodeData;
  } {
    const node = this.getNodeById(id);
    const data = node && node.data;
    return {
      node: node,
      data: data,
    };
  }
}
