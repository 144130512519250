import { HttpClient } from '@angular/common/http';
import { sha1 } from 'object-hash';
import { Injectable, OnDestroy } from '@angular/core';
import { assign } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LibraryItem } from 'src/app/model/library-item.model';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { UpdateLibraryItemMessage } from 'src/app/services/liveupdates/messages';
import { SketchupService } from 'src/app/services/sketchup.service';
import { environment } from 'src/environments/environment';
import { PluginService } from '../plugins/plugin.service';
import { LibraryLabelsService } from './library-labels.service';
import { NewLibraryItemDialogData } from './new-library-item/new-library-item-dialog';
import { LibraryCategory } from 'src/app/model/library-category';
import { getRandomString } from 'src/app/core/helpers/getRandomString';
import { LibraryLiveUpdateService } from 'src/app/services/liveupdates/library-lables-live-update.service';
import { SearchService } from 'src/app/services/search.service';
import { DataAttribute } from 'src/app/model/data-attribute.model';

@Injectable({
  providedIn: 'root',
})
export class LibraryService implements OnDestroy {
  // all the items
  allTheItems: Map<string, BehaviorSubject<LibraryItem[]>> = new Map();

  // used by data nodes to update the linked item
  updatedLibraryItemSignal: Subject<{ item: LibraryItem; pluginID: string }> =
    new Subject();

  labelUpdatesSubscription: Subscription;
  qid = 'library-service:' + getRandomString(3);

  private categoryCache: Map<string, LibraryCategory[]> = new Map();
  private categoryTagsCache: Map<string, string[]> = new Map();

  constructor(
    private http: HttpClient,
    private libraryLabelsService: LibraryLabelsService,
    private libraryLiveUpdatesService: LibraryLiveUpdateService,
    private sketchUp: SketchupService
  ) {
    // these were previously placed in setPlugin
    this.initLiveUpdateHandlers();
    this.subscribeToLabelUpdates();
  }

  initLiveUpdateHandlers() {
    this.libraryLiveUpdatesService.updateLibraryItemSignal.subscribe(
      (uli: UpdateLibraryItemMessage) => {
        this.updateIfNeeded(uli);
      }
    );

    this.libraryLiveUpdatesService.deleteLibraryItemSignal.subscribe((id) => {
      this.removeItem(id);
    });

    this.libraryLiveUpdatesService.reloadLibrarySignal.subscribe((_) => {
      this.reloadAllLibraries();
    });
  }

  ngOnDestroy(): void {
    this.labelUpdatesSubscription.unsubscribe();
    // this.labelsSubscription.unsubscribe()
  }

  libraryAPItoLibraryItem(i: any): LibraryItem {
    var item = {
      id: String(i.id),
      name: i.name,
      localName: i.local_name,
      attributes: this.preprocessAttributes(i.attributes),
      labels: this.libraryLabelsService.getLabelsWithIDs(i.labelIDs),
      labelIDs: i.labelIDs,
      thumbnailHash: i.thumbnailHash,
      thumbnail: null,
      access: i.access,
    };

    item.labels = this.libraryLabelsService.getLabelsWithIDs(item.labelIDs);

    return item;
  }

  preprocessAttributes(attributes: DataAttribute[]) {
    return attributes.map((a) => {
      if (a.list == null) delete a.list;
      return a;
    });
  }

  async reloadAllLibraries() {
    for (const pluginID of this.allTheItems.keys()) {
      await this.getAll(pluginID);
    }
  }

  async getAll(pluginID: string) {
    const url = environment.apiUrl + '/librarynode/fetch';
    const request = {
      plugin: pluginID,
    };

    const response = await this.http.post<any[]>(url, request).toPromise();
    const theItems = response.map((i) => this.libraryAPItoLibraryItem(i));

    var subject = this.allTheItems.get(pluginID);
    if (!subject) {
      this.allTheItems.set(pluginID, new BehaviorSubject([]));
    }
    this.allTheItems.get(pluginID).next(theItems);

    // this.updateSelectedItem()
    // this.filterItems()

    await this.getCategoryAttributeTagsForPlugin(pluginID);
  }

  async getOrFetchItem(id: string, pluginID: string): Promise<LibraryItem> {
    var item = this.allTheItems
      .get(pluginID)
      .getValue()
      .find((i) => i.id == id);
    if (item) return item;

    item = await this.fetchItem(id);
    return item;
  }

  async fetchItem(id: string): Promise<LibraryItem> {
    const url = environment.apiUrl + '/librarynode/' + id;

    // TODO: remove duplicate calls
    // create a fetchingItems Map<id::sha, subject<item>>
    // if the id::sha is already in the map, subscribe to the promise

    const item = await this.http.get<any>(url).toPromise();
    return this.libraryAPItoLibraryItem(item);
  }

  async createItem(data: NewLibraryItemDialogData) {
    const url = environment.apiUrl + '/librarynode/create';

    const postData = {
      name: data.itemName,
      bundle: data.plugin.id,
      category_id: data.itemCategory.id,
    };

    const newItem = await this.http
      .post<LibraryItem>(url, postData)
      .toPromise();
    newItem.labels = [];
    newItem.labelIDs = [];

    return newItem;
  }

  async saveItem(item: LibraryItem) {
    const url = environment.apiUrl + '/librarynode/';

    const itemRequest = {
      id: item.id,
      labelIDs: item.labels.map((l) => l.id),
      name: item.name,
      localName: item.localName,
      attributes: item.attributes,
      access: item.access,
    };

    try {
      await this.http.post<any>(url, itemRequest).toPromise();

      await this.sketchUp.bridge.get('GardN8replant', item.id);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  removeItem(itemID: string) {
    this.allTheItems.forEach((pluginItemsSubject) => {
      const initCount = pluginItemsSubject.getValue().length;
      const filtered = pluginItemsSubject
        .getValue()
        .filter((i) => i.id != itemID);

      if (initCount != filtered.length) {
        pluginItemsSubject.next(filtered);
      }
    });
  }

  async deleteItem(item: LibraryItem) {
    const url = environment.apiUrl + '/librarynode/' + item.id;

    try {
      await this.http.delete<LibraryItem>(url).toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }

  async updateThumbnail(item: LibraryItem) {
    const url = environment.apiUrl + '/librarynode/thumbnail';

    const request = {
      id: item.id,
      thumbnail_content: item.thumbnail,
    };

    try {
      await this.http.post<LibraryItem>(url, request).toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }

  async fetchThumbnail(item: LibraryItem): Promise<string | undefined> {
    const url =
      environment.apiUrl +
      '/librarynode/thumbnail/' +
      item.id +
      '?h=' +
      item.thumbnailHash;

    try {
      const result = await this.http.get<any>(url).toPromise();
      return result.content;
    } catch (err) {
      return undefined;
    }
  }

  // async autoThumbnail(itemName: string) : Promise<string | undefined> {
  //   const url = "https://pixabay.com/api/"
  //   const params = {
  //     "key": "24290438-cf910ff5d4352d4015da89334",
  //     "q": itemName,
  //     "image_type": "photo",
  //     "safesearch": true,
  //     "per_page": 5
  //   }

  //   const results = await this.http.get(url, { "params" : params }).toPromise()
  //   const hits = results['hits']
  //   if (hits) {
  //     console.log(results)
  //     console.log(hits)
  //     try {
  //       var image = hits[0]
  //       const imageData = await this.http.get(image['previewURL']).toPromise()
  //       return btoa(imageData.toString())
  //     } catch (e) {
  //       console.error("can't fetch image from pixabay")
  //       console.error(e)
  //     }
  //     return undefined
  //   } else {
  //     return undefined
  //   }
  // }

  subscribeToLabelUpdates() {
    this.labelUpdatesSubscription =
      this.libraryLabelsService.updatedLabel.subscribe((newLabel) => {
        this.allTheItems.forEach((pluginLibraryItems) => {
          var pluginItems = pluginLibraryItems.getValue();
          pluginItems.forEach((item) =>
            item.labels
              .filter((label) => label.id == newLabel.id)
              .forEach((label) => assign(label, newLabel))
          );
        });

        // apply the new label data to the labels attached to library items
      });

    // console.error("REMOVE THIS SUBSCRIPTION")
    // the libraryLiveUpdates service must be able to check what labels are currently open
    // this.labelsSubscription = this.libraryLabelsService.labels.subscribe(allTheLabels => {
    //   // this.libraryLiveUpdatesService.updateLabelsForLibrary(allTheLabels, this.plugin.getValue().id)
    // })
  }

  // Live updates
  async updateIfNeeded(message: UpdateLibraryItemMessage) {
    var allTheItemsSubject = this.allTheItems.get(message.pluginID);
    var allTheItems = allTheItemsSubject.getValue();

    var currentItemIndex = allTheItems.findIndex(
      (i) => String(i.id) == message.libraryItemID
    );
    if (
      currentItemIndex > -1 &&
      sha1(allTheItems[currentItemIndex]) == message.hash
    ) {
      // the item is up to date
      return;
    }

    try {
      const item = await this.fetchItem(message.libraryItemID);

      // search again
      var currentItemIndex = allTheItems.findIndex(
        (i) => String(i.id) == message.libraryItemID
      );

      // update
      if (currentItemIndex > -1) {
        allTheItems[currentItemIndex] = item;
      } else {
        allTheItems.push(item);
      }

      allTheItemsSubject.next(allTheItems);
      this.updatedLibraryItemSignal.next({
        item: item,
        pluginID: message.pluginID,
      });
    } catch (err) {
      console.error("couldn't fetch the updated Library Item");
    }
  }

  removeLabel(labelID: string) {
    this.reloadAllLibraries();
  }

  async fetchCategories(pluginID: string): Promise<LibraryCategory[]> {
    const cached = this.categoryCache.get(pluginID);
    if (cached) return cached;

    const url = environment.apiUrl + '/library/category/fetch';
    const request = { pluginID: pluginID };
    const categories = await this.http
      .post<LibraryCategory[]>(url, request)
      .toPromise();
    this.categoryCache.set(pluginID, categories);
    return categories;
  }

  async getCategoryAttributeTagsForPlugin(pluginID: string): Promise<string[]> {
    const cached = this.categoryTagsCache.get(pluginID);
    if (cached) return cached;

    const url = environment.apiUrl + '/library/category/attribute-tags';
    const request = { pluginID: pluginID };
    const response = await this.http.post<any>(url, request).toPromise();
    const lockedTags = response.tags;
    this.categoryTagsCache.set(pluginID, lockedTags);
    return lockedTags;
  }

  getCachedCategoryAttributeTagsForPlugin(pluginID: string): string[] {
    return this.categoryTagsCache.get(pluginID) || [];
  }
}
