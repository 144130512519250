<div id="header">
  <mat-icon (click)="close()">apps</mat-icon>
  <div class="title">{{ "MATERIAL_PICKER.SELECT_MATERIAL" | translate }}</div>
  <div class="spacer"></div>
  <mat-icon (click)="close()" class="close icons" [inline]="true"
    >close</mat-icon
  >
</div>

<ng-template [ngIf]="state === 'ItemsLoaded'">
  <div class="top-container row">
    <ng-template [ngIf]="!showSearch" [ngIfElse]="searchbox">
      <div class="breadcrumbs">
        <span>
          <mat-icon
            (click)="navigateToCollectionLevel(-1)"
            [ngClass]="{ linked: materialPathsService.localFolder?.length > 0 }"
            class="folder btm"
            inline="true"
            >folder</mat-icon
          >
        </span>
        <span
          class="crumb"
          *ngFor="let crumb of currentCollection.path; let i = index"
          (click)="navigateToCollectionLevel(i)"
          [ngClass]="{ last: i === currentCollection.path.length - 1 }"
          >{{ ellipse(crumb, 30) }}
        </span>
      </div>
      <ng-template [ngIf]="currentCollection?.root === true">
        <button class="btn-primary" mat-button (click)="pickFolder()">
          {{ "MATERIAL_PICKER.ADD_FOLDER" | translate }}
        </button>
      </ng-template>
      <ng-template [ngIf]="!currentCollection?.root === true">
        <mat-icon class="pointer" (click)="showSearch = true" inline="true"
          >search</mat-icon
        >
      </ng-template>
    </ng-template>
    <ng-template #searchbox>
      <sw-search-box
        *ngIf="state === 'ItemsLoaded'"
        [hideLabels]="true"
      ></sw-search-box>
      <mat-icon class="pointer" (click)="showSearch = false" inline="true"
        >close</mat-icon
      >
    </ng-template>
  </div>
</ng-template>

<div id="content" class="wbox scrolly no-top-padding">
  <!-- <div class="row" style="padding-bottom:1rem">
        <div class="spacer"></div>
        <div *ngIf="this.currentCollection?.root === true">
            <button class="btn-primary" 
            mat-button 
            (click)="pickFolder()">{{ 'MATERIAL_PICKER.ADD_FOLDER' | translate  }}</button>
        </div>    
        <div class="spacer"></div>
    </div> -->

  <ng-template [ngIf]="state === 'Loading'">
    <div id="loader" class="center spacer">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </ng-template>

  <ng-template [ngIf]="state === 'ItemsLoaded'">
    <div class="materials-grid">
      <div
        *ngFor="let item of filteredItems; let i = index; trackBy: materialByID"
        class="item"
        [ngClass]="{
          collection: item.path,
          'root-collection': currentCollection.root
        }"
      >
        <ng-template
          [ngIf]="currentCollection.root === true"
          [ngIfElse]="materialIcon"
        >
          <!-- root folder -->
          <mat-icon
            (click)="openFolder(item.path[0])"
            [matTooltip]="item.path[0]"
            matTooltipPosition="after"
            class="folder btm icon"
            inline="true"
            >folder</mat-icon
          >
          <div
            (click)="selectMaterial(item)"
            class="collection-name"
            *ngIf="item.path"
          >
            {{ ellipse(item.path[0], 35) }}
          </div>

          <sw-dots-menu [itemID]="'p' + i" [ngClass]="'toolset-menu'">
            <div
              class="hover-btn red delete"
              [inlineSVG]="'icon/delete.svg'"
              matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
              matTooltipPosition="above"
              (click)="delete(item.path)"
            ></div>
            <div
              class="hover-btn rescan"
              [inlineSVG]="'icon/refresh.svg'"
              matTooltip="{{ 'MATERIAL_PICKER.RESCAN' | translate }}"
              matTooltipPosition="above"
              (click)="rescan(item.path)"
            ></div>
          </sw-dots-menu>
        </ng-template>
        <ng-template #materialIcon>
          <div
            class="material"
            (click)="selectMaterial(item)"
            matTooltip="{{ item.name }}"
            matTooltipPosition="above"
          >
            <ng-template [ngIf]="item.thumbnail" [ngIfElse]="colorBlock">
              <img src="{{ item.thumbnail }}" />
            </ng-template>
            <ng-template #colorBlock>
              <sw-color-block
                class="colors"
                [colors]="item.color"
              ></sw-color-block>
            </ng-template>
          </div>
          <div
            (click)="selectMaterial(item)"
            class="collection-name"
            *ngIf="item.path"
          >
            {{ item.name }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="spacer"></div>
  </ng-template>

  <ng-template [ngIf]="state === 'NoResults'">
    <sw-message
      [message]="'MATERIAL_PICKER.NO_MATERIALS' | translate"
      icon="apps_outage"
      actionText="{{ 'MATERIAL_PICKER.PICK_FOLDER' | translate }}"
      (action)="pickFolder()"
    >
    </sw-message>
  </ng-template>

  <ng-template [ngIf]="state === 'NoFolder'">
    <sw-message
      [message]="'MATERIAL_PICKER.NO_FOLDER' | translate"
      icon="folder_off"
      actionText="{{ 'MATERIAL_PICKER.PICK_FOLDER' | translate }}"
      (action)="pickFolder()"
    >
    </sw-message>
  </ng-template>

  <ng-template [ngIf]="state === 'NotSketchup'">
    <sw-message
      [message]="'MATERIAL_PICKER.NOT_SKETCHUP' | translate"
      icon="smart_toy"
      actionText="{{ 'GLOBAL.CLOSE' | translate }}"
      (action)="close()"
    >
    </sw-message>
  </ng-template>

  <!-- <ng-template [ngIf]="(state !== 'NoResults') && (state !== 'NotSketchup') && (state !== 'NoFolder')">
    <div class="row buttons">
        <div class="spacer"></div>
        <div class="btn-primary" 
            (click)="close()">{{ 'GLOBAL.DONE' | translate }}</div>
        <div class="spacer"></div>
    </div>
</ng-template> -->
</div>
