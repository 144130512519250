const dash = 5;
const gap = 5;

export const colors = {
  background: 'white',
  gridPoints: 'rgba(51,171,142,0.8)',

  elementFillColor: '#FFCE1F',
  elementHoleHoverStroke: '#33AB8E',

  elementStroke: '#FFCE1F 1 dotted',
  routerElementStroke: '#186262',
  routerElementsStrokeHover: '#FFCE1F',

  edge: '#33AB8E',
  edgeHover: '#FFCE1F',
  vertex: '#33AB8E',
  vertexHover: '#FFCE1F',

  snapped: '#DE2D06',

  activeTool: '#DE2D06',

  guidelineColor: '#333333',
  dimmedGuidelineColor: '#CCC',
  guidelineHoveredColor: '#FFCE1F',
  guidelineDash: [5, 5],
  guidelineThickness: 1,
  centerPointDashPattern: [
    1,
    5,
    dash,
    gap,
    dash,
    gap,
    dash,
    gap,
    dash,
    gap,
    dash,
    gap,
    dash,
    gap,
    dash,
    gap,
    dash,
    gap,
    dash,
    gap,
  ],

  // grid: "#FFCE1F",
  // gridStroke: "#FFCE1F 1 dotted",
  // gridStrokeSelected: "#FFCE1F 1 solid",
};
