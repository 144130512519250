import { AfterContentInit, Component } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { UIService } from './core/services/ui.service';
import { NotificationsService } from './components/sw-notification/notifications.service';
import { SketchupService } from './services/sketchup.service';
import { LanguageService } from './core/services/language.service';
import { ProjectLiveUpdatesService } from './services/liveupdates/project-live-updates.service';
import { UserPresenceService } from './modules/user-presence-module/user-presence.service';
import { LibraryLiveUpdateService } from './services/liveupdates/library-lables-live-update.service';
import { SWMaterialsService } from './modules/materials/sw-materials.service';

@Component({
  selector: 'sw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentInit {
  title = 'SmartWorks';
  version = undefined;

  constructor(
    public authService: AuthService,
    public uiService: UIService,
    public sketchUp: SketchupService,
    public projectLiveUpdates: ProjectLiveUpdatesService,
    public libraryLiveUpdateService: LibraryLiveUpdateService,
    public ups: UserPresenceService,
    public notificationsService: NotificationsService,
    public languageService: LanguageService
  ) {}

  ngAfterContentInit(): void {
    switch (window.location.hostname) {
      case 'localhost':
        this.version = 'local';
        break;
      case 'qa-sw.kaapisoft.com':
        this.version = 'QA';
        break;
      case 'dev-sw.kaapisoft.com':
        this.version = 'dev';
        break;
      case 'smartworks.kaapisoft.com':
        this.version = 'staging';
        break;
    }
  }
}
