import { DragDirective } from './drag.directive';

export class wTreeNodeDragEvent {
  constructor(
    public container: wTreeListSource,
    public node: any,
    public wDragRef: DragDirective // used for triggering the drop event
  ) {}
}

export interface wTreeListSource {
  // id: string
  source: any;
  sourceList: Array<any>;
  sourceObjectType: string;
}

export interface wTreeListTarget {
  // id: string
  target: any;
  targetList: Array<any>;
  targetAcceptType: string;
}

export class wDragDropListEvent {
  constructor(
    public to: wTreeListTarget,
    public from: wTreeListSource,
    public toIndex: number,
    public fromIndex: number
  ) {}
}

/*


export class wTreeNodeDragEvent<T> {
    constructor(
        public container: wTreeList<T>,
        public node: T,
        public wDragRef: DragDirective, // used for triggering the drop event
    ) { }
}

export interface wTreeList<T> {
    // id: string
    target: any
    targetList: Array<T>
    // targetAcceptType: string
}

export class wDragDropListEvent<F, T> {
    constructor(
        public to: wTreeList<T>,
        public from: wTreeList<F>,
        public toIndex: number,
        public fromIndex: number,
    ) { }
}

*/
