import { NgModule } from '@angular/core';
import { LibraryItemListComponent } from './library-item-list/library-item-list.component';
import { LibraryItemRowComponent } from './library-item-row/library-item-row.component';
import { LibraryLabelsListComponent } from './labels/library-labels-list/library-labels-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserPresenceModule } from '../user-presence-module/user-presence.module';
import { TranslateModule } from '@ngx-translate/core';
import { LibraryComponent } from './library.component';
import { LabelEditorComponent } from './labels/label-editor/label-editor.component';
import { NewLibraryItemDialogComponent } from './new-library-item/new-library-item-dialog';
import { AttributesModule } from '../attributes/attributes.module';
import { LibraryService } from './library.service';
import { NodeLinksService } from './node-links.service';
import { MaterialsModule } from '../materials/materials.module';

const components = [
  LibraryComponent,
  LibraryItemListComponent,
  LibraryItemRowComponent,
  LibraryLabelsListComponent,
  LabelEditorComponent,
];

@NgModule({
  declarations: [...components, NewLibraryItemDialogComponent],
  imports: [
    SharedModule,
    UserPresenceModule,
    TranslateModule.forChild(),
    AttributesModule,
    MaterialsModule,
  ],
  providers: [NodeLinksService, LibraryService],
  exports: [...components],
})
export class LibraryModule {}
