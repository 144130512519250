import { Injectable } from '@angular/core';
import { Pt, Line } from 'pts';
import { Fabric8Element } from '../../models/element.model';
import { Fabric8NodeType, getNodeType } from '../../models/Fabric8NodeType';
import { Fabric8Object } from '../../models/object.model';
import { Fabric8SettingsService } from '../../settings/fabric8-settings.service';
import { CurrentObjectService } from '../current-object.service';
import { Fabric8NodeDataPointIndex } from './models/Fabric8NodeDataPointIndex.model';
import { Fabric8LinePointIndex } from './models/Fabric8LinePointIndex.model';
import { Fabric8MouseEvent } from './models/Fabric8MouseEvent.model';

export enum SnapTo {
  nothing,
  objectPoints,
  objectLines,
  elementPoints,
  elementLines, // todo
  guidelinesIntersections,
  guidelineToObjectIntersections,
  guidelines,
  grid,
  roundUnits,
}

@Injectable({
  providedIn: 'root',
})
export class SnapService {
  private snapInSound = new Audio('/assets/sounds/snap-out.wav');
  private snapOutSound = new Audio('/assets/sounds/snap.wav');
  private isSnapping: SnapTo = undefined;

  constructor(
    private settings: Fabric8SettingsService,
    private currentObject: CurrentObjectService
  ) {}

  to(
    object: Fabric8Object,
    event: Fabric8MouseEvent,
    snapOrder: SnapTo[]
  ): { point: Pt; snapped?: SnapTo } {
    if (!event.objectSpacePoint)
      return {
        point: undefined,
        snapped: undefined,
      };

    if (!object) {
      // remove the object-related snap options
      // we're still allowing snapping to the grid and round units

      const excludedSnaps = new Set([
        SnapTo.objectPoints,
        SnapTo.objectLines,
        SnapTo.guidelineToObjectIntersections,
        SnapTo.guidelines,
        SnapTo.guidelinesIntersections,
        SnapTo.elementLines,
        SnapTo.elementPoints,
      ]);

      snapOrder = snapOrder.filter((s) => !excludedSnaps.has(s));
    }

    // for each snapOrder
    for (const snapTo of snapOrder) {
      var p: Pt | void;

      switch (snapTo) {
        case SnapTo.objectPoints:
          p = this.snapToObjectPoints(event);
          break;
        case SnapTo.objectLines:
          p = this.snapToObjectLines(event);
          break;
        case SnapTo.elementPoints:
          // snap to drill, router and guideline segment points
          p = this.snapToElementPoints(event);
          break;
        case SnapTo.elementLines:
          p = this.snapToElementLines(event);
          break;
        case SnapTo.guidelinesIntersections:
          p = this.snapToGuidelinesIntersections(event);
          break;
        case SnapTo.guidelineToObjectIntersections:
          p = this.snapToGuidelineAndObjectIntersections(event);
          break;
        case SnapTo.guidelines:
          p = this.snapToGuidelines(event);
          break;
        case SnapTo.grid:
          p = this.snapToGrid(event.objectSpacePoint);
          break;
        case SnapTo.roundUnits:
          p = this.snapRound(event.objectSpacePoint);
          break;
      } // end switch

      if (p) {
        if (this.isSnapping !== snapTo) {
          this.isSnapping = snapTo;
          // play snap sound
          this.snapInSound.volume = 0.75;
          this.snapInSound.play();
        }
        return { point: p, snapped: snapTo };
      }
    } // end for

    if (this.isSnapping) {
      this.isSnapping = undefined;
      // play snap out sound
      this.snapOutSound.volume = 0.2;
      this.snapOutSound.play();
    }
    return { point: event.objectSpacePoint, snapped: undefined };
  }

  private snapToObjectPoints(event: Fabric8MouseEvent): Pt | void {
    // if hoveredLinePointIndices contains a pointIndex,
    // then select that point as a snap candidate
    var pointCandidateIndex: Fabric8LinePointIndex;
    const object = this.currentObject.get();

    event.hoveredLinePointIndices
      .filter((i) => i.pointIndex > -1)
      .forEach((i) => {
        pointCandidateIndex = i;
      });

    if (pointCandidateIndex) {
      // we have a single point candidate, snap to it
      return object.lines[pointCandidateIndex.lineIndex][
        pointCandidateIndex.pointIndex
      ];
    }
  }

  private snapToObjectLines(event: Fabric8MouseEvent): Pt | void {
    const cursorPoint = event.objectSpacePoint;

    // if hoveredLinePointIndices contains a lineIndex,
    // then select that line as a snap candidate
    const lineCandidateIndex = event.hoveredLinePointIndices[0];
    if (!lineCandidateIndex) {
      return;
    }
    return Line.perpendicularFromPt(
      this.currentObject.get().lines[lineCandidateIndex.lineIndex],
      cursorPoint
    );
  }

  private snapToElementPoints(event: Fabric8MouseEvent): Pt | void {
    // if hoveredElementPointIndices contains a pointIndex,
    // then select that point as a snap candidate
    var pointCandidateIndex: Fabric8NodeDataPointIndex;
    const selectedPointIndex = event.currentNodeDataPointIndex;

    const elementNodes = this.currentObject.getObjectNodesFlatList([
      Fabric8NodeType.Element,
      Fabric8NodeType.GuidelineSegment,
    ]);

    event.hoveredNodeDataPointIndices
      // remove the indices that are not points
      .filter((i) => i.pointIndex > -1)
      .filter(
        (i) =>
          i.type == Fabric8NodeType.Element ||
          i.type == Fabric8NodeType.GuidelineSegment
      )
      // remove the current point
      .filter(
        (i) =>
          !(
            i.id === selectedPointIndex.id &&
            i.pointIndex === selectedPointIndex.pointIndex
          )
      )
      // remove the endpoints if it's a closed path
      .filter((i) => {
        // is it the same element?
        if (i.id !== selectedPointIndex.id) return true;

        // get the element
        const elementNode = elementNodes.find(
          (e) => e.id == selectedPointIndex.id
        );

        const type = getNodeType(elementNode.data);
        const isElement = type == Fabric8NodeType.Element;
        const isGuidelineSegment = type == Fabric8NodeType.GuidelineSegment;

        if (isElement) {
          const element = elementNode.data as Fabric8Element;
          if (element?.isClosedPath != true) return true;
        }

        // is the selected point the first or last point?
        const selectedFirstPoint = selectedPointIndex.pointIndex === 0;
        const selectedLastPoint =
          selectedPointIndex.pointIndex === elementNode.data.points.length - 1;
        const hoveringStartPoint = i.pointIndex === 0;
        const hoveringLastPoint =
          i.pointIndex === elementNode.data.points.length - 1;

        if (selectedFirstPoint === true && hoveringLastPoint === true)
          return false;
        if (selectedLastPoint === true && hoveringStartPoint === true)
          return false;
      })
      // .map((i) => {
      //   return i;
      // })
      .forEach((i) => {
        pointCandidateIndex = i;
        //TODO: pick the closest point
        // add the distance to the pointIndices when we get them
      });

    if (pointCandidateIndex) {
      // we have a single point candidate, snap to it
      const elementNode = elementNodes.find(
        (e) => e.id == pointCandidateIndex.id
      );
      const data = elementNode && elementNode.data;
      return data.points[pointCandidateIndex.pointIndex];
    }
  }

  private snapToElementLines(event: Fabric8MouseEvent): Pt | void {
    // const lineCandidateIndex = event.hoveredElementPointIndices[0]
    // if (! lineCandidateIndex) {
    //   return
    // }
    // return Line.perpendicularFromPt(
    //   object.elements[lineCandidateIndex.elementIndex].lines[lineCandidateIndex.lineIndex], cursorPoint)
  }

  private snapToGuidelinesIntersections(event: Fabric8MouseEvent): Pt | void {
    // find guildeline intersections
    const hoveredGuidelineIndices = event.hoveredNodeDataPointIndices.filter(
      (i) =>
        i.type == Fabric8NodeType.Guideline ||
        i.type == Fabric8NodeType.GuidelineSegment
    );

    if (hoveredGuidelineIndices.length > 1) {
      // we have two guideline candidates, snap to their intersection

      const { guideline: guideline1 } =
        this.currentObject.getGuidelineDataWithId(
          hoveredGuidelineIndices[0].id
        );

      const { guideline: guideline2 } =
        this.currentObject.getGuidelineDataWithId(
          hoveredGuidelineIndices[1].id
        );

      const guideline1Points = guideline1.points;
      const guideline2Points = guideline2.points;

      return Line.intersectRay2D(guideline1Points, guideline2Points);
    }
  }

  private snapToGuidelineAndObjectIntersections(
    event: Fabric8MouseEvent
  ): Pt | void {
    const hoveredGuidelineIndices = event.hoveredNodeDataPointIndices.filter(
      (i) =>
        i.type == Fabric8NodeType.Guideline ||
        i.type == Fabric8NodeType.GuidelineSegment
    );

    if (
      hoveredGuidelineIndices.length > 0 &&
      event.hoveredLinePointIndices.length > 0
    ) {
      const object = this.currentObject.get();
      // we have a guideline candidate and a line candidate, snap to their intersection
      const { guideline } = this.currentObject.getGuidelineDataWithId(
        hoveredGuidelineIndices[0].id
      );
      const guidelinePoints = guideline.points;
      const line = object.lines[event.hoveredLinePointIndices[0].lineIndex];
      return Line.intersectRay2D(line, guidelinePoints);
    }
  }

  private snapToGuidelines(event: Fabric8MouseEvent): Pt | void {
    const cursorPoint = event.objectSpacePoint;
    // if hoveredGuidelineIndices contains a lineIndex,
    // then select that line as a snap candidate
    const lineCandidateIndex = event.hoveredNodeDataPointIndices.find(
      (i) => i.type == Fabric8NodeType.Guideline
    );

    if (!lineCandidateIndex) return;

    const { guideline } = this.currentObject.getGuidelineDataWithId(
      lineCandidateIndex.id
    );
    const guidelinePoints = guideline.points;
    const line = [...guidelinePoints];

    return Line.perpendicularFromPt(line, cursorPoint);
  }

  snapToGrid(point: Pt): Pt {
    if (!this.settings.getGridSnap()) return;

    const gridSize = this.settings.getGridSize();
    const gridPoint = new Pt(
      Math.round(point.x / gridSize) * gridSize,
      Math.round(point.y / gridSize) * gridSize
    );
    return gridPoint;
  }

  snapRound(point: Pt): Pt {
    if (!this.settings.getUnitSnap()) return;

    const gridPoint = new Pt(
      Math.round(point.x * 10) / 10,
      Math.round(point.y * 10) / 10
    );
    return gridPoint;
  }
}
