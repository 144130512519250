import { NgModule } from '@angular/core';
import { Cabn8Component } from './cabn8/cabn8.component';
import { SmartworksPluginCustomUiComponent } from './smartworks-plugin-custom-ui/smartworks-plugin-custom-ui.component';
import { PapBasicComponent } from './pap-basic/pap-basic.component';
import { SmartworksPluginComponent } from './swplugin/swplugin.component';
import { SketchupObjectsComponent } from './sketchup-objects/sketchup-objects.component';
import { SharedModule } from '../../shared/shared.module';
import { PluginService } from './plugin.service';
import { Gardn8Component } from './gardn8/gardn8.component';
import { TranslateModule } from '@ngx-translate/core';
import { LibraryModule } from '../library/library.module';
import { AttributesModule } from '../attributes/attributes.module';
import { WTreeModule } from '../wTree/w-tree.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserPresenceModule } from '../user-presence-module/user-presence.module';
import { MaterialsModule } from '../materials/materials.module';
import { Cabn8MaterialPresetsComponent } from './cabn8/cabn8-material-presets/cabn8-material-presets.component';
import { Fabric8Component } from './fabric8/fabric8.component';
import { Cabn8MaterialsDialogComponent } from './cabn8/cabn8-materials-dialog/cabn8-materials-dialog.component';
import { Cabn8MaterialsResetDialogComponent } from './cabn8/cabn8-materials-reset-dialog/cabn8-materials-reset-dialog.component';

const components = [
  SmartworksPluginComponent,
  Cabn8Component,
  SmartworksPluginCustomUiComponent,
  PapBasicComponent,
  SketchupObjectsComponent,
  Gardn8Component,
  Cabn8MaterialPresetsComponent,
  Fabric8Component,
  Cabn8MaterialsDialogComponent,
  Cabn8MaterialsResetDialogComponent,
];

const modules = [
  SharedModule,
  TranslateModule,
  LibraryModule,
  AttributesModule,
  WTreeModule,
  DragDropModule,
  UserPresenceModule,
  MaterialsModule,
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components],
  providers: [PluginService],
})
export class PluginsModule {}
