import { Pt } from 'pts';

export function skpPoint2Pt(point: SkpPoint): Pt {
  return new Pt(point[0], point[1]);
}
export function Pt2skpPoint(point: Pt): SkpPoint {
  // drop the z axis
  return [point[0], point[1]];
}

export type SkpPoint = [number, number];
export type SkpLine = [SkpPoint, SkpPoint];
export type SWLine = [Pt, Pt];
