import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { Observable, from } from 'rxjs';

import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

const TOKEN_HEADER_KEY = 'x-access-token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.addAuthenticationToken(req)).pipe(
      mergeMap((authReq) => next.handle(authReq)),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            // token error
            this.authService.logout();
          }
          // 403 should be returned when the user doesn't have access to the requested resource
        }
        throw err;
      })
    );
  }

  async addAuthenticationToken(
    req: HttpRequest<any>
  ): Promise<HttpRequest<any>> {
    // exclude /auth calls
    if (req.url.includes('auth/')) {
      return req;
    }
    // exclude /assets calls
    if (req.url.includes('assets/')) {
      return req;
    }

    if (req.url.includes('https://pixabay.com/api')) {
      return req;
    }

    // get or refresh the token
    const accessToken = await this.authService.getOrRefreshToken();
    if (!accessToken) {
      // if the accessToken is missing, don't add any header
      return req;
    }

    var headers = {
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      'If-Modified-Since': '0',
    };
    headers[TOKEN_HEADER_KEY] = accessToken;

    return req.clone({ setHeaders: headers });
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
