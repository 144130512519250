import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'sw-message',
  templateUrl: './sw-message.component.html',
  styleUrls: ['./sw-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() message: string | null;
  @Input() icon: string;
  @Input() actionText: string | undefined;
  @Output() action = new EventEmitter();

  constructor() {}

  onAction() {
    this.action.emit();
  }
}
