<h1 mat-dialog-title>{{ 'NEW_TEMPLATE.TITLE' | translate }}</h1>
<div mat-dialog-content>
  <!-- <p>{{ 'NEW_TEMPLATE.MESSAGE' | translate }}</p> -->
  <mat-form-field>
    <mat-label>{{ 'NEW_TEMPLATE.NAME_PLACEHOLDER' | translate }}</mat-label>
    <input matInput [(ngModel)]="data.templateName" cdkFocusInitial />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button class="btn-primary grey" (click)="onNoClick()">
    {{ 'GLOBAL.CANCEL' | translate | uppercase }}
  </button>
  <div class="spacer"></div>
  <button
    mat-button
    class="btn-primary"
    [mat-dialog-close]="data"
    [disabled]="data.templateName.length < 2"
  >
    {{ 'GLOBAL.OK' | translate }}
  </button>
</div>
