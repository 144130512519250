import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Fabric8ToolbarService } from '../toolbar/fabric8-toolbar.service';
import { ObjectsManagerService } from '../services/objects-manager.service';
import { Fabric8Element } from '../models/element.model';
import { Fabric8NodeType } from '../models/Fabric8NodeType';
import { CurrentObjectService } from '../services/current-object.service';
import { Fabric8ToolTypeUtility } from './Fabric8ToolType';

@Injectable({
  providedIn: 'root',
})
export class ToolNameAutocompleteService implements OnDestroy {
  objectsSubscription: Subscription;
  toolSetSubscription: Subscription;

  toolNamesMap: Map<string, Set<string>> = new Map();
  toolNames$ = new BehaviorSubject<Map<string, string[]>>(
    new Map<string, string[]>()
  );

  constructor(
    private objectsManager: ObjectsManagerService,
    private currentObjectService: CurrentObjectService,
    private toolsetService: Fabric8ToolbarService
  ) {
    this.objectsSubscription = this.objectsManager.objects$.subscribe(
      (objects) => {
        objects?.forEach((object) => {
          this.objectsManager
            .getElementsFlatListForObject([Fabric8NodeType.Element], object)
            .forEach((element: Fabric8Element) => {
              // this.names.add(element.tool.name);

              var names = this.toolNamesMap.get(element.tool.type) || new Set();
              names.add(element.tool.name);
              this.toolNamesMap.set(element.tool.type, names);
            });
        });
        // update the toolNames$ observable
        this.setToolNames();
      }
    );

    this.toolSetSubscription = this.toolsetService.toolsetSubject.subscribe(
      (toolset) => {
        toolset
          .filter(
            (toolGroup) => Fabric8ToolTypeUtility.get(toolGroup.type) == false
          )
          .forEach((toolGroup) => {
            toolGroup.tools.forEach((tool) => {
              // this.names.add(tool.name);

              var names = this.toolNamesMap.get(tool.type) || new Set();
              names.add(tool.name);
              this.toolNamesMap.set(tool.type, names);
            });
          });
        this.setToolNames();
      }
    );
  }

  ngOnDestroy(): void {
    this.objectsSubscription.unsubscribe();
    this.toolSetSubscription.unsubscribe();
  }

  setToolNames() {
    const map = new Map<string, string[]>();

    this.toolNamesMap.forEach((value, groupName) => {
      map.set(groupName, this.getSortedNames(groupName));
    });

    this.toolNames$.next(map);
  }

  getSortedNames(groupName: string): string[] {
    return Array.from(this.toolNamesMap.get(groupName))
      .filter((n) => n !== '')
      .sort((a, b) => a.localeCompare(b));
  }
}
