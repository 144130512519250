import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    public authService: AuthService,
    private translate: TranslateService
  ) {
    this.initLanguage();
  }

  userSubscription: Subscription;

  initLanguage() {
    this.translate.addLangs(['en', 'nl']);
    this.translate.setDefaultLang('en');

    this.userSubscription = this.authService.user$.subscribe((user) => {
      if (user) {
        this.translate.use(user.language || 'en');
      }
    });
  }
}
