<div class="tool-group" 
  *ngFor="let toolGroup of toolSetGroups">

  <!-- show the tool name only if the tool is selected -->
  <div class="btn-primary tool"   
    (click)="selectTool(toolGroup, toolGroup.selected)"
    [ngClass]="{ 'active': toolService.getCurrentTool() === toolGroup.selected}">
      <span class="tool-icon" [inlineSVG]="getToolIcon(toolGroup.selected)"></span>
      <span class="tool-name">{{ getToolName(toolGroup.selected) }}</span>    
  </div>

  <ng-container *ngIf="toolGroup.tools.length > 1">

    <div class="floating">
      <div class="tool-menu">
        <ng-container *ngFor="let tool of toolGroup.tools">
          <div class="tool-menu-item small-title"
            (click)="selectTool(toolGroup, tool)"
            [ngClass]="{ 'active': toolService.getCurrentTool() === tool }">
            {{ getToolName(tool) }}
          </div>

        </ng-container>
      </div>
    </div>
    
  </ng-container>

</div>