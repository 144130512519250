<div id="grid" class="wbox">
    <div id="header" class="row">
        <mat-icon class="pointer" inline>handyman</mat-icon>
        {{ 'FABRIC8.TOOLSET_TITLE' | translate }}
        <div class="spacer"></div>
        <mat-icon class="pointer" (click)="close()">close</mat-icon>
    </div>

    <div id="body">

        <div *ngIf="(toolbarService.toolsetSubject | async) as toolSetGroups">
            <div class="flex column" *ngFor="let toolGroup of toolSetGroups; let toolGroupIndex = index">
                <sw-tool-group [toolGroupIndex]="toolGroupIndex"></sw-tool-group>
            </div>
        </div>
        
    </div>

    <section id="footer" class="row center">
        <button mat-button 
        class="btn-primary" 
        (click)="close()"
        >{{ 'GLOBAL.OK' | translate }}</button>
    </section>
</div>
