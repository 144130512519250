<ng-template [ngIf]="plugin && plugin.available" [ngIfElse]="missing">
  <div class="pd" cdkDrag [cdkDragData]="data">
    <div
      cdkDragHandle
      class="project-data-header"
      [ngClass]="{ collapsed: !expanded }"
    >
      <!-- expand/collapse arrow -->
      <div style="display: flex; align-items: center">
        <div
          id="arrow"
          class="hover-btn transparent"
          [inlineSVG]="
            expanded ? 'icon/arrow-expanded.svg' : 'icon/arrow-collapsed.svg'
          "
          [ngClass]="{ collapsed: !expanded, expanded: expanded }"
          (click)="expanded = !expanded"
        ></div>

        <div class="name">{{ plugin.name }}</div>
      </div>
      <div class="spacer"></div>

      <ng-container #customButtonsContainer> </ng-container>

      <!-- <div class="icon plus" 
            [inlineSVG]="'icon/plus.svg'"
            (click)="addNewItem()"
            matTooltip="{{ 'PROJECT_EDIT.NEW_ITEM_TOOLTIP' | translate }}" matTooltipPosition="above"></div>     -->

      <sw-dots-menu [itemID]="data.id" [light]="true">
        <div
          class="hover-btn red"
          [inlineSVG]="'icon/delete.svg'"
          (click)="onClickRemove()"
          matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
          matTooltipPosition="above"
        ></div>
        <ng-template [ngIf]="!plugin.library">
          <div
            class="hover-btn"
            [inlineSVG]="'icon/save-template.svg'"
            (click)="showSaveAsTemplate()"
            matTooltip="{{
              'PROJECT_EDIT.SAVE_AS_TEMPLATE_TOOLTIP' | translate
            }}"
            matTooltipPosition="above"
          ></div>
        </ng-template>
      </sw-dots-menu>
    </div>

    <ng-template [ngIf]="expanded">
      <sw-plugin-custom-ui [plugin]="plugin" [projectData]="data">
      </sw-plugin-custom-ui>
    </ng-template>
  </div>
</ng-template>

<ng-template #missing>
  <div cdkDragHandle class="project-data-header disabled">
    <div class="name">{{ pluginName() }}</div>
    <div class="spacer"></div>
    <sw-dots-menu [itemID]="data.id" [light]="true">
      <div
        class="hover-btn red"
        [inlineSVG]="'icon/delete.svg'"
        (click)="onClickRemove()"
        matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
        matTooltipPosition="above"
      ></div>
    </sw-dots-menu>
  </div>
  <sw-message
    class="warning-message"
    icon="warning"
    [message]="licenseRequiredMessage()"
  ></sw-message>
</ng-template>
