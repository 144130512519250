import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'sw-sharing-view',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss'],
})
export class SharingComponent {
  @Input() access: any;
  @Output() accessChange = new EventEmitter<any>();

  shareWithEmail: string;

  constructor(private authService: AuthService) {}

  validateEmail(value) {
    var input = document.createElement('input');

    input.type = 'email';
    input.required = true;
    input.value = value;

    return typeof input.checkValidity === 'function'
      ? input.checkValidity()
      : /\S+@\S+\.\S+/.test(value);
  }

  addShareEmail() {
    if (this.access == undefined) {
      this.access = [];
    }

    if (this.shareWithEmail == undefined) return;

    this.shareWithEmail = this.shareWithEmail.trim();

    if (this.validateEmail(this.shareWithEmail) == false) return;

    if (this.access.find((uAccess) => uAccess.email == this.shareWithEmail)) {
      // already shared with this user
      this.shareWithEmail = '';
      return;
    }

    this.access.push({
      email: this.shareWithEmail,
      access: 'owner',
    });

    this.shareWithEmail = '';
  }

  onClickRemoveSharing(index) {
    this.access.splice(index, 1);
  }

  isCurrentUser(userEmail: string) {
    return userEmail == this.authService.user$?.value.email;
  }
}
