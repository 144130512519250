<div class="row">
  <div
    #n
    class="sw-label"
    id="label-name"
    contenteditable
    [ngStyle]="{ 'background-color': label.color }"
    (input)="setName(n.textContent)"
  >
    {{ labelInputName }}
  </div>
  <div class="spacer"></div>
  <div
    *ngIf="label.access && label.access.length > 1"
    class="icon shared"
    matTooltip="{{ 'LABELS.SHARED' | translate }}"
    matTooltipPosition="above"
  >
    <mat-icon [inline]="true">people</mat-icon>
  </div>
</div>
<div id="color-picker">
  <div
    *ngFor="let color of labelService.colors"
    [ngStyle]="{ 'background-color': color }"
    class="pointer"
    (click)="setColor(color)"
  >
    &nbsp;
  </div>
</div>
<div id="sharing">
  <sw-sharing-view [(access)]="label.access"> </sw-sharing-view>
</div>
<div class="row buttons">
  <div class="btn-primary grey" (click)="cancel()">
    {{ "GLOBAL.CANCEL" | translate }}
  </div>
  <div class="spacer"></div>
  <div class="btn-primary" (click)="save()">
    {{ "GLOBAL.DONE" | translate }}
  </div>
</div>
