<div class="top row">
  <div class="icon" [inlineSVG]="'fabric8/DRILL.svg'"></div>
  {{ "Fabric8" | translate }}
  <div class="spacer"></div>
  <mat-icon inline class="pointer" (click)="openSettings()">tune</mat-icon>
  <mat-icon inline class="pointer" (click)="openToolsetSettings()"
    >handyman</mat-icon
  >
</div>

<div id="fabric8-objects">
  <div *ngIf="objectsManager.objects$ | async as objects; else loading">
    <div
      class="object"
      *ngFor="let object of objects; let i = index"
      [ngClass]="{ selected: currentObjectID === object.guid }"
    >
      <div class="row fabric8-object" (click)="clickSelectObject(object)">
        <!-- expand/collapse arrow -->
        <!-- <div class="arrow"
        *ngIf="object.elements?.length"
        class="hover-btn transparent"
        [inlineSVG]="expanded ? 'icon/arrow-expanded.svg' : 'icon/arrow-collapsed.svg'"
        [ngClass]="{ 'collapsed': !expanded, 
                    'expanded': expanded }"
        (click)="expanded = !expanded"
        ></div> -->
        <div class="name">
          {{ object.name }}
        </div>
        <div class="spacer"></div>
        <sw-dots-menu>
          <div
            class="hover-btn red"
            [inlineSVG]="'icon/delete.svg'"
            matTooltip="{{ 'GLOBAL.DELETE' | translate }}"
            matTooltipPosition="above"
            (click)="deleteFabric8Object(object)"
          ></div>
          <!-- new group -->
          <div
            class="hover-btn"
            [inlineSVG]="'icon/plus.svg'"
            matTooltip="{{ 'NEW_GROUP.NAME' | translate }}"
            matTooltipPosition="above"
            (click)="addFabric8GroupNode(object)"
          ></div>
        </sw-dots-menu>
      </div>
      <div class="elements" *ngIf="currentObjectID === object.guid">
        <sw-fabric8-elements [(object)]="currentObject"> </sw-fabric8-elements>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="row center">
      <div class="spinner center">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </div>
  </ng-template>

  <div class="row buttons">
    <div class="spacer"></div>
    <div class="btn-primary" (click)="importSelection()">
      {{ "FABRIC8.IMPORT_OBJECT" | translate }}
    </div>
    <div class="btn-primary" (click)="export()">
      {{ "FABRIC8.EXPORT_OBJECTS" | translate }}
    </div>
    <div class="spacer"></div>
    <mat-icon (click)="reload()" inline class="icon lod-icon pointer"
      >refresh</mat-icon
    >
  </div>
</div>
