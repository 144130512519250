import { Fabric8Tool } from '../../models/tool.model';
import { Fabric8ToolType } from '../Fabric8ToolType';

export const cutToolSet: Fabric8Tool[] = [
  {
    name: '',
    radius: 5 / 2,
    type: Fabric8ToolType.CUT,
    enabled: true,
  },
];
