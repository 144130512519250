export interface MMHubCandidate {
  name: string;
  guid: string;
  definition: string;
  definition_instances: number;
  repetition: number;
  hub: string;
  lod: string;
  polyCount: number;
  children?: MMHubCandidate[];
  parent?: MMHubCandidate;
  ancestor_hub?: boolean;

  indentLevel?: number;
  flat?: boolean;
  buildingState?: MMHubCandidateBuildState;
  expanded: boolean;
  hasChildren: boolean;
  error?: string;
}

export enum MMHubCandidateBuildState {
  'queued',
  'empty',
  '2d',
  'proxy',
  'done',
  'error',
  'warning',
}
