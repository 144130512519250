<h1 mat-dialog-title>{{ 'NEW_PROJECT.TITLE' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'NEW_PROJECT.MESSAGE' | translate }}</p>
  <mat-form-field>
    <mat-label>{{ 'NEW_PROJECT.NAME_LABEL' | translate }}</mat-label>
    <input matInput [(ngModel)]="data.projectName" cdkFocusInitial />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button class="btn-primary grey" (click)="onNoClick()">
    {{ 'GLOBAL.CANCEL' | translate | uppercase }}
  </button>
  <div class="spacer"></div>
  <button
    mat-button
    class="btn-primary"
    [mat-dialog-close]="data"
    [disabled]="data.projectName.length === 0"
  >
    {{ 'GLOBAL.OK' | translate }}
  </button>
</div>
