import { Component } from '@angular/core';

@Component({
  selector: 'sw-app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent {
  constructor() {}
}
