import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { PostformService } from 'src/app/core/services/postform.service';

@Component({
  selector: 'sw-app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent {
  userMenu = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private postForm: PostformService
  ) {}

  gotoLogin() {
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.authService.logout();
    this.gotoLogin();
  }

  openProfile() {
    var user = this.authService.user$.getValue();
    if (user == undefined) {
      return;
    }

    const data = {
      token: user.accessToken,
      redirect_url: '/enduser',
    };

    this.postForm.openWindowWithPost(
      environment.licenseServer + '/enduser/site/jwtlogin',
      data
    );
  }
}
