import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataAttribute } from 'src/app/model/data-attribute.model';
import { UnitConversionService } from 'src/app/modules/project/services/unit-conversion.service';
import { Fabric8Element } from '../../models/element.model';
import { Fabric8Node } from '../../models/node.model';
import { ToolService } from '../../services/tool.service';

@Component({
  selector: 'sw-fabric8-element',
  templateUrl: './fabric8-element.component.html',
  styleUrls: ['../css/elements.scss', '../fabric8-elements.component.scss'],
})
export class Fabric8ElementComponent implements OnInit {
  @Input() parent: Fabric8Node;
  @Output() parentChange = new EventEmitter<Fabric8Node>();

  element: Fabric8Element;
  // @Output() elementChange = new EventEmitter<Fabric8Element>();

  @Output() delete = new EventEmitter<Fabric8Element>();

  defaultUnit: string;
  defaultUnitSymbol: string;
  isEditingDepthValue: boolean = false;

  constructor(
    public toolService: ToolService,
    private unitConversion: UnitConversionService
  ) {}

  ngOnInit(): void {
    this.defaultUnit = this.unitConversion.getUnitsFromType('length');
    this.defaultUnitSymbol = this.unitConversion.getUnitSymbolFromUnits(
      this.defaultUnit
    );

    this.element = this.parent.data as Fabric8Element;
  }

  enableEditingDepth() {
    this.isEditingDepthValue = true;
  }

  updateDepth(newDepthValue: string): void {
    const dataAttr: DataAttribute = {
      name: '',
      value: newDepthValue,
      value_raw: null,
      units: this.defaultUnit,
      type: 'length',
      list: null,
      tag: '',
      access: null,
      options: null,
      errors: [],
    };
    const valueRaw = this.unitConversion.rawValueFromInput(dataAttr);
    const depthValue: number = parseFloat(
      this.unitConversion.valueFromRawValueAndUnit({
        ...dataAttr,
        ...{ value_raw: valueRaw },
      })
    );

    // this.element.depth = depthValue;
    this.parentChange.emit(this.parent);

    this.isEditingDepthValue = false;
  }

  getDepthValue(): string {
    const toolDepth = this.element.tool.depth;

    return this.isEditingDepthValue
      ? `${String(toolDepth)} ${this.defaultUnitSymbol}`
      : `${String(toolDepth)}`;
  }
}
