import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Fabric8Tool, Fabric8ToolGroup } from '../models/tool.model';
import { ToolService } from '../services/tool.service';
import { getToolIcon, getToolName } from '../toolset/toolset';
import { Fabric8ToolbarService } from './fabric8-toolbar.service';

@Component({
  selector: 'sw-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  private toolSetSubscription: Subscription;
  private toolSubscription: Subscription;

  getToolIcon = getToolIcon;
  getToolName = getToolName;
  toolSetGroups: Fabric8ToolGroup[];

  constructor(
    public toolService: ToolService,
    private toolbarService: Fabric8ToolbarService
  ) {}

  ngOnInit() {
    this.initToolSetSubscription();
    this.initToolSubscription();
  }

  private initToolSubscription() {
    this.toolSubscription = this.toolService.currentTool.subscribe((tool) => {
      if (tool === undefined) return;

      const toolType = tool.type;

      this.toolSetGroups.forEach((toolGroup, index) => {
        if (toolGroup?.type === toolType) {
          this.toolSetGroups[index].selected = tool;
          return;
        }
      });
    });
  }

  private initToolSetSubscription() {
    this.toolSetSubscription = this.toolbarService.toolsetSubject.subscribe(
      (toolSetGroups) => {
        if (toolSetGroups === undefined) return;

        this.toolSetGroups = toolSetGroups.map((toolObj) => {
          toolObj.selected = toolObj.tools[0];
          return toolObj;
        });

        // auto select first tool
        const firstToolGroup = this.toolSetGroups[0];
        console.log('firstToolGroup', firstToolGroup);
        this.toolService.selectToolsetOfType(firstToolGroup.type);
      }
    );
  }

  ngOnDestroy() {
    this.toolSetSubscription.unsubscribe();
    this.toolSubscription.unsubscribe();
  }

  selectTool(toolGroup: Fabric8ToolGroup, tool?: Fabric8Tool) {
    const theTool = this.toolService.getTool(toolGroup, tool);
    this.toolService.setCurrentTool(theTool);
  }
}
