<div id="grid" class="wbox">
  <div id="header" class="row">
    <mat-icon>tune</mat-icon>
    {{ "Model Manager Settings" | translate }}
    <div class="spacer"></div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <section id="settings" class="container scrolly">
    <div *ngFor="let s of mm.settings | keyvalue" class="row">
      <mat-icon class="check pointer" (click)="mm.toggle(s.key)">{{
        s.value ? "check_box" : "check_box_outline_blank"
      }}</mat-icon>
      {{ mm.name(s.key) | translate }}
    </div>
  </section>
  <section id="footer" class="row center">
    <button mat-button class="btn-primary" (click)="close()">
      {{ "GLOBAL.OK" | translate }}
    </button>
  </section>
</div>
