import { Fabric8Element, SkpFabric8Element } from './element.model';
import { Fabric8Guideline, SkpFabric8Guideline } from './guideline.model';
import { v4 as uuidv4 } from 'uuid';
import {
  Fabric8GuidelineSegment,
  SkpFabric8GuidelineSegment,
} from './guideline-segment.model';
import { Fabric8NodeData } from './types/Fabric8NodeData.type';
import { SkpFabric8NodeData } from './types/SkpFabric8NodeData.type';
import { Fabric8NodeType } from './Fabric8NodeType';

export const IFabric8Node = Symbol('IFabric8Node');
export interface IFabric8Node {
  [IFabric8Node]: boolean;
  id: string;
  name: string;
  // parentNode?: Fabric8Node;
  children?: Fabric8Node[];

  type: Fabric8NodeType;
  readOnly: boolean;
  data?: Fabric8NodeData;
}

export interface SkpFabric8Node {
  id: string;
  name: string;
  children?: SkpFabric8Node[];

  type: Fabric8NodeType;
  readOnly: boolean;
  data?: SkpFabric8NodeData;
}

// TODO: convert to generic class
//  Fabric8Node<T extends Fabric8NodeData>

export class Fabric8Node implements IFabric8Node {
  [IFabric8Node]: boolean = true;
  id: string;
  name: string;
  // parentNode?: Fabric8Node;
  children: Fabric8Node[] = [];
  type: Fabric8NodeType;
  data?: Fabric8NodeData;
  readOnly: boolean;

  constructor(node: IFabric8Node);
  constructor(node: string);
  constructor(node: SkpFabric8Node);
  constructor(node: string | SkpFabric8Node | IFabric8Node) {
    if (typeof node == 'string') {
      const data = JSON.parse(node);
      return this.fromSkp(data);
    }

    if (node[IFabric8Node]) {
      const n = node as IFabric8Node;
      //generate uuid if not present
      this.id = n.id == '' ? uuidv4() : n.id;
      this.name = n.name;
      this.readOnly = n.readOnly;

      if (n.children) {
        this.children = n.children.map((child) => new Fabric8Node(child));
      }

      this.type = n.type;
      if (this.type == Fabric8NodeType.Element) {
        this.data = new Fabric8Element(n.data as Fabric8Element);
      } else if (this.type == Fabric8NodeType.Guideline) {
        this.data = new Fabric8Guideline(n.data as Fabric8Guideline);
      } else if (this.type == Fabric8NodeType.GuidelineSegment) {
        this.data = new Fabric8GuidelineSegment(
          n.data as Fabric8GuidelineSegment
        );
      }
    } else {
      const n = node as SkpFabric8Node;
      this.fromSkp(n);
    }
    return this;
  }

  private fromSkp(node: SkpFabric8Node): Fabric8Node {
    this.id = node.id;
    this.name = node.name;
    this.type = node.type;
    this.readOnly = node.readOnly;

    if (node.children) {
      this.children = node.children.map((child) => new Fabric8Node(child));
    }

    if (this.type == Fabric8NodeType.Element) {
      this.data = new Fabric8Element(node.data as unknown as SkpFabric8Element);
    } else if (this.type == Fabric8NodeType.Guideline) {
      this.data = new Fabric8Guideline(
        node.data as unknown as SkpFabric8Guideline
      );
    } else if (this.type == Fabric8NodeType.GuidelineSegment) {
      this.data = new Fabric8GuidelineSegment(
        node.data as unknown as SkpFabric8GuidelineSegment
      );
    }

    return this;
  }

  public toSkp(): SkpFabric8Node {
    var e: SkpFabric8Node = {
      id: this.id,
      name: this.name,
      children: this.children.map((child) => child.toSkp()),
      type: this.type,
      readOnly: this.readOnly,
    };

    if (this.type == Fabric8NodeType.Element) {
      const data = this.data as Fabric8Element;
      e.data = data.toSkp();
    } else if (this.type == Fabric8NodeType.Guideline) {
      const data = this.data as Fabric8Guideline;
      e.data = data.toSkp();
    } else if (this.type == Fabric8NodeType.GuidelineSegment) {
      const data = this.data as Fabric8GuidelineSegment;
      e.data = data.toSkp();
    }

    return e;
  }

  public toJSON(): string {
    const json = JSON.stringify(this.toSkp(), null, 2);
    return json;
  }
}
