import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LibraryItem } from 'src/app/model/library-item.model';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { SketchupService } from 'src/app/services/sketchup.service';
import { LibraryService } from './library.service';
import { HttpClient } from '@angular/common/http';
import { getRandomString } from 'src/app/core/helpers/getRandomString';
import { PluginService } from '../plugins/plugin.service';
import { SearchService } from 'src/app/services/search.service';
import { NewLibraryItemDialogData } from './new-library-item/new-library-item-dialog';

@Injectable()
export class LibraryViewService implements OnDestroy {
  public plugin: SWPlugin; //*

  // selected item
  public item = new BehaviorSubject<LibraryItem>(null);

  // the displayed items
  public items = new BehaviorSubject<LibraryItem[]>(null);

  public isEditing$ = new BehaviorSubject<boolean>(true);
  // public currentPanel = new BehaviorSubject<false | "labels" | "materials">(false)

  searchSubscription: Subscription;

  qid = 'library-view-service:' + getRandomString(3);

  public addNewItemEvent = new EventEmitter();

  constructor(
    private libraryService: LibraryService,
    private pluginService: PluginService,
    private searchService: SearchService,
    private sketchUp: SketchupService
  ) {
    if (sketchUp.isSketchup) {
      this.isEditing$.next(false);
    }

    this.searchSubscription = this.searchService.query$.subscribe((q) => {
      this.filterItems();
    });
  }

  setPlugin(id: string) {
    this.plugin = this.pluginService.pluginWithID(id);

    var subject = this.libraryService.allTheItems.get(id);
    if (!subject) {
      this.libraryService.allTheItems.set(id, new BehaviorSubject([]));
    }

    this.libraryService.allTheItems.get(id).subscribe((items) => {
      this.updateSelectedItem();
      this.filterItems();
    });
  }

  async createItem(data: NewLibraryItemDialogData) {
    const newItem = await this.libraryService.createItem(data);
    this.selectItem(newItem);
  }

  async saveItem(item: LibraryItem) {
    return await this.libraryService.saveItem(item);
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

  getAll() {
    this.libraryService.getAll(this.plugin.id);
  }

  allTheItems(): LibraryItem[] {
    return this.libraryService.allTheItems.get(this.plugin.id).getValue();
  }

  selectItem(item: LibraryItem | null) {
    this.item.next(item);
  }

  updateSelectedItem() {
    var currentItemID = this.item.getValue()?.id;
    var selectedItem = this.allTheItems().find((i) => i.id == currentItemID);
    this.selectItem(selectedItem);
  }

  toggleEditing(newValue: boolean | undefined) {
    if (newValue != undefined) {
      this.isEditing$.next(newValue);
    } else {
      this.isEditing$.next(!this.isEditing$.getValue());
    }
  }

  // // detail panel
  // toggleShowingLabels() {
  //   if (this.currentPanel.getValue() == 'labels') {
  //     this.closeDetailPanel()
  //   } else {
  //     this.showLabels()
  //   }
  // }

  // showMaterials() {
  //   console.warn("showMaterials")
  //   this.currentPanel.next('materials')
  // }

  // showLabels()        { this.currentPanel.next('labels') }

  // closeDetailPanel()  { this.currentPanel.next(false) }
  // // end detail panel

  async filterItems() {
    if (!this.plugin) return;
    var searchQuery = this.searchService.query();

    var filteredItems: LibraryItem[] = this.allTheItems();

    if (
      (searchQuery.query == null || searchQuery.query.length == 0) &&
      searchQuery.labels.length == 0
    ) {
      this.items.next(filteredItems);
      return;
    }

    if (searchQuery.query && searchQuery.query.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        var searchString =
          (item.name || '') +
          (item.localName || '') +
          item.labels.map((l) => l.name).join();

        searchString = searchString.replace(/\s+/g, '');
        return searchString
          .toLowerCase()
          .includes(searchQuery.query.toLowerCase());
      });
    }

    filteredItems = filteredItems.filter((item) =>
      searchQuery.labels.every(
        (element) => item.labelIDs.indexOf(element.id) > -1
      )
    );

    this.items.next(filteredItems);
  }
}
