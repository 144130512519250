import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { mqRxStompConfig } from 'src/app/modules/rmq/mq-rx-stomp.config';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { MQService } from './mq.service';

@NgModule({
  declarations: [
    ConnectionStatusComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ConnectionStatusComponent,
  ],
  providers: [
    MQService,
    {
      provide: InjectableRxStompConfig,
      useValue: mqRxStompConfig,
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    }
  ]
})
export class RabbitMQModule { }
