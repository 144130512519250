<h1 mat-dialog-title>{{ "CABN8.MATERIAL_PRESETS_TOOLTIP" | translate }}</h1>
<div mat-dialog-content>
  <p>
    {{ "CABN8.MATERIALS_NOT_IN_SYNC" | translate }}
  </p>
</div>
<div mat-dialog-actions class="column">
  <div class="btn-primary" [mat-dialog-close]="'export'">
    <div class="icon" [inlineSVG]="'icon/sketchup.svg'"></div>
    {{ "CABN8.EXPORT_MATERIALS_BUTTON" | translate }}
  </div>
  <div class="spacer"></div>
  <div class="btn-primary" [mat-dialog-close]="'import'">
    <div class="icon" [inlineSVG]="'icon/sketchup.svg'"></div>
    {{ "CABN8.IMPORT_MATERIALS_BUTTON" | translate }}
  </div>
  <div class="spacer"></div>
  <div class="btn-primary" (click)="onNoClick()">
    {{ "GLOBAL.CANCEL" | translate }}
  </div>
</div>
