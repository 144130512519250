<ly-img-cropper
  [config]="myConfig"
  [(scale)]="scale"
  (ready)="onReady($event)"
  (minScale)="minScale = $event"
  (cleaned)="ready = false"
  (cropped)="onCropped($event)"
  (loaded)="onLoaded($event)"
  (error)="onError($event)"
>
  <span>{{ "IMAGE_UPLOADER.DRAG_DROP" | translate }}</span>
</ly-img-cropper>
<div class="row">
  <div class="btn-primary grey" (click)="onCancel()">
    {{ "GLOBAL.CANCEL" | translate }}
  </div>
  <div class="spacer"></div>
  <ng-template [ngIf]="ready" [ngIfElse]="selectFile">
    <ly-slider
      [thumbVisible]="false"
      [min]="minScale"
      [max]="1"
      [color]="'#0185eb'"
      [(ngModel)]="scale"
      (input)="setScale($event.value)"
      [step]="0.000001"
    ></ly-slider>
    <div class="spacer"></div>
    <div class="btn-primary" (click)="onDone()">
      {{ "GLOBAL.DONE" | translate }}
    </div>
  </ng-template>
  <ng-template #selectFile>
    <div class="btn-primary" (click)="_fileInput.click()">
      {{ "IMAGE_UPLOADER.SELECT_FILE" | translate }}
    </div>
  </ng-template>
</div>
<input
  #_fileInput
  type="file"
  (change)="cropper.selectInputEvent($event)"
  accept="image/*"
  hidden
/>
