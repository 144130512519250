<div class="center">
  <div *ngIf="sketchUp.isSketchup" class="btn-primary" (click)="openFabric8()">
    <div class="icon" [inlineSVG]="'fabric8/DRILL.svg'"></div>
    {{ "FABRIC8.OPEN_FABRIC8" | translate }}
  </div>

  <div
    *ngIf="sketchUp.notSketchup"
    class="btn-primary disabled"
    matTooltipPosition="above"
    matTooltip="{{ 'FABRIC8.WEB_SKETCHUP_MESSAGE' | translate }}"
  ></div>
</div>
